import { useFormik } from "formik";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { editAdvancePaymentValidationSchema } from "../../../validations/Validations";
import { Alert, Snackbar } from "@mui/material";


export default function EditAdvancePayment() {
    const AdvancePaymentInfo = useSelector((state: any) => state.AdvancePaymentInfo.upData);
    const employee = useSelector((state: any) => state.employeeInfo.value);
    const branchInfo = useSelector((state: any) => state.branchInfo.value);
    const auth = useSelector((state: any) => state.authUserInfo.value);
    const localAdvancePaymentInfo = JSON.parse(localStorage.getItem("advancepaymentinfo"));
    console.log("adv", AdvancePaymentInfo, employee, branchInfo, auth);

    const [status, setStatus] = useState();
    const [open, setOpen] = useState(false);
    const [msg, setMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const formik = useFormik({
        initialValues: {
            branch_id: (AdvancePaymentInfo?.branch_id ?? localAdvancePaymentInfo?.branch_id) || "",
            employee_id: (AdvancePaymentInfo?.employee_id ?? localAdvancePaymentInfo?.employee_id) || "",
            payment_date: (AdvancePaymentInfo?.payment_date.substring(0, 10) ?? localAdvancePaymentInfo?.payment_date) || "",
            amount: (AdvancePaymentInfo?.amount ?? localAdvancePaymentInfo?.amount) || "",
            reason: (AdvancePaymentInfo?.reason ?? localAdvancePaymentInfo?.reason) || "",
            payment_mode: (AdvancePaymentInfo?.payment_mode ?? localAdvancePaymentInfo?.payment_mode) || "Cash",
        },
        validationSchema: editAdvancePaymentValidationSchema,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const ob = {
                    employee_id: values.employee_id,
                    branch_id: values.branch_id,
                    payment_date: values.payment_date,
                    amount: values.amount,
                    reason: values.reason,
                    payment_mode: values.payment_mode,
                    // created_by: AdvancePaymentInfo.created_by,
                    updated_by: auth.uid,
                };
                console.log(ob);
                const url = `${urls.ADVANCED_PAYMENT_UPDATE}${AdvancePaymentInfo?.id ?? localAdvancePaymentInfo?.id}`;
                console.log(url)
                const resp = await AuthApiService.PutApiCall(url, ob, auth.token)
                console.log(resp);
                if (resp.status === 200) {
                    setMsg(resp.data.msg);
                    setStatus(resp.data.status)
                    handleClickOpen();
                    if (auth.roleValue == "SCM") {
                        if (resp.data.status == 201) {
                            formik.resetForm()
                            setTimeout(() => {
                                navigate('/saloonChain/advancePayment')
                                localStorage.removeItem("advancepaymentinfo")
                            }, 2000)
                        } else {
                            setTimeout(() => {
                                navigate('/saloonChain/editAdvancePayment')
                            }, 2000)
                        }
                    } else if (auth.roleValue == "BRM") {
                        if (resp.data.status == 201) {
                            formik.resetForm()
                            setTimeout(() => {
                                navigate('/branchManager/managerAdvancePayment')
                                localStorage.removeItem("advancepaymentinfo")
                            }, 2000)
                        } else {
                            setTimeout(() => {
                                navigate('/branchManager/editAdvancePayment')
                            }, 2000)
                        }
                    }

                } else {
                    if (resp.data && resp.data.err) {
                        setMsg(resp.data.err);
                        setStatus(resp.data.status)

                    } else {
                        setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
                        setStatus(resp.data.status)
                    }
                }
            } catch (error: any) {
                console.error("Error:", error);
                setMsg("Network Error !");
                setStatus(error.response.status);
                handleClickOpen()
            }
            finally {
                setTimeout(() => {
                    // setMsg("");
                    setLoading(false);
                }, 1000)
            }
        }
    }
    )
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div className="container-fluid p-0">
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert>
            </Snackbar>
            <div className="container">

                <div className="row pr-xl-5 pl-xl-5">
                    <div className="col-lg-10 col-md-8 col-sm-8 d-flex justify-content-center">
                        <div className="text-center">
                            <h4>Update Employee Advance</h4>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4 mt-2 d-flex pr-2 justify-content-end">
                        <Link to="/saloonChain/advancePayment">
                            <span>
                                <button className=" btn btn-sm btn-purple">All Payment List</button>
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="p-1 px-xl-2 py-xl-2 justify-content-center">
                <div className="container">
                    {/* <b className={status ? "text-success" : "text-danger"}>{msg}</b> */}
                    <div className="row justify-content-center mt-3">
                        <div className="col-lg-12">
                            <form
                                onFocus={() => setMsg("")}
                                onSubmit={formik.handleSubmit}>
                                <div className="mb-1 mb-xl-3 row">
                                    <label className="col-sm-3 col-form-label" htmlFor="branch_id">
                                        Branch Name<span style={{ color: "red" }}>*</span> :
                                    </label>
                                    <div className="col-sm-7">
                                        <select
                                            id="branch_id"
                                            name="branch_id"
                                            className={`form-control shadow-sm ${formik.touched.branch_id && formik.errors.branch_id ? 'is-invalid' : ''}`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.branch_id}
                                            disabled
                                        >
                                            <option value="">Select Branch Name</option>
                                            {branchInfo?.map((data: any) => { return <option value={data.id}>{data.branch_name}</option> })}
                                        </select>
                                        <div className="invalid-feedback">{formik.touched.branch_id && typeof formik.errors.branch_id === 'string' ? (formik.errors.branch_id) : null}</div>
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label className="col-sm-3 col-form-label" htmlFor="employee_id">
                                        Employee Name<span style={{ color: "red" }}>*</span> :
                                    </label>
                                    <div className="col-sm-7">
                                        <select
                                            id="employee_id"
                                            name="employee_id"
                                            className={`form-control shadow-sm ${formik.touched.employee_id && formik.errors.employee_id ? 'is-invalid' : ''}`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.employee_id}
                                            disabled
                                            filterOption={(option, inputValue) =>
                                                option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                            }
                                        ><option value="">Select Employee Name</option>
                                            {employee?.map((data: any) => { return <option value={data.id}>{data.first_name + " " + data.last_name}</option> })}
                                        </select>
                                        <div className="invalid-feedback">{formik.touched.employee_id && typeof formik.errors.employee_id === 'string' ? (formik.errors.employee_id) : null}</div>
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label className="col-sm-3 col-form-label" htmlFor="payment_date">
                                        Payment Date<span style={{ color: "red" }}>*</span> :
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="date"
                                            placeholder="Enter Start Date"
                                            id="payment_date"
                                            name="payment_date"
                                            className={`form-control shadow-sm ${formik.touched.payment_date && formik.errors.payment_date ? 'is-invalid' : ''}`}
                                            min={employee.filter((data: any) => data.id == formik.values.employee_id).map((data: any) => data.date_of_joining.substring(0, 10))}
                                            max={employee.filter((data: any) => data.id == formik.values.employee_id).map((data: any) => data.date_of_relieving?.substring(0, 10) == null ? undefined : data.date_of_relieving?.substring(0, 10))}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.payment_date}
                                        />
                                        <div className="invalid-feedback">{formik.touched.payment_date && typeof formik.errors.payment_date === 'string' ? (formik.errors.payment_date) : null}</div>
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label className="col-sm-3 col-form-label" htmlFor="amount">
                                        Amount<span style={{ color: "red" }}>*</span> :
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="number"
                                            placeholder="Enter Amount"
                                            id="amount"
                                            name="amount"
                                            min="0"
                                            className={`form-control shadow-sm ${formik.touched.amount && formik.errors.amount ? 'is-invalid' : ''}`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.amount}
                                        />
                                        <div className="invalid-feedback">{formik.touched.amount && typeof formik.errors.amount === 'string' ? (formik.errors.amount) : null}</div>
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label className="col-sm-3 col-form-label" htmlFor="reason">
                                        Reason :
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            placeholder="Enter Reason"
                                            id="reason"
                                            name="reason"
                                            className={`form-control shadow-sm ${formik.touched.reason && formik.errors.reason ? 'is-invalid' : ''}`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.reason}
                                        />
                                        {/* <div className="invalid-feedback">{formik.touched.reason && typeof formik.errors.reason === 'string' ? (formik.errors.reason) : null}</div> */}
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label className="col-sm-4 col-form-label">
                                        Payment Mode<span style={{ color: "red" }}>*</span> :
                                    </label>

                                    <div className="form-check col-sm-3 col-3">
                                        <input
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            // value={formik.values.payment_mode}
                                            className="form-check-input"
                                            type="radio"
                                            name="payment_mode"
                                            id="flexRadioDefault1"
                                            value="Cash"
                                            checked={formik.values.payment_mode === "Cash"}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="flexRadioDefault1"
                                        >
                                            Cash
                                        </label>
                                    </div>
                                    <div className="form-check col-sm-3 col-3">
                                        <input
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            // value={formik.values.payment_mode}
                                            className="form-check-input"
                                            type="radio"
                                            name="payment_mode"
                                            id="flexRadioDefault2"
                                            value="Online"
                                            checked={formik.values.payment_mode === "Online"}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="flexRadioDefault2"
                                        >
                                            Online
                                        </label>
                                    </div>
                                </div>
                                <div className="mt-3 mb-xl-3 row text-center">
                                    <div className="col-lg-5"></div>
                                    <div className="col-lg-2">
                                        <button type="submit" className="btn btn-md form-control btn-purple" disabled={loading ? true : false}>
                                            {loading ? "Updating..." : "Update"}
                                        </button>
                                    </div>
                                    <div className="col-lg-5"></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
