import { faArrowDown, faArrowUp, faPlus, faSearch, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { listBranchServicesReducer } from "../../../reduxData/branchServicesSlice";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { Alert, Snackbar } from "@mui/material";
import Select from "react-select"
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { listAdvancePaymentReducer } from "../../../reduxData/advancePaymentSlice";
import { getCustomerTransactionReducer, listCustomerBillReducer, listCustomerDuesReducer, listCustomerReducer } from "../../../reduxData/customerSlice";

export default function ManagerCustomerDues() {
  const _ = require("lodash")
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const branchServicesInfo = useSelector((state: any) => state.branchServicesInfo.value);
  const customerInfo = useSelector((state: any) => state.customerInfo.dues);
  const branchInfo = useSelector((state: any) => state.branchInfo.value);
  const auth = useSelector((state: any) => state.authUserInfo.value);
  console.log(customerInfo)
  const [loading, setLoading] = useState(false);
  const [sort, setsort] = useState(false);
  const [msg, setMsg] = useState("");
  const [status, setStatus] = useState();
  const [open, setOpen] = useState(false);
  const [val, setval] = useState();
  const dispatch = useDispatch();
  const [showDetails, setShowDetails] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(false);
  const navigate = useNavigate();
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const currentDate = new Date().toISOString().substring(0, 10);

  const [searchInput, setSearchInput] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [month, setMonth] = useState(`${currentYear}-0${currentMonth}`);


  const sortByName = () => {
    setsort(!sort);
    const sortedProducts = _.orderBy(customerInfo, [(item: any) => item?.data?.name.toLowerCase()], sort ? 'desc' : 'asc');
    dispatch(listCustomerReducer(sortedProducts));
  }
  const totalRecords = customerInfo?.pagination?.totalRecords
  console.log("total records", totalRecords)

  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPageButtons = 3;

    if (totalPages <= maxPageButtons) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const leftEllipsis = currentPage > 2;
      const rightEllipsis = currentPage < totalPages - 1;

      if (leftEllipsis) {
        pageNumbers.push(1, '...');
      }

      const start = Math.max(1, currentPage - 1);
      const end = Math.min(totalPages, currentPage + 1);

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }

      if (rightEllipsis) {
        pageNumbers.push('...', totalPages);
      }
    }

    return pageNumbers;
  };

  const customersDuesList = async (page: number, itemsPerPage: number, searchInput: any) => {
    let query: any = {};
    console.log((/^\d+$/).test(searchInput))
    if ((/^\d+$/).test(searchInput)) {
      query.mobileDigits = searchInput;
    }
    else {
      query.nameChars = searchInput;
    }
    try {
      setLoading(true)
      const URL = `${urls.CUSTOMER_DUES}/${page}/${itemsPerPage}`;
      const res = await AuthApiService.SearchApiCallWithQuery(URL, query, auth.token)
      console.log("customer", res, URL)
      if (res.status) {
        dispatch(listCustomerDuesReducer(res.data));
      }
    } catch (error) {
      setMsg("Network Error !");
    } finally {
      setLoading(false)
    }

  };


  //   const onSearch = async (searchInput: any, branch: any, page: any, itemsPerPage: any) => {
  //     let url = `${urls.CUSTOMER_LIST_BY_SEARCH}/${page}/${itemsPerPage}`;
  //     let queryParams = {};

  //     if (queryParams) {
  //       if (branch && branch.value && branch.value !== "All") {
  //         queryParams.branch_id = branch.value;
  //       }

  //       if (searchInput) {

  //         if (/^\d+$/.test(searchInput)) {
  //           queryParams.mobileDigits = searchInput;
  //         } else {
  //           queryParams.nameChars = searchInput;
  //         }
  //       }

  //       try {
  //         setLoading(true);

  //         const queryString = new URLSearchParams(queryParams).toString();

  //         if (queryString) {
  //           url += `?${queryString}`;
  //         }

  //         const res = await AuthApiService.SearchApiCallWithQuery(url, {}, auth.token);
  //         console.log(url, res.data.data)

  //         if (res.status) {
  //           setLoading(false);
  //           dispatch(listCustomerReducer(res.data));
  //         }
  //       } catch (error) {
  //         console.error("Error occurred during search:", error);
  //         setLoading(false);
  //         setMsg("Network Error !");
  //       }
  //     } else {
  //       await customersDuesList(currentPage, itemsPerPage)
  //     }
  //   };




  useEffect(() => {
    customersDuesList(currentPage, itemsPerPage, searchInput);
  }, [currentPage]);



  const handleChange = async (page: number) => {
    setCurrentPage(page);
    // if (searchInput != '') {
    //     await onSearch(searchInput, page, itemsPerPage)
    // }
    // else {
    await customersDuesList(page, itemsPerPage, searchInput);
    // }
  };


  const loadingSpinner = () => {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  const toggleRowExpansion = (index: any) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const renderRowExpansionContent = (data: any) => {
    return (<><th></th>
      {<tr ><td><strong>Service:</strong> {data.service_info.map((data2: any) => <>{data2.service_type ?? data2.product_name}<span>({data2.quantity ?? data2.quantity})</span><br /></>)}</td></tr>}
      {<tr><td><strong>Amount({auth.currency}):</strong> {data.service_info.map((data2: any) => <>{data2.amount}<br /></>)}</td></tr>}
      {/* {<tr><td><strong>Paid:</strong> {data.paymentInfo[0].amount_paid}</td></tr>} */}
    </>
    );
  };

  const handleWindowResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleViewHistory = (data: any) => {
    navigate("/saloonChain/customerHistory");
    dispatch(getCustomerTransactionReducer(data));
  }

  const ShowDetails = (data: any) => {
    console.log(showDetails, data)
    // let temp = [...showDetails];
    let temp = showDetails.includes(data) ? showDetails.filter(d => d != data) : [...showDetails, data]
    console.log(temp)
    setShowDetails(temp);
    setSelectedCustomer(true);
  }

  const handleShowBill = async(data) => {
    console.log(data)
    const url = `${urls.TRANSACTION_GET}${data}`
    const res = await AuthApiService.GetApiCall(url,auth.token);
    console.log(res,url)
    if(res.status == 200 && res.data.status == 200){
        const ob = res.data.data;
        dispatch(listCustomerBillReducer(ob)); 
        localStorage.setItem("billinfo", JSON.stringify(res.data.data)) 
        navigate("/branchManager/managerCustomerBill")
    }
}

  return <>
    {/* {addModal()} */}
    <div className="container-fluid pt-2">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert>
      </Snackbar>
      <div className="container">
        <div className="row">
          <div className="col-lg-10 col-md-10 col-sm-10">
            <div className="text-center">
              <h4>Customer's Dues</h4>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2 mt-2 d-flex justify-content-end">
            <Link to="/branchManager/managerCustomerList">
              <button className="btn btn-sm btn-purple">All Customers</button>
            </Link>
          </div>
          {/* <div className="col-lg-2 col-md-2 col-sm-2 mt-2 d-flex justify-content-end"> */}
          {/* <Link to="/branchManager/addCustomer"> */}
          {/* <button className="btn btn-sm btn-purple" onClick={handleAddModelShow}><FontAwesomeIcon icon={faPlus} />&nbsp;New Customer</button> */}
          {/* </Link> */}
          {/* </div> */}
          <div className="row mt-2 mt-xl-4 pb-4 p-1">
            {/* <div className="col-lg-2 col-md-2 col-sm-12 ">
              <label>From Date:</label>

              <div className="input-group input-group-md">
                <input
                  type="date"
                  className="form-control shadow-sm"
                  placeholder="Search..."
                  value={currentDate}
                /></div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-12 ">
              <label>To Date:</label>

              <div className="input-group input-group-md">
                <input
                  type="date"
                  className="form-control shadow-sm"
                  placeholder="Search..."
                  value={currentDate}
                /></div>
            </div> */}
            <div className="col-lg-3 col-md-3 col-sm-12 ">
              <label>Search:</label>

              <div className="input-group input-group-md">
                <input
                  type="text"
                  className="form-control shadow-sm"
                  placeholder="Enter Name or Mobile"
                  value={searchInput}
                  onChange={(e) => {
                    setCurrentPage(1);
                    setSearchInput(e.target.value);
                    customersDuesList(1, itemsPerPage, e.target.value)
                    // onSearch(e.target.value, val, currentPage, itemsPerPage); // Trigger search on input change
                  }}
                />
              </div>
            </div>
          </div>
        </div>

      </div>
      {
        loading || customerInfo?.data?.length <= 0 ? (
          <div className="mt-4">
            {loading ? (
              // Display loading spinner
              <div className="d-flex justify-content-center align-items-center">
                {loadingSpinner()}
              </div>
            ) : (
              // Display custom message for no records
              <h5>No Customer found.</h5>
            )}
          </div>
        ) :
          <div className="mt-2 mt-xl-4 mt-sm-2 justify-content-center">
            <div className={`table-container table-responsive`}>
              <table className="table table-striped table-hover border-light-subtle" id="report-table">
                <thead>
                  <tr>
                    <th>Total Amount({auth.currency})</th>
                    <th>Total Paid({auth.currency})</th>
                    <th>Total Due({auth.currency})</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    <tr>
                      <td>{customerInfo?.due_report?.total_amount}</td>
                      <td>{customerInfo?.due_report?.total_paid}</td>
                      <td>{customerInfo?.due_report?.total_due}</td>
                    </tr>

                  }
                </tbody>
              </table>
            </div>
            <div className={`table-container table-responsive`}>
              <table className="table table-striped table-hover border-light-subtle" id={isMobile ? '' : 'report-table'}>
                <thead>
                  <tr>
                    <th>Date</th>
                    {<th>{isMobile ? "Cust. Name" : "Customer Name"}</th>}
                    {!isMobile && <th>Service</th>}
                    {!isMobile && <th>Qty</th>}
                    {!isMobile && <th>Amount({auth.currency})</th>}
                    {/* {!isMobile &&<th></th>} */}
                    {<th>Payment({auth.currency})</th>}
                    {isMobile && <th></th>}
                  </tr>
                </thead>
                <tbody>
                  {
                    customerInfo?.data?.map((data: any, index: any) => {
                      const isExpanded = expandedRowIndex === index;
                      return (<React.Fragment>
                        <tr key={data.id}>

                          <td>{new Date(data.transaction_date).toLocaleString('en-GB', { dateStyle: "short" })}<br /><button className="btn btn-link" onClick={() => {handleShowBill(data.id)}}>Bill</button></td>
                          {/* {<td>{<span style={{ cursor: "pointer" }} onClick={() => { ShowDetails(data?.customer_info?.id) }}>{selectedCustomer && showDetails.includes(data?.customer_info?.id) ? <>{data?.customer_info?.name}<br />{data?.customer_info?.mobile}</> : <p style={{ textDecoration: "underline" }} className="link-primary text-underline">{data?.customer_info?.name}</p>}</span>}</td>} */}
                          {<td>{<span>{data?.customer_info?.name}<br />{data?.customer_info?.mobile}</span>}</td>}
                          {!isMobile && <td>{data.service_info.map((data2: any) => <>{data2.service_type ?? data2.product_name}<br /></>)}</td>}
                          {!isMobile && <td>{data.service_info.map((data2: any) => <>{data2.quantity}<br /></>)}</td>}

                          {!isMobile && <td>{data.service_info.map((data2: any) => <>{data2.amount}<br /></>)}</td>}
                          <td className={`${isMobile ? 'text-wrap' : 'text-nowrap'}`}><b>Total :</b>{data.amount}<br /><b>Paid :</b>{data.paymentInfo[0].amount_paid}<br /><b>Due :</b>{data.dues ?? "0"}<br /><b>Mode :</b>{data.paymentInfo[0].payment_mode}</td>
                          {/* <td>{data.dues}</td> */}
                          {isMobile && (
                            <td>
                              {!isExpanded ? (
                                <button
                                  className="btn btn-link"
                                  onClick={() => toggleRowExpansion(index)}
                                >
                                  <FontAwesomeIcon
                                    icon={faArrowDown}
                                    className="fontIcon"
                                    size="sm"
                                  />
                                </button>
                              ) : (
                                <button
                                  className="btn btn-link"
                                  onClick={() => toggleRowExpansion(index)}
                                >
                                  <FontAwesomeIcon
                                    icon={faArrowUp}
                                    className="fontIcon"
                                    size="sm"
                                  />
                                </button>
                              )}</td>
                          )}
                        </tr>
                        {isExpanded && isMobile && (
                          <tr>
                            <td colSpan="4">{renderRowExpansionContent(data)}</td>
                          </tr>
                        )}
                      </React.Fragment>);
                    })
                  }
                </tbody>
              </table>
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button
                      className="page-link "
                      onClick={() => handleChange(currentPage - 1)}
                      disabled={currentPage === 1 || loading}
                    >
                      Previous
                    </button>
                  </li>
                  {generatePageNumbers().map((page, index) => (
                    <li
                      key={index}
                      className={`page-item ${page === currentPage ? 'active' : ''}`}
                    >
                      {page === '...' ? (
                        <span className="page-link">...</span>
                      ) : (
                        <button
                          className="page-link btn-sm"
                          onClick={() => handleChange(page)}
                          disabled={loading}
                        >
                          {page}
                        </button>
                      )}
                    </li>
                  ))}
                  <li
                    className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
                  >
                    <button
                      className="page-link btn-sm"
                      onClick={() => handleChange(currentPage + 1)}
                      disabled={loading || currentPage === totalPages}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
      }
    </div >
  </>
}