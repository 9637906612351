import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp, faEdit, faPen, faPlus, faSearch, faSort, faTrash, } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { incentiveEmployeeValidationSchema } from "../../../validations/Validations";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { addIncentiveEmployeeReducer, listIncentiveEmployeeReducer, updateIncentiveEmployee, updateIncentiveEmployeeReducer } from "../../../reduxData/incentiveEmployeeSlice";
import Select from "react-select";
import { Alert, Snackbar } from "@mui/material";
import { Button, Modal } from "react-bootstrap";
import { listEmployeeLeaveReducer } from "../../../reduxData/leaveSlice";

export default function IncentiveEmployeeList() {
    const [expandedRowIndex, setExpandedRowIndex] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const _ = require("lodash");
    const [msg, setMsg] = useState("");
    const [status, setStatus] = useState()
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [upLoading, setUpLoading] = useState(false);
    const [recordLoading, setRecordLoading] = useState<string | null>(null);
    const [bVal, setBVal] = useState();
    const [val, setval] = useState();
    const [rulesval, setrulesval]: any = useState([]);
    const [ro, setro] = useState();
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [searchInput, setSearchInput] = useState();
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [checkAll, setCheckAll] = useState(undefined);

    const incentiveEmployee = useSelector((state: any) => state.incentiveEmployeeInfo.value)
    const branchInfo = useSelector((state: any) => state.branchInfo.value);

    const admin = useSelector((state: any) => state.authUserInfo.value);
    const incentiveRules = useSelector((state: any) => state.incentiveRuleInfo.value)
    const employeeList = useSelector((state: any) => state.employeeInfo.value);
    const UpincentiveEmployee = useSelector((state: any) => state.incentiveEmployeeInfo.upData)
    const [sort, setsort] = useState(false);
    const [statusLoading, setStatusLoading] = useState<string | null | boolean>(null);



    const toggleRowExpansion = (index: any) => {
        setExpandedRowIndex(expandedRowIndex === index ? null : index);
    };


    const renderRowExpansionContent = (emp: any) => {
        return (<><th></th>
            <tr> <td className="text-wrap">
                <b>Incentive Rule:&nbsp;</b><span>{emp.incentiveRuleInfo?.description}</span>

            </td></tr>
            <tr> <td className="text-wrap">
                <b>Branch:&nbsp;</b><span>{emp.employeeInfo?.branch_info?.branch_name}</span>

            </td></tr></>

        );
    };

    const formik = useFormik({
        initialValues: {
            employee_id: "",
            incentive_rule_id: [],
            isActive: "",
            created_by: "",
            updated_by: "",
        },
        validationSchema: incentiveEmployeeValidationSchema,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const ob: any = {
                    employee_id: values.employee_id,
                    incentive_rule_id: _.uniq(values.incentive_rule_id),
                    isActive: true,
                    created_by: admin.uid,
                    updated_by: admin.uid,
                };
                const resp = await AuthApiService.SaveApiCall(urls.INCENTIVE_EMPLOYEE_SAVE, ob, admin.token);
                // formik.handleReset
                // formik.setValues({
                //     employee_id: "",
                //     incentive_rule_id: [],
                //     isActive: "",
                //     created_by: "",
                //     updated_by: "",
                // });
                setrulesval([]);
                if (resp.status === 200) {
                    setMsg(resp.data.msg);
                    handleClickOpen();
                    setStatus(resp.data.status)
                    incentiveEmployeeList();
                    handleAddModelClose();
                } else {
                    if (resp.data && resp.data.err) {
                        setMsg(resp.data.err);
                        setStatus(resp.data.status)

                    } else {
                        setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
                        setStatus(resp.data.status)
                    }
                }
            } catch (error: any) {
                setMsg("Network Error !");
                setStatus(error.response.status);
                handleClickOpen()
            }
            finally {
                setLoading(false);
                setTimeout(() => {
                    //setAddMsg('');
                    // formik.resetForm();
                    // window.location.reload()

                }, 1000);
            }
        },
    });

    const handleEdit = (emp: any) => {
        dispatch(updateIncentiveEmployeeReducer(emp));

        handleUpdateModalShow();

        formik.setValues({
            employee_id: emp.employee_id,
            incentive_rule_id: emp.incentive_rule_id,
            isActive: emp.isActive.toString(),
            created_by: emp.created_by,
            updated_by: emp.updated_by,
        });
        const ra = incentiveEmployee.filter((data2: any) => data2.employee_id == emp.employee_id).map((el: any) => el.incentive_rule_id)
        const rb = rulesOptions.filter((data: any) => !ra.includes(data.value));
        setro(rb);
    };

    const incentiveEmployeeList = async () => {
        try {
            const response = await AuthApiService.GetApiCall(urls.INCENTIVE_EMPLOYEE_LIST, admin.token);
            if (response.status) {
                dispatch(listIncentiveEmployeeReducer(_.orderBy(response.data.data, (item: any) => item.employeeInfo?.first_name?.toLowerCase(), ['asc'])))
            } else {
                setMsg(response.data.msg);
            }
        } catch (error) {
            const errorMessage: string = (error as any)?.response?.data?.msg || 'An error occurred. Please try again.';
            setMsg(errorMessage);
        }
    }

    // useEffect(()=>{
    //     incentiveEmployeeList()
    // },[])

    const [inEmpId, setInEmpId] = useState<number | null>(null);

    useEffect(() => {
        if (UpincentiveEmployee && UpincentiveEmployee.id) {
            setInEmpId(UpincentiveEmployee.id);
        }

    }, [UpincentiveEmployee]);

    const handleWindowResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const handleEditSubmit = async (event: any) => {
        event.preventDefault()
        try {
            setUpLoading(true);

            const updatedData = {
                employee_id: formik.values.employee_id,
                incentive_rule_id: formik.values.incentive_rule_id,
                isActive: formik.values.isActive === "true", // Convert string to boolean
                created_by: UpincentiveEmployee.created_by,
                updated_by: admin.uid, // Assuming you want to update the "updated_by" field
            };

            // const up = {
            //     ...UpincentiveEmployee,
            //     ...updatedData
            // }
            const upURL = urls.INCENTIVE_EMPLOYEE_UPDATE + inEmpId;
            const resp = await AuthApiService.PutApiCall(upURL, updatedData, admin.token);

            if (resp.status) {
                setMsg(resp.data.msg);
                handleClickOpen();
                setStatus(resp.data.status)
                incentiveEmployeeList();
                handleUpdateModalClose();


            } else {

                setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
                setStatus(resp.data.status)
            }
        } catch (error: any) {

            setMsg("Network Error !");
            setStatus(error.response.status);
            handleClickOpen()
        } finally {
            setUpLoading(false);
            setTimeout(() => {
                //setUpMsg('');
                // window.location.reload()

            }, 1000);
        }
    };

    const branchOption = branchInfo && Array.isArray(branchInfo) ? [{ value: "All", label: "All" }, ...branchInfo.map((item: any) => ({
        value: item.id,
        label: item.branch_name,
    }))] : [];

    const branchOptions = branchInfo.map((item: any) => ({
        value: item.id,
        label: item.branch_name,
    }));

    const employeeOptions = bVal != undefined ? employeeList?.filter((e: any) => e.branch_info.id === bVal?.value && e.is_active).map((item: any) => ({
        value: item.id,
        label: item.first_name + " " + item.last_name
    }))
        :
        employeeList?.filter((e: any) => e.is_active).map((item: any) => ({
            value: item.id,
            label: item.first_name + " " + item.last_name
        }))
    const rulesOptions = incentiveRules.map((item: any) => ({
        value: item.id,
        label: item.description
    }))

    const employeeOption = val != undefined && val?.label != "All" ? [{ value: "All", label: "All" }, ...employeeList?.filter((e: any) => e.branch_info.id === val?.value && e.is_active).map((item: any) => ({
        value: item.id,
        label: item.first_name + " " + item.last_name
    }))]
        :
        [{ value: "All", label: "All" }, ...employeeList?.filter((e: any) => e.is_active).map((item: any) => ({
            value: item.id,
            label: item.first_name + " " + item.last_name
        }))]



    useEffect(() => {
        if (branchInfo.length === 1) {
            const singleBranch = branchInfo[0];
            const singleBranchOption = { value: singleBranch.id, label: singleBranch.branch_name };
            setSelectedBranch(singleBranchOption);
            //   onBranchSearch(singleBranchOption);
        }
    }, [branchInfo]);

    const Delete = async (data: any) => {
        const al = confirm("Are you sure you want to delete");
        try {
            if (al) {
                setRecordLoading(data.id);
                const url = urls.INCENTIVE_EMPLOYEE_DELETE + data.id
                const resp = await AuthApiService.DelApiCall(url, admin.token);
                if (resp.status) {
                    const newlist: any = incentiveEmployee.filter((d: any) => { return d.id !== data.id })
                    dispatch(listIncentiveEmployeeReducer(newlist))
                    setMsg(resp.data.msg)
                    handleClickOpen();
                    setStatus(resp.data.status)
                }
            }
            else { return }
        } catch (error: any) {
            setMsg("Network Error !");
            setStatus(error.response.status);
            handleClickOpen()
        } finally {
            setRecordLoading(null);
            setTimeout(() => {
                // setMsg('');
            }, 5000);
        }
    }

    const sortByName = () => {
        setsort(!sort);
        if (sort) {
            const nw = _.orderBy(incentiveEmployee, (item: any) => item.employeeInfo.first_name, ['asc'])
            dispatch(listIncentiveEmployeeReducer(nw))
        }
        else {
            const nw = _.orderBy(incentiveEmployee, (item: any) => item.employeeInfo.first_name, ['desc'])
            dispatch(listIncentiveEmployeeReducer(nw))
        }
    }

    const handleActive = async (id: any) => {
        const url = urls.INCENTIVE_EMPLOYEE_UPDATE + id;
        const obj = { isActive: true }
        const res = await AuthApiService.PutApiCall(url, obj, admin.token);
    }

    const statusUpdate = async (emp: any) => {
        try {
            setStatusLoading(emp.id);

            const updatedList = incentiveEmployee.map((e: any) =>
                e.id === emp.id ? { ...e, isActive: !e.isActive } : e
            );

            // Optimistically update the UI
            // dispatch(listIncentiveEmployeeReducer(updatedList))

            const confirmMessage = emp.isActive
                ? 'Are you sure you want to deactivate the Incentive employee?'
                : 'Are you sure you want to activate the Incentive employee?';

            const userConfirmed = window.confirm(confirmMessage);

            if (!userConfirmed) {
                // If the user cancels the action, revert the UI update
                // setsortedlist(sortedlist);
                return;
            }

            const ob = {
                isActive: !emp.isActive,
                created_by: admin.uid,
                updated_by: admin.uid
            };

            const UPURL = urls.INCENTIVE_EMPLOYEE_UPDATE + emp.id;
            const resp = await AuthApiService.PutApiCall(UPURL, ob, admin.token);

            if (resp.status === 200) {
                setMsg(resp.data.msg);
                setStatus(resp.data.status)
                dispatch(listIncentiveEmployeeReducer(updatedList));
                // window.location.reload()
            } else {
                // If there's an error, revert the UI update
                setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
                // setStatus(false);
                setStatus(resp.data.status)

                // setsortedlist(sortedlist);
            }
        } catch (error: any) {
            setMsg('Network Error!');
            // setStatus(false);
            setStatus(error.data.status)

        } finally {
            setStatusLoading(null);
            setTimeout(() => {
                // setMsg("");
                // window.location.reload()
            }, 1000);
        }
    };

    const loadingSpinner = () => {
        return <div className="d-flex justify-content-center">
            <div className="spinner-border" >
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onSearch = async (data: any, data2: any) => {
        // console.log("data" + data, "data2" + data2, "searchInput" + searchInput, "val" + val);
        if (data != null || data2 != null) {
            setLoading(true)
            const url = `${urls.INCENTIVE_EMPLOYEE_SEARCH_BY_BRANCH_OR_EMPLOYEE}`;
            const obj = {
                branchId: data?.label == "All" ? "" : data?.value,
                employeeId: data2?.label == "All" ? "" : data2?.value
            }
            const res = await AuthApiService.SearchApiCallWithQuery(url, obj, admin.token);
            if (res.status) {
                dispatch(listIncentiveEmployeeReducer(res.data.data))
                setLoading(false)
            }
        }
        else {
            // dispatch(listIncentiveEmployeeReducer(oldList))
            await incentiveEmployeeList()
        }
    };

    // const onBranchSearch = async (data: any) => {
    //     if (data !== null) {
    //         setLoading(true)
    //         const url = `${urls.INCENTIVE_EMPLOYEE_SEARCH_BY_BRANCH_OR_EMPLOYEE}`;
    //         const obj = {
    //             branch_id: val?.label=="All"?"":val?.value,
    //       employee_id: searchInput?.label=="All"?"":searchInput?.value
    //         }
    //         const res = await AuthApiService.SearchApiCallForEmployeeAndBranch(url, data.value, admin.token);
    //         if (res.status) {
    //             setLoading(null)
    //             // setsortedlist(res.data.data)
    //             // dispatch(listEmployeeReducer(res.data.data));
    //         }
    //     }
    //     else {
    //         window.location.reload()
    //     }
    // };

    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const handleUpdateModalClose = () => setShowUpdateModal(false);
    const handleUpdateModalShow = () => setShowUpdateModal(true);

    const [showAddModal, setShowAddModal] = useState(false);
    const handleAddModelClose = () => setShowAddModal(false);
    const handleAddModelShow = () => {
        setrulesval([]);
        formik.resetForm()
        setShowAddModal(true);
    }


    const updateModal = () => {
        return <>
            <Modal show={showUpdateModal} onHide={handleUpdateModalClose} centered backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Incentive Employee</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleEditSubmit}>

                        <div className="mb-3 row">
                            <label htmlFor="employee" className="col-sm-4 col-form-label">Employee<span style={{ color: "red" }}>*</span> :</label>
                            <div className="col-sm-8">
                                <Select
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    menuPortalTarget={document.body}
                                    menuPosition="fixed"
                                    id="employee_id"
                                    name="employee_id"
                                    className={`react-select-container ${formik.touched.employee_id && formik.errors.employee_id ? "is-invalid" : ""
                                        }`}
                                    options={employeeOptions}
                                    isSearchable
                                    placeholder="Select Employee"
                                    onChange={(selectedOption) => {
                                        formik.setFieldValue("employee_id", selectedOption.value);
                                        //                             const ra = incentiveEmployee.filter((data2)=>data2.employee_id==formik.values.employee_id).map((el)=>el.incentive_rule_id)
                                        // const rb = rulesOptions.filter((data)=>!ra.includes(data.value));
                                        // console.log(rb);
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={employeeOptions.find((option: any) => option.value === formik.values.employee_id) || null}
                                />
                                {formik.touched.employee_id && formik.errors.employee_id ? (
                                    <div className="invalid-feedback">{formik.errors.employee_id}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="Irule" className="col-sm-4 col-form-label">Incentive Rule<span style={{ color: "red" }}>*</span> :</label>
                            <div className="col-sm-8">
                                <Select
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    menuPortalTarget={document.body}
                                    menuPosition="fixed"
                                    id="incentive_rule_id"
                                    name="incentive_rule_id"
                                    className={`react-select-container ${formik.touched.incentive_rule_id && formik.errors.incentive_rule_id ? "is-invalid" : ""
                                        }`}
                                    options={rulesOptions}
                                    isSearchable
                                    placeholder="Select Rule"
                                    onChange={(selectedOption) => formik.setFieldValue("incentive_rule_id", selectedOption.value)}
                                    onBlur={formik.handleBlur}
                                    value={rulesOptions.find((option: any) => option.value === formik.values.incentive_rule_id) || null}
                                />
                                {/* <div className="dropdown">
  <button className="btn btn-secondary dropdown-toggle col-12" type="button" data-bs-toggle="dropdown" aria-expanded="false">
    Select Rule
  </button>
  <ul className="dropdown-menu scrollable-menu">
    <li className="col mx-4 justify-content-center d-flex"><div className="form-check form-check-inline"><label className="form-check-label">Select All</label><input type="checkbox" onChange={(e)=>{if(e.target.checked){setCheckAll(true);handleChangeAllChecked()}else{setCheckAll(undefined);handleChangeAllUnChecked()}}} className="form-check-input" name="incentive_rule_id"/></div></li>
    {ro?.map((data)=><li>
        <div className="form-check ms-1">
  <input className="form-check-input" name="incentive_rule_id" type="checkbox" checked={checkAll} onChange={(e)=>{e.target.checked?handleChangeChecked(data.value):handleChangeUnchecked(data.value)}} id="flexCheckDefault" />
  <label className="form-check-label" htmlFor="flexCheckDefault">
    {data.label}
  </label>
</div></li>)}
  </ul>
</div> */}
                                {formik.touched.incentive_rule_id && formik.errors.incentive_rule_id ? (
                                    <div className="invalid-feedback">{formik.errors.incentive_rule_id}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="active" className="col-sm-4 col-form-label">Status<span style={{ color: "red" }}>*</span> </label>
                            <div className="col-sm-8">
                                <select
                                    className="form-control form-select shadow-sm"
                                    id="isActive"
                                    name="isActive"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.isActive}
                                >
                                    <option value="true">Active</option>
                                    <option value="false">DeActive</option>
                                </select>
                            </div>
                        </div>


                        <div className="mb-3 row p-2 pb-0">
                            <button type="submit" className="btn btn-purple text-center btn-sm" disabled={upLoading}>
                                {upLoading ? 'Updating...' : 'Update'}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" disabled={upLoading ? true : false} onClick={handleUpdateModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    }

    const addModal = () => {
        return <>
            <Modal show={showAddModal} onHide={handleAddModelClose} centered backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Incentive Employee</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onFocus={() => setMsg("")} onSubmit={formik.handleSubmit}>

                        <div className="mb-3 row">
                            <label htmlFor="employee" className="col-sm-4 col-form-label">Branch :</label>
                            <div className="col-sm-8">
                                <Select
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    menuPortalTarget={document.body}
                                    menuPosition="fixed"
                                    id="branch_id"
                                    name="branch_id"
                                    className={`react-select-container`}
                                    options={branchOptions}
                                    isSearchable
                                    isClearable
                                    placeholder="All Branches"
                                    onChange={(selectedOption: any) => setBVal(selectedOption)}

                                />

                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label htmlFor="employee" className="col-sm-4 col-form-label">Employee<span style={{ color: "red" }}>*</span> :</label>
                            <div className="col-sm-8">
                                <Select
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    menuPortalTarget={document.body}
                                    menuPosition="fixed"
                                    id="employee_id"
                                    name="employee_id"
                                    className={`react-select-container ${formik.touched.employee_id && formik.errors.employee_id ? "is-invalid" : ""
                                        }`}
                                    options={employeeOptions}
                                    isSearchable
                                    placeholder="All Employees"
                                    onChange={(selectedOption) => {
                                        formik.setFieldValue("employee_id", selectedOption.value);
                                        // const ra = incentiveEmployee.filter((data2)=>data2.employee_id==formik.values.employee_id).map((el)=>el.incentive_rule_id)
                                        // const rb = rulesOptions.filter((data)=>!ra.includes(data.value));
                                        // setro(rb);
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={employeeOptions.find((option: any) => option.value === formik.values.employee_id) || null}
                                />
                                {formik.touched.employee_id && formik.errors.employee_id ? (
                                    <div className="invalid-feedback">{formik.errors.employee_id}</div>
                                ) : null}
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label htmlFor="Irule" className="col-sm-4 col-form-label">Incentive Rule<span style={{ color: "red" }}>*</span> :</label>
                            <div className="col-sm-8">
                                <div className="dropdown">
                                    <button className="btn btn-secondary dropdown-toggle col-12" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Select Rule
                                    </button>
                                    <ul className="dropdown-menu scrollable-menu">
                                        <li className="col mx-4 justify-content-center d-flex"><div className="form-check form-check-inline"><label className="form-check-label">Select All</label><input type="checkbox" onChange={(e) => { if (e.target.checked) { setCheckAll(true); handleChangeAllChecked() } else { setCheckAll(undefined); handleChangeAllUnChecked() } }} className="form-check-input" name="incentive_rule_id" /></div></li>
                                        {rulesOptions?.map((data: any) => <li>
                                            <div className="form-check ms-1">
                                                <input className="form-check-input" name="incentive_rule_id" type="checkbox" checked={checkAll} onChange={(e) => { e.target.checked ? handleChangeChecked(data.value) : handleChangeUnchecked(data.value) }} id="flexCheckDefault" />
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    {data.label}
                                                </label>
                                            </div></li>)}
                                        {/* {rulesOptions.map((data)=><li>
        <div className="form-check ms-1">
  <input className="form-check-input" name="incentive_rule_id" type="checkbox" checked={checkAll} onChange={(e)=>{e.target.checked?handleChangeChecked(data.value):handleChangeUnchecked(data.value)}} id="flexCheckDefault" />
  <label className="form-check-label" htmlFor="flexCheckDefault">
    {data.label}
  </label>
</div></li>)} */}
                                    </ul>
                                </div>

                                {/* <Select
                                 styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                 menuPortalTarget={document.body}
                                 menuPosition="fixed"
                                    id="incentive_rule_id"
                                    name="incentive_rule_id"
                                    className={`basic-multi-select ${formik.touched.incentive_rule_id && formik.errors.incentive_rule_id ? "is-invalid" : ""
                                        }`}
                                    options={rulesOptions}
                                    isSearchable
                                    isMulti
                                    isClearable
                                    closeMenuOnSelect={false}
                                    placeholder="Select Rule"
                                    onChange={(selectedOption)=>{formik.setFieldValue("incentive_rule_id", selectedOption.map((d)=>d.value));console.log(selectedOption.map((d)=>d.value))}}
                                    // onChange={(selectedOption) => formik.setFieldValue("incentive_rule_id", selectedOption.value)}
                                    onBlur={formik.handleBlur}
                                    // value={rulesOptions.map((option: any) => option.value)}
                                /> */}
                                {formik.touched.incentive_rule_id && formik.errors.incentive_rule_id ? (
                                    <div className="invalid-feedback">{formik.errors.incentive_rule_id}</div>
                                ) : null}
                            </div>
                        </div>

                        <div className="mb-3 row p-2 pb-0">
                            <button type="submit" className="btn btn-purple text-center btn-sm" disabled={loading}>
                                {loading ? 'Saving...' : 'Add'}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" disabled={loading ? true : false} onClick={handleAddModelClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    }

    const handleChangeAllChecked = () => {
        // const ra = incentiveEmployee.filter((data2)=>data2.employee_id==formik.values.employee_id).map((el)=>el.incentive_rule_id)
        // const rb = rulesOptions.filter((data)=>!ra.includes(data.value)).map((data)=>data.value);
        // console.log(rb);
        const a = rulesOptions.map((d: any) => d.value);
        setrulesval(a);
        formik.setFieldValue("incentive_rule_id", a)
    }

    const handleChangeAllUnChecked = () => {
        const a = [];
        setrulesval(a);
        formik.setFieldValue("incentive_rule_id", a)
    }

    const handleChangeUnchecked = (e: any) => {
        const ind = rulesval.findIndex((d: any) => d == e);
        const b = rulesval.toSpliced(ind, 1);
        setrulesval(b);
        formik.setFieldValue("incentive_rule_id", b)
    }

    const handleChangeChecked = (e: any) => {
        // const a = rulesval;
        const a = rulesval.filter((d: any) => d != e)
        const b = [...a, e]
        setrulesval(b);
        // ab.push(e)
        formik.setFieldValue("incentive_rule_id", b)
    }

    return <>
        {updateModal()}
        {addModal()}
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
        >
            <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert>
        </Snackbar>
        <div className="container-fluid p-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 col-md-8 col-sm-8 d-flex justify-content-center ">
                        <div className="text-center">
                            <h4>Incentive Employees</h4>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4 mt-3 mt-2 d-flex justify-content-end">
                        {/* <Link to="/saloonChain/newIncentiveEmployee"> */}
                        <button onClick={() => handleAddModelShow()} className="btn btn-sm btn-purple"><FontAwesomeIcon icon={faPlus} />&nbsp;New Incentive Employee</button>
                        {/* </Link> */}
                    </div>
                </div>
            </div>
            <div className="row mt-2 mt-xl-2 pb-3 p-1">
                <div className="col-lg-3 col-md-3 col-sm-12">
                    <label>Select Branch</label>
                    {/* <Select
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={"All"}
                        placeholder="All"
                        // isDisabled={isDisabled}
                        isLoading={branchInfo?.length <= 0 ? true : false}
                        isClearable={true}
                        // isRtl={isRtl}
                        isSearchable={true}
                        options={branchOption}
                        onChange={(e: string) => { setval(e); onSearch(e, searchInput) }}
                        value={val}
                    /> */}
                    <Select
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder="All"
                        isLoading={branchInfo?.length <= 0}
                        isClearable={true}
                        isSearchable={true}
                        options={branchOption}
                        onChange={(e: any) => { setSelectedBranch(e); setval(e); onSearch(e, searchInput) }}
                        value={selectedBranch}
                    />
                </div>
                <div className="col-lg-3 col-md-3">
                    <label>Select Employee</label>
                    <Select
                        // type="text"
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder={val?.label || "All"}
                        isLoading={employeeList.length <= 0 ? true : false}
                        isClearable={true}
                        isSearchable={true}
                        options={employeeOption}
                        // value={searchInput}
                        onChange={(e: string) => { setSearchInput(e); onSearch(val, e) }}
                        escapeClearsValue={true}
                        backspaceRemovesValue={false}
                    />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-1"></div>
            </div>
            {/* <b className={status ? "text-success" : "text-danger"}>{msg}</b> */}

            {loading || incentiveEmployee.length <= 0 ? (
                <div className="mt-4">
                    {loading ? (
                        // Display loading spinner
                        <div className="d-flex justify-content-center align-items-center">
                            {loadingSpinner()}
                        </div>
                    ) : (
                        // Display custom message for no records
                        <h5>No incentive rules found. You can assign New Rule to Employee.</h5>
                    )}
                </div>
            ) : <div className="mt-2 mt-xl-2 mt-sm-2 justify-content-center">
                <div className="table-responsive">
                    <table className="table table-striped  table-hover border-light-subtle ">
                        <thead>
                            <tr className="text-nowrap">
                                <th>
                                </th>
                                <th style={{ "cursor": "pointer" }} onClick={() => { sortByName() }} className="text-center">Employee&nbsp;<FontAwesomeIcon icon={faSort} cursor="pointer" onClick={() => { sortByName() }} /></th>
                                {!isMobile && <th style={{ "width": "700px" }} className="text-center">Incentive Rule</th>}
                                {!isMobile && <th className="text-center">Branch</th>}
                                <th className="text-center">Status</th>
                                {isMobile && <th></th>}
                            </tr>
                        </thead>
                        <tbody className="text-nowrap">
                            {incentiveEmployee?.map((emp: any, index: any) => {
                                const isExpanded = expandedRowIndex === index;
                                return (<React.Fragment key={index}>
                                    <tr className="text-center" key={emp.id}>
                                        <td>{isMobile ? <><FontAwesomeIcon icon={faEdit} className="fontIcon" onClick={() => handleEdit(emp)} style={{ cursor: "pointer" }} /> <br></br>{recordLoading === emp.id ? <>Deleting...</> : <><FontAwesomeIcon icon={faTrash} className="fontIcon" onClick={() => { Delete(emp) }} cursor="pointer" /></>}</> : <><FontAwesomeIcon icon={faEdit} className="fontIcon" onClick={() => handleEdit(emp)} style={{ cursor: "pointer" }} /> &nbsp;{recordLoading === emp.id ? <>Deleting...</> : <><FontAwesomeIcon icon={faTrash} className="fontIcon" onClick={() => { Delete(emp) }} cursor="pointer" /></>}</>}</td>

                                        <td className={`text-center ${isMobile ? 'text-wrap' : 'text-nowrap'}`}>{isMobile ? <>{emp.employeeInfo?.first_name}<br></br>{emp.employeeInfo?.last_name}</> : <>{emp.employeeInfo?.first_name}&nbsp; {emp.employeeInfo?.last_name}</>}</td>
                                        {!isMobile && <td style={{ "width": "700px" }} className="text-center">{emp.incentiveRuleInfo?.description}</td>}
                                        {!isMobile && <td className={`text-center ${isMobile ? 'text-wrap' : 'text-nowrap'}`}>{emp.employeeInfo?.branch_info?.branch_name}</td>}

                                        <td><button
                                            style={{
                                                width: isMobile ? "90px" : "150px"
                                            }}
                                            // style={{ "width": "120px" }}
                                            className={`btn btn-sm ${emp.isActive ? "btn-warning" : "btn-danger"}`}
                                            onClick={() => statusUpdate(emp)}
                                        >
                                            {statusLoading === emp.id
                                                ? `${emp.isActive ? "Activating..." : "Deactivating..."}`
                                                : `${emp.isActive ? "Active" : "DeActive"}`
                                            }
                                        </button></td>


                                        {isMobile && (
                                            <td>
                                                {!isExpanded ? (
                                                    <button
                                                        className="btn btn-link"
                                                        onClick={() => toggleRowExpansion(index)}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faArrowDown}
                                                            className="fontIcon"
                                                            size="sm"
                                                        />
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="btn btn-link"
                                                        onClick={() => toggleRowExpansion(index)}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faArrowUp}
                                                            className="fontIcon"
                                                            size="sm"
                                                        />
                                                    </button>
                                                )}
                                            </td>
                                        )}
                                    </tr>
                                    {isExpanded && isMobile && (
                                        <tr>
                                            <td colSpan="4">{renderRowExpansionContent(emp)}</td>
                                        </tr>
                                    )}
                                </React.Fragment>)
                            })}

                        </tbody>
                    </table>
                </div>
            </div>}
        </div >


        {/* Add Incentive Employee Modal */}
        {/* <div className="modal" id="AddIncentiveEMployee">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">New Incentive Employee</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div className="modal-body p-4">
                        <b className={status ? "text-success" : "text-danger"}>{addmsg}</b>
                        <form onFocus={() => setMsg("")} onSubmit={formik.handleSubmit}>

                            <div className="mb-3 row">
                                <label htmlFor="employee" className="col-sm-4 col-form-label">Branch :</label>
                                <div className="col-sm-8">
                                    <Select
                                        id="branch_id"
                                        name="branch_id"
                                        className={`react-select-container`}
                                        options={branchOptions}
                                        isSearchable
                                        isClearable
                                        placeholder="All Branches"
                                        onChange={(selectedOption) => setBVal(selectedOption)}

                                    />

                                </div>
                            </div>

                            <div className="mb-3 row">
                                <label htmlFor="employee" className="col-sm-4 col-form-label">Employee<span style={{ color: "red" }}>*</span> :</label>
                                <div className="col-sm-8">
                                    <Select
                                        id="employee_id"
                                        name="employee_id"
                                        className={`react-select-container ${formik.touched.employee_id && formik.errors.employee_id ? "is-invalid" : ""
                                            }`}
                                        options={employeeOptions}
                                        isSearchable
                                        placeholder="All Employees"
                                        onChange={(selectedOption) => formik.setFieldValue("employee_id", selectedOption.value)}
                                        onBlur={formik.handleBlur}
                                        value={employeeOptions.find((option: any) => option.value === formik.values.employee_id) || null}
                                    />
                                    {formik.touched.employee_id && formik.errors.employee_id ? (
                                        <div className="invalid-feedback">{formik.errors.employee_id}</div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="mb-3 row">
                                <label htmlFor="Irule" className="col-sm-4 col-form-label">Incentive Rule<span style={{ color: "red" }}>*</span> :</label>
                                <div className="col-sm-8">
                                    <Select
                                        id="incentive_rule_id"
                                        name="incentive_rule_id"
                                        className={`react-select-container ${formik.touched.incentive_rule_id && formik.errors.incentive_rule_id ? "is-invalid" : ""
                                            }`}
                                        options={rulesOptions}
                                        isSearchable
                                        placeholder="Select Rule"
                                        onChange={(selectedOption) => formik.setFieldValue("incentive_rule_id", selectedOption.value)}
                                        onBlur={formik.handleBlur}
                                        value={rulesOptions.find((option: any) => option.value === formik.values.incentive_rule_id) || null}
                                    />
                                    {formik.touched.incentive_rule_id && formik.errors.incentive_rule_id ? (
                                        <div className="invalid-feedback">{formik.errors.incentive_rule_id}</div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="mb-3 row p-2 pb-0">
                                <button type="submit" className="btn btn-purple text-center btn-sm" disabled={loading}>
                                    {loading ? 'Saving...' : 'Add'}
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-sm btn-danger" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div> */}



        {/* Add Incentive Employee Update Modal */}
        {/* <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="staticBackdropLabel">Update Incentive Employee</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div className="modal-body p-4">
                        <b className={status ? "text-success" : "text-danger"}>{upMsg}</b>
                        <form onSubmit={handleEditSubmit}>

                            <div className="mb-3 row">
                                <label htmlFor="employee" className="col-sm-4 col-form-label">Employee<span style={{ color: "red" }}>*</span> :</label>
                                <div className="col-sm-8">
                                    <Select
                                        id="employee_id"
                                        name="employee_id"
                                        className={`react-select-container ${formik.touched.employee_id && formik.errors.employee_id ? "is-invalid" : ""
                                            }`}
                                        options={employeeOptions}
                                        isSearchable
                                        placeholder="Select Employee"
                                        onChange={(selectedOption) => formik.setFieldValue("employee_id", selectedOption.value)}
                                        onBlur={formik.handleBlur}
                                        value={employeeOptions.find((option: any) => option.value === formik.values.employee_id) || null}
                                    />
                                    {formik.touched.employee_id && formik.errors.employee_id ? (
                                        <div className="invalid-feedback">{formik.errors.employee_id}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="Irule" className="col-sm-4 col-form-label">Incentive Rule<span style={{ color: "red" }}>*</span> :</label>
                                <div className="col-sm-8">
                                    <Select
                                        id="incentive_rule_id"
                                        name="incentive_rule_id"
                                        className={`react-select-container ${formik.touched.incentive_rule_id && formik.errors.incentive_rule_id ? "is-invalid" : ""
                                            }`}
                                        options={rulesOptions}
                                        isSearchable
                                        placeholder="Select Rule"
                                        onChange={(selectedOption) => formik.setFieldValue("incentive_rule_id", selectedOption.value)}
                                        onBlur={formik.handleBlur}
                                        value={rulesOptions.find((option: any) => option.value === formik.values.incentive_rule_id) || null}
                                    />
                                    {formik.touched.incentive_rule_id && formik.errors.incentive_rule_id ? (
                                        <div className="invalid-feedback">{formik.errors.incentive_rule_id}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="active" className="col-sm-4 col-form-label">Status<span style={{ color: "red" }}>*</span> </label>
                                <div className="col-sm-8">
                                    <select
                                        className="form-control form-select shadow-sm"
                                        id="isActive"
                                        name="isActive"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.isActive}
                                    >
                                        <option value="true">Active</option>
                                        <option value="false">DeActive</option>
                                    </select>
                                </div>
                            </div>


                            <div className="mb-3 row p-2 pb-0">
                                <button type="submit" className="btn btn-purple text-center btn-sm" disabled={upLoading}>
                                    {upLoading ? 'Updating...' : 'Update'}
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-sm btn-danger" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div> */}
    </>
}





// import { Link, useNavigate } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEdit, faPen, faPlus, faSearch, faSort, faTrash, } from "@fortawesome/free-solid-svg-icons";
// import { useEffect, useMemo, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useFormik } from "formik";
// import { incentiveEmployeeValidationSchema } from "../../../validations/Validations";
// import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
// import { addIncentiveEmployeeReducer, listIncentiveEmployeeReducer, updateIncentiveEmployee, updateIncentiveEmployeeReducer } from "../../../reduxData/incentiveEmployeeSlice";
// import Select from "react-select";
// // import { Alert } from "react-bootstrap";
// import { Alert, Snackbar } from "@mui/material";

// export default function IncentiveEmployeeList() {
//     const _ = require("lodash");
//     const [msg, setMsg] = useState("");
//     // const [addmsg, setAddMsg] = useState("");
//     // const [upMsg, setUpMsg] = useState("");
//     const [status, setStatus] = useState()
//     const [open, setOpen] = useState(false);
//     const [loading, setLoading] = useState(false);
//     const [upLoading, setUpLoading] = useState(false);
//     // const [delLoading, setDelLoading] = useState(false);
//     const [recordLoading, setRecordLoading] = useState<string | null>(null);
//     const [isEditModalOpen, setIsEditModalOpen] = useState(false);
//     const [bVal, setBVal] = useState();
//     console.log(bVal)
//     const dispatch = useDispatch()
//     const navigate = useNavigate()

//     const incentiveEmployee = useSelector((state: any) => state.incentiveEmployeeInfo.value)
//     const branchInfo = useSelector((state: any) => state.branchInfo.value);

//     //const incentiveEmployee = _.orderBy(incentiveEmp, (item: any) => item.employeeInfo?.first_name?.toLowerCase(), ['asc'])
//     console.log("saved", incentiveEmployee)
//     const admin = useSelector((state: any) => state.authUserInfo.value);
//     const incentiveRules = useSelector((state: any) => state.incentiveRuleInfo.value)
//     const employeeList = useSelector((state: any) => state.employeeInfo.value);
//     console.log(branchInfo, employeeList);
//     const UpincentiveEmployee = useSelector((state: any) => state.incentiveEmployeeInfo.upData)
//     // const [sortedlist, setsortedlist] = useState();
//     const [sort, setsort] = useState(false);
//     const [statusLoading, setStatusLoading] = useState<string | null | boolean>(null);

//     const formik = useFormik({
//         initialValues: {
//             employee_id: "",
//             incentive_rule_id: "",
//             isActive: "",
//             created_by: "",
//             updated_by: "",
//         },
//         validationSchema: incentiveEmployeeValidationSchema,
//         onSubmit: async (values) => {
//             try {
//                 setLoading(true);
//                 const ob: any = {
//                     employee_id: values.employee_id,
//                     incentive_rule_id: values.incentive_rule_id,
//                     isActive: true,
//                     created_by: admin.uid,
//                     updated_by: admin.uid,
//                 };

//                 const resp = await AuthApiService.SaveApiCall(urls.INCENTIVE_EMPLOYEE_SAVE, ob, admin.token);
//                 console.log(resp)
//                 if (resp.status === 200) {
//                     setMsg(resp.data.msg);
//                     handleClickOpen();
//                     setStatus(resp.data.status)
//                     dispatch(addIncentiveEmployeeReducer(ob));
//                 } else {
//                     if (resp.data && resp.data.err) {
//                         setMsg(resp.data.err);
//                         setStatus(resp.data.status)

//                     } else {
//                         setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
//                         setStatus(resp.data.status)
//                     }
//                 }
//             } catch (error: any) {
//                 console.error("Error:", error);
//                 setMsg("Network Error !");
//                 setStatus(error.response.status);
//                 handleClickOpen()
//             }
//             finally {
//                 setLoading(false);
//                 setTimeout(() => {
//                     //setAddMsg('');
//                     formik.resetForm();
//                 }, 2000);
//             }
//         },
//     });

//     const handleEdit = (emp: any) => {
//         dispatch(updateIncentiveEmployeeReducer(emp));
//         setIsEditModalOpen(true); // Open the edit modal

//         formik.setValues({
//             employee_id: emp.employee_id,
//             incentive_rule_id: emp.incentive_rule_id,
//             isActive: emp.isActive.toString(),
//             created_by: emp.created_by,
//             updated_by: emp.updated_by,
//         });
//     };

//     const [inEmpId, setInEmpId] = useState<number | null>(null);

//     useEffect(() => {
//         if (UpincentiveEmployee && UpincentiveEmployee.id) {
//             setInEmpId(UpincentiveEmployee.id);
//         }

//     }, [UpincentiveEmployee]);

//     const handleEditSubmit = async (event: any) => {
//         event.preventDefault()
//         try {
//             setUpLoading(true);

//             const updatedData = {
//                 employee_id: formik.values.employee_id,
//                 incentive_rule_id: formik.values.incentive_rule_id,
//                 isActive: formik.values.isActive === "true", // Convert string to boolean
//                 created_by: UpincentiveEmployee.created_by,
//                 updated_by: admin.uid, // Assuming you want to update the "updated_by" field
//             };

//             // const up = {
//             //     ...UpincentiveEmployee,
//             //     ...updatedData
//             // }
//             const upURL = urls.INCENTIVE_EMPLOYEE_UPDATE + inEmpId;
//             const resp = await AuthApiService.PutApiCall(upURL, updatedData, admin.token);

//             if (resp.status) {
//                 setMsg(resp.data.msg);
//                 handleClickOpen();
//                 setStatus(resp.data.status)
//                 dispatch(updateIncentiveEmployeeReducer(updatedData));

//                 setIsEditModalOpen(false);


//             } else {

//                 setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
//                 setStatus(resp.data.status)
//             }
//         } catch (error: any) {

//             setMsg("Network Error !");
//             setStatus(error.response.status);
//             handleClickOpen()
//         } finally {
//             setUpLoading(false);
//             setTimeout(() => {
//                 //setUpMsg('');
//             }, 5000);
//         }
//     };

//     const branchOptions = branchInfo.map((item: any) => ({
//         value: item.id,
//         label: item.branch_name,
//     }));
//     const employeeOptions = bVal != undefined ? employeeList?.filter((e: any) => e.branch_info.id === bVal?.value && e.is_active).map((item: any) => ({
//         value: item.id,
//         label: item.first_name + " " + item.last_name
//     }))
//         :
//         employeeList?.filter((e: any) => e.is_active).map((item: any) => ({
//             value: item.id,
//             label: item.first_name + " " + item.last_name
//         }))
//     const rulesOptions = incentiveRules.map((item: any) => ({
//         value: item.id,
//         label: item.description
//     }))

//     const employeeOption = bVal != undefined ? employeeList?.filter((e: any) => e.branch_info.id === bVal?.value && e.is_active).map((item: any) => ({
//         value: item.first_name,
//         label: item.first_name + " " + item.last_name
//     }))
//         :
//         employeeList?.filter((e: any) => e.is_active).map((item: any) => ({
//             value: item.first_name,
//             label: item.first_name + " " + item.last_name
//         }))



//     const Delete = async (data: any) => {
//         const al = confirm("Are you sure you want to delete");
//         try {
//             if (al) {
//                 setRecordLoading(data.id);
//                 const url = urls.INCENTIVE_EMPLOYEE_DELETE + data.id
//                 const resp = await AuthApiService.DelApiCall(url, admin.token);
//                 console.log(resp)
//                 if (resp.status) {
//                     const newlist: any = incentiveEmployee.filter((d: any) => { return d.id !== data.id })
//                     dispatch(listIncentiveEmployeeReducer(newlist))
//                     setMsg(resp.data.msg)
//                     handleClickOpen();
//                     setStatus(resp.data.status)
//                 }
//             }
//             else { return }
//         } catch (error: any) {
//             console.error("Error:", error);
//             setMsg("Network Error !");
//             setStatus(error.response.status);
//             handleClickOpen()
//         } finally {
//             setRecordLoading(null);
//             setTimeout(() => {
//                 // setMsg('');
//             }, 5000);
//         }
//     }

//     const sortByName = () => {
//         setsort(!sort);
//         if (sort) {
//             const nw = _.orderBy(incentiveEmployee, (item: any) => item.employeeInfo.first_name, ['asc'])
//             dispatch(listIncentiveEmployeeReducer(nw))
//         }
//         else {
//             const nw = _.orderBy(incentiveEmployee, (item: any) => item.employeeInfo.first_name, ['desc'])
//             dispatch(listIncentiveEmployeeReducer(nw))
//         }
//     }

//     const onSearch = async (data: any) => {
//         // const oldList = incentiveEmployee;
//         if (data !== null) {
//             setLoading(true)
//             const url = `${urls.INCENTIVE_EMPLOYEE_SEARCH}`;
//             const ob = { nameChars: data.value }
//             console.log(url, ob)
//             const res = await AuthApiService.SearchApiCall(url, ob, admin.token);
//             console.log(res);
//             if (res.status) {
//                 dispatch(listIncentiveEmployeeReducer(res.data.data))
//                 setLoading(false)
//                 console.log(res);
//             }
//         }
//         else {
//             // dispatch(listIncentiveEmployeeReducer(oldList))
//             window.location.reload()
//         }
//     };

//     const handleActive = async (id: any) => {
//         const url = urls.INCENTIVE_EMPLOYEE_UPDATE + id;
//         const obj = { isActive: true }
//         const res = await AuthApiService.PutApiCall(url, obj, admin.token);
//     }


//     // const statusUpdate = async (emp: any) => {
//     //     try {
//     //         setStatusLoading(emp.id)
//     //         const confirmMessage = emp.isActive
//     //             ? 'Are you sure you want to deactivate the Incentive employee?'
//     //             : 'Are you sure you want to activate the Incentive employee?';
//     //         const userConfirmed = window.confirm(confirmMessage);
//     //         if (!userConfirmed) {
//     //             return;
//     //         }
//     //         let ob;
//     //         if (emp?.isActive) {
//     //             ob = {
//     //                 isActive: !emp?.isActive,

//     //                 created_by: admin.uid,
//     //                 updated_by: admin.uid
//     //             };
//     //         } else {
//     //             ob = {
//     //                 isActive: !emp?.isActive,

//     //                 created_by: admin.uid,
//     //                 updated_by: admin.uid
//     //             };
//     //         }
//     //         const UPURL = urls.INCENTIVE_EMPLOYEE_UPDATE + emp.id;
//     //         const resp = await AuthApiService.PutApiCall(UPURL, ob, admin.token);
//     //         console.log(resp);
//     //         if (resp.status === 200) {
//     //             setMsg(resp.data.msg);
//     //             setStatus(true);
//     //             const updatedList = sortedlist.map((emp: any) =>
//     //                 emp.id === emp.id ? { ...emp, isActive: !emp.isActive } : emp
//     //             );
//     //             dispatch(listIncentiveEmployeeReducer(updatedList));
//     //             setsortedlist(updatedList);
//     //             // const updatedCurrentPage = sortedlist.map((emp: any) =>
//     //             //     emp.id === emp.id ? { ...emp, isActive: !emp.isActive } : emp
//     //             // );
//     //             // // Update the current page in the Redux store
//     //             // dispatch(listIncentiveEmployeeReducer(updatedCurrentPage));
//     //         } else {
//     //             if (resp.data && resp.data.err) {
//     //                 setMsg(resp.data.err);
//     //                 setStatus(false);
//     //             } else {
//     //                 setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
//     //                 setStatus(false);
//     //             }
//     //         }
//     //     } catch (error) {
//     //         setMsg('Network Error !');
//     //         console.error('Error:', error);
//     //         setStatus(false);
//     //     } finally {
//     //         setStatusLoading(null);
//     //         setTimeout(() => {
//     //             setMsg("")
//     //         }, 2000);
//     //     }
//     // };

//     const statusUpdate = async (emp: any) => {
//         try {
//             setStatusLoading(emp.id);

//             const updatedList = incentiveEmployee.map((e: any) =>
//                 e.id === emp.id ? { ...e, isActive: !e.isActive } : e
//             );

//             // Optimistically update the UI
//             dispatch(listIncentiveEmployeeReducer(updatedList))

//             const confirmMessage = emp.isActive
//                 ? 'Are you sure you want to deactivate the Incentive employee?'
//                 : 'Are you sure you want to activate the Incentive employee?';

//             const userConfirmed = window.confirm(confirmMessage);

//             if (!userConfirmed) {
//                 // If the user cancels the action, revert the UI update
//                 // setsortedlist(sortedlist);
//                 return;
//             }

//             const ob = {
//                 isActive: !emp.isActive,
//                 created_by: admin.uid,
//                 updated_by: admin.uid
//             };

//             const UPURL = urls.INCENTIVE_EMPLOYEE_UPDATE + emp.id;
//             const resp = await AuthApiService.PutApiCall(UPURL, ob, admin.token);

//             if (resp.status === 200) {
//                 setMsg(resp.data.msg);
//                 setStatus(resp.data.status)
//                 dispatch(listIncentiveEmployeeReducer(updatedList));
//             } else {
//                 // If there's an error, revert the UI update
//                 setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
//                 setStatus(false);
//                 // setsortedlist(sortedlist);
//             }
//         } catch (error) {
//             setMsg('Network Error!');
//             console.error('Error:', error);
//             setStatus(false);
//         } finally {
//             setStatusLoading(null);
//             setTimeout(() => {
//                 setMsg("");
//             }, 2000);
//         }
//     };

//     const loadingSpinner = () => {
//         return <div className="d-flex justify-content-center">
//             <div className="spinner-border" >
//                 <span className="visually-hidden">Loading...</span>
//             </div>
//         </div>
//     }

//     const handleClickOpen = () => {
//         setOpen(true);
//     };

//     const handleClose = () => {
//         setOpen(false);
//     };

//     return <>
//         <Snackbar
//             anchorOrigin={{ vertical: "top", horizontal: "center" }}
//             open={open}
//             autoHideDuration={5000}
//             onClose={handleClose}
//         >
//             <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert>
//         </Snackbar>
//         <div className="container-fluid">
//             <div className="container mt-5">
//                 <div className="row">
//                     <div className="col-lg-10 col-md-8 col-sm-8">
//                         <div className="text-center">
//                             <h3>Incentive Employees</h3>
//                         </div>
//                     </div>
//                     <div className="col-lg-2 col-md-4 col-sm-4 mt-3 mt-sm-0 text-sm-end">
//                         {/* <Link to="/saloonChain/newIncentiveEmployee"> */}
//                         <button data-bs-toggle="modal" data-bs-target="#AddIncentiveEMployee" className="btn btn-sm btn-purple"><FontAwesomeIcon icon={faPlus} />&nbsp;New Incentive Employee</button>
//                         {/* </Link> */}
//                     </div>
//                 </div>
//             </div>
//             <div className="row mt-5 pb-4">
//                 <div className="col-lg-3 col-md-3">
//                     <label>Select Employee</label>
//                     <Select
//                         // type="text"
//                         className="basic-single"
//                         classNamePrefix="select"
//                         placeholder="All"
//                         isLoading={employeeList.length <= 0 ? true : false}
//                         isClearable={true}
//                         isSearchable={true}
//                         options={employeeOption}
//                         // value={searchInput}
//                         onChange={(e: string) => { onSearch(e) }}
//                         escapeClearsValue={true}
//                         backspaceRemovesValue={false}
//                     />
//                 </div>
//                 <div className="col-lg-4 col-md-4 col-sm-1"></div>
//             </div>
//             {/* <b className={status ? "text-success" : "text-danger"}>{msg}</b> */}

//             {loading || incentiveEmployee.length < 0 ? loadingSpinner() : <div className="mt-2 justify-content-center">
//                 <div className="table-responsive">
//                     <table className="table table-striped  table-hover border-light-subtle ">
//                         <thead>
//                             <tr className="text-nowrap">
//                                 <th>
//                                 </th>
//                                 <th className="text-center">Employee&nbsp;<FontAwesomeIcon icon={faSort} cursor="pointer" onClick={() => { sortByName() }} /></th>
//                                 <th style={{ "width": "700px" }} className="text-center">Incentive Rule</th>
//                                 <th className="text-center">Status</th>
//                             </tr>
//                         </thead>
//                         <tbody className="text-nowrap">
//                             {incentiveEmployee?.map((emp: any) => (
//                                 <tr className="text-center" key={emp.id}>
//                                     <td><FontAwesomeIcon icon={faEdit} className="fontIcon" data-bs-toggle="modal" data-bs-target="#EditIncentiveEmployee" onClick={() => handleEdit(emp)} style={{ cursor: "pointer" }} /> &nbsp;{recordLoading === emp.id ? <>Deleting...</> : <><FontAwesomeIcon icon={faTrash} className="fontIcon" onClick={() => { Delete(emp) }} cursor="pointer" /></>}</td>

//                                     <td className="text-center">{emp.employeeInfo?.first_name}&nbsp;{emp.employeeInfo?.last_name}</td>
//                                     <td style={{ "width": "700px" }} className="text-center">{emp.incentiveRuleInfo?.description}</td>
//                                     {/* <td>{emp.isActive ? <button style={{ "width": "120px" }} className="btn btn-sm btn-warning" onClick={() => statusUpdate(emp)}>{statusLoading === emp.id ? "DeActivating..." : "Active"}</button> :

//                                             <button style={{ "width": "120px" }} className="btn btn-sm btn-success" onClick={() => statusUpdate(emp)}>{statusLoading === emp.id ? "Activating..." : "DeActive"}</button>}</td> */}
//                                     <td><button
//                                         style={{ "width": "120px" }}
//                                         className={`btn btn-sm ${emp.isActive ? "btn-warning" : "btn-danger"}`}
//                                         onClick={() => statusUpdate(emp)}
//                                     >
//                                         {statusLoading === emp.id
//                                             ? `${emp.isActive ? "Activating..." : "Deactivating..."}`
//                                             : `${emp.isActive ? "Active" : "DeActive"}`
//                                         }
//                                     </button></td>
//                                 </tr>))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>}
//         </div >


//         {/* Add Incentive Employee Modal */}
//         <div className="modal" id="AddIncentiveEMployee">
//             <div className="modal-dialog modal-dialog-centered">
//                 <div className="modal-content">
//                     <div className="modal-header">
//                         <h4 className="modal-title">New Incentive Employee</h4>
//                         <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
//                     </div>
//                     <div className="modal-body p-4">
//                         {/* <b className={status ? "text-success" : "text-danger"}>{addmsg}</b> */}
//                         <form onFocus={() => setMsg("")} onSubmit={formik.handleSubmit}>

//                             <div className="mb-3 row">
//                                 <label htmlFor="employee" className="col-sm-4 col-form-label">Branch :</label>
//                                 <div className="col-sm-8">
//                                     <Select
//                                         id="branch_id"
//                                         name="branch_id"
//                                         className={`react-select-container`}
//                                         options={branchOptions}
//                                         isSearchable
//                                         isClearable
//                                         placeholder="All Branches"
//                                         onChange={(selectedOption) => setBVal(selectedOption)}
//                                     // onBlur={formik.handleBlur}
//                                     // value={employeeOptions.find((option: any) => option.value === formik.values.employee_id) || null}
//                                     />
//                                     {/* {formik.touched.employee_id && formik.errors.employee_id ? (
//                                         <div className="invalid-feedback">{formik.errors.employee_id}</div>
//                                     ) : null} */}
//                                 </div>
//                             </div>

//                             <div className="mb-3 row">
//                                 <label htmlFor="employee" className="col-sm-4 col-form-label">Employee<span style={{ color: "red" }}>*</span> :</label>
//                                 <div className="col-sm-8">
//                                     <Select
//                                         id="employee_id"
//                                         name="employee_id"
//                                         className={`react-select-container ${formik.touched.employee_id && formik.errors.employee_id ? "is-invalid" : ""
//                                             }`}
//                                         options={employeeOptions}
//                                         isSearchable
//                                         placeholder="All Employees"
//                                         onChange={(selectedOption) => formik.setFieldValue("employee_id", selectedOption.value)}
//                                         onBlur={formik.handleBlur}
//                                         value={employeeOptions.find((option: any) => option.value === formik.values.employee_id) || null}
//                                     />
//                                     {formik.touched.employee_id && formik.errors.employee_id ? (
//                                         <div className="invalid-feedback">{formik.errors.employee_id}</div>
//                                     ) : null}
//                                 </div>
//                             </div>

//                             <div className="mb-3 row">
//                                 <label htmlFor="Irule" className="col-sm-4 col-form-label">Incentive Rule<span style={{ color: "red" }}>*</span> :</label>
//                                 <div className="col-sm-8">
//                                     <Select
//                                         id="incentive_rule_id"
//                                         name="incentive_rule_id"
//                                         className={`react-select-container ${formik.touched.incentive_rule_id && formik.errors.incentive_rule_id ? "is-invalid" : ""
//                                             }`}
//                                         options={rulesOptions}
//                                         isSearchable
//                                         placeholder="Select Rule"
//                                         onChange={(selectedOption) => formik.setFieldValue("incentive_rule_id", selectedOption.value)}
//                                         onBlur={formik.handleBlur}
//                                         value={rulesOptions.find((option: any) => option.value === formik.values.incentive_rule_id) || null}
//                                     />
//                                     {formik.touched.incentive_rule_id && formik.errors.incentive_rule_id ? (
//                                         <div className="invalid-feedback">{formik.errors.incentive_rule_id}</div>
//                                     ) : null}
//                                 </div>
//                             </div>

//                             <div className="mb-3 row p-2 pb-0">
//                                 <button type="submit" className="btn btn-purple text-center btn-sm" disabled={loading}>
//                                     {loading ? 'Saving...' : 'Add'}
//                                 </button>
//                             </div>
//                         </form>
//                     </div>
//                     <div className="modal-footer">
//                         <button type="button" className="btn btn-sm btn-danger" data-bs-dismiss="modal">Close</button>
//                     </div>
//                 </div>
//             </div>
//         </div>


//         {/* Add Incentive Employee Update Modal */}
//         <div className="modal" id="EditIncentiveEmployee">
//             <div className="modal-dialog modal-dialog-centered">
//                 <div className="modal-content">
//                     <div className="modal-header">
//                         <h4 className="modal-title">Update Incentive Employee</h4>
//                         <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
//                     </div>
//                     <div className="modal-body p-4">
//                         {/* <b className={status ? "text-success" : "text-danger"}>{upMsg}</b> */}
//                         <form onSubmit={handleEditSubmit}>

//                             <div className="mb-3 row">
//                                 <label htmlFor="employee" className="col-sm-4 col-form-label">Employee<span style={{ color: "red" }}>*</span> :</label>
//                                 <div className="col-sm-8">
//                                     <Select
//                                         id="employee_id"
//                                         name="employee_id"
//                                         className={`react-select-container ${formik.touched.employee_id && formik.errors.employee_id ? "is-invalid" : ""
//                                             }`}
//                                         options={employeeOptions}
//                                         isSearchable
//                                         placeholder="Select Employee"
//                                         onChange={(selectedOption) => formik.setFieldValue("employee_id", selectedOption.value)}
//                                         onBlur={formik.handleBlur}
//                                         value={employeeOptions.find((option: any) => option.value === formik.values.employee_id) || null}
//                                     />
//                                     {formik.touched.employee_id && formik.errors.employee_id ? (
//                                         <div className="invalid-feedback">{formik.errors.employee_id}</div>
//                                     ) : null}
//                                 </div>
//                             </div>
//                             <div className="mb-3 row">
//                                 <label htmlFor="Irule" className="col-sm-4 col-form-label">Incentive Rule<span style={{ color: "red" }}>*</span> :</label>
//                                 <div className="col-sm-8">
//                                     <Select
//                                         id="incentive_rule_id"
//                                         name="incentive_rule_id"
//                                         className={`react-select-container ${formik.touched.incentive_rule_id && formik.errors.incentive_rule_id ? "is-invalid" : ""
//                                             }`}
//                                         options={rulesOptions}
//                                         isSearchable
//                                         placeholder="Select Rule"
//                                         onChange={(selectedOption) => formik.setFieldValue("incentive_rule_id", selectedOption.value)}
//                                         onBlur={formik.handleBlur}
//                                         value={rulesOptions.find((option: any) => option.value === formik.values.incentive_rule_id) || null}
//                                     />
//                                     {formik.touched.incentive_rule_id && formik.errors.incentive_rule_id ? (
//                                         <div className="invalid-feedback">{formik.errors.incentive_rule_id}</div>
//                                     ) : null}
//                                 </div>
//                             </div>
//                             <div className="mb-3 row">
//                                 <label htmlFor="active" className="col-sm-4 col-form-label">Status<span style={{ color: "red" }}>*</span> </label>
//                                 <div className="col-sm-8">
//                                     <select
//                                         className="form-control form-select shadow-sm"
//                                         id="isActive"
//                                         name="isActive"
//                                         onChange={formik.handleChange}
//                                         onBlur={formik.handleBlur}
//                                         value={formik.values.isActive}
//                                     >
//                                         <option value="true">Active</option>
//                                         <option value="false">DeActive</option>
//                                     </select>
//                                 </div>
//                             </div>


//                             <div className="mb-3 row p-2 pb-0">
//                                 <button type="submit" className="btn btn-purple text-center btn-sm" disabled={upLoading}>
//                                     {upLoading ? 'Updating...' : 'Update'}
//                                 </button>
//                             </div>
//                         </form>
//                     </div>
//                     <div className="modal-footer">
//                         <button type="button" className="btn btn-sm btn-danger" data-bs-dismiss="modal">Close</button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </>
// }



