import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../Header";

export default function ManageDailyTransactionByAmount() {
    const admin = useSelector((state: any) => state.authUserInfo.value);
    const idInfo = useSelector((state: any) => state.transactionInfo.upData);
    const [isLoading, setLoading] = useState(false);
    const [empdata, setEmpdata] = useState();
    const [amount, setAmount] = useState([]);
    const [empName, setEmpName] = useState({});
    const [branch, setBranch] = useState('')
    console.log(empName, empdata)
    const navigate = useNavigate();

    console.log("fdghjk", idInfo)

    const loadingSpinner = () => {
        return <div className="container-fluid mt-5"><div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
        </div>
    }


    const formatDate = (dateString) => {
        const [day, month, year] = dateString.split('-');
        return `${year}-${month}-${day}`;
    }

    // const handleEmployeeReport = async () => {

    //     console.log(idInfo.d)
    //     if (idInfo == null) {
    //         navigate("/branchManager/managerTransactionReport")
    //     }
    //     setLoading(true)

    //     const startDate = formatDate(idInfo.d);
    //     const endDate = startDate;

    //     const url = `${urls.TRANSACTION_LIST_BY_EMPLOYEE}${startDate}/${endDate}/1/100`;
    //     console.log(url)
    //     const data = {
    //         employee_id: parseInt(idInfo.employeeId, 10)
    //     }
    //     console.log(data)
    //     const res = await AuthApiService.SearchApiCallWithQuery(url, data, admin.token);
    //     console.log("res", res)
    //     if (res.status) {
    //         console.log(res)
    //         setLoading(false);
    //         const finalarr = []
    //         res.data?.transactions?.forEach((data: any) => {
    //             console.log(data.date);
    //             console.log("data of amount", data);

    //             const arr = [];
    //             finalarr.push({
    //                 date: data.date,
    //                 customerName: data?.transaction_info?.transaction_details?.customer_name,
    //                 customerPhone: data?.transaction_info?.transaction_details?.customer_mobile,
    //                 serviceType: data?.transaction_info?.transaction_details?.service_type,

    //                 amount: data?.transaction_info?.transaction_details?.amount
    //             });
    //             // arr.push({data.total_sum.total_sum});
    //             // finalarr.push(arr);
    //         })
    //         console.log("finalarr", finalarr);
    //         setempdata(_.orderBy(finalarr, ['date']))
    //     }
    // }


    // const handleEmployeeReport = async () => {
    //     if (!idInfo) {
    //         navigate("/branchManager/managerTransactionReport");
    //         return;
    //     }

    //     setLoading(true);

    //     const startDate = formatDate(idInfo.d);
    //     const endDate = startDate;

    //     const url = `${urls.TRANSACTION_LIST_BY_EMPLOYEE}${startDate}/${endDate}/1/100`;

    //     const data = {
    //         employee_id: parseInt(idInfo.employeeId, 10)
    //     };

    //     try {
    //         const res = await AuthApiService.SearchApiCallWithQuery(url, data, admin.token);

    //         if (res.status && res.data?.transactions) {
    //             const transactions = res.data.transactions;
    //             const finalarr = [];
    //             transactions.forEach(transaction => {
    //                 transaction.transaction_info.forEach(info => {
    //                     finalarr.push({
    //                         date: transaction.date,
    //                         customerName: info.transaction_details?.customer_name,
    //                         customerPhone: info.transaction_details?.customer_mobile,
    //                         serviceType: info.transaction_details?.service_type,
    //                         amount: info.transaction_details?.amount
    //                     });
    //                 });
    //             });
    //             setEmpdata(finalarr);
    //         } else {
    //             console.log("No transaction info found in response");
    //             setEmpdata([]);
    //         }
    //     } catch (error) {
    //         console.error("Error fetching data:", error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const handleEmployeeReport = async () => {
        // if (!idInfo) {
        //     navigate("/branchManager/managerTransactionReport");
        //     return;
        // }

        setLoading(true);

        const startDate = formatDate(idInfo.d);
        const endDate = startDate;

        const url = `${urls.TRANSACTION_LIST_BY_EMPLOYEE}${startDate}/${endDate}/1/100`;
        console.log(url)
        const data = {
            employee_id: parseInt(idInfo.employeeId, 10)
        };

        try {
            const res = await AuthApiService.SearchApiCallWithQuery(url, data, admin.token);

            if (res.status && res.data?.transactions) {
                console.log("res", res);

                const finalarr = [];
                const amnt = []
                res?.data?.transactions?.forEach((transaction: any) => {
                    setBranch({ bname: transaction?.branch_info?.branch_name })
                    transaction?.transaction_info?.forEach((info: any) => {
                        amnt.push({ amtTotal: transaction?.total_sum })
                        setEmpName({ id: info?.emp_id, name: info?.emp_name })

                        info.transaction_details.forEach((data: any) => {

                            data.service_info.forEach((data2: any) => {
                                const serviceAmount = parseFloat(data2?.item_details?.service_amount) || 0;
                                const productAmount = parseFloat(data2?.item_details?.product_amount) || 0;
                                const discountPercent = parseFloat(data2?.item_details?.discount_percent) || 0;
                                const discount_amt = parseFloat(
                                    (serviceAmount * ((100 - discountPercent) / 100)).toFixed(2)
                                );
                                const discount_amt2 = parseFloat(
                                    (productAmount * ((100 - discountPercent) / 100)).toFixed(2)
                                );

                                finalarr.push({
                                    date: transaction.date,
                                    customerName: data?.customer_name ?? '-',
                                    customerPhone: data?.customer_mobile ?? '-',
                                    serviceType: data2?.item_details?.service_type ?? data2?.item_details?.product_name,
                                    amount: !isNaN(discount_amt) ? discount_amt : discount_amt2,
                                    pMode: data?.payment_mode,


                                });

                            })



                        });
                    })

                });

                // const arr2 = []
                // finalarr.forEach(data=>{
                //     data.serviceType.forEach(data2=>{
                //         arr2.push({...data,serviceType:data2})
                //         })})
                setEmpdata(finalarr); // Ensure the variable name is corrected here
                console.log(finalarr);
                setAmount(amnt)
                console.log("amout", amnt)

            } else {
                console.log("No transaction info found in response");
                setEmpdata([]);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        handleEmployeeReport();
    }, [])

    const handlePrint = () => {
        window.print();
    };
    return <>
        <div className="container-fluid p-0 p-xl-0 pr-xl-0 pl-xl-0  mt-sm-0 mt-0  mt-xl-0">
            <div className="container">

                <div className="row m-0 d-print-none">

                    <div className="col-lg-2 col-md-2 col-sm-3 col-2 mt-2 d-flex justify-content-start">
                        <Link to="/branchManager/managerTransactionReport">
                            <span>
                                <button className=" btn btn-sm btn-purple">Back</button>
                            </span>
                        </Link>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-6 col-6">

                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-3 col-4 mt-2 d-flex justify-content-end">
                        <Link to="">
                            <span>
                                <button className=" btn btn-sm btn-purple" onClick={handlePrint}>Print PDF</button>
                            </span>
                        </Link>
                    </div>

                </div>

            </div>
            {isLoading ? loadingSpinner() : <div className="container-fluid">
                <h4 className="text-center mb-1 m-xl-2 mt-1 mt-xl-1"> Branch Name - {branch?.bname}</h4>
                <h5 className="text-center mb-2 m-xl-4 mt-1 mt-xl-1">Employee ID - {empName?.id}  , Employee Name-{empName?.name}</h5>
                <h4 className="text-center mb-3">{idInfo?.dat}</h4>
                {/* <div className="table-responsive">
          <table id="report-table" className="table table-striped  table-hover border-light-subtle ">
            <thead>
                <tr className="text-center"><th colSpan={3} className="text-center">Overall Total</th></tr>
            </thead>
            <tbody>
                <tr className="text-center">
                    <td>Cash Amount (INR)</td>
                    <td>Online Amount (INR)</td>
                    <td>Total Amount (INR)</td>
                </tr>
            </tbody>
            <tfoot>
                <tr className="text-center">
                    <th style={{color:"red"}}>{totalSum?.cash_amount}</th>
                    <th style={{color:"blue"}}>{totalSum?.online_amount}</th>
                    <th>{totalSum?.total_sum}</th>
                </tr>
            </tfoot>
          </table>
        </div> */}
                <div className="table-responsive">
                    <table id="report-table" className="table table-striped  table-hover border-light-subtle ">
                        <thead>
                            <tr className="text-center">
                                <th>S.NO</th>
                                <th>Date</th>

                                <th>Service <br />Type</th>
                                {/* <th>Description</th> */}
                                <th>Amount({admin.currency})</th>
                                <th>Customer <br />Name</th>
                                <th>Customer <br />Phone</th>
                            </tr>
                        </thead>
                        <tbody>
                            {empdata?.map((data, index) => (
                                <tr key={index} className="text-center">
                                    <td>{index + 1}</td>
                                    <td>{data.date || "N/A"}</td>
                                    <td>{data.serviceType}</td>
                                    <td style={{ color: data.pMode === "Cash" ? "blue" : "red" }}>{data.amount}</td>
                                    <td>{data.customerName}</td>
                                    <td>{data.customerPhone}</td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td className="text-center btn-cash" colSpan="6">
                                    <b className="mr-5">Total Cash Amount({admin.currency})</b> &nbsp;=&nbsp;

                                    {amount.length === 0 ?
                                        <b>Loading...</b>
                                        : <b>
                                            {amount[0].amtTotal.cash_amount}
                                        </b>}
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center btn-online" colSpan="6">
                                    <b>Total Online Amount({admin.currency})</b> &nbsp;=&nbsp;

                                    {amount.length === 0 ? (
                                        <b>Loading...</b>
                                    ) : <b>{amount[0].amtTotal.online_amount}
                                    </b>}</td>
                            </tr>
                            <tr>
                                <td className="text-center btn-Total" colSpan="6">
                                    <b>Total Amount({admin.currency})</b>&nbsp; =&nbsp;

                                    {amount.length === 0 ? (
                                        <b>Loading...</b>
                                    ) : <b>
                                        {amount[0].amtTotal.total_sum}</b>
                                    }   </td>
                            </tr>
                        </tfoot>

                    </table>
                </div>
            </div>
            }
        </div >

    </>
}