import { Link } from "react-router-dom";
import { faFacebookF, faTwitter, faWhatsapp, faInstagram } from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Footer() {
    return <>
        <footer className="footer d-print-none">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
                <span className="text-muted text-center text-sm-left d-block d-sm-inline-block"></span>
                <span className="float-none  text-muted fw-bold float-sm-right d-block mt-1 mt-sm-0 text-center">Copyright © 2024. All rights reserved. </span>
            </div>
        </footer>
    </>
}


