
import Login from './components/Login';
import Register from './components/Register';
import './styles.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Home from './components/Home';
import { useSelector } from 'react-redux';
import Dashboard from './components/Dashboard';

import DailyTransaction from './components/SaloonChainAdmin/DailyTransactions/DailyTransaction';
import AdvancePayment from './components/SaloonChainAdmin/AdvancePayment/AdvancePayment';
import Leaves from './components/SaloonChainAdmin/Leaves/Leaves';
import NewEmployee from './components/SaloonChainAdmin/Employees/NewEmployee';
import EmployeeList from './components/SaloonChainAdmin/Employees/EmployeeList';
import EditEmployee from './components/SaloonChainAdmin/Employees/EditEmployee';
import NewService from './components/SaloonChainAdmin/Services/NewService';
import EditService from './components/SaloonChainAdmin/Services/EditService';
import ServiceList from './components/SaloonChainAdmin/Services/ServiceList';
import NewIncentiveRule from './components/SaloonChainAdmin/IncentiveRules/NewIncentiveRule';
import EditIncentiveRule from './components/SaloonChainAdmin/IncentiveRules/EditIncentiveRule';
import IncentiveRuleList from './components/SaloonChainAdmin/IncentiveRules/IncentiveRuleList';
import IncentiveEmployeeList from './components/SaloonChainAdmin/IncentiveEmployees/IncentiveEmployeeList';
import NewAdvancePaymentForAdmin from './components/SaloonChainAdmin/AdvancePayment/NewAdvancePaymentForAdmin'
import BranchList from './components/SaloonChainAdmin/Branch/BranchList';
import NewBranch from './components/SaloonChainAdmin/Branch/NewBranch';
import EditBranch from './components/SaloonChainAdmin/Branch/EditBranch';
import LeaveList from './components/BranchManagerAdmin/ManageLeaves/LeaveList';
import NewLeave from './components/BranchManagerAdmin/ManageLeaves/NewLeave';
import EditLeave from './components/SaloonChainAdmin/Leaves/EditLeave';
import NewAdvancePayment from './components/BranchManagerAdmin/ManageAdvancePayments/NewAdvancePayment';
import DailyTransactionList from './components/BranchManagerAdmin/ManageTransactions/DailyTransactionsList';
import LeaveReport from './components/SaloonChainAdmin/Leaves/LeaveReport';
import ManagerAdvancePayment from './components/BranchManagerAdmin/ManageAdvancePayments/ManagerAdvancePayment';
import EditDailyTransaction from './components/SaloonChainAdmin/DailyTransactions/EditDailyTransaction';
import TransactionReport from './components/SaloonChainAdmin/DailyTransactions/TransactionReport';
import EditAdvancePayment from './components/SaloonChainAdmin/AdvancePayment/EditAdvancePayment';
import AddDailyTransaction from './components/BranchManagerAdmin/ManageTransactions/AddDailyTransaction';
import ManageLeaveReport from './components/BranchManagerAdmin/ManageLeaves/ManageLeaveReport';
import ManageDailyTransactionReport from './components/BranchManagerAdmin/ManageTransactions/ManageDailyTransactionReport';
import GenerateSalary from './components/SaloonChainAdmin/Salary/GenerateSalary';
import SalarySlip from './components/SaloonChainAdmin/Salary/SalarySlip';
import ChangePassword from './components/ChangePassword';
import ManageDailyTransactionReportByDate from './components/BranchManagerAdmin/ManageTransactions/ManageDailyTransactionReportByDate';
import TransactionReportByDate from './components/SaloonChainAdmin/DailyTransactions/TransactionReportByDate';
import Setting from './components/Setting';
import ManagerEmployeeList from './components/BranchManagerAdmin/ManageEmployees/ManagerEmployeeList';
import ManagerServicesList from './components/BranchManagerAdmin/ManageServices/ManagerServicesList';
import ManageDailyTransactionReportByEmployee from './components/BranchManagerAdmin/ManageTransactions/ManageDailyTransactionReportByEmployee';
import TransactionReportByEmployee from './components/SaloonChainAdmin/DailyTransactions/TransactionReportByEmployee';
import TransactionReportByAmount from './components/SaloonChainAdmin/DailyTransactions/TransactionReportByAmount';
import PreviewSalarySlip from './components/SaloonChainAdmin/Salary/PreviewSalarySlip';
import ManageDailyTransactionByAmount from './components/BranchManagerAdmin/ManageTransactions/ManageDailyTransactionByAmount';
import AddCustomer from './components/BranchManagerAdmin/ManageCustomers/AddCustomer';
import ManagerCustomerList from './components/BranchManagerAdmin/ManageCustomers/ManagerCustomerList';
import ManagerCustomerHistory from './components/BranchManagerAdmin/ManageCustomers/ManagerCustomerHistory';
import CustomerList from './components/SaloonChainAdmin/Customers/CustomerList';
import CustomerHistory from './components/SaloonChainAdmin/Customers/CustomerHistory';
import ManagerCustomerBill from './components/BranchManagerAdmin/ManageCustomers/ManagerCustomerBill';
import ProductList from './components/SaloonChainAdmin/Products/ProductList';
import NewProduct from './components/SaloonChainAdmin/Products/NewProduct';
import ManageProductList from './components/BranchManagerAdmin/ManageProducts/ManageProductList';
import AddProduct from './components/BranchManagerAdmin/ManageProducts/AddProduct';
import ExpensesList from './components/SaloonChainAdmin/Expenses/ExpensesList';
import ExpenseHeadList from './components/SaloonChainAdmin/Expenses/ExpenseHeadList';
import AddExpenseHead from './components/SaloonChainAdmin/Expenses/AddExpenseHead';
import AddExpense from './components/SaloonChainAdmin/Expenses/AddExpense';
import ManagerExpensesList from './components/BranchManagerAdmin/ManageExpenses/ManagerExpensesList';
import ManagerAddExpense from './components/BranchManagerAdmin/ManageExpenses/ManagerAddExpense';
import ManagerAddExpenseHead from './components/BranchManagerAdmin/ManageExpenses/ManagerAddExpenseHead';
import ManagerExpenseHeadList from './components/BranchManagerAdmin/ManageExpenses/ManagerExpenseHeadList';
import EditExpense from './components/SaloonChainAdmin/Expenses/EditExpense';
import EditProduct from './components/SaloonChainAdmin/Products/EditProduct';
import CustomerDues from './components/SaloonChainAdmin/Customers/CustomerDues';
import ManagerCustomerDues from './components/BranchManagerAdmin/ManageCustomers/ManagerCustomerDues';
import { useEffect } from 'react';
import DailyTransactionsListForStaff from './components/Staff/StaffTransaction/DailyTransactionsListForStaff';
import AdvancePaymentForStaff from './components/Staff/StaffAdvance/AdvancePaymentForStaff';
import LeaveListForStaff from './components/Staff/StaffLeaves/LeaveListForStaff';
import IncentiveRulesForStaff from './components/Staff/StaffIncentiveRules/IncentiveRulesForStaff';
import EditProducts from './components/BranchManagerAdmin/ManageProducts/EditProducts';
import ManagerEditDailyTransaction from './components/BranchManagerAdmin/ManageTransactions/ManagerEditDailyTransaction';
import AddDailyTransactionAdmin from './components/SaloonChainAdmin/DailyTransactions/AddDailyTransactionAdmin';
import AdvancePaymentReport from './components/SaloonChainAdmin/AdvancePayment/AdvancePaymentReport';
import NewLeaveAdmin from './components/SaloonChainAdmin/Leaves/NewLeaveAdmin';
import ProductTransaction from './components/SaloonChainAdmin/DailyTransactions/ProductTransaction';
import ProductTransactionReport from './components/SaloonChainAdmin/DailyTransactions/ProductTransactionReport';
import BalanceSheet from './components/SaloonChainAdmin/BalanceSheets/BalanceSheet';

export const App = () => {

    const authUser = useSelector((state: any) => state.authUserInfo.value)
    const localInfo = localStorage.getItem("authInfo")
    console.log("local", localInfo)
    const navigate = useNavigate();

    useEffect(() => {
       const token = localStorage.getItem('token');
        if (token && authUser.isLogin && authUser.roleValue == "SCM") {
            navigate('/saloonChain');
        } else if (token && authUser.isLogin && authUser.roleValue == "BRM") {
            navigate('/branchManager');
        }
        const auth = localStorage.getItem("authInfo")
        if (auth == "" || auth == null) {
            navigate('/');
        }
    }, []);

    return <>


        {authUser.isLogin ? (
            <>

                <Routes>
                    {/* <Route path='/transactionReport' element={< TransactionReport />} /> */}
                    {/* <Route path='/TransactionReportByDate' element={<TransactionReportByDate />} /> */}
                    {/* <Route path='/TransactionReportByEmployee' element={<TransactionReportByEmployee />} /> */}

                    <Route path='/saloonChain' element={<Dashboard />}>
                        <Route index element={<Home />} />
                        
                        <Route path='branchDetails' element={< BranchList />} />
                        <Route path='newBranch' element={< NewBranch />} />
                        <Route path='editBranch' element={< EditBranch />} />

                        <Route path='newEmployee' element={< NewEmployee />} />
                        <Route path='employeeDetails' element={< EmployeeList />} />
                        <Route path='editEmployee' element={< EditEmployee />} />

                        <Route path='newIncentiveRule' element={< NewIncentiveRule />} />
                        <Route path='editIncentiveRule' element={< EditIncentiveRule />} />
                        <Route path='incentiveRuleDetails' element={< IncentiveRuleList />} />

                        <Route path='incentiveEmployeeDetails' element={< IncentiveEmployeeList />} />
                        {/* <Route path='newIncentiveEmployee' element={< NewIncentiveEmployee />} /> */}

                        <Route path='dailyTransaction' element={< DailyTransaction />} />
                        <Route path='transactionProductsList' element={< ProductTransaction />} />

                        <Route path='addDailyTransaction' element={< AddDailyTransactionAdmin />} />

                        <Route path='editDailyTransaction' element={< EditDailyTransaction />} />
                        <Route path='transactionReport' element={< TransactionReport />} />
                        <Route path='transactionReportOfProducts' element={< ProductTransactionReport />} />

                        <Route path='transactionReportByDate' element={<TransactionReportByDate />} />
                        <Route path='transactionReportByEmployee' element={<TransactionReportByEmployee />} />
                        <Route path='transactionReportByAmount' element={<TransactionReportByAmount />} />


                        <Route path='advancePayment' element={< AdvancePayment />} />
                        <Route path='newadvancePayment' element={< NewAdvancePaymentForAdmin />} />

                        <Route path='editAdvancePayment' element={< EditAdvancePayment />} />
                        <Route path='advancePaymentReport' element={< AdvancePaymentReport />} />


                        <Route path='leaves' element={< Leaves />} />
                        <Route path='editleave' element={<EditLeave />} />
                        <Route path='report' element={<LeaveReport />} />
                        <Route path='newLeave' element={<NewLeaveAdmin />} />


                        <Route path='newservice' element={< NewService />} />
                        <Route path='editService' element={< EditService />} />
                        <Route path='serviceDetails' element={< ServiceList />} />
                        <Route path='setting' element={<Setting />} />


                        <Route path='salary' element={< GenerateSalary />} />
                        <Route path='salarySlip' element={< SalarySlip />} />
                        <Route path='previewSalarySlip' element={< PreviewSalarySlip />} />

                        <Route path='changePassword' element={<ChangePassword />}></Route>

                        <Route path='customerList' element={< CustomerList />} />
                        <Route path='customerHistory' element={<CustomerHistory />} />
                        <Route path='customerDues' element={<CustomerDues />} />
                        <Route path='customerBill' element={<ManagerCustomerBill />} />

                        <Route path='productDetails' element={< ProductList />} />
                        <Route path='newProduct' element={<NewProduct />} />
                        <Route path='updateProduct' element={<EditProduct />} />

                        <Route path='expenseHeadList' element={<ExpenseHeadList />} />
                        <Route path='newExpenseHead' element={<AddExpenseHead />} />
                        <Route path='expenseList' element={<ExpensesList />} />
                        <Route path='newExpense' element={<AddExpense />} />
                        <Route path='editExpense' element={<EditExpense />} />

                        <Route path='balanceSheet' element={<BalanceSheet />} />

                    </Route>
                </Routes>

                <Routes>
                    <Route path='/branchManager' element={<Dashboard />}>
                        <Route index element={<Home />} />

                        <Route path='employeeslist' element={<ManagerEmployeeList />} />

                        <Route path='serviceslist' element={<ManagerServicesList />} />

                        <Route path='dailytransactionslist' element={<DailyTransactionList />} />
                        <Route path='transactionProductsList' element={< ProductTransaction />} />

                        <Route path='addDailyTransaction' element={<AddDailyTransaction />} />
                        <Route path='managerTransactionReport' element={<ManageDailyTransactionReport />} />
                        <Route path='managerTransactionByDate' element={<ManageDailyTransactionReportByDate />} />
                        <Route path='managerTransactionByEmployee' element={<ManageDailyTransactionReportByEmployee />} />

                        <Route path='managerTransactionByAmount' element={<ManageDailyTransactionByAmount />} />
                        <Route path='editDailyTransaction' element={< ManagerEditDailyTransaction />} />
                        <Route path='transactionReport' element={< TransactionReport />} />

                        <Route path='leavelist' element={<LeaveList />} />
                        <Route path='managerLeaveReport' element={<ManageLeaveReport />} />
                        <Route path='newleave' element={<NewLeave />} />
                        <Route path='editleave' element={<EditLeave />} />


                        <Route path='managerAdvancePayment' element={< ManagerAdvancePayment />} />
                        <Route path='newadvancePayment' element={<NewAdvancePayment />} />
                        <Route path='editAdvancePayment' element={< EditAdvancePayment />} />

                        <Route path='changePassword' element={<ChangePassword />}></Route>

                        <Route path='managerCustomerList' element={< ManagerCustomerList />} />
                        <Route path='managerCustomerHistory' element={<ManagerCustomerHistory />} />
                        <Route path='addCustomer' element={<AddCustomer />} />
                        <Route path='managerCustomerBill' element={<ManagerCustomerBill />} />
                        <Route path='managerCustomerDues' element={<ManagerCustomerDues />} />

                        <Route path='productDetails' element={< ManageProductList />} />
                        <Route path='addProduct' element={<AddProduct />} />
                        <Route path='editProduct' element={< EditProducts />} />

                        <Route path='managerExpenseHeadList' element={<ManagerExpenseHeadList />} />
                        <Route path='managerNewExpenseHead' element={<ManagerAddExpenseHead />} />
                        <Route path='managerExpenseList' element={<ManagerExpensesList />} />
                        <Route path='managerNewExpense' element={<ManagerAddExpense />} />

                    </Route>
                </Routes>


                <Routes>
                    <Route path='/staff' element={<Dashboard />}>
                        <Route index element={<Home />} />
                        <Route path='changePassword' element={<ChangePassword />}></Route>

                        <Route path='myleaves' element={<LeaveListForStaff />} />

                        <Route path='mytransactions' element={<DailyTransactionsListForStaff />} />
                        <Route path='myadvanceamount' element={<AdvancePaymentForStaff />} />
                        <Route path='myincentives' element={<IncentiveRulesForStaff />} />



                    </Route>
                </Routes>
            </>
        ) :
            <Routes>
                <Route path='/' element={<Login />}></Route>
                <Route path='' element={<Login />}></Route>
                <Route path='/CustomerBill' element={<ManagerCustomerBill />} />
                <Route path='/login' element={<Login />}></Route>
                <Route path='/changePassword' element={<ChangePassword />}></Route>

                <Route path='/register' element={<Register />}></Route>
                <Route path='*' element={<Login />}></Route>
            </Routes>
        }


        <Routes>
            <Route path='/CustomerBill' element={<ManagerCustomerBill />} />
        </Routes>

        {/* {authUser.isLogin && <Footer />} */}
    </>
}






