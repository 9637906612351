
import { json, Link, useNavigate } from "react-router-dom";
import { faSearch, faBell, faPowerOff, faCog, faUser, faInfo, faLock, faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { authDetailReducer } from "../reduxData/authSlice";

import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from "react";
import logo from "../../public/images/Saloonist_Logo/newLogo.png";
import logoSmall from "../../public/images/Saloonist_Logo/newSmallLogo.png"; // Add small logo image
import logomin from "../../public/images/Saloonist_Logo/Saloonist_Icon.png";
import dummy from "../../public/images/Saloonist_Logo/dummy.jpeg";

import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import AuthApiService, { urls } from "../apiServices/AuthApiService";
export default function Header() {
    const authUser = useSelector((state: any) => state.authUserInfo.value)
    const loginInfo = JSON.parse(localStorage.getItem('loginData'));
    const [userid, setUserId] = useState('')

    const [srole, setRole] = useState([])
    const SERVER = process.env.BASEURL;
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        const token = localStorage.getItem('authInfo');
        if (token) {
            const decodedToken: any = jwtDecode(token);
            const currentTime = Date.now() / 1000; // Convert milliseconds to seconds
            if (currentTime > decodedToken.exp) {
                logout();
            } else {

                const timeout = setTimeout(logout, (decodedToken.exp - currentTime) * 1000); // Convert seconds to milliseconds
                return () => clearTimeout(timeout);
            }
        }
        // Add event listener for beforeunload
        window.addEventListener('beforeunload', handleBeforeUnload);
        // Cleanup function to remove event listener
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, []);

    const handleBeforeUnload = (event: any) => {
        event.preventDefault();
        logout();
    };

    console.log(`${SERVER}/${authUser.saloon_logo}`)
    const logout = () => {
        dispatch(authDetailReducer({
            saloonName: undefined,
            uid: undefined,
            token: undefined,
            isLogin: false,
            firstname: undefined,
            lastname: undefined,
            role: undefined,
            roleName: undefined,
        }))
        localStorage.removeItem("authInfo")
        localStorage.removeItem("token")
        localStorage.removeItem("loginData")
        localStorage.removeItem("empinfo")
        localStorage.removeItem("leaveReportInfo")
        localStorage.removeItem("transactioninfo")
        localStorage.removeItem("billinfo")
        localStorage.removeItem("leaveinfo")
        localStorage.removeItem("serviceinfo")
        localStorage.removeItem("incentiveruleinfo")
        localStorage.removeItem("customerhistoryinfo")
        localStorage.removeItem("productinfo")
        navigate("/")
    }

    const forStaffRole = () => {
        if (loginInfo?.roles?.length === 2) {
            const loginInfo = JSON.parse(localStorage.getItem('loginData'));
            const role = loginInfo?.roles?.filter((r: any) => r.role_id != authUser.role)
            setRole(role)
            console.log("role", role)
        }
    }
    useEffect(() => {
        forStaffRole()
    }, [])

    const switchLogin = async (selectedRoleId: any) => {
        const logininfo = JSON.parse(localStorage.getItem('loginData'));
        const brmRole = logininfo.roles.find((role: any) => role.role_id === 4);
        const saloon = brmRole ? brmRole.additional_info : undefined;
        const currency = brmRole ? brmRole.currencyInfo : undefined;
        const setting = brmRole ? brmRole.settingInfo : undefined;
        const user = loginInfo?.user_info?.id
        console.log(user)
        console.log(selectedRoleId)
        const confirm = window.confirm(selectedRoleId == 4 ? "Do You want to Login as Branch Manager ? " : "Do You want to Login as Employee/Staff ? ")
        if (confirm) {
            const ob = {
                user_id: user,
                role_id: selectedRoleId
            }
            console.log("ob", ob, urls.REGENERATE_TOKEN)
            try {

                console.log("ob", ob, urls.REGENERATE_TOKEN)
                console.log("Login Info Data :", loginInfo.token)
                const response = await AuthApiService.PostApiCallWithUrl(urls.REGENERATE_TOKEN, ob, loginInfo.token);
                console.log("response data :", response);
                console.log("ob", ob, urls.REGENERATE_TOKEN)
                if (response.data.status === true) {
                    console.log(brmRole, saloon, currency, setting)
                    if (selectedRoleId == 4) {
                        const d = dispatch(
                            authDetailReducer({
                                uid: response.data.user_id,
                                token: response.data.token,
                                isLogin: true,
                                firstname: logininfo.user_info.first_name,
                                lastname: logininfo.user_info.last_name,
                                role: 4,
                                roleValue: "BRM",
                                roleName: brmRole.role_name,
                                saloonName: saloon ? saloon.branch_name : null,
                                saloon_id: saloon ? saloon.id : null,
                                saloon_logo: setting ? setting.logo : null,
                                currency: currency ? currency.symbol : null

                            })
                        );
                        console.log("branch manager", d);
                        navigate('/branchManager');
                    }
                    else
                        if (selectedRoleId == 5) {
                            const d = dispatch(
                                authDetailReducer({
                                    uid: response.data.user_id,
                                    token: response.data.token,
                                    isLogin: true,
                                    firstname: logininfo.user_info.first_name,
                                    lastname: logininfo.user_info.last_name,
                                    role: 5,
                                    roleValue: "STF",
                                    roleName: "Staff",
                                    saloonName: saloon ? saloon.branch_name : "",
                                    saloon_id: saloon ? saloon.id : "",
                                    saloon_logo: setting ? setting.logo : "",
                                    currency: currency ? currency.symbol : ""
                                })
                            );
                            console.log("staff", d);
                            navigate('/staff');
                        }
                }
            }
            catch (error) {
                console.error("Switch login error:", error);
                console.error("Error in switchLogin:", error);
            }
        }
    };


    // const switchLogin = async (selectedRoleId: any) => {
    //     // const logininfo = JSON.parse(localStorage.getItem('loginData'));
    //     // const brmRole = logininfo.roles.find((role: any) => role.role_id === 4);
    //     // const saloon = brmRole ? brmRole.additional_info : undefined;
    //     // const currency = brmRole ? brmRole.currencyInfo : undefined;
    //     // const setting = brmRole ? brmRole.settingInfo : undefined;

    //     console.log(selectedRoleId)
    //     const confirm = window.confirm(selectedRoleId == 4 ? "Do You want to Login as Branch Manager ? " : "Do You want to Login as Employee/Staff ? ")
    //     if (confirm) {
    //         const ob = {
    //             user_id: loginInfo.user_info.id,
    //             role_id: parseInt(selectedRoleId)
    //         }
    //         console.log("ob", ob, urls.REGENERATE_TOKEN)
    //         try {
    //             console.log("ob", ob, urls.REGENERATE_TOKEN)
    //             console.log(loginInfo.token)
    //             const response = await AuthApiService.PostApiCallWithUrl(urls.REGENERATE_TOKEN, ob, loginInfo.token);
    //             console.log("response", response);
    //             console.log("ob", ob, urls.REGENERATE_TOKEN)
    //             if (response.data.status === true) {
    //                 // console.log(brmRole, saloon, currency, setting)
    //                 if (selectedRoleId == 4) {
    //                     const d = dispatch(
    //                         authDetailReducer({
    //                             uid: response.data.user_id,
    //                             token: response.data.token,
    //                             isLogin: true,
    //                             firstname: loginInfo?.user_info.first_name,
    //                             lastname: loginInfo?.user_info.last_name,
    //                             role: "4",
    //                             roleValue: "BRM",
    //                             saloonName: loginInfo?.roles?.additional_info?.name ?? "",
    //                             saloon_id: loginInfo?.roles?.additional_info?.id ?? "",
    //                             saloon_logo: loginInfo?.roles?.settingInfo ? loginInfo?.roles?.settingInfo?.logo : "",
    //                             currency: loginInfo?.roles?.currencyInfo == null ? "" : loginInfo?.roles?.currencyInfo?.symbol
    //                         })
    //                     );
    //                     console.log("branch manager", d);
    //                     navigate('/branchManager');
    //                 }
    //                 else
    //                     if (selectedRoleId == 5) {

    //                         const d = dispatch(
    //                             authDetailReducer({
    //                                 uid: response.data.user_id,
    //                                 token: response.data.token,
    //                                 isLogin: true,
    //                                 firstname: loginInfo.user_info.first_name,
    //                                 lastname: loginInfo.user_info.last_name,
    //                                 role: 5,
    //                                 roleValue: "STF",
    //                                 saloonName: loginInfo?.roles?.additional_info?.name ? loginInfo?.roles?.additional_info?.name : "",
    //                                 saloon_id: loginInfo?.roles?.additional_info?.id ? loginInfo?.roles?.additional_info?.id : "",
    //                                 saloon_logo: loginInfo?.roles?.settingInfo ? loginInfo?.roles?.settingInfo?.logo : "",
    //                                 currency: loginInfo?.roles?.currencyInfo == null ? "" : loginInfo?.roles?.currencyInfo?.symbol
    //                             })
    //                         );
    //                         console.log("staff", d);
    //                         navigate('/staff');
    //                     }
    //             }
    //         }
    //         catch (error) {
    //             console.error("Switch login error:", error);
    //             console.error("Error in switchLogin:", error);
    //         }
    //     }
    // };


    return <>
        <nav className="navbar navbar-expand-sm navbar-light bg-white fixed-top d-print-none">

            <div className="text-center navbar-brand-wrapper mx-2 d-flex align-items-center">
                <Link className="mx-1 d-lg-none " to="" data-bs-toggle="offcanvas" data-bs-target="#offcanvasResponsive" aria-controls="offcanvasResponsive">
                    <img src={logomin} className="img-fluid small-logo d-lg-none d-md-none" alt="Small Logo" />

                    {/* <FontAwesomeIcon icon={faBars} className="fontIcon" /> */}
                </Link>
                <Link className="navbar-brand brand-logo" to="">
                    <img src={logo} className="img-fluid large-logo d-none d-lg-block d-md-block" alt="Large Logo" />
                </Link>
                <Link className="navbar-brand brand-logo" to="">
                    <img src={logoSmall} className="img-fluid d-lg-none d-md-none" alt="Small Logo" />
                </Link>
            </div>

            <div className="collapse navbar-collapse  " id="navbarNav">
                <ul className="navbar-nav ">
                    <li className="nav-item dropdown fontIcon ">
                        <div className="nav-link fontIcon">
                            <h6>{authUser.saloon_logo ? <img src={`${SERVER}/${authUser.saloon_logo}`} className="img-fluid " height="50px" width="50px" /> : <img src={dummy} className="img-fluid " height="50px" width="50px" />}{authUser.saloonName}</h6>
                        </div>
                    </li>
                </ul>

                <ul className="navbar-nav ms-auto">
                    <li className="nav-item dropdown fontIcon mr-0">
                        <div className="nav-link fontIcon">
                            <h6 className=" text-center ">{authUser.firstname}&nbsp;{authUser.lastname}<br></br>
                                <h6 style={{ "fontSize": "15px", alignItems: "center" }} className="text-dark text-center font-weight-normal font-monospace" >({authUser.roleName})</h6></h6>
                        </div>
                    </li>
                    {/* {authUser.roleValue != "SCM" ?
                        <li className="nav-item dropdown fontIcon ">
                            <div className="nav-link fontIcon">
                                <h6><b className="form-check form-switch">
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onClick={() => switchLogin(role[0].role_id)} />
                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Switch to {role.map((r: any) => <b>{r.role_name}</b>)}</label>
                                    </div>
                                </b></h6>
                            </div>
                        </li> : <></>} */}


                    {loginInfo?.roles?.length >= 2 ? (
                        <li className="nav-item dropdown fontIcon">
                            <div className="nav-link fontIcon">

                                <select className="form-select border" id="roleSelect" onChange={(e) => switchLogin(e.target.value)}>
                                    <option disabled selected>switch to</option>
                                    {srole?.map((r: any) => (
                                        <option key={r.role_id} value={r.role_id}>{r.role_name}</option>
                                    ))}
                                </select>


                            </div>
                        </li>
                    ) : <></>}






                    <li className="nav-item  dropdown">
                        <Link className="nav-link dropdown-toggle fontIcon" to="#" data-toggle="dropdown" id="profileDropdown" data-bs-toggle="dropdown">
                            <FontAwesomeIcon icon={faUser} className="fontIcon" />
                        </Link>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="profileDropdown">
                            {authUser.roleValue === "SCM" ? <><li>
                                <Link className="dropdown-item" to="setting">
                                    <FontAwesomeIcon icon={faCog} className="fontIcon" /> Settings
                                </Link>
                            </li>
                                <li>
                                    <Link className="dropdown-item" to="./changePassword">
                                        <FontAwesomeIcon icon={faLock} className="fontIcon" /> Change Password
                                    </Link>
                                </li>
                                <li>
                                    <b className="dropdown-item" style={{ cursor: "pointer" }} onClick={logout} >
                                        <FontAwesomeIcon icon={faPowerOff} className="fontIcon" /> Logout
                                    </b>
                                </li></> : <>
                                <li>
                                    <Link className="dropdown-item" to="./changePassword">
                                        <FontAwesomeIcon icon={faLock} className="fontIcon" /> Change Password
                                    </Link>
                                </li>
                                <li>
                                    <b className="dropdown-item" style={{ cursor: "pointer" }} onClick={logout} >
                                        <FontAwesomeIcon icon={faPowerOff} className="fontIcon" /> Logout
                                    </b>
                                </li></>}
                        </ul>

                    </li>

                </ul>




            </div>
            <Link className="mx-3 d-lg-none d-md-none" to="" data-bs-toggle="offcanvas" data-bs-target="#offcanvasResponsive" aria-controls="offcanvasResponsive">
                <FontAwesomeIcon icon={faBars} className="fontIcon" />
            </Link>
        </nav >
    </>
}



