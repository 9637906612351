import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { Link } from "react-router-dom";
import Header from "../../Header";

export default function TransactionReportByDate() {
  const admin = useSelector((state: any) => state.authUserInfo.value);
  const { date, branchinfo } = useSelector((state: any) => state.transactionInfo.upData);
  const [isLoading, setLoading] = useState(false);
  const [Date, setDate] = useState(date);
  const [totalSum, setTotalSum] = useState(0);
  const [transactionInfo, setTransactionInfo] = useState([]);
  const [employeeName, setEmployeeName] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  console.log(transactionInfo, date, branchinfo);

  const loadingSpinner = () => {
    return <div className="container-fluid mt-5"><div className="d-flex justify-content-center align-items-center">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
    </div>
  }

  const handleDateReport = async () => {
    setLoading(true);
    const url = urls.TRANSACTION_LIST_BY_DATE + branchinfo + "/" + date;
    console.log("url", url)
    const res = await AuthApiService.GetApiCall(url, admin.token);
    console.log(res);
    if (res.data.length == 0) {
      setDate(date);
      setLoading(false)
    }
    else if (res.status === 200 && res.data.length != 0) {
      setLoading(false);
      const data = res.data.transactions[0];
      const { date, total_sum, transaction_info } = data;
      setDate(date);
      setTotalSum(total_sum);
      setTransactionInfo(transaction_info);

      const empName = [];
      for (let ti of transaction_info) {
        var nm = ti.emp_name + "\n" + ti.emp_id;
        empName.push(nm);
      }
      setEmployeeName(empName);

      var final = [];
      for (let ti of transaction_info) {
        var arr = []
        for (let ta of ti.transaction_details) {
          var a = ta.amount + " (" + ta.payment_mode + ")";
          arr.push(a);
        }
        final.push(arr);
      }
      console.log(final);

      var maxLength = 0;
      for (let subArray of final) {
        if (Array.isArray(subArray) && subArray.length > maxLength) {
          maxLength = subArray.length;
        }
      }
      console.log(maxLength)

      var finalarr = []
      for (let i = 0; i <= maxLength - 1; i++) {
        let arr = [];
        for (let j of final) {
          for (let k = i; k <= maxLength; k++) {
            if (j[i] == undefined) {
              arr.push("0")
            } else {
              let a = j[i];
              arr.push(a);
            }
            break;
          }
        }
        finalarr.push(arr);
      }
      setEmployeeData(finalarr);

    }
  }
  useEffect(() => { handleDateReport() }, []);
  const handlePrint = () => {
    window.print();
  };
  return <>


    <div className="content-wrapper p-2 p-xl-0">
      <div className="container-fluid">

        <div className="row m-0 mt-xl-1 mt-4 d-print-none">
          {/* <div className="col-lg-12 col-md-12 col-sm-12 "> */}
          <div className="col-lg-2 col-md-2 col-sm-3 col-2 mt-2 d-flex justify-content-start">
            <Link to="/saloonChain/transactionReport">
              <span>
                <button className=" btn btn-sm btn-purple">Back</button>
              </span>
            </Link>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-6 col-6">

          </div>
          <div className="col-lg-2 col-md-2 col-sm-3 col-4 mt-2 d-flex justify-content-end">
            <Link to="">
              <span>
                <button className=" btn btn-sm btn-purple" onClick={handlePrint}>Print PDF</button>
              </span>
            </Link>
          </div>
          {/* </div> */}
        </div>
      </div>
      <h4 className="text-center my-3">Branchwise Transaction Report On Date : {Date.split('-').reverse().join('-')}</h4>
      <div className="table-responsive">
        <table id="report-table" className="table table-striped  table-hover border-light-subtle ">
          <thead>
            <tr className="text-center"><th colSpan={3} className="text-center">Overall Total</th></tr>
          </thead>
          <tbody>
            <tr className="text-center">
              <th className="btn-cash">Cash Amount (INR)</th>
              <th className="btn-online">Online Amount (INR)</th>
              <th className="btn-Total">Total Amount (INR)</th>
            </tr>
          </tbody>
          <tfoot>
            <tr className="text-center">
              <th style={{ color: "blue" }}>{totalSum ? totalSum?.cash_amount : 0}</th>
              <th className="btn-onlineText">{totalSum ? totalSum?.online_amount : 0}</th>
              <th>{totalSum ? totalSum?.total_sum : 0}</th>
            </tr>
          </tfoot>
        </table>
      </div>
      <div className="table-responsive">
        <table id="report-table" className="table table-striped  table-hover border-light-subtle ">
          <thead>
            <tr className="text-center"><th></th>{employeeName.map((data) => { return <th>{data}</th> })}</tr>
          </thead>
          <tbody>
            {employeeData?.map((data) => { return <tr className="text-center"><td></td>{data?.map((da: any) => { return <td style={{ color: da.includes("Cash") ? "blue " : "red" }}>{da.split("(", 1)}</td> })}</tr> })}
          </tbody>
          <tfoot>
            <tr><td className="text-center btn-cash">
              <b >Total Cash</b>
            </td>
              {transactionInfo.length == 0 ? <td>0</td> : transactionInfo.map((data) => { return <td style={{ color: "blue", fontWeight: "bold" }} className="text-center">{data.amount_sum.cash_amount}</td> })}</tr>

            <tr>
              <td className="text-center btn-online">
                <b>Total Online</b>
              </td>
              {transactionInfo.length == 0 ? <td>0</td> : transactionInfo.map((data) => { return <td style={{ color: "red", fontWeight: "bold" }} className="text-center">{data.amount_sum.online_amount}</td> })}</tr>
            <tr>
              <td className="text-center btn-Total">
                <b>Total Sum</b>
              </td>
              {transactionInfo.length == 0 ? <td>0</td> : transactionInfo.map((data) => { return <td style={{ fontWeight: "bold" }} className="text-center">{data.amount_sum.total_sum}</td> })}</tr>
          </tfoot>
        </table>
      </div>
    </div>

  </>
}
