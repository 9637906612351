// import { Link, useNavigate } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowDown, faArrowUp, faEdit, faPlus, faSort } from "@fortawesome/free-solid-svg-icons";
// import React, { useState, useEffect } from "react";
// import Select from "react-select";
// import { useDispatch, useSelector } from "react-redux";
// import { listBranchServicesReducer, updateBranchServicesReducer } from "../../../reduxData/branchServicesSlice";
// import AuthApiService, { urls } from "../../../apiServices/AuthApiService";

// export default function ServiceList() {
//   const _ = require("lodash")
//   const [expandedRowIndex, setExpandedRowIndex] = useState(null);
//   const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

//   const admin = useSelector((state: any) => state.authUserInfo.value);
//   const branchServicesInfo = useSelector((state: any) => state.branchServicesInfo.value);
//   const branchInfo = useSelector((state: any) => state.branchInfo.value);
//   const [sortedlist, setsortedlist] = useState(branchServicesInfo);
//   const [sort, setsort] = useState(false);
//   const [val, setval] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 10;
//   const dispatch = useDispatch();
//   const [branchSearchValue, setBranchSearchValue] = useState();

//   const navigate = useNavigate();

//   const totalRecords = branchServicesInfo?.pagination?.totalRecords;
//   console.log("total records", totalRecords, branchServicesInfo)
//   const totalPages = Math.ceil(totalRecords / itemsPerPage);


//   useEffect(() => {
//     const fetchData = async () => {
//       await branchservicelist(currentPage, itemsPerPage);
//     };

//     fetchData();
//   }, [currentPage, itemsPerPage]);


//   const generatePageNumbers = () => {
//     const pageNumbers = [];
//     const maxPageButtons = 3;

//     if (totalPages <= maxPageButtons) {
//       for (let i = 1; i <= totalPages; i++) {
//         pageNumbers.push(i);
//       }
//     } else {
//       const leftEllipsis = currentPage > 2;
//       const rightEllipsis = currentPage < totalPages - 1;

//       if (leftEllipsis) {
//         pageNumbers.push(1, '...');
//       }

//       const start = Math.max(1, currentPage - 1);
//       const end = Math.min(totalPages, currentPage + 1);

//       for (let i = start; i <= end; i++) {
//         pageNumbers.push(i);
//       }

//       if (rightEllipsis) {
//         pageNumbers.push('...', totalPages);
//       }
//     }

//     return pageNumbers;
//   };

//   const handleChange = async (page: any) => {
//     setCurrentPage(page);
//     await branchservicelist(page, itemsPerPage);
//   };

//   const toggleRowExpansion = (index: any) => {
//     setExpandedRowIndex(expandedRowIndex === index ? null : index);
//   };

//   const renderRowExpansionContent = (data: any) => {
//     console.log(data)
//     return (<><th></th>
//       {<tr><td className="text-wrap">
//         <strong>Description:</strong><span>{data.description != "" ? data.description : '-'}</span>
//       </td></tr>}
//       {<tr><td className="text-wrap">
//         <strong>Gender:</strong><span>{data.serviceMasterInfo?.gender}</span>
//       </td></tr>}
//       {<tr><td className="text-wrap">
//         <strong>Category:</strong><span>{data.serviceMasterInfo?.service_cat_master_info?.category_title}</span>
//       </td></tr>}
//     </>

//     );
//   };


//   const edit = (data: any) => {
//     console.log(data);
//     localStorage.setItem("serviceinfo", JSON.stringify({
//       id: data?.id, branch_id: data?.branch_id, service_id: data?.service_id,
//       service_charge: data?.service_charge, description: data?.description
//     }))
//     dispatch(updateBranchServicesReducer(data));
//     navigate("/saloonChain/editService");
//   };
//   const sortByServiceName = () => {
//     setsort(!sort);
//     const sortedServices = _.orderBy(branchServicesInfo, (item: any) => item.serviceMasterInfo.service_title, sort ? 'desc' : 'asc');
//     dispatch(listBranchServicesReducer(sortedServices));
//   }

//   const branchOptions = branchInfo && Array.isArray(branchInfo) ? [{ value: "All", label: "All" }, ...branchInfo.map((item: any) => ({
//     value: item.id,
//     label: item.branch_name,
//   }))] : [];

//   // const onBranchSearch = async (data: any, page: any, itemsPerPage: any) => {
//   //   var b = data.value
//   //   setLoading(true);
//   //   let url = `${urls.BRANCH_SERVICES_LIST_PAGINATION}/${page}/${itemsPerPage}`;
//   //   if (b !== "All") {
//   //     url = `${urls.BRANCH_SERVICES_BY_ID}/${page}/${itemsPerPage}?branchId=${b}`;
//   //   }
//   //   console.log(url)
//   //   try {
//   //     const res = await AuthApiService.SearchApiCallWithQuery(url, b, admin.token);
//   //     // console.log(res, res?.data?.pagination)
//   //     if (res.status) {
//   //       dispatch(listBranchServicesReducer(res.data));
//   //       // setRecord(res?.data?.pagination)
//   //     }
//   //   } catch (error) {
//   //     console.error(error);
//   //   } finally {
//   //     setLoading(false);
//   //   }
//   // };



//   const onBranchSearch = async (data: any, page: any, itemsPerPage: any) => {


//     try {
//       setLoading(true);
//       let queryParams: any = {}
//       if (queryParams) {
//         if (data && data.value && data.value !== "All") {
//           queryParams.branchId = data.value;
//         }
//         const queryString = new URLSearchParams(queryParams).toString();
//         let url = `${urls.BRANCH_SERVICES_LIST_PAGINATION}/${page}/${itemsPerPage}`;
//         if (queryString) {
//           url += `?${queryString}`;
//         }

//         const res = await AuthApiService.SearchApiCallWithQuery(url, {}, admin.token);
//         // console.log(res, res?.data?.pagination)
//         if (res.status) {
//           dispatch(listBranchServicesReducer(res.data));
//           // setRecord(res?.data?.pagination)
//         }
//       }
//       else {
//         await branchservicelist(currentPage, itemsPerPage);
//       }
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const loadingSpinner = () => {
//     return (
//       <div className="d-flex justify-content-center align-items-center">
//         <div className="spinner-border" role="status">
//           <span className="visually-hidden">Loading...</span>
//         </div>
//       </div>
//     );
//   }

//   const branchservicelist = async (page: number, itemsPerPage: number) => {
//     try {
//       setLoading(true)
//       const URL = `${urls.BRANCH_SERVICES_LIST_PAGINATION}/${page}/${itemsPerPage}`;
//       const res = await AuthApiService.GetApiCallWithPagination(URL, admin.token, page, itemsPerPage);
//       console.log("branch service", res)

//       if (res.status) {
//         dispatch(listBranchServicesReducer(res.data));
//         console.log(res)
//       }
//     } catch (error) {
//       // setMsg(error);
//     } finally {
//       setLoading(false)
//     }

//   };

//   const handleWindowResize = () => {
//     setIsMobile(window.innerWidth < 768);
//   };

//   // useEffect(() => {
//   //   branchservicelist(currentPage, itemsPerPage);
//   // }, [currentPage, itemsPerPage]);



//   useEffect(() => {
//     window.addEventListener('resize', handleWindowResize);

//     return () => {
//       window.removeEventListener('resize', handleWindowResize);
//     };
//   }, []);

//   return (
//     <>
//       <div className="container-fluid p-0">
//         <div className="container">
//           <div className="row">
//             <div className="col-lg-10 col-md-8 col-sm-8">
//               <div className="text-center">
//                 <h4>Services</h4>
//               </div>
//             </div>
//             <div className="col-lg-2 col-md-4 col-sm-4 mt-2 d-flex justify-content-end">

//               <Link to="/saloonChain/newService">
//                 <button className="btn btn-sm btn-purple">
//                   <FontAwesomeIcon icon={faPlus} />
//                   &nbsp;New Service
//                 </button>
//               </Link>

//             </div>
//           </div>
//         </div>
//         <div className="row mt-2 mt-xl-4 pb-4 p-1">
//           <div className="col-lg-3 col-md-3 col-sm-12">
//             <label>Select Branch</label>
//             <Select
//               styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
//               menuPortalTarget={document.body}
//               menuPosition="fixed"
//               className="basic-single"
//               classNamePrefix="select"
//               defaultValue={"All"}
//               placeholder="All"
//               isLoading={branchInfo.length <= 0 ? true : false}
//               isClearable={true}
//               isSearchable={true}
//               options={branchOptions}
//               onChange={(e: string) => { setval(e); onBranchSearch(e, currentPage, itemsPerPage) }}
//               value={val}
//             />
//           </div>
//         </div>

//         {loading || branchServicesInfo?.data?.length <= 0 ? (
//           <div className="mt-4">
//             {loading ? (
//               // Display loading spinner
//               <div className="d-flex justify-content-center align-items-center">
//                 {loadingSpinner()}
//               </div>
//             ) : (
//               // Display custom message for no records
//               <h5>No Services found. You can add New Services.</h5>
//             )}
//           </div>
//         ) : <div className="mt-2 mt-xl-2 mt-sm-2 justify-content-center">
//           <div className={`table-container ${isMobile ? 'table-fixed' : 'table-responsive'}`}>
//             <table className="table table-striped table-hover border-light-subtle">
//               <thead>
//                 <tr>
//                   <th></th>
//                   <th className="text-wrap">Branch</th>
//                   <th className="text-wrap" style={{ cursor: "pointer" }} onClick={() => { sortByServiceName() }}>
//                     Service &nbsp;
//                     <FontAwesomeIcon icon={faSort} cursor="pointer" onClick={() => { sortByServiceName() }} />
//                   </th>
//                   {!isMobile && <th className="text-center">Category</th>}

//                   <th className="text-center">{isMobile ? <>Charges < br /> (INR)</> : <> Charges (INR)</>}</th>
//                   {!isMobile && <th>Gender</th>}

//                   {!isMobile && <th>Description</th>}
//                   {/* {!isMobile && <th>Status</th>} */}
//                   {isMobile && <th></th>}

//                 </tr>
//               </thead>
//               <tbody>
//                 {branchServicesInfo?.data?.map((data: any, index: any) => {
//                   const isExpanded = expandedRowIndex === index;
//                   return (
//                     <React.Fragment key={index}>
//                       <tr>
//                         <td
//                           className="fontIcon"
//                           onClick={() => edit(data)}
//                           style={{ cursor: "pointer" }}
//                         >
//                           <FontAwesomeIcon
//                             icon={faEdit}
//                             className="fontIcon"
//                             size="sm"
//                           />
//                         </td>
//                         <td className="text-wrap">{data.branchinfo?.branch_name}</td>
//                         <td className="text-wrap">{data.serviceMasterInfo?.service_title}</td>
//                         {!isMobile && <td className="text-center text-wrap">{data.serviceMasterInfo?.service_cat_master_info?.category_title}</td>}

//                         <td className="text-center">{data?.service_charge}</td>

//                         {!isMobile && <td className="text-wrap">{data.serviceMasterInfo?.gender}</td>}

//                         {!isMobile && <td>{data.description ? data.description : '-'}</td>}
//                         {/* {!isMobile && <td>-</td>} */}
//                         {isMobile && (
//                           <td>
//                             {!isExpanded ? (
//                               <button
//                                 className="btn btn-link"
//                                 onClick={() => toggleRowExpansion(index)}
//                               >
//                                 <FontAwesomeIcon
//                                   icon={faArrowDown}
//                                   className="fontIcon"
//                                   size="sm"
//                                 />
//                               </button>
//                             ) : (
//                               <button
//                                 className="btn btn-link"
//                                 onClick={() => toggleRowExpansion(index)}
//                               >
//                                 <FontAwesomeIcon
//                                   icon={faArrowUp}
//                                   className="fontIcon"
//                                   size="sm"
//                                 />
//                               </button>
//                             )}
//                           </td>
//                         )}
//                       </tr>
//                       {isExpanded && isMobile && (
//                         <tr>
//                           <td colSpan="5">{renderRowExpansionContent(data)}</td>
//                         </tr>
//                       )}
//                     </React.Fragment>
//                   );
//                 })}
//               </tbody>
//             </table>
//             <nav aria-label="Page navigation example">
//               <ul className="pagination">
//                 <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
//                   <button
//                     className="page-link "
//                     onClick={() => handleChange(currentPage - 1)}
//                     disabled={currentPage === 1 || loading}
//                   >
//                     Previous
//                   </button>
//                 </li>
//                 {generatePageNumbers().map((page, index) => (
//                   <li
//                     key={index}
//                     className={`page-item ${page === currentPage ? 'active' : ''}`}
//                   >
//                     {page === '...' ? (
//                       <span className="page-link">...</span>
//                     ) : (
//                       <button
//                         className="page-link btn-sm"
//                         onClick={() => handleChange(page)}
//                         disabled={loading}
//                       >
//                         {page}
//                       </button>
//                     )}
//                   </li>
//                 ))}
//                 <li
//                   className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
//                 >
//                   <button
//                     className="page-link btn-sm"
//                     onClick={() => handleChange(currentPage + 1)}
//                     disabled={loading || currentPage === totalPages}
//                   >
//                     Next
//                   </button>
//                 </li>
//               </ul>
//             </nav>
//           </div>
//         </div>
//         }
//       </div>
//     </>
//   );
// }




import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp, faEdit, faPlus, faSort } from "@fortawesome/free-solid-svg-icons";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { listBranchServicesReducer, updateBranchServicesReducer } from "../../../reduxData/branchServicesSlice";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";

export default function ServiceList() {
  const _ = require("lodash")
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const admin = useSelector((state: any) => state.authUserInfo.value);
  const branchServicesInfo = useSelector((state: any) => state.branchServicesInfo.value);
  const branchInfo = useSelector((state: any) => state.branchInfo.value);
  const [sortedlist, setsortedlist] = useState(branchServicesInfo);
  const [sort, setsort] = useState(false);
  const [val, setval] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleRowExpansion = (index: any) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const renderRowExpansionContent = (data: any) => {
    return (<><th></th>
      {<tr><td className="text-wrap">
        <strong>Description:</strong><span>{data.description != "" ? data.description : '-'}</span>
      </td></tr>}
      {<tr><td className="text-wrap">
        <strong>Gender:</strong><span>{data.serviceMasterInfo?.gender}</span>
      </td></tr>}
      {<tr><td className="text-wrap">
        <strong>Category:</strong><span>{data.serviceMasterInfo?.service_cat_master_info?.category_title}</span>
      </td></tr>}
    </>

    );
  };


  const edit = (data: any) => {
    localStorage.setItem("serviceinfo", JSON.stringify({
      id: data?.id, branch_id: data?.branch_id, service_id: data?.service_id,
      service_charge: data?.service_charge, description: data?.description
    }))
    dispatch(updateBranchServicesReducer(data));
    navigate("/saloonChain/editService");
  };
  const sortByServiceName = () => {
    setsort(!sort);
    const sortedServices = _.orderBy(branchServicesInfo, (item: any) => item.serviceMasterInfo.service_title, sort ? 'desc' : 'asc');
    dispatch(listBranchServicesReducer(sortedServices));
  }

  const branchOptions = branchInfo && Array.isArray(branchInfo) ? [{ value: "All", label: "All" }, ...branchInfo.map((item: any) => ({
    value: item.id,
    label: item.branch_name,
  }))] : [];

  const onBranchSearch = async (data: any) => {
    if (data !== null && data?.value != "All") {
      setLoading(true)
      const url = `${urls.BRANCH_SERVICES_BY_ID}?branchId=${data.value}`;
      const res = await AuthApiService.SearchApiCallForEmployeeAndBranch(url, data.value, admin.token);
      if (res.status) {
        setLoading(false)
        dispatch(listBranchServicesReducer(res.data.data))
      }
    } else {
      await branchservicelist()
    }
  };

  const loadingSpinner = () => {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  const branchservicelist = async () => {
    try {
      const res = await AuthApiService.GetApiCall(urls.BRANCH_SERVICES_LIST, admin.token);

      if (res.status) {
        dispatch(listBranchServicesReducer(res.data.data));
      }
    } catch (error) {
      // setMsg(error);
    }

  };

  const handleWindowResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    branchservicelist();
  }, [])

  useEffect(() => {
    if (branchInfo.length === 1) {
      const singleBranch = branchInfo[0];
      const singleBranchOption = { value: singleBranch.id, label: singleBranch.branch_name };
      setSelectedBranch(singleBranchOption);
      onBranchSearch(singleBranchOption);
    }
  }, [branchInfo]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <>
      <div className="container-fluid p-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-md-8 col-sm-8">
              <div className="text-center">
                <h4>Services</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4 mt-2 d-flex justify-content-end">

              <Link to="/saloonChain/newService">
                <button className="btn btn-sm btn-purple">
                  <FontAwesomeIcon icon={faPlus} />
                  &nbsp;New Service
                </button>
              </Link>

            </div>
          </div>
        </div>
        <div className="row mt-2 mt-xl-4 pb-4 p-1">
          <div className="col-lg-3 col-md-3 col-sm-12">
            <label>Select Branch</label>
            {/* <Select
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              className="basic-single"
              classNamePrefix="select"
              defaultValue={"All"}
              placeholder="All"
              isLoading={branchInfo.length <= 0 ? true : false}
              isClearable={true}
              isSearchable={true}
              options={branchOptions}
              onChange={(e: string) => { setval(e); onBranchSearch(e) }}
              value={val}
            /> */}
            <Select
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              className="basic-single"
              classNamePrefix="select"
              placeholder="All"
              isLoading={branchInfo.length <= 0}
              isClearable={true}
              isSearchable={true}
              options={branchOptions}
              onChange={(e: any) => { setSelectedBranch(e); onBranchSearch(e); }}
              value={selectedBranch}
            />
          </div>
        </div>

        {loading || branchServicesInfo.length <= 0 ? (
          <div className="mt-4">
            {loading ? (
              // Display loading spinner
              <div className="d-flex justify-content-center align-items-center">
                {loadingSpinner()}
              </div>
            ) : (
              // Display custom message for no records
              <h5>No Services found. You can add New Services.</h5>
            )}
          </div>
        ) : <div className="mt-2 mt-xl-2 mt-sm-2 justify-content-center">
          <div className={`table-container ${isMobile ? 'table-fixed' : 'table-responsive'}`}>
            <table className="table table-striped table-hover border-light-subtle">
              <thead>
                <tr>
                  <th></th>
                  <th className="text-wrap">Branch</th>
                  <th className="text-wrap" style={{ cursor: "pointer" }} onClick={() => { sortByServiceName() }}>
                    Service &nbsp;
                    <FontAwesomeIcon icon={faSort} cursor="pointer" onClick={() => { sortByServiceName() }} />
                  </th>
                  {!isMobile && <th className="text-center">Category</th>}

                  <th className="text-center">{isMobile ? <>Charges < br /> (INR)</> : <> Charges (INR)</>}</th>
                  {!isMobile && <th>Gender</th>}

                  {!isMobile && <th>Description</th>}
                  {/* {!isMobile && <th>Status</th>} */}
                  {isMobile && <th></th>}

                </tr>
              </thead>
              <tbody>
                {branchServicesInfo?.map((data: any, index: any) => {
                  const isExpanded = expandedRowIndex === index;
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td
                          className="fontIcon"
                          onClick={() => edit(data)}
                          style={{ cursor: "pointer" }}
                        >
                          <FontAwesomeIcon
                            icon={faEdit}
                            className="fontIcon"
                            size="sm"
                          />
                        </td>
                        <td className="text-wrap">{data.branchinfo?.branch_name}</td>
                        <td className="text-wrap">{data.serviceMasterInfo?.service_title}</td>
                        {!isMobile && <td className="text-center text-wrap">{data.serviceMasterInfo?.service_cat_master_info?.category_title}</td>}

                        <td className="text-center">{data?.service_charge}</td>

                        {!isMobile && <td className="text-wrap">{data.serviceMasterInfo?.gender}</td>}

                        {!isMobile && <td>{data.description ? data.description : '-'}</td>}
                        {/* {!isMobile && <td>-</td>} */}
                        {isMobile && (
                          <td>
                            {!isExpanded ? (
                              <button
                                className="btn btn-link"
                                onClick={() => toggleRowExpansion(index)}
                              >
                                <FontAwesomeIcon
                                  icon={faArrowDown}
                                  className="fontIcon"
                                  size="sm"
                                />
                              </button>
                            ) : (
                              <button
                                className="btn btn-link"
                                onClick={() => toggleRowExpansion(index)}
                              >
                                <FontAwesomeIcon
                                  icon={faArrowUp}
                                  className="fontIcon"
                                  size="sm"
                                />
                              </button>
                            )}
                          </td>
                        )}
                      </tr>
                      {isExpanded && isMobile && (
                        <tr>
                          <td colSpan="5">{renderRowExpansionContent(data)}</td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        }
      </div>
    </>
  );
}

