import { faArrowDown, faArrowUp, faSearch, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import React from "react";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { listCustomerTransactionReducer } from "../../../reduxData/customerSlice";

export default function CustomerHistory() {
    const admin = useSelector((state: any) => state.authUserInfo.value);
    const [expandedRowIndex, setExpandedRowIndex] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const _ = require("lodash");
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const dispatch = useDispatch()
    const branchInfo = useSelector((state: any) => state.branchInfo.value);
    const customerHistoryInfo = useSelector((state: any) => state.customerInfo.upData);
    const localCustomerHistoryInfo = JSON.parse(localStorage.getItem("customerhistoryinfo"))
    const customerTransactionInfo = useSelector((state: any) => state.customerInfo.value2);
    const currentDate = new Date().toISOString().substring(0, 10);
    const [fromDate, setFromDate] = useState()
    const [toDate, setToDate] = useState()
    const [searching, setSearching] = useState(false);

    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();

    const [month, setMonth] = useState(`${currentYear}-0${currentMonth}`);


    const branchOptions = [{ value: "All", label: "All" }, ...branchInfo.map((item: any) => ({
        value: item.id,
        label: item.branch_name,
    }))];

    const totalRecords = customerTransactionInfo?.pagination?.totalRecords

    const totalPages = Math.ceil(totalRecords / itemsPerPage);
    const generatePageNumbers = () => {
        const pageNumbers = [];
        const maxPageButtons = 3;

        if (totalPages <= maxPageButtons) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            const leftEllipsis = currentPage > 2;
            const rightEllipsis = currentPage < totalPages - 1;

            if (leftEllipsis) {
                pageNumbers.push(1, '...');
            }

            const start = Math.max(1, currentPage - 1);
            const end = Math.min(totalPages, currentPage + 1);

            for (let i = start; i <= end; i++) {
                pageNumbers.push(i);
            }

            if (rightEllipsis) {
                pageNumbers.push('...', totalPages);
            }
        }

        return pageNumbers;
    };

    const toggleRowExpansion = (index: any) => {
        setExpandedRowIndex(expandedRowIndex === index ? null : index);
    };

    const renderRowExpansionContent = (data: any) => {
        return (<><th></th>
            {/* {<tr><td className="text-wrap"><strong>Service:</strong> {data.service_info.map((data2: any) => <>{data2.service_type ?? data2.product_name}<span>({data2.quantity ?? data2.quantity})</span><br /></>)}</td>
            </tr>} */}
            {<tr><td><strong>Amount:</strong> {data.service_info.map((data2: any) => <>{data2.amount}<br /></>)}</td></tr>}
        </>
        );
    };


    const handleWindowResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    const CustomerHistory = async (page: number, itemsPerPage: number, fromDate: any, toDate: any) => {

        const data = { from_date: fromDate, to_date: toDate }

        try {
            setLoading(true)
            const url = `${urls.CUSTOMER_TRANSACTIONS}/${page}/${itemsPerPage}/${customerHistoryInfo?.id ?? localCustomerHistoryInfo?.id}`;
            const res = await AuthApiService.SearchApiCallWithQuery(url, data, admin.token)
            if (res.status) {
                dispatch(listCustomerTransactionReducer(res.data));
            }
        } catch (error) {
            setMsg("Network Error !");
        } finally {
            setLoading(false)
        }
    }

    const handleChange = async (page: number) => {
        setCurrentPage(page);
        // if (searchInput != '') {
        //     await onSearch(searchInput, page, itemsPerPage)
        // }
        // else {
        await CustomerHistory(page, itemsPerPage, fromDate, toDate);
        // }
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        CustomerHistory(currentPage, itemsPerPage, fromDate, toDate)
    }, [])

    return <>
        <div className="container-fluid pt-2">
            <div className="container">
                <div className="row m-0">

                    <div className="col-lg-2 col-md-2 col-sm-3 col-2 mt-2 d-flex justify-content-start">
                        <Link to="/saloonChain/customerList">
                            <span>
                                <button className=" btn btn-sm btn-purple">Back</button>
                            </span>
                        </Link>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <div className="text-center">
                            <h4>Transaction History of Customer</h4>
                            <h5>Name-{customerHistoryInfo?.name ?? localCustomerHistoryInfo?.name} </h5>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2 mt-2 d-flex justify-content-end">
                        <Link to="/saloonChain/customerList">
                            <button className="btn btn-sm btn-purple">All Customers</button>
                        </Link>
                    </div>
                    {/* <div className="col-lg-1 col-md-1 col-sm-1 mt-2 d-flex justify-content-end">
                        <Link to="/branchManager/managerTransactionReport">
                            <button className="btn btn-sm btn-purple">Report</button>
                        </Link>
                    </div> */}
                </div>
                <div className="row mt-2 mt-xl-4 pb-4 p-1 align-items-end">
                    <div className="col-lg-2 col-md-2 col-sm-12 col-4 m-0 ">
                        <label>From Date:</label>

                        <div className="input-group input-group-md">
                            <input
                                type="date"
                                className="form-control shadow-md"
                                placeholder="Search..."
                                value={fromDate}
                                max={currentDate}
                                onChange={(e) => {
                                    if (new Date(e.target.value) > new Date(toDate)) {
                                        setToDate(e.target.value); setFromDate(e.target.value);
                                    } else {
                                        setFromDate(e.target.value)
                                    }
                                }}
                            /></div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-4 m-0 p-0 mr-xl-2 ">
                        <label>To Date:</label>

                        <div className="input-group input-group-md">
                            <input
                                type="date"
                                className="form-control shadow-md"
                                placeholder="Search..."
                                value={toDate}
                                max={currentDate}
                                min={fromDate}
                                onChange={(e) => {
                                    setToDate(e.target.value)
                                }}
                            /></div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-4 m-0 ml-1 ml-xl-2">
                        <button className="btn btn-purple" onClick={() => { CustomerHistory(1, itemsPerPage, fromDate, toDate); setSearching(true); setCurrentPage(1) }}>Submit</button>
                    </div>
                    {searching && <div className="col-lg-2 col-md-2 col-sm-12 pt-1 pt-xl-0">
                        <button className="btn btn-danger" onClick={() => { CustomerHistory(1, itemsPerPage, null, null); setSearching(false); setCurrentPage(1) }}>Reset</button>
                    </div>}
                </div>
            </div>

            {
                loading || customerTransactionInfo?.data?.length === 0 ? (
                    <div className="mt-4">
                        {loading ? (
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>) : (
                            <h5>No Records found.</h5>
                        )}
                    </div>
                ) :
                    <div className="mt-2 mt-xl-2 mt-sm-2 justify-content-center">
                        <div className={`table-container table-responsive`}>
                            <table id="report-table" className="table table-striped  table-hover border-light-subtle ">
                                <thead>
                                    <tr>
                                        <th>Total Amount</th>
                                        <th>Total Paid</th>
                                        <th>Total Due</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{customerTransactionInfo?.due_report?.total_amount}</td>
                                        <td>{customerTransactionInfo?.due_report?.total_paid}</td>
                                        <td>{customerTransactionInfo?.due_report?.total_due}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={`table-container table-responsive`}>
                            <table className="table table-striped  table-hover border-light-subtle " id={isMobile ? '' : 'report-table'}>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Service</th>
                                        <th>Qty.</th>
                                        {!isMobile && <th>Amount</th>}
                                        <th>Payment</th>
                                        {/* <th>Due</th> */}
                                        {isMobile && <th></th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        customerTransactionInfo?.data?.map((data: any, index: any) => {
                                            const isExpanded = expandedRowIndex === index;
                                            return (<React.Fragment>
                                                <tr key={data.id}>
                                                    <td>{new Date(data.transaction_date).toLocaleString('en-GB', { dateStyle: "short" })}</td>
                                                    <td>{data.service_info.map((data2: any) => <>{data2.service_type ?? data2.product_name}<br /></>)}</td>
                                                    <td>{data.service_info.map((data2: any) => <>{data2.quantity}<br /></>)}</td>
                                                    {!isMobile && <td>{data.service_info.map((data2: any) => <>{data2.amount}<br /></>)}</td>}
                                                    <td className={`${isMobile ? 'text-wrap' : 'text-nowrap'}`}><b>Total :</b>{data.amount}<br /><b>Paid :</b>{data.paymentInfo[0].amount_paid}<br /><b>Due :</b>{data.dues ?? "0"}<br /><b>Mode :</b>{data.paymentInfo[0].payment_mode}</td>
                                                    {/* <td>{data.dues}</td> */}
                                                    {isMobile && (
                                                        <td>
                                                            {!isExpanded ? (
                                                                <button
                                                                    className="btn btn-link"
                                                                    onClick={() => toggleRowExpansion(index)}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faArrowDown}
                                                                        className="fontIcon"
                                                                        size="sm"
                                                                    />
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    className="btn btn-link"
                                                                    onClick={() => toggleRowExpansion(index)}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faArrowUp}
                                                                        className="fontIcon"
                                                                        size="sm"
                                                                    />
                                                                </button>
                                                            )}
                                                        </td>
                                                    )}
                                                </tr>
                                                {isExpanded && isMobile && (
                                                    <tr>
                                                        <td colSpan="5">{renderRowExpansionContent(data)}</td>
                                                    </tr>
                                                )}
                                            </React.Fragment>
                                            )
                                        }
                                        )
                                    }
                                </tbody>
                            </table>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                        <button
                                            className="page-link "
                                            onClick={() => handleChange(currentPage - 1)}
                                            disabled={currentPage === 1 || loading}
                                        >
                                            Previous
                                        </button>
                                    </li>
                                    {generatePageNumbers().map((page, index) => (
                                        <li
                                            key={index}
                                            className={`page-item ${page === currentPage ? 'active' : ''}`}
                                        >
                                            {page === '...' ? (
                                                <span className="page-link">...</span>
                                            ) : (
                                                <button
                                                    className="page-link btn-sm"
                                                    onClick={() => handleChange(page)}
                                                    disabled={loading}
                                                >
                                                    {page}
                                                </button>
                                            )}
                                        </li>
                                    ))}
                                    <li
                                        className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
                                    >
                                        <button
                                            className="page-link btn-sm"
                                            onClick={() => handleChange(currentPage + 1)}
                                            disabled={loading || currentPage === totalPages}
                                        >
                                            Next
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>}
        </div >
    </>
}