import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import AuthApiService, { urls } from "../apiServices/AuthApiService";
import { Alert, Snackbar } from "@mui/material";
import { authDetailReducer } from "../reduxData/authSlice";
export default function ChangePassword() {
    const authInfo = useSelector((state: any) => state.authUserInfo.value);
    const dispatch = useDispatch();
    const [status, setStatus] = useState();
    const navigate = useNavigate()
    const [msgs, setMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const validationSchema = Yup.object({
        opassword: Yup.string().required("Old Password is required"),
        password: Yup.string().required("New Password is required").min(6, "new Password must be at least 6 characters"),
        cpassword: Yup.string()
            .required("Confirm New Password is required")
            .oneOf([Yup.ref("password"), ""], "Confirm Passwords must match"),
    });


    const logout = () => {
        dispatch(authDetailReducer({
            token: undefined,
            isLogin: false
        }))
        localStorage.removeItem("authInfo")
        navigate("/")

    }

    const formik = useFormik({
        initialValues: {
            password: "",
            created_by: "",
            updated_by: "",
            cpassword: "",
            opassword: ""
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const created_by = authInfo.uid;
                const updated_by = authInfo.uid;
                const ob = {
                    password: values.password,
                    created_by: created_by,
                    updated_by: updated_by
                }
                console.log("ob", ob)
                const url = urls.UPDATE_USER + authInfo.uid
                const resp = await AuthApiService.PutApiCall(url, ob, authInfo.token)
                console.log("resp", resp)
                if (resp.status === 200) {
                    handleClickOpen();
                    setMsg(resp.data.msg);
                    setStatus(resp.data.status)
                    if (resp.data.status == 201) {
                        formik.resetForm()
                        setTimeout(() => {

                            logout()
                        }, 2000)
                    } else {
                        setTimeout(() => {
                            navigate('/saloonChain/changePassword')
                        }, 2000)
                    }
                }
            } catch (error: any) {
                console.log("Error:", error);
                if (error.response && error.response.status === 400 && error.response.data && error.response.data.msg) {
                    setMsg(error.response.data.msg);
                } else {
                    setMsg("Error");
                }
                setStatus(false);
                handleClickOpen()

            }
            finally {
                setTimeout(() => {
                    setLoading(false);


                }, 2000)
            }
        }
    })

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return <>
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
        >
            <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msgs}</Alert>
        </Snackbar>
        <div className="login-page">
            <div className="container-fluid ">
                <div className="row w-100 h-100 mx-0">
                    <div className="col-lg-4 mx-auto">

                        <h4 className="font-weight-light text-center">Update Your Password</h4>
                        <form className="pt-3" onSubmit={formik.handleSubmit}>
                            <div className="form-group mb-3">
                                <label>Old Password</label>
                                <input type="password" id="opassword" name="opassword" value={formik.values.opassword} className={`form-control shadow-sm ${formik.touched.opassword && formik.errors.opassword ? "is-invalid" : ""}`} placeholder="Old Password" onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} />
                                <div className="invalid-feedback">{formik.touched.opassword && formik.errors.opassword}</div>
                            </div>
                            <div className="form-group mb-3">
                                <label>New Password</label>
                                <input type="password"
                                    className={`form-control shadow-sm ${formik.touched.password && formik.errors.password ? "is-invalid" : ""}`}
                                    id="password"
                                    placeholder="Enter Password"
                                    name="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} />
                                <div className="invalid-feedback">{formik.touched.password && formik.errors.password}</div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Confirm Password</label>
                                <input type="password"
                                    className={`form-control shadow-sm ${formik.touched.cpassword && formik.errors.cpassword ? "is-invalid" : ""}`}
                                    id="cpassword"
                                    placeholder="Enter Confirm Password"
                                    name="cpassword"
                                    value={formik.values.cpassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <div className="invalid-feedback">{formik.touched.cpassword && formik.errors.cpassword}</div>
                            </div>

                            <div className="mt-3 text-center">
                                <button className="btn btn-purple form-control" type="submit" >

                                    {loading ? "Updating..." : "Update"}
                                </button>
                            </div>
                            {/* <div className="my-2 d-flex justify-content-between align-items-center">
                                    <Link to="/" className="text-primary">
                                        Login to continue
                                    </Link>
                                </div> */}
                            {/* <div className="font-weight-light">
                                    Don't have an account?
                                    <Link to="/register" className="text-primary">
                                        Create
                                    </Link>
                                </div> */}
                            <b className="text-danger"></b>
                        </form>
                    </div>
                    {/* </div> */}
                </div>
            </div>
        </div>
    </>
}