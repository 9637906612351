import { faEdit, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { GenSalaryReducer } from "../../../reduxData/SalarySlice";
import Select from "react-select";
import { Alert, Snackbar } from "@mui/material";
import * as Yup from 'yup';
import { branch } from "../../../validations/Validations";
import { useFormik } from "formik";

export default function BalanceSheet() {
    const [msg, setMsg] = useState("");
    const [open, setOpen] = useState(false);
    const [checkMsg, setCheckMsg] = useState("");
    const [showSave, setShowSave] = useState(false);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState();
    const [balanceData, setBalanceData] = useState({}); // Initialize as null
    const [showTable, setShowTable] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const admin = useSelector((state: any) => state.authUserInfo.value);
    const branchInfo = useSelector((state: any) => state.branchInfo.value);
    const currentDate = new Date().toISOString().substring(0, 7);
    const [showMonthlyForm, setShowMonthlyForm] = useState(true);
    const [showDateWiseForm, setShowDateWiseForm] = useState(false);
    const current = new Date().toISOString().substring(0, 10)
    const [fromDate, setFromDate] = useState(currentDate)
    const [selectedBranchName, setSelectedBranchName] = useState("");
  const [selectedBranch, setSelectedBranch] = useState(null);

    const location = useLocation();

    const branchOptions = [{ value: "All", label: "All" }, ...branchInfo.map((item: any) => ({
        value: item.id,
        label: item.branch_name,
    }))];

    useEffect(() => {
        if (branchInfo.length === 1) {
          const singleBranch = branchInfo[0];
          const singleBranchOption = { value: singleBranch.id, label: singleBranch.branch_name };
          setSelectedBranch(singleBranchOption);
        //   onBranchSearch(singleBranchOption);
        }
      }, [branchInfo]);

    const formik = useFormik({
        initialValues: {
            branch_id: "",
            month: currentDate,
            from_date: "",
            to_date: ""
        },
        validationSchema: branch,
        onSubmit: async (values) => {
            try {
                setShowSave(false);
                setLoading(true);
                let url = urls.BALANCE_SHEET;

                if (showMonthlyForm) {
                    if (values.branch_id !== "All" && values.branch_id !== "") {
                        url += `?year_month=${values.month}&branch_id=${values.branch_id}`;
                    } else {
                        url += `?year_month=${values.month}`;
                    }
                } else if (showDateWiseForm) {
                    if (values.branch_id !== "All" && values.branch_id !== "") {
                        url += `?from_date=${values.from_date}&to_date=${values.to_date}&branch_id=${values.branch_id}`;
                    } else {
                        url += `?from_date=${values.from_date}&to_date=${values.to_date}`;
                    }
                }

                const res = await AuthApiService.SearchApi(url, admin.token);
                if (res.status && res.data) {
                    setBalanceData(res.data.data);
                    setShowTable(true);
                    if (values.branch_id !== "All" && values.branch_id !== "") {
                        const selectedBranch = branchOptions.find(item => item.value === values.branch_id);
                        setSelectedBranchName(selectedBranch ? selectedBranch.label : "");
                    } else {
                        setSelectedBranchName("All");
                    // handleClickOpen();
                    // setMsg("")}
                    }
                } else {
                    setBalanceData(null);
                    handleClickOpen();
                    setMsg("Something went wrong")
                }
            } catch (error) {
                handleClickOpen();
                setMsg("Something went wrong")
            } finally {
                setLoading(false);
            }
        }
    });

    useEffect(() => {
        if (location.state != null && location.state.message === "saved") {
            formik.setValues({ branch_id: location.state.branchid, month: location.state.date });
            formik.handleSubmit();
        }
    }, []);

    const handleMonthlyClick = () => {
        setShowMonthlyForm(true);
        setShowDateWiseForm(false);
        setShowTable(false);
        setSelectedBranchName("")
        formik.setFieldValue("branch_id" ,"")
    };

    const handleDateWiseClick = () => {
        setShowDateWiseForm(true);
        setShowMonthlyForm(false);
        setShowTable(false);
        setSelectedBranchName("")
        formik.setFieldValue("branch_id" ,"")
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const loadingSpinner = () => {
        return (
            <div className="d-flex justify-content-center">
                <div className="spinner-border">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    };

    return (
        <div className="container-fluid p-0">
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status === true) ? "success" : checkMsg === "Salary already created for the specified branch and month." ? "warning" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert>
            </Snackbar>
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 col-md-10 col-sm-10">
                        <div className="text-center">
                            <h4>Balance Sheet</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-2 mt-xl-4 pb-0 p-1 d-flex flex-row">
                <div className="col-lg-12 col-md-12 justify-content-center">
                    {showDateWiseForm && (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row m-0 p-0">
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <label>Branch</label>
                                    <Select
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        menuPortalTarget={document.body}
                                        menuPosition="fixed"
                                        className="basic-single"
                                        classNamePrefix="select"
                                        // defaultValue={"All"}
                                        placeholder="All"
                                        isLoading={branchInfo.length <= 0 ? true : false}
                                        isClearable={true}
                                        isSearchable={true}
                                        options={branchOptions}
                                        onChange={(selectedOption) => {
                                            formik.setFieldValue("branch_id", selectedOption ? selectedOption.value : ""); setShowTable(false); setSelectedBranch(selectedOption);
                                        }}
                                        value={selectedBranch}
                                    />
                                    {formik.touched.branch_id && formik.errors.branch_id ? (
                                        <div className="text-danger">{formik.errors.branch_id}</div>
                                    ) : null}
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-2 m-0">
                                    <label>From Date:</label>
                                    <div className="input-group input-group-md">
                                        <input
                                            type="date"
                                            className="form-control shadow-sm"
                                            value={formik.values.from_date}
                                            onChange={(e) => formik.setFieldValue("from_date", e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-2 m-0 p-0 mr-xl-2">
                                    <label>To Date:</label>
                                    <div className="input-group input-group-md">
                                        <input
                                            type="date"
                                            className="form-control shadow-sm"
                                            value={formik.values.to_date}
                                            onChange={(e) => formik.setFieldValue("to_date", e.target.value)}
                                            min={formik.values.from_date}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-1 col-md-1 col-sm-1 col-1 m-0 ml-1 ml-xl-2">
                                    <label></label>
                                    <br />

                                    <button type="submit" className="btn btn-md btn-purple" >View</button>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2">
                                    <label></label>
                                    <br />

                                    <button type="button" className="btn btn-md btn-darkblue form-control" onClick={handleMonthlyClick}>Monthly</button>
                                </div>
                            </div>
                        </form>
                    )}

                    {showMonthlyForm && (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row m-0 p-0">
                                <div className="row m-0 p-0">
                                    <div className="col-lg-4 col-md-4 col-sm-4">
                                        <label>Branch</label>
                                        <Select
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            menuPortalTarget={document.body}
                                            menuPosition="fixed"
                                            className="basic-single"
                                            classNamePrefix="select"
                                            // defaultValue={"All"}
                                            placeholder="All"
                                            isLoading={branchInfo.length <= 0 ? true : false}
                                            isClearable={true}
                                            isSearchable={true}
                                            options={branchOptions}
                                            onChange={(selectedOption) => {
                                                formik.setFieldValue("branch_id", selectedOption ? selectedOption.value : ""); setShowTable(false);
                                                setSelectedBranch(selectedOption);
                                            }}
                                            value={selectedBranch}
                                        />
                                        {formik.touched.branch_id && formik.errors.branch_id ? (
                                            <div className="text-danger">{formik.errors.branch_id}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-3">
                                        <label>Month</label>
                                        <input
                                            type="month"
                                            className="form-control shadow-sm"
                                            name="month"
                                            max={currentDate}
                                            value={formik.values.month}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                    <div className="col-lg-1 col-md-1 col-sm-1 col-1 m-0 ml-1 ml-xl-2">
                                        <label></label>
                                        <br />

                                        <button type="submit" className="btn btn-md btn-purple">View</button>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-2">
                                        <label></label>
                                        <br />
                                        <button type="button" className="btn btn-md btn-darkblue form-control" onClick={handleDateWiseClick}>Date Wise</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )}
                </div>
            </div>

            {/* Show table only when balanceData has data */}
            {loading ? (
                loadingSpinner()
            ) : showTable && (
                <div className="row mt-2 mt-xl-4 pb-0 p-1 justify-content-between ">
                    <div className="col-12 col-xl-8 offset-xl-2">
                        <table className="table table-bordered table w-75">
                            <thead>
                                <tr>
                                <th colSpan={2} className="text-center">
                               <h5> {formik.values.branch_id === "" ? "Balance of all branches" : `Balance of ${selectedBranchName}`}</h5>
                                  </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Transaction Amount</th>
                                    <td>{balanceData?.total_transaction || 0}</td>
                                </tr>
                                <tr>
                                    <th>Employee Advance</th>
                                    <td>{balanceData?.total_advance || 0}</td>
                                </tr>
                                <tr>
                                    <th>Expenses</th>
                                    <td>{balanceData?.total_expenses || 0}</td>
                                </tr>
                                <tr>
                                    <th>Others</th>
                                    <td>0</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr className="table-secondary">
                                    <th>Total Balance</th>
                                    <td>{balanceData?.profitloss || 0}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
}






// import { faCalendarAlt, faEdit, faSearch } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
// import { GenSalaryReducer } from "../../../reduxData/SalarySlice";
// import Select from "react-select";
// import { Alert, Snackbar } from "@mui/material";
// import * as Yup from 'yup';
// import { branch } from "../../../validations/Validations";
// import { useFormik } from "formik";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

// export default function BalanceSheet() {
//     const [msg, setMsg] = useState("");
//     const [checkMsg, setCheckMsg] = useState("");

//     const [open, setOpen] = useState(false);
//     const [loading, setLoading] = useState(false);
//     const [balanceData, setBalanceData] = useState({});
//     const [showTable, setShowTable] = useState(false);
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const admin = useSelector((state: any) => state.authUserInfo.value);
//     const branchInfo = useSelector((state: any) => state.branchInfo.value);
//     const currentDate = new Date().toISOString().substring(0, 10);
//     const [showMonthlyForm, setShowMonthlyForm] = useState(true);
//     const [showDateWiseForm, setShowDateWiseForm] = useState(false);
//     const [fromDate, setFromDate] = useState(new Date()); 
//     const [toDate, setToDate] = useState(new Date());

//     const location = useLocation();

//     const branchOptions = [{ value: "All", label: "All" }, ...branchInfo.map((item: any) => ({
//         value: item.id,
//         label: item.branch_name,
//     }))];

//     const formik = useFormik({
//         initialValues: {
//             branch_id: "",
//             month: currentDate.substring(0, 7),
//             from_date: currentDate,
//             to_date: currentDate
//         },
//         validationSchema: branch,
//         onSubmit: async (values) => {
//             try {
//                 setLoading(true);
//                 let url = urls.BALANCE_SHEET;

//                 if (showMonthlyForm) {
//                     if (values.branch_id !== "All" && values.branch_id !== "") {
//                         url += `?year_month=${values.month}&branch_id=${values.branch_id}`;
//                     } else {
//                         url += `?year_month=${values.month}`;
//                     }
//                 } else if (showDateWiseForm) {
//                     if (values.branch_id !== "All" && values.branch_id !== "") {
//                         url += `?from_date=${values.from_date}&to_date=${values.to_date}&branch_id=${values.branch_id}`;
//                     } else {
//                         url += `?from_date=${values.from_date}&to_date=${values.to_date}`;
//                     }
//                 }

//                 const res = await AuthApiService.SearchApi(url, admin.token);
//                 if (res.status && res.data) {
//                     setBalanceData(res.data.data);
//                     setShowTable(true);
//                 } else {
//                     setBalanceData(null);
//                     handleClickOpen();
//                     setMsg("Something went wrong");
//                 }
//             } catch (error) {
//                 handleClickOpen();
//                 setMsg("Something went wrong");
//             } finally {
//                 setLoading(false);
//             }
//         }
//     });

//     useEffect(() => {
//         if (location.state != null && location.state.message === "saved") {
//             formik.setValues({ branch_id: location.state.branchid, month: location.state.date.substring(0, 7) });
//             formik.handleSubmit();
//         }
//     }, []);

//     const handleMonthlyClick = () => {
//         setShowMonthlyForm(true);
//         setShowDateWiseForm(false);
//     };

//     const handleDateWiseClick = () => {
//         setShowDateWiseForm(true);
//         setShowMonthlyForm(false);
//     };

//     const handleClickOpen = () => {
//         setOpen(true);
//     };

//     const handleClose = () => {
//         setOpen(false);
//     };

//     const loadingSpinner = () => {
//         return (
//             <div className="d-flex justify-content-center">
//                 <div className="spinner-border">
//                     <span className="visually-hidden">Loading...</span>
//                 </div>
//             </div>
//         );
//     };

//     const handleFromDateChange = (date) => {
//         setFromDate(date);
//         const formattedDate = date.toISOString().substring(0, 10); // Format date to YYYY-MM-DD
//         formik.setFieldValue("from_date", formattedDate);
//     };

//     const handleToDateChange = (date) => {
//         setToDate(date);
//         const formattedDate = date.toISOString().substring(0, 10); // Format date to YYYY-MM-DD
//         formik.setFieldValue("to_date", formattedDate);
//     };

//     return (
//         <div className="container-fluid p-0">
//             <Snackbar
//                 anchorOrigin={{ vertical: "top", horizontal: "center" }}
//                 open={open}
//                 autoHideDuration={5000}
//                 onClose={handleClose}
//             >
//                 <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status === true) ? "success" : checkMsg === "Salary already created for the specified branch and month." ? "warning" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert>
//             </Snackbar>
//             <div className="container">
//                 <div className="row">
//                     <div className="col-lg-10 col-md-10 col-sm-10">
//                         <div className="text-center">
//                             <h4>Balance Sheet</h4>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="row mt-2 mt-xl-4 pb-0 p-1 d-flex flex-row">
//                 <div className="col-lg-12 col-md-12 justify-content-center">
//                     {/* {showDateWiseForm && (
//                         <form onSubmit={formik.handleSubmit}>
//                             <div className="row m-0 p-0">
//                                 <div className="col-lg-4 col-md-4 col-sm-4">
//                                     <label>Branch</label>
//                                     <Select
//                                         styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
//                                         menuPortalTarget={document.body}
//                                         menuPosition="fixed"
//                                         className="basic-single"
//                                         classNamePrefix="select"
//                                         defaultValue={"All"}
//                                         placeholder="All"
//                                         isLoading={branchInfo.length <= 0 ? true : false}
//                                         isClearable={true}
//                                         isSearchable={true}
//                                         options={branchOptions}
//                                         onChange={(selectedOption) => {
//                                             formik.setFieldValue("branch_id", selectedOption ? selectedOption.value : ""); // Set a default value when nothing is selected
//                                         }}
//                                     />
//                                     {formik.touched.branch_id && formik.errors.branch_id ? (
//                                         <div className="text-danger">{formik.errors.branch_id}</div>
//                                     ) : null}
//                                 </div>
//                                 <div className="col-lg-2 col-md-2 col-sm-2 col-2 m-0">
//                                     <label>From Date:</label>
//                                     <div className="input-group input-group-md">
//                                         <input
//                                             type="date"
//                                             className="form-control shadow-sm"
//                                             // value={formik.values.from_date}
//                                             value={current}
//                                             onChange={(e) => formik.setFieldValue("from_date", e.target.value)}
//                                         />
//                                     </div>
//                                 </div>
//                                 <div className="col-lg-2 col-md-2 col-sm-2 col-2 m-0 p-0 mr-xl-2">
//                                     <label>To Date:</label>
//                                     <div className="input-group input-group-md">
//                                         <input
//                                             type="date"
//                                             className="form-control shadow-sm"
//                                             // value={formik.values.to_date}
//                                             value={current}
//                                             min={fromDate}
//                                             // onChange={(e) => formik.setFieldValue("to_date", e.target.value)}
//                                             onChange={(e) => { setFromDate(e.target.value) }}
//                                         />
//                                     </div>
//                                 </div>
//                                 <div className="col-lg-1 col-md-1 col-sm-1 col-1 m-0 ml-1 ml-xl-2">
//                                     <label></label>
//                                     <br />

//                                     <button type="submit" className="btn btn-md btn-purple" >View</button>
//                                 </div>
//                                 <div className="col-lg-2 col-md-2 col-sm-2">
//                                     <label></label>
//                                     <br />

//                                     <button type="button" className="btn btn-md btn-darkblue form-control" onClick={handleMonthlyClick}>Monthly</button>
//                                 </div>
//                             </div>
//                         </form>
//                     )} */}
// {showDateWiseForm && (
//                         <form onSubmit={formik.handleSubmit}>
//                             <div className="row m-0 p-0">
//                                 <div className="col-lg-4 col-md-4 col-sm-4">
//                                     <label>Branch</label>
//                                     <Select
//                                         styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
//                                         menuPortalTarget={document.body}
//                                         menuPosition="fixed"
//                                         className="basic-single"
//                                         classNamePrefix="select"
//                                         defaultValue={"All"}
//                                         placeholder="All"
//                                         isLoading={branchInfo.length <= 0 ? true : false}
//                                         isClearable={true}
//                                         isSearchable={true}
//                                         options={branchOptions}
//                                         onChange={(selectedOption) => {
//                                             formik.setFieldValue("branch_id", selectedOption ? selectedOption.value : ""); 
//                                         }}
//                                     />
//                                     {formik.touched.branch_id && formik.errors.branch_id ? (
//                                         <div className="text-danger">{formik.errors.branch_id}</div>
//                                     ) : null}
//                                 </div>
//                                 <div className="col-lg-2 col-md-2 col-sm-2 col-2 m-0">
//                                     <label>From Date:</label>
//                                     <div className="input-group input-group-md">
//                                         <DatePicker
//                                             className="form-control shadow-sm"
//                                             selected={fromDate}
//                                             onChange={handleFromDateChange}
//                                             dateFormat="yyyy-MM-dd"
//                                             placeholderText="Select From Date"
//                                         />
//                                     </div>
//                                 </div>
//                                 <div className="col-lg-2 col-md-2 col-sm-2 col-2 m-0 p-0 mr-xl-2">
//                                     <label>To Date:</label>
//                                     <div className="input-group input-group-md">
                                    
                                           
//                                        <DatePicker
//                                             className="form-control shadow-sm"
//                                             selected={toDate}
//                                             onChange={handleToDateChange}
//                                             dateFormat="yyyy-MM-dd"
//                                             placeholderText="Select To Date"
//                                             minDate={fromDate}
                                            
                                        
//                                         />
//                                     </div>
//                                 </div>
//                                 <div className="col-lg-1 col-md-1 col-sm-1 col-1 m-0 ml-1 ml-xl-2">
//                                     <label></label>
//                                     <br />

//                                     <button type="submit" className="btn btn-md btn-purple">View</button>
//                                 </div>
//                                 <div className="col-lg-2 col-md-2 col-sm-2">
//                                     <label></label>
//                                     <br />
//                                     <button type="button" className="btn btn-md btn-darkblue form-control" onClick={handleMonthlyClick}>Monthly</button>
//                                 </div>
//                             </div>
//                         </form>
//                     )}
//                     {showMonthlyForm && (
//                         <form onSubmit={formik.handleSubmit}>
//                             <div className="row m-0 p-0">
//                                 <div className="row m-0 p-0">
//                                     <div className="col-lg-4 col-md-4 col-sm-4">
//                                         <label>Branch</label>
//                                         <Select
//                                             styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
//                                             menuPortalTarget={document.body}
//                                             menuPosition="fixed"
//                                             className="basic-single"
//                                             classNamePrefix="select"
//                                             defaultValue={"All"}
//                                             placeholder="All"
//                                             isLoading={branchInfo.length <= 0 ? true : false}
//                                             isClearable={true}
//                                             isSearchable={true}
//                                             options={branchOptions}
//                                             onChange={(selectedOption) => {
//                                                 formik.setFieldValue("branch_id", selectedOption ? selectedOption.value : ""); 
//                                             }}
//                                         />
//                                         {formik.touched.branch_id && formik.errors.branch_id ? (
//                                             <div className="text-danger">{formik.errors.branch_id}</div>
//                                         ) : null}
//                                     </div>
//                                     <div className="col-lg-3 col-md-3 col-sm-3">
//                                         <label>Month</label>
//                                         <input
//                                             type="month"
//                                             className="form-control shadow-sm"
//                                             name="month"
//                                             max={currentDate}
//                                             value={formik.values.month}
//                                             onChange={formik.handleChange}
//                                         />
//                                     </div>
//                                     <div className="col-lg-1 col-md-1 col-sm-1 col-1 m-0 ml-1 ml-xl-2">
//                                         <label></label>
//                                         <br />

//                                         <button type="submit" className="btn btn-md btn-purple">View</button>
//                                     </div>
//                                     <div className="col-lg-2 col-md-2 col-sm-2">
//                                         <label></label>
//                                         <br />
//                                         <button type="button" className="btn btn-md btn-darkblue form-control" onClick={handleDateWiseClick}>Date Wise</button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </form>
//                     )}
//                 </div>
//             </div>

//             {/* Show table only when balanceData has data */}
//             {loading ? (
//                 loadingSpinner()
//             ) : showTable && (
//                 <div className="row mt-2 mt-xl-4 pb-0 p-1 justify-content-between ">
//                     <div className="col-12 col-xl-8 offset-xl-2">
//                         <table className="table table-bordered table w-75">
//                             <thead>
//                                 <tr>
//                                     <th colSpan={2} className="text-center">
//                                         Total Balance
//                                     </th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 <tr>
//                                     <th>Transactions:</th>
//                                     <td>{balanceData?.total_transaction || 0}</td>
//                                 </tr>
//                                 <tr>
//                                     <th>Employee Advance:</th>
//                                     <td>{balanceData?.total_advance || 0}</td>
//                                 </tr>
//                                 <tr>
//                                     <th>Expenses:</th>
//                                     <td>{balanceData?.total_expenses || 0}</td>
//                                 </tr>
//                                 <tr>
//                                     <th>Others:</th>
//                                     <td></td>
//                                 </tr>
//                             </tbody>
//                             <tfoot>
//                                 <tr className="table-secondary">
//                                     <th>Total:</th>
//                                     <td>{balanceData?.profitloss || 0}</td>
//                                 </tr>
//                             </tfoot>
//                         </table>
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// }
