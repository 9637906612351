import { Alert, Snackbar } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { ExpenseSchema } from "../../../validations/Validations";
import { listExpenseHeadReducer } from "../../../reduxData/expenseHeadSlice";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";

export default function EditExpense(){
    const authInfo = useSelector((state: any) => state.authUserInfo.value);
    const expenseHeadInfo = useSelector(
        (state: any) => state.expenseHeadInfo.value
      );
    const branchInfo = useSelector((state: any) => state.branchInfo.value);
    const expenseInfo = useSelector((state: any) => state.expenseInfo.upData);
    const localExpenseInfo = JSON.parse(localStorage.getItem("expenseinfo"));
    console.log("auth", authInfo,expenseHeadInfo,expenseInfo)
    const dispatch = useDispatch();
    const [status, setStatus] = useState();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate()
    const [msgs, setMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const currentDate = new Date().toISOString().substring(0,10)


    const expensesHeadOptions = expenseHeadInfo?.data?.map((item:any)=>({
        value:item?.id,
        label:item?.category
    }));

    const branchOptions = branchInfo.map((item: any) => ({
        value: item.id,
        label: item.branch_name,
    }));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const ExpenseHeadList = async () => {
        try{
          setLoading(true)
        const url = `${urls.ALL_EXPENSE_HEAD_LIST}`;
        const res = await AuthApiService.GetApiCall(
          url,
          authInfo.token,
        );
        console.log(res)
        dispatch(listExpenseHeadReducer(res.data));
        }
        catch(error){
          console.log(error)
          setStatus(false)
          setMsg("Network Error")
          handleClickOpen()
        }
        finally{
          setLoading(false)
        }
      };

    const formik = useFormik({
        initialValues: {
            item_name:(expenseInfo?.item_name??localExpenseInfo?.item_name),
            expense_head_id:(expenseInfo?.expense_head_id??localExpenseInfo?.expense_head_id),
            branch_id:(expenseInfo?.branch_id??localExpenseInfo?.branch_id),
            invoice_no:(expenseInfo?.invoice_no??localExpenseInfo?.invoice_no),
            date:(expenseInfo?.date?.substring(0,10)??localExpenseInfo?.date),
            amount:(expenseInfo?.amount??localExpenseInfo?.amount),
            description: (expenseInfo?.description??localExpenseInfo?.description)
        },
        validationSchema: ExpenseSchema,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const ob = {
                    item_name:values.item_name,
                    expense_head_id: values.expense_head_id,
                    branch_id: values.branch_id,
                    invoice_no: values.invoice_no,
                    date: values.date,
                    amount: values.amount,
                    description: values.description,
                    isActive: true,
                    // created_by: authInfo.uid,
                    updated_by: authInfo.uid
                };
                const url = `${urls.UPDATE_EXPENSE}/${expenseInfo?.id??localExpenseInfo?.id}`
                console.log("ob", ob,url);
                const resp = await AuthApiService.PutApiCall(url, ob, authInfo.token);
                console.log("resp", resp);
                if (resp.status === 200 || resp.data.status===201) {
                    setMsg(resp.data.msg);
                    handleClickOpen();
                    formik.resetForm()
                    setStatus(resp.data.status)
                    setTimeout(() => {
                        navigate("/saloonChain/expenseList")
                        localStorage.removeItem("expenseinfo")
                    }, 2000)
                } else {
                    setMsg(resp.data.msg);
                    handleClickOpen();
                    formik.resetForm()
                    setStatus(resp.data.status)
                    setTimeout(() => {
                        navigate("/saloonChain/expenseList")
                        localStorage.removeItem("expenseinfo")
                    }, 2000)
                }
            } catch (error: any) {
                console.error("Error:", error);
                setMsg(error.response.data.error == "Internal server error" ? "All Fileds Are Required" : error.response.data.msg);
                setStatus(error.response.status);
                handleClickOpen()
            }
            finally {
                    setLoading(false);
            }
        },
    });

    const GetExpenseHeadByBranch = async(data:any) =>{
        try{
            setLoading(true)
        const url = `${urls.EXPENSE_HEAD_BY_BRANCH}/${data}`;
        const resp = await AuthApiService.GetApiCall(url,authInfo.token);
        console.log(resp);
        dispatch(listExpenseHeadReducer(resp.data));
        }
        catch(error){
          console.log(error)
          setStatus(false)
          setMsg("Network Error")
          handleClickOpen()
        }
        finally{
          setLoading(false)
        }
    }

    useEffect(()=>{
        ExpenseHeadList()
    },[])

    return <>
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
        >
            <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msgs}</Alert>
        </Snackbar>
        <div className="container p-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 col-md-10 col-sm-10">
                        <div className="text-center">
                            <h4>Update Expense</h4>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2 mt-2 d-flex justify-content-end">
                        <Link
                            to="/saloonChain/expenseList">
                            <button className="btn btn-sm btn-purple" >All Expenses</button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="p-1 px-xl-2 py-xl-2 justify-content-center">
                <div className="container">
                    <div className="row justify-content-center mt-3">
                        <div className="col-lg-12">
                            <form
                                onFocus={() => setMsg("")}
                                onSubmit={formik.handleSubmit}>
                                     <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="branch_id" className="col-sm-3 col-form-label">Branch Name<span style={{ color: "red" }}>*</span> :</label>
                                    <div className="col-sm-7">
                                        <Select
                                            id="branch_id"
                                            name="branch_id"
                                            className={`react-select-container ${formik.touched.branch_id && formik.errors.branch_id ? 'is-invalid' : ''}`}
                                            options={branchOptions}
                                            isSearchable
                                            placeholder="Select Branch Name"
                                            isDisabled
                                            onChange={(selectedOption) =>{ 
                                                formik.setFieldValue("expense_head_id",null)
                                                formik.setFieldValue("branch_id", selectedOption.value);
                                                GetExpenseHeadByBranch(selectedOption.value)
                                            }
                                            }
                                            onBlur={formik.handleBlur}
                                            value={
                                                branchOptions.find((option: any) => option.value === formik.values.branch_id) ||
                                                null
                                            }
                                        />
                                        <div className="invalid-feedback">{formik.touched.branch_id && typeof formik.errors.branch_id === 'string' ? (formik.errors.branch_id) : null}</div>

                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="expense_head_id" className="col-sm-3 col-form-label">Expense Head<span style={{ color: "red" }}>*</span> :</label>
                                    <div className="col-sm-7">
                                        <Select
                                            id="expense_head_id"
                                            name="expense_head_id"
                                            isLoading={expenseHeadInfo.data?.length<1}
                                            className={`react-select-container ${formik.touched.expense_head_id && formik.errors.expense_head_id ? 'is-invalid' : ''}`}
                                            options={expensesHeadOptions}
                                            isSearchable
                                            placeholder="Select Expense Head"
                                            onChange={(selectOptions) =>
                                                formik.setFieldValue("expense_head_id", selectOptions.value)
                                            }
                                            onBlur={formik.handleBlur}
                                            value={
                                                expensesHeadOptions?.find((option: any) => option.value === formik.values.expense_head_id) ||
                                                null
                                            }
                                        />
                                        <div className="invalid-feedback">{formik.touched.expense_head_id && typeof formik.errors.expense_head_id === 'string' ? (formik.errors.expense_head_id) : null}</div>

                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="item_name" className="col-sm-3 col-form-label">Expense Name<span style={{ color: "red" }}>*</span> :</label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            placeholder="Enter Expense Name"
                                            id="item_name"
                                            name="item_name"
                                            className={`form-control shadow-sm ${formik.touched.item_name && formik.errors.item_name ? 'is-invalid' : ''}`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.item_name}
                                        />
                                        <div className="invalid-feedback">{formik.touched.item_name && typeof formik.errors.item_name === 'string' ? (formik.errors.item_name) : null}</div>

                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="date" className="col-sm-3 col-form-label">Date<span style={{ color: "red" }}>*</span> :</label>
                                    <div className="col-sm-7">
                                        <input
                                            type="date"
                                            placeholder="Enter Date"
                                            id="date"
                                            name="date"
                                            max={currentDate}
                                            className={`form-control shadow-sm ${formik.touched.date && formik.errors.date ? 'is-invalid' : ''}`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.date}
                                        />
                                        <div className="invalid-feedback">{formik.touched.date && typeof formik.errors.date === 'string' ? (formik.errors.date) : null}</div>
                                    </div>
                                </div>

                                <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="amount" className="col-sm-3 col-form-label">Amount<span style={{ color: "red" }}>*</span> :</label>
                                    <div className="col-sm-7">
                                        <input
                                            type="number"
                                            placeholder="Enter Amount"
                                            id="amount"
                                            name="amount"
                                            className={`form-control shadow-sm ${formik.touched.amount && formik.errors.amount ? 'is-invalid' : ''}`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.amount}
                                        />
                                        <div className="invalid-feedback">{formik.touched.amount && typeof formik.errors.amount === 'string' ? (formik.errors.amount) : null}</div>
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="invoice_no" className="col-sm-3 col-form-label">Invoice No. :</label>
                                    <div className="col-sm-7">
                                    <input
                                            type="text"
                                            placeholder="Enter Invoice No."
                                            id="invoice_no"
                                            name="invoice_no"
                                            className={`form-control shadow-sm ${formik.touched.invoice_no && formik.errors.invoice_no ? 'is-invalid' : ''}`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.invoice_no}
                                        />
                                        <div className="invalid-feedback">{formik.touched.invoice_no && typeof formik.errors.invoice_no === 'string' ? (formik.errors.invoice_no) : null}</div>

                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="desc" className="col-sm-3 col-form-label">Description :</label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            placeholder="Enter Description"
                                            id="description"
                                            name="description"
                                            className={`form-control shadow-sm ${formik.touched.description && formik.errors.description ? 'is-invalid' : ''}`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.description}
                                        />
                                        <div className="invalid-feedback">{formik.touched.description && typeof formik.errors.description === 'string' ? (formik.errors.description) : null}</div>

                                    </div>
                                </div>
                                <div className="mt-3 mb-xl-3 row text-center">
                                    <div className="col-lg-5"></div>
                                    <div className="col-lg-2">
                                        <button type="submit" className="btn btn-md form-control btn-purple" disabled={loading ? true : false}>
                                            {loading ? "Updating..." : "Update"}
                                        </button>
                                    </div>
                                    <div className="col-lg-5"></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}