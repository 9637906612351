import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import AuthApiService, { urls } from "../apiServices/AuthApiService";
import { useEffect, useState } from "react";
import { listsettingReducer, updateSettingReducer } from "../reduxData/settingSlice";
import { Alert, Snackbar } from "@mui/material";
import { settingsValidationSchema } from "../validations/Validations";
import { useNavigate } from "react-router-dom";

export default function Setting() {
    const admin = useSelector((state: any) => state.authUserInfo.value);
    const settingList = useSelector((state: any) => state.settingInfo.value);
    const SERVER = process.env.BASEURL;
    console.log(settingList);
    console.log(admin.saloon_id);
    const navigate = useNavigate()

    const salonSetting = settingList?.find((s: any) => s.saloon_id === admin.saloon_id) || {};
    console.log("filtered data", salonSetting);

    const [msg, setMsg] = useState("");
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(false);
    const dispatch = useDispatch();

    // const formik = useFormik({
    //     initialValues: {
    //         weekoff_day: salonSetting?.weekoff_day || "",
    //         leaves_in_month: salonSetting?.leaves_in_month || 4,
    //         created_by: salonSetting?.created_by,
    //         updated_by: admin.uid,
    //         logo: salonSetting.logo
    //     },
    //     validationSchema: settingsValidationSchema,
    //     onSubmit: async (values, { resetForm, setFieldValue }) => {


    //         if (settingList.saloon_id === admin.salon_id) {
    //             try {
    //                 setLoading(true);
    //                 const formData = new FormData();
    //                 formData.append("weekoff_day", values.weekoff_day);
    //                 formData.append("leaves_in_month", values.leaves_in_month);
    //                 formData.append("created_by", salonSetting.created_by ? salonSetting.created_by : admin.uid)
    //                 formData.append(" updated_by", admin.uid)
    //                 formData.append("saloon_id", salonSetting.saloon_id)
    //                 formData.append("logo", values.logo ? values.logo : salonSetting[0]?.logo);
    //                 console.log("formData upload", formData)
    //                 const resp = await AuthApiService.PutApiFormData(urls.SETTING_UPDATE, formData, admin.token);
    //                 console.log("update", resp);
    //                 if (resp.data.status === 201) {
    //                     // resetForm({ values: { ...values, ...up } });
    //                     setMsg(resp.data.msg);
    //                     handleClickOpen();
    //                     setStatus(true);
    //                     resetForm();

    //                 } else {
    //                     if (resp.data && resp.data.err) {
    //                         setMsg(resp.data.err);
    //                         setStatus(false);
    //                     } else {
    //                         setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
    //                         setStatus(false);
    //                     }
    //                 }


    //             } catch (error: any) {
    //                 console.error("Error:", error);
    //                 setMsg(error.response.data.err);
    //                 handleClickOpen();
    //                 setStatus(false);
    //             } finally {
    //                 setLoading(false);
    //                 setTimeout(() => {
    //                     // setMsg("");
    //                 }, 5000);
    //             }
    //         }
    //         else {
    //             try {
    //                 const formData = new FormData();
    //                 formData.append("weekoff_day", values.weekoff_day);
    //                 formData.append("leaves_in_month", values.leaves_in_month);
    //                 formData.append("created_by", admin.uid)
    //                 formData.append(" updated_by", admin.uid)
    //                 formData.append("saloon_id", admin.saloon_id)
    //                 formData.append("logo", values.logo);
    //                 console.log("formdata", formData)
    //                 const resp = await AuthApiService.PostApiFormData(urls.SETTING_SAVE, formData, admin.token);
    //                 console.log(resp);

    //                 if (resp.status === 200) {
    //                     console.log("resp", resp)
    //                     setMsg(resp.data.msg);
    //                     setStatus(true);
    //                     // resetForm();
    //                     if (resp.data.status == 201) {
    //                         // formik.resetForm()
    //                         setTimeout(() => {
    //                             navigate('/saloonChain/setting')
    //                         }, 1000)
    //                     } else {
    //                         setTimeout(() => {
    //                             navigate('/saloonChain/setting')
    //                         }, 2000)
    //                     }
    //                 } else {
    //                     if (resp.data && resp.data.err) {
    //                         setMsg(resp.data.err);
    //                         setStatus(false);
    //                     } else {
    //                         setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
    //                         setStatus(false);
    //                     }
    //                 }
    //             } catch (error: any) {
    //                 console.error("Error:", error);
    //                 setMsg(error.response.data.err);
    //                 handleClickOpen();
    //                 setStatus(false);
    //             } finally {
    //                 setLoading(false);
    //                 setTimeout(() => {
    //                     // setMsg("");
    //                 }, 5000);
    //             }
    //         }
    //     }
    // })


    const formik = useFormik({
        initialValues: {
            weekoff_day: salonSetting?.weekoff_day || "",
            leaves_in_month: salonSetting?.leaves_in_month || 4,
            created_by: salonSetting?.created_by || admin.uid,
            updated_by: admin.uid,
            logo: salonSetting.logo ? salonSetting.logo : ""
        },
        validationSchema: settingsValidationSchema,
        onSubmit: async (values, { resetForm, setFieldValue }) => {
            try {
                setLoading(true);
                const formData = new FormData();
                formData.append("weekoff_day", values.weekoff_day);
                formData.append("leaves_in_month", values.leaves_in_month);
                formData.append("created_by", values.created_by);
                formData.append("updated_by", admin.uid);
                formData.append("saloon_id", admin.saloon_id);
                formData.append("logo", values.logo);

                let resp;
                if (settingList.length === 0) {
                    resp = await AuthApiService.PostApiFormData(urls.SETTING_SAVE, formData, admin.token);
                } else {
                    resp = await AuthApiService.PutApiFormData(urls.SETTING_UPDATE, formData, admin.token);
                }

                console.log(resp);

                if (resp.data.status === 201) {
                    setMsg(resp.data.msg);
                    setStatus(true); // Update status state
                    handleClickOpen(); // Open Snackbar
                    setTimeout(() => {
                        navigate('/saloonChain/setting')
                    }, 1000)
                } else {
                    if (resp.data && resp.data.err) {
                        setMsg(resp.data.err);
                        setStatus(false);
                    } else {
                        setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
                        setStatus(false);
                    }
                }
            } catch (error: any) {
                console.error("Error:", error);
                setMsg(error.response.data.err);
                setStatus(false);
            } finally {
                setLoading(false);
            }
        }
    });


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <div className="container p-0">
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
            >
                {/* <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert> */}
                <Alert onClose={handleClose} severity={status ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert>
            </Snackbar>
            <h4 className="text-center">Settings</h4>
            <div className="d-flex mt-xl-3 mt-2 p-2 p-lg-2">
                <form onSubmit={formik.handleSubmit}>
                    {/* <b className={status ? "text-success" : "text-danger"}>{msg}</b> */}

                    <div className="mb-3 row">
                        <label className="col-sm-6 col-form-label">Weekoff Day:</label>
                        <div className="col-sm-6">
                            <select
                                name="weekoff_day"
                                id="weekoff_day"
                                className={`form-select ${formik.touched.weekoff_day && formik.errors.weekoff_day ? "is-invalid" : ""}`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.weekoff_day}
                            >
                                {/* <option value={undefined}>Select Weekoff Day</option> */}
                                <option value="Mon">Monday</option>
                                <option value="Tue">Tuesday</option>
                                <option value="Wed">Wednesday</option>
                                <option value="Thu">Thursday</option>
                                <option value="Fri">Friday</option>
                                <option value="Sat">Saturday</option>
                                <option value="Sun">Sunday</option>
                                <option value="No" selected>No WeekOff</option>

                            </select>
                            {formik.touched.weekoff_day && typeof formik.errors.weekoff_day === 'string' ? (
                                <div className="invalid-feedback">{formik.errors.weekoff_day}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label className="col-sm-6 col-form-label">Total Leaves in a month:</label>
                        <div className="col-sm-6">
                            <input
                                type="text"
                                name="leaves_in_month"
                                id="leaves_in_month"
                                className={`form-control shadow-sm ${formik.touched.leaves_in_month && formik.errors.leaves_in_month ? "is-invalid" : ""
                                    }`}
                                placeholder="Enter Total Leaves"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.leaves_in_month}
                            />
                            {formik.touched.leaves_in_month && typeof formik.errors.leaves_in_month === 'string' ? (
                                <div className="invalid-feedback">{formik.errors.leaves_in_month}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label className="col-sm-6 col-form-label">Upload Logo</label>
                        <div className="col-sm-6">
                            {settingList && settingList.length > 0 && (
                                <img src={`${SERVER}/${settingList[0].logo}`} alt="Salon Logo" height="80px" width="200px" />
                            )}
                            <input
                                type="file"
                                name="logo"
                                id="logo"
                                className="form-control"
                                placeholder="Enter Total Leaves"
                                onChange={(event: any) => {
                                    formik.setFieldValue("logo", event.currentTarget.files[0]);
                                }}
                            // // onBlur={formik.handleBlur}
                            // value={formik.values.logo}
                            />
                            {/* {formik.touched.leaves_in_month && typeof formik.errors.leaves_in_month === 'string' ? (
                                <div className="invalid-feedback">{formik.errors.leaves_in_month}</div>
                            ) : null} */}
                        </div>
                    </div>
                    <div className="mb-3 d-flex">
                        <div className="col-lg-3">
                            <button type="submit" className="btn form-control btn-purple btn-md">
                                {loading ? "saving..." : "save"}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

// import { useFormik } from "formik";
// import { useDispatch, useSelector } from "react-redux";
// import AuthApiService, { urls } from "../apiServices/AuthApiService";
// import { useEffect, useState } from "react";
// import { listsettingReducer, updateSettingReducer } from "../reduxData/settingSlice";
// import { Alert, Snackbar } from "@mui/material";
// import { settingsValidationSchema } from "../validations/Validations";

// export default function Setting() {
//     const admin = useSelector((state: any) => state.authUserInfo.value);
//     const settingList = useSelector((state: any) => state.settingInfo.value);
//     console.log(settingList);
//     console.log(admin);

//     const salonSetting = settingList?.find((s: any) => s.saloon_id === admin.saloon_id) || {};
//     console.log("filtered data", salonSetting);

//     const [msg, setMsg] = useState("");
//     const [open, setOpen] = useState(false);
//     const [loading, setLoading] = useState(false);
//     const [status, setStatus] = useState(false);
//     const dispatch = useDispatch();

//     const formik = useFormik({
//         initialValues: {
//             weekoff_day: salonSetting?.weekoff_day || "",
//             leaves_in_month: salonSetting?.leaves_in_month || 4,
//             created_by: salonSetting?.created_by,
//             updated_by: admin.uid
//         },
//         validationSchema: settingsValidationSchema,
//         onSubmit: async (values, { resetForm, setFieldValue }) => {
//             try {
//                 setLoading(true);

//                 if (settingList.saloon_id === admin.salon_id) {
//                     // const up = {
//                     //     weekoff_day: values.weekoff_day,
//                     //     leaves_in_month: values.leaves_in_month,
//                     //     created_by: salonSetting.created_by ? salonSetting.created_by : admin.uid,
//                     //     updated_by: admin.uid,
//                     // };
//                     const formData = new FormData();
//                     formData.append("weekoff_day", values.weekoff_day);
//                     formData.append("leaves_in_month", values.leaves_in_month);
//                     formData.append("created_by", salonSetting.created_by ? salonSetting.created_by : admin.uid)
//                     formData.append(" updated_by", admin.uid)
//                     formData.append("saloon_id", salonSetting.saloon_id)
//                     formData.append("logo", "");
//                     const resp = await AuthApiService.PutApiFormData(urls.SETTING_UPDATE, formData, admin.token);
//                     console.log("update", resp);
//                     if (resp.data.status === 201) {
//                         // resetForm({ values: { ...values, ...up } });
//                         setMsg(resp.data.msg);
//                         handleClickOpen();
//                         setStatus(true);
//                         resetForm();

//                     } else {
//                         if (resp.data && resp.data.err) {
//                             setMsg(resp.data.err);
//                             setStatus(false);
//                         } else {
//                             setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
//                             setStatus(false);
//                         }
//                     }


//                 } else {
//                     // const ob = {
//                     //     saloon_id: admin.saloon_id,
//                     //     weekoff_day: values.weekoff_day,
//                     //     leaves_in_month: values.leaves_in_month,
//                     //     created_by: salonSetting.created_by ? salonSetting.created_by : admin.uid,
//                     //     updated_by: admin.uid,
//                     // };

//                     const formData = new FormData();
//                     formData.append("weekoff_day", values.weekoff_day);
//                     formData.append("leaves_in_month", values.leaves_in_month);
//                     formData.append("created_by", salonSetting.created_by ? salonSetting.created_by : admin.uid)
//                     formData.append(" updated_by", salonSetting.updated_by ? salonSetting.updated_by : admin.uid)
//                     formData.append("saloon_id", admin.saloon_id)
//                     formData.append("logo", "");
//                     console.log(formData)
//                     const resp = await AuthApiService.PostApiFormData(urls.SETTING_SAVE, formData, admin.token);
//                     console.log(resp);

//                     if (resp.status === 200) {
//                         console.log("resp", resp)
//                         setMsg(resp.data.msg);
//                         setStatus(true);
//                         resetForm();
//                     } else {
//                         if (resp.data && resp.data.err) {
//                             setMsg(resp.data.err);
//                             setStatus(false);
//                         } else {
//                             setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
//                             setStatus(false);
//                         }
//                     }
//                 }
//             } catch (error: any) {
//                 console.error("Error:", error);
//                 setMsg(error.response.data.err);
//                 handleClickOpen();
//                 setStatus(false);
//             } finally {
//                 setLoading(false);
//                 setTimeout(() => {
//                     // setMsg("");
//                 }, 5000);
//             }
//         }

//     });

//     const handleClickOpen = () => {
//         setOpen(true);
//     };

//     const handleClose = () => {
//         setOpen(false);
//     };

//     return (
//         <div className="container p-0">
//             <Snackbar
//                 anchorOrigin={{ vertical: "top", horizontal: "center" }}
//                 open={open}
//                 autoHideDuration={5000}
//                 onClose={handleClose}
//             >
//                 <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert>
//             </Snackbar>
//             <h3 className="text-center">Settings</h3>
//             <div className="d-flex mt-xl-3 mt-2 p-2 p-lg-2">
//                 <form onSubmit={formik.handleSubmit}>
//                     {/* <b className={status ? "text-success" : "text-danger"}>{msg}</b> */}

//                     <div className="mb-3 row">
//                         <label className="col-sm-6 col-form-label">Weekoff Day:</label>
//                         <div className="col-sm-6">
//                             <select
//                                 name="weekoff_day"
//                                 id="weekoff_day"
//                                 className={`form-select ${formik.touched.weekoff_day && formik.errors.weekoff_day ? "is-invalid" : ""}`}
//                                 onChange={formik.handleChange}
//                                 onBlur={formik.handleBlur}
//                                 value={formik.values.weekoff_day}
//                             >
//                                 {/* <option value={undefined}>Select Weekoff Day</option> */}
//                                 <option value="Mon">Monday</option>
//                                 <option value="Tue">Tuesday</option>
//                                 <option value="Wed">Wednesday</option>
//                                 <option value="Thu">Thursday</option>
//                                 <option value="Fri">Friday</option>
//                                 <option value="Sat">Saturday</option>
//                                 <option value="Sun">Sunday</option>
//                             </select>
//                             {formik.touched.weekoff_day && typeof formik.errors.weekoff_day === 'string' ? (
//                                 <div className="invalid-feedback">{formik.errors.weekoff_day}</div>
//                             ) : null}
//                         </div>
//                     </div>
//                     <div className="mb-3 row">
//                         <label className="col-sm-6 col-form-label">Total Leaves in a month:</label>
//                         <div className="col-sm-6">
//                             <input
//                                 type="text"
//                                 name="leaves_in_month"
//                                 id="leaves_in_month"
//                                 className={`form-control shadow-sm ${formik.touched.leaves_in_month && formik.errors.leaves_in_month ? "is-invalid" : ""
//                                     }`}
//                                 placeholder="Enter Total Leaves"
//                                 onChange={formik.handleChange}
//                                 onBlur={formik.handleBlur}
//                                 value={formik.values.leaves_in_month}
//                             />
//                             {formik.touched.leaves_in_month && typeof formik.errors.leaves_in_month === 'string' ? (
//                                 <div className="invalid-feedback">{formik.errors.leaves_in_month}</div>
//                             ) : null}
//                         </div>
//                     </div>
//                     <div className="mb-3 d-flex">
//                         <div className="col-lg-3">
//                             <button type="submit" className="btn form-control btn-purple btn-md">
//                                 {loading ? "saving..." : "save"}
//                             </button>
//                         </div>
//                     </div>
//                 </form>
//             </div>
//         </div>
//     );
// }
