import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp, faEdit, faPen, faPlus, faSearch, faSort, faTrash } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listTransactionReducer, updateTransactionReducer } from "../../../reduxData/transactionSlice";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import Select from "react-select";
import { Alert, Snackbar } from "@mui/material";
import { listCustomerBillReducer } from "../../../reduxData/customerSlice";


export default function ProductTransaction() {
    const [expandedRowIndex, setExpandedRowIndex] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const _ = require("lodash");
    const [sort, setsort] = useState(false);
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    const [val, setval] = useState();
    const [status, setStatus] = useState();
    const [open, setOpen] = useState(false);
    const [searchInput, setSearchInput] = useState();
    const [recordLoading, setRecordLoading] = useState<string | null>(null);
    const admin = useSelector((state: any) => state.authUserInfo.value);
    const transactioninfo = useSelector((state: any) => state.transactionInfo.value);
    const branchInfo = useSelector((state: any) => state.branchInfo.value);
    const employeeInfo = useSelector((state: any) => state.employeeInfo.value);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isDateSearching, setDateSearching] = useState(false);
    const currentDate = new Date().toISOString().substring(0, 10)
    const [fromDate, setFromDate] = useState(currentDate)
    const [toDate, setToDate] = useState(currentDate);
    const [showReset, setShowReset] = useState(false);
    const [showDetails, setShowDetails] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(false);
    const [binfo, setBInfo] = useState();
    const [msgs, setMsgs] = useState("");
    const [grandTotal, setGrandTotal] = useState();
    const [selectedBranch, setSelectedBranch] = useState(null);

    const branchOptions = branchInfo && Array.isArray(branchInfo) ? [{ value: "All", label: "All" }, ...branchInfo.map((item: any) => ({
        value: item.id,
        label: item.branch_name,
    }))] : [];
    useEffect(() => {
        if (branchInfo.length === 1) {
            const singleBranch = branchInfo[0];
            const singleBranchOption = { value: singleBranch.id, label: singleBranch.branch_name };
            setSelectedBranch(singleBranchOption);
            //   onBranchSearch(singleBranchOption);
        }
    }, [branchInfo]);
    const totalRecords = transactioninfo?.pagination?.totalRecords



    const totalPages = Math.ceil(totalRecords / itemsPerPage);
    const generatePageNumbers = () => {
        const pageNumbers = [];
        const maxPageButtons = 3; // Adjust the number of buttons you want to show

        if (totalPages <= maxPageButtons) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            const leftEllipsis = currentPage > 2;
            const rightEllipsis = currentPage < totalPages - 1;

            if (leftEllipsis) {
                pageNumbers.push(1, '...');
            }

            const start = Math.max(1, currentPage - 1);
            const end = Math.min(totalPages, currentPage + 1);

            for (let i = start; i <= end; i++) {
                pageNumbers.push(i);
            }

            if (rightEllipsis) {
                pageNumbers.push('...', totalPages);
            }
        }

        return pageNumbers;
    };
    const transactionlist = async (page: number, itemsPerPage: number) => {
        try {
            setLoading(true)
            let URL
            if (admin.roleValue === "BRM") {
                URL = `${urls.PRODUCT_TRANSACTION_LIST}?branch_id=${admin.saloon_id}&page_number=${page}&no_of_records=${100}`;
            } else {
                URL = `${urls.PRODUCT_TRANSACTION_LIST}?page_number=${page}&no_of_records=${100}`;

            }
            const res = await AuthApiService.GetApi(URL, admin.token);
            setBInfo(res.data.branch_info)

            if (res.status) {
                var gt = res.data.totals;
                setGrandTotal(gt);
                dispatch(listTransactionReducer(res.data));
            }
        } catch (error) {
            setMsg("Network Error !");
        } finally {
            setLoading(false)
        }

    };

    function Edit(data: any) {
        const today = new Date().toISOString().split('T')[0];
        const transactionDate = new Date(data.transaction_date).toISOString().split('T')[0];
        localStorage.setItem("transactioninfo", JSON.stringify(data));
        dispatch(updateTransactionReducer(data));
        if (admin.roleValue === "BRM") {
            if (transactionDate === today) {
                localStorage.setItem("transactioninfo", JSON.stringify(data));
                navigate('/branchManager/editDailyTransaction')
            } else {
                setMsg("You can't edit transaction for this date.");
                setStatus(false);
                setOpen(true);
            }
        } else {
            localStorage.setItem("transactioninfo", JSON.stringify(data));
            navigate("/saloonChain/editDailyTransaction")
        }
    }
    const Delete = async (data: any) => {
        const al = confirm("Are you sure you want to delete");
        if (al) {
            try {
                setRecordLoading(data.id);
                const url = urls.TRANSACTION_DELETE + data.id;
                const resp = await AuthApiService.DelApiCall(url, admin.token);
                if (resp.status) {
                    const newlist: any = transactioninfo.data?.filter((d: any) => { return d.id !== data.id })
                    dispatch(listTransactionReducer(newlist));
                    await transactionlist(currentPage, itemsPerPage)
                    setMsg(resp.data.msg);
                    setStatus(resp.data.status)
                    handleClickOpen();
                }
                else {
                    setMsg(resp.data.msg)
                    setStatus(resp.data.status)
                }
            } catch (error: any) {
                setMsg("Network Error or Delete Failed!");

                setStatus(error.response.status);
                handleClickOpen()
            } finally {
                setRecordLoading(null);
                setTimeout(() => {
                    // setMsg('');
                }, 5000);
            }
        }
        else { return }
    }
    const handleDateSubmit = async (val: any, fromDate: any, toDate: any, page: any, itemsPerPage: any) => {
        setDateSearching(true);
        setLoading(true);

        const url = `${urls.PRODUCT_TRANSACTION_LIST}`;
        const queryParams = {
            branch_id: val?.label == "All" ? "" : val?.value,
            from_date: fromDate,
            to_date: toDate,
            page_number: page,
            no_of_records: itemsPerPage
        };

        const res = await AuthApiService.SearchApiCallWithQuery(url, queryParams, admin.token);
        setBInfo(res.data.branch_info)

        if (res.status === 200) {
            var gt = res.data.totals;
            setGrandTotal(gt);
            dispatch(listTransactionReducer(res.data));
            setBInfo(res.data.branch_info)
            // var gt = res.data.grand_total;
            // setGrandTotal(gt);
            setLoading(false);
        }
    };

    const handleChange = async (page: number) => {
        setCurrentPage(page)
        if (isDateSearching) {
            await handleDateSubmit(val, fromDate, toDate, page, itemsPerPage)
        }
        else {
            await transactionlist(page, itemsPerPage);
        }
    };

    const loadingSpinner = () => {
        return <div className="d-flex justify-content-center">
            <div className="spinner-border" >
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    }

    useEffect(() => {
        if (isDateSearching) {
            handleDateSubmit(val, fromDate, toDate, currentPage, itemsPerPage)
        }
        else {
            transactionlist(currentPage, itemsPerPage);
        }
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const sorting = (d) => {
        setsort(!sort)
        if (d == 'branch') {
            const data = sort ? _.orderBy(transactioninfo.data, (d) => d.branch_info.branch_name, ['asc']) : _.orderBy(transactioninfo.data, (d) => d.branch_info.branch_name, ['desc']);
            const transactionInfo = { ...transactioninfo, data };
            dispatch(listTransactionReducer(transactionInfo));
        }
        else if (d == 'employee') {
            const data = sort ? _.orderBy(transactioninfo?.data, (d) => d.employee_info?.first_name, ['asc']) : _.orderBy(transactioninfo?.data, (d) => d.employee_info?.first_name, ['desc']);
            const transactionInfo = { ...transactioninfo, data };
            dispatch(listTransactionReducer(transactionInfo));
        }
        else if (d == 'service') {
            const data = sort ? _.orderBy(transactioninfo?.data, (d) => d.transactionItems?.map(d => d.service_type), ['asc']) : _.orderBy(transactioninfo?.data, (d) => d?.transactionItems?.map(d => d.service_type), ['desc']);
            const transactionInfo = { ...transactioninfo, data };
            dispatch(listTransactionReducer(transactionInfo));
        }
        else if (d == 'payment') {
            const data = sort ? _.orderBy(transactioninfo.data, (d) => d.payment_info.map(d => d.payment_mode), ['asc']) : _.orderBy(transactioninfo.data, (d) => d.payment_info.map(d => d.payment_mode), ['desc']);
            const transactionInfo = { ...transactioninfo, data };
            dispatch(listTransactionReducer(transactionInfo));
        }
        else if (d == 'tdate') {
            const data = sort ? _.orderBy(transactioninfo.data, (d) => d.transaction_date, ['asc']) : _.orderBy(transactioninfo.data, (d) => d.transaction_date, ['desc']);
            const transactionInfo = { ...transactioninfo, data };
            dispatch(listTransactionReducer(transactionInfo));
        }
    }

    const toggleRowExpansion = (index: any) => {
        setExpandedRowIndex(expandedRowIndex === index ? null : index);
    };

    const renderRowExpansionContent = (data: any) => {
        return (<><th></th>
            <tr> <td className="text-wrap">  <b>Service Type:&nbsp;</b><span>{data?.transactionItems?.map((d: any) => <><span>{d.service_type ?? d.product_name}</span>,</>)}</span></td></tr>
            <tr> <td className="text-wrap">  <b>Qty. :&nbsp;</b><span>{data?.transactionItems?.map((d: any) => <><span>{d.quantity}</span>,</>)}</span></td></tr>
            <tr> <td className="text-wrap"><b>Amount(INR) :&nbsp;</b>{data?.transactionItems?.map((d: any) => <><span>{d?.amount}</span>,</>)}</td></tr>
            <tr> <td className="text-wrap">  <b>Trans. Date:&nbsp;</b><span>{new Date(data.transaction_date).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                timeZone: 'UTC'
            })}&nbsp;
                {new Date(data.transaction_date).toLocaleTimeString('en-GB', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                    timeZone: 'UTC'
                })}</span></td></tr>
            <tr> <td className="text-wrap"> <b>Remark:&nbsp;</b><span>{data.remark == "" ? "-" : data.remark}</span></td></tr>
            <tr> <td className="text-wrap"><b>Cust.Name:&nbsp;</b><span>{data?.customerinfo?.name == null ? "-" : data?.customerinfo?.name}</span></td></tr>
            <tr> <td className="text-wrap"> <b>Cust.Email:&nbsp;</b><span>{data?.customerinfo?.email == null ? "-" : data?.customerinfo?.email}</span></td></tr>
            <tr> <td className="text-wrap"> <b>Cust.Phone:&nbsp;</b><span>{data?.customerinfo?.mobile}</span></td></tr>

        </>

        )
    }
    const handleShowBill = async (data: any) => {
        const url = `${urls.TRANSACTION_GET}${data}`
        const res = await AuthApiService.GetApiCall(url, admin.token);
        if (res.status == 200 && res.data.status == 200) {
            const ob = res.data.data;
            dispatch(listCustomerBillReducer(ob));
            localStorage.setItem("billinfo", JSON.stringify(res.data.data))
            navigate("/saloonChain/customerBill")
        }
    }
    const handleWindowResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    return <>
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
        >
            <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert>
        </Snackbar>
        <div className="container-fluid P-0">
            <div className="container">

                <div className="row">

                    {admin.roleValue === "BRM" ? <>
                        <div className="col-lg-9 col-md-9 col-sm-9 col-3 mt-2 d-flex justify-content-start">
                            <Link to="/branchManager/dailytransactionslist">
                                <span>
                                    <button className=" btn btn-sm btn-purple">Back</button>
                                </span>
                            </Link>
                        </div>

                        <div className="col-lg-2 col-md-2 col-sm-2 col-6 mt-2 d-flex justify-content-center">
                            <Link to="/branchManager/addDailyTransaction">
                                <button className="btn btn-sm btn-purple"><FontAwesomeIcon icon={faPlus} />&nbsp;New Transaction</button>
                            </Link>
                        </div>
                        {/* <div className="col-lg-2 col-md-2 col-sm-2 mt-2 col-2 d-flex  justify-content-end">
                            <Link to="/branchManager/dailytransactionslist">

                                <button className=" btn btn-purple btn-sm">
                                    All Transactions
                                </button>

                            </Link>
                        </div> */}
                        <div className="col-lg-1 col-md-1 col-sm-1 col-3 mt-2 d-flex justify-content-end">
                            <Link to="/branchManager/managerTransactionReport">
                                <button className="btn btn-sm btn-purple">Reports</button>
                            </Link>
                        </div> </> : <>
                        <div className="col-lg-9 col-md-9 col-sm-9 col-3 mt-2 d-flex justify-content-start">
                            <Link to="/saloonChain/dailyTransaction">
                                <span>
                                    <button className=" btn btn-sm btn-purple">&nbsp;Back &nbsp;</button>
                                </span>
                            </Link>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 col-6 mt-2 d-flex justify-content-center">
                            <Link to="/saloonChain/addDailyTransaction">
                                <button className="btn btn-sm btn-purple"><FontAwesomeIcon icon={faPlus} />&nbsp;New Transaction</button>
                            </Link>
                        </div>
                        {/* <div className="col-lg-2 col-md-2 col-sm-2 col-4 mt-2 justify-content-between">
                            <Link to="/saloonChain/dailyTransaction">

                                <button className=" btn btn-purple btn-sm">
                                    All Transactions
                                </button>

                            </Link>
                        </div> */}
                        <div className="col-lg-1 col-md-1 col-sm-1 col-3 mt-2 justify-content-end">
                            <Link to="/saloonChain/transactionReport">
                                <button className="btn btn-sm btn-purple">Reports</button>
                            </Link>
                        </div></>}


                </div>
                <br></br>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="text-center">
                            <h4>Product Transactions</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-2 mt-xl-4 pb-2 pb-xl-4 p-1">
                {admin.roleValue === "BRM" ? "" : <div className="col-lg-3 col-md-3 col-sm-12">
                    <label>Select Branch</label>
                    <Select
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={"All"}
                        placeholder="All"
                        // isDisabled={isDisabled}
                        isLoading={branchInfo.length <= 0 ? true : false}
                        isClearable={true}
                        // isRtl={isRtl}
                        isSearchable={true}
                        options={branchOptions}
                        onChange={(e: any) => {
                            setShowReset(true);
                            setval(e);
                            setCurrentPage(1);
                            handleDateSubmit(e, fromDate, toDate, 1, itemsPerPage);
                            setSelectedBranch(e);
                        }}
                        // onChange={(e) => { setShowReset(true); setval(e); setCurrentPage(1); handleDateSubmit(e, searchInput, 1, itemsPerPage) }}
                        // value={val}
                        value={selectedBranch}

                    />
                </div>}
                <div className="col-lg-5 col-md-5 p-0 m-0">
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        setCurrentPage(1);
                        handleDateSubmit(val, fromDate, toDate, 1, itemsPerPage);
                    }}>
                        <div className="row m-0 p-0">
                            <div className="col-lg-4 col-md-4 col-sm-6 col-4 m-0">
                                <label>From Date:</label>
                                <div className="input-group input-group-md ">
                                    <input
                                        type="Date"
                                        className="form-control shadow-sm"
                                        placeholder="Search..."
                                        value={fromDate}
                                        onChange={(e) => { setFromDate(e.target.value) }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 col-4 m-0 p-0 mr-xl-2 ">

                                <label>To Date:</label>
                                <div className="input-group input-group-md">
                                    <input
                                        type="Date"
                                        className="form-control shadow-sm"
                                        placeholder="Search..."
                                        value={toDate}
                                        min={fromDate}
                                        onChange={(e) => { setToDate(e.target.value) }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-3 col-sm-6 col-4 m-0 ml-1 ml-xl-2">

                                <label></label>
                                <br />
                                <button type="submit" className="btn btn-md btn-purple" onClick={() => { setShowReset(true) }}>Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
                {showReset && <div className="col-1">
                    <label></label>
                    <br />
                    <button className="btn btn-danger" onClick={() => { setShowReset(false); setCurrentPage(1); setFromDate(currentDate); setToDate(currentDate); setDateSearching(false), setSearchInput(""), setval(""), transactionlist(1, itemsPerPage) }}>Reset</button>
                </div>}
            </div>


            {loading || transactioninfo?.data?.length === 0 ? (
                <div className="mt-4">
                    {loading ? (
                        // Display loading spinner
                        <div className="d-flex justify-content-center align-items-center">
                            {loadingSpinner()}
                        </div>
                    ) : (
                        // Display custom message for no records
                        <h5>No Transaction Details found.</h5>
                    )}
                </div>
            ) : (<div className="mt-2 mt-xl-2 mt-sm-2 justify-content-center">
                {grandTotal != undefined && <div className="table-responsive">
                    <table id="report-table" className="table table-striped table-hover border-light-subtle justify-content-center">
                        <thead>
                            <tr>
                                <th className="btn-cash">
                                    Total Cash
                                </th>
                                <th className="btn-online">
                                    Total Online
                                </th>
                                <th className="btn-Total">
                                    Grand Total
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{grandTotal.totalCashAmount}</td>
                                <td>{grandTotal.totalOnlineAmount}</td>
                                <td>{grandTotal.grandTotal}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>}
                <div className={`table-container table-responsive`}>
                    <table className="table table-striped  table-hover border-light-subtle " id={isMobile ? 're-table' : 'report-table'}>
                        <thead>
                            <tr className="text-nowrap">
                                {!isMobile && <th>
                                </th>}
                                <th>
                                </th>
                                {!isMobile && <th style={{ cursor: "pointer" }} onClick={() => { sorting('tdate') }}>Trans. Date &nbsp;<FontAwesomeIcon icon={faSort} /></th>}
                                {!isMobile && <th>Customer</th>}
                                {!isMobile && <th style={{ cursor: "pointer" }} className="text-wrap" onClick={() => { sorting('service') }}>Service Type &nbsp;<FontAwesomeIcon icon={faSort} /></th>}
                                {!isMobile && <th>Qty.</th>}
                                {!isMobile && <th>Amount(INR)</th>}
                                <th onClick={() => { sorting('payment') }}>{isMobile ? "Pmt. " : "Payment"} </th>
                                <th style={{ cursor: "pointer" }} onClick={() => { sorting('branch') }} >Branch &nbsp;<FontAwesomeIcon icon={faSort} /></th>
                                {!isMobile && <th>Remark</th>}
                                {isMobile && <th></th>}

                            </tr>
                        </thead>
                        <tbody className="text-nowrap">
                            {transactioninfo?.data?.map((data: any, index: number) => {
                                const isExpanded = expandedRowIndex === index;
                                return (
                                    <React.Fragment>
                                        <tr key={index}>
                                            {!isMobile && <td>{index + 1}</td>}
                                            <td>
                                                {isMobile ? <><FontAwesomeIcon cursor="pointer" icon={faEdit} className="fontIcon" onClick={() => { Edit(data) }} />
                                                    <br></br>
                                                    {recordLoading === data.id ? <>Deleting...</> :
                                                        <FontAwesomeIcon onClick={() => { Delete(data) }} cursor="pointer" icon={faTrash} className="fontIcon" />}</>
                                                    : <><FontAwesomeIcon cursor="pointer" icon={faEdit} className="fontIcon" onClick={() => { Edit(data) }} /> &nbsp;
                                                        {recordLoading === data.id ? <>Deleting...</> : <FontAwesomeIcon onClick={() => { Delete(data) }} cursor="pointer" icon={faTrash} className="fontIcon" />}</>}
                                            </td>
                                            {!isMobile && (
                                                <td>
                                                    {new Date(data.transaction_date).toLocaleDateString('en-GB', {
                                                        day: '2-digit',
                                                        month: '2-digit',
                                                        year: 'numeric',
                                                        timeZone: 'UTC'
                                                    })}&nbsp;<br />
                                                    {new Date(data.transaction_date).toLocaleTimeString('en-GB', {
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        hour12: false,
                                                        timeZone: 'UTC'
                                                    })}<br /><button className="btn btn-link" onClick={() => { handleShowBill(data.id) }}>Bill</button>
                                                </td>
                                            )}
                                            {!isMobile && <td>{data?.customerinfo?.name == null ? "-" : data?.customerinfo?.name} <br />{data?.customerinfo?.mobile}</td>}
                                            {!isMobile && <td >{data?.transactionItems?.map((d: any) => <><span>{d.service_type ?? d.product_name}</span><br /></>)}</td>}
                                            {!isMobile && <td >{data?.transactionItems?.map((d: any) => <><span>{d.quantity}</span><br /></>)}</td>}
                                            {!isMobile && <td>{data?.transactionItems?.map((d: any) => <><span>{d.amount}</span><br /></>)}</td>}
                                            <td className={`${isMobile ? 'text-wrap' : 'text-nowrap'}`}><b>Total :</b>{data.amount}<br /><b>Paid :</b>{data?.
                                                total_amount_paid
                                            }<br /><b>Due :</b>{data?.dues ?? "0"}<br />
                                            </td>

                                            <td className={`${isMobile ? 'text-wrap' : 'text-nowrap'}`}>{data?.branch_info?.branch_name ? data?.branch_info?.branch_name : binfo?.branch_name}</td>
                                            {!isMobile && <td>{data.remark == "" ? "-" : data.remark}</td>}
                                            {isMobile && (
                                                <td>
                                                    {!isExpanded ? (
                                                        <button
                                                            className="btn btn-link"
                                                            onClick={() => toggleRowExpansion(index)}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faArrowDown}
                                                                className="fontIcon"
                                                                size="sm"
                                                            />
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="btn btn-link"
                                                            onClick={() => toggleRowExpansion(index)}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faArrowUp}
                                                                className="fontIcon"
                                                                size="sm"
                                                            />
                                                        </button>
                                                    )}</td>
                                            )}
                                        </tr>
                                        {isExpanded && isMobile && (
                                            <tr>
                                                <td colSpan="6">{renderRowExpansionContent(data)}</td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                )
                            }
                            )}
                        </tbody>
                    </table>

                    <nav aria-label="Page navigation example">
                        <ul className="pagination">
                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                <button
                                    className="page-link "
                                    onClick={() => handleChange(currentPage - 1)}
                                    disabled={currentPage === 1 || loading}
                                >
                                    Previous
                                </button>
                            </li>
                            {generatePageNumbers()?.map((page, index) => (
                                <li
                                    key={index}
                                    className={`page-item ${page === currentPage ? 'active' : ''}`}
                                >
                                    {page === '...' ? (
                                        <span className="page-link">...</span>
                                    ) : (
                                        <button
                                            className="page-link btn-sm"
                                            onClick={() => handleChange(page)}
                                            disabled={loading}
                                        >
                                            {page}
                                        </button>
                                    )}
                                </li>
                            ))}
                            <li
                                className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
                            >
                                <button
                                    className="page-link btn-sm"
                                    onClick={() => handleChange(currentPage + 1)}
                                    disabled={loading || currentPage === totalPages}
                                >
                                    Next
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>)}
        </div >
    </>
}











