// validationSchemas.ts
import * as Yup from "yup";


export const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
});

// export const branchValidationSchema = Yup.object({
//     branch_name: Yup.string().required("Branch Name is required"),
//     saloon_type: Yup.string().required("Saloon Type is required"),
//     branch_address: Yup.string().required("Address is required"),
//     phone_number: Yup.string()
//         .required("Phone is required")
//         .matches(/^\d{10}$/, "Phone number must be 10 digits"),
//     email: Yup.string().email("Invalid email format").nullable(),
// });

export const branchValidationSchema = Yup.object({
    branch_name: Yup.string().required("Branch Name is required"),
    saloon_type: Yup.string().required("Saloon Type is required"),
    branch_address: Yup.string().required("Address is required"),
    phone_number: Yup.string()
        .required("Phone is required")
        .matches(/^\d{10}$/, "Phone number must be 10 digits"),
    email: Yup.string()
        .email("Invalid email format")
        .test('validEmail', 'Email must be a valid format', function (value) {
            // Allow nullable if value is empty
            if (!value) {
                return true;
            }
            // Check for valid email format
            return value.includes('@') && value.includes('.');
        }),
    currency: Yup.string().nullable()
});



export const incentiveRuleValidationSchema = Yup.object({
    // branch_id: Yup.number().required("Branch is required"),
    // rule_title: Yup.string().required("Rule Title is required"),
    from_amount: Yup.number().required("From Amount is required"),
    to_amount: Yup.number().required("To Amount is required"),
    incentive_percent: Yup.string().required("Incentive Percent is required"),
    description: Yup.string().required("Description is required"),
});

export const incentiveEmployeeValidationSchema = Yup.object({
    employee_id: Yup.number().required("Please Select Employee "),
    incentive_rule_id: Yup.array().required("Please Select Incentive rule"),

});

export const employeeValidationSchema = Yup.object({
    emp_id: Yup.string()
        .matches(/^[A-Za-z0-9_-]+$/, "Employee Code must be in letters, numbers, hyphens, or underscores."),
    first_name: Yup.string().required("First Name is required."),
    last_name: Yup.string().required("Last Name is required."),
    gender: Yup.string().required("Gender is required."),
    email: Yup.string().email("Invalid email format").nullable(),
    mobile_1: Yup.string()
        .required("Contact is required.")
        .matches(/^\d{10}$/, "Contact number must be 10 digits."),
    mobile_2: Yup.string().matches(/^\d{10}$/, "Contact number must be 10 digits."),
    date_of_birth: Yup.date().nullable(),
    date_of_joining: Yup.date().required("Date of Joining is required."),
    category: Yup.string().required("Designation is required."),
    branch_id: Yup.string().required("Branch is required."),
    salary: Yup.string().required("Salary is required."),
    // is_active: Yup.string().required("Status is required."),
    // created_by: Yup.string().required("Created By is required."),
    // updated_by: Yup.string().required("Updated By is required."),
});


export const editEmployeeValidationSchema = Yup.object({
    emp_id: Yup.string().matches(/^[A-Za-z0-9_-]+$/, "Employee Code must be in letters, numbers, hyphens, or underscores."),
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    gender: Yup.string().required("Gender is required"),
    // email: Yup.string().email("Invalid email format").nullable(),
    mobile_1: Yup.string()
        .required("Contact is required.")
        .matches(/^\d{10}$/, "Contact number must be 10 digits."),
    mobile_2: Yup.string().matches(/^\d{10}$/, "Contact number must be 10 digits."),
    date_of_birth: Yup.date().nullable(),
    date_of_joining: Yup.date().required("Date of Joining is required"),
    date_of_relieving: Yup.date().nullable(),
    category: Yup.string().required("Designation is required"),
    branch_id: Yup.string().required("Branch is required"),
    salary: Yup.string().required("Salary is required"),
    // is_active: Yup.string().required("Status is required"),
    // created_by: Yup.string().required("Created By is required"),
    // updated_by: Yup.string().required("Updated By is required"),
});


export const leaveValidationSchema = Yup.object({
    employee_id: Yup.string().required("Employee Name is required"),
    leave_type: Yup.string().required("Leave Type is required"),
    start_date: Yup.string().required("Start Date is required"),
    reason: Yup.string().optional(),
    end_date: Yup.string().required("End Date is required"),
});


export const leaveValidation = Yup.object({
    employee_id: Yup.string().required("Employee Name is required"),
    leave_type: Yup.string().required("Leave Type is required"),
    leave_date: Yup.string().required("Leave Date is required"),
    reason: Yup.string().optional(),
});

export const serviceValidationSchema = Yup.object({
    branch_id: Yup.string().required("Branch Name is required"),
    service_id: Yup.string().required("Service Name is required"),
    // service_charge: Yup.string().required("Service Charge is required"),
    // description: Yup.string().required("Description is required")
});


export const AdvancePaymentValidationSchema = Yup.object({
    employee_id: Yup.string().required("Employee Name is required"),
    payment_date: Yup.string().required("Payment Date is required"),
    amount: Yup.number().min(0, "Enter Positive Number").required("Amount is required"),
    payment_mode: Yup.string().required("Payment Mode is required"),
    reason: Yup.string().optional(),
});
export const editAdvancePaymentValidationSchema = Yup.object({
    branch_id: Yup.string().required("Branch Name is required"),
    employee_id: Yup.string().required("Employee Name is required"),
    payment_date: Yup.string().required("Payment Date is required"),
    amount: Yup.number().min(0, "Enter Positive Number").required("Amount is required"),
    payment_mode: Yup.string().required("Payment Mode is required"),
    reason: Yup.string().optional(),
});


export const editDailyTransactionValidationSchema = Yup.object({
    branch_id: Yup.string().required("Branch Name is required"),
    employee_id: Yup.string().required("Employee Name is required"),
    amount: Yup.number().min(0, "Enter Postive Number").required("Amount is required"),
    transaction_date: Yup.string().required("Transaction date is required"),
    payment_mode: Yup.string().required("Payment Mode is required"),
    service_type: Yup.string().required("Service Type is required"),
    remark: Yup.string().optional(),
    customer_name: Yup.string().optional(),
    cutomer_email: Yup.string().optional(),
    customer_mobile: Yup.string().optional(),
});




// export const AddDailyTransactionValidationSchema = Yup.object().shape({
//     employee_id: Yup.string().required("Employee Name is required"),
//     amount_paid: Yup.number()
//         .min(0, "Enter Positive Number")
//         .required("Amount is required").optional(),
//     transaction_date: Yup.string().required("Transaction date is required"),
//     payment_mode: Yup.string().required("Payment Mode is required"),
//     item_type: Yup.string().required("Service Type is required"),
//     customer_name: Yup.string().when('amount_paid',{is:(amount_paid)=>(amount_paid<serviceTotal),then:Yup.string().required("Name is required."),otherwise:Yup.string().optional()}),
//     customer_mobile: Yup.string().when('amount_paid',(amount_paid)=>amount_paid<serviceTotal?Yup.string().required("Number is required"):Yup.string().optional()),
// });


export const branchManagervalidationSchema = Yup.object().shape({
    employee: Yup.string().required("Please select an employee"),
    username: Yup.string()
        .required("Username is required")
        .matches(/^[0-9]+$/, "Username must be a number")
        .min(6, "Username must be at least 6 characters"),
    password: Yup.string().required("Password is required").min(6, "Password must be at least 6 characters"),
    cpassword: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const makeUserValidationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required").min(6, "Username must be at least 6 characters"),
    email: Yup.string().email("Invalid email format").required(),
    password: Yup.string().required("Password is required").min(6, "Password must be at least 6 characters"),
    cpassword: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
});


export const settingsValidationSchema = Yup.object({
    weekoff_day: Yup.string().required("Weekoff Day is required"),
    leaves_in_month: Yup.string().required("Leaves in month is required"),

})
export const dateOfRelievingValidationSchema = Yup.object({
    date_of_relieving: Yup.date().required("Relieving Date is required")
})


export const ProductValidationSchema = Yup.object({
    product_name: Yup.string().required('Product name is required'),
    description: Yup.string().optional(),
    sale_price: Yup.number().min(0, 'Sale price must be a non-negative number').required('Sale price is required'),
    purchase_price: Yup.number().min(0, 'Purchase price must be a non-negative number').optional(),
    stock_count: Yup.number().min(0, 'Stock count must be a non-negative number').optional(),
    unit: Yup.number().min(0, 'Unit must be a non-negative number').required('Unit is required'),
    branch_id: Yup.string().required("Branch Name is required"),
});


export const CustomerValidationSchema = Yup.object({
    name: Yup.string().required('Customer name is required'),
    email: Yup.string().email("Invalid email format").optional(),
    mobile: Yup.string()
        .required("Contact is required.")
        .matches(/^\d{10}$/, "Contact number must be 10 digits."),
})

export const ExpenseHeadSchema = Yup.object({
    branch_id: Yup.string().required("Branch Name is required"),
    category: Yup.string().required('Expense Head is required'),
    description: Yup.string(),
});

export const ExpenseSchema = Yup.object({
    item_name: Yup.string().required('Expense Name is required'),
    expense_head_id: Yup.string().required("Expense Head is Required"),
    branch_id: Yup.string().required("Branch Name is Required."),
    date: Yup.date().required("Date is Required."),
    invoice_no: Yup.string(),
    amount: Yup.number().min(0, "Enter Postive Number.").required("Amount is Required"),
    description: Yup.string(),
});

export const branch = Yup.object().shape({
    branch_id: Yup.string().nullable().notRequired(),
});
