import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp, faEdit, faPen, faPlus, faSearch, faSort, } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { listEmployeeReducer, updateEmployeeReducer } from "../../../reduxData/employeeSlice";
import Select from "react-select";
import { useFormik } from "formik";
import { dateOfRelievingValidationSchema, makeUserValidationSchema } from "../../../validations/Validations";
import { Button, Modal } from "react-bootstrap";
import { Alert, Snackbar } from "@mui/material";

export default function EmployeeList() {
    const [expandedRowIndex, setExpandedRowIndex] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const _ = require("lodash");
    const admin = useSelector((state: any) => state.authUserInfo?.value);
    const adminrole = useSelector((state: any) => state.authUserInfo?.rolesValue);
    const currentDate = new Date().toISOString().substring(0, 10);
    const employeeList = useSelector((state: any) => state.employeeInfo?.value);
    const upEmployee = useSelector((state: any) => state.employeeInfo?.upData);
    const branchInfo = useSelector((state: any) => state.branchInfo?.value);
    const [open, setOpen] = useState(false);
    const [sort, setsort] = useState(false);
    const [msg, setMsg] = useState("");
    const [status, setStatus] = useState()
    const [loading, setLoading] = useState(false);
    const [uploading, setUpLoading] = useState<string | null | boolean>(null);
    const [mkLoading, setMkLoading] = useState(false)
    const [statusLoading, setStatusLoading] = useState(false)

    const [searchInput, setSearchInput] = useState("");
    const [val, setval] = useState(null);
    const [deactivateEmployee, setDeactivateEmployee] = useState(null);
    const [createUser, setCreateUser] = useState(null);
    const [loginDetail, setLoginDetail] = useState(null);
    const [makeUser, setMakeUser] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const EmpPerPage = 20;
    const [dateOfRelievingInput, setDateOfRelievingInput] = useState<string | null>(currentDate);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const formik2 = useFormik({
        initialValues: {
            username: loginDetail?.mobile_1 ?? "",
            email: loginDetail?.email ?? "",
            password: "",
            cpassword: "",
        },
        validationSchema: makeUserValidationSchema,
        onSubmit: async (values) => {
            // var getRoleVal = loginDetail?.category_info?.category_title == "Employee" || loginDetail?.category_info?.category_title == "Staff" ? adminrole?.filter((d: any) => d.value == "STF").map((d: any) => d.id)[0] :  "";
            var getRoleVal = (
                loginDetail?.category_info?.category_title == "Employee" || loginDetail?.category_info?.category_title == "Staff"
            ) ? (
                adminrole?.filter((d: any) => d.value == "STF").map((d: any) => d.id)[0]
            ) : (
                loginDetail?.category_info?.category_title == "Manager"
            ) ? (
                adminrole?.filter((d: any) => d.value == "BRM").map((d: any) => d.id)[0]
            ) : "";
            const obj = {
                username: values.username,
                password: values.password,
                email: loginDetail?.email ?? values.email,
                isActive: true,
                role_id: getRoleVal,
                created_by: admin.uid,
                updated_by: admin.uid
            }
            try {
                setMkLoading(true)
                const url = `${urls.MAKE_USER}/${loginDetail?.id}`;
                const res = await AuthApiService.SaveApiCall(url, obj, admin.token);
                if (res.status) {
                    handleClickOpen()
                    setMsg(res.data.msg);
                    setStatus(res.data.status);
                    if (res.data.status == true) {
                        setTimeout(() => {
                            handleMakeUserClose();
                            navigate('/saloonChain/employeeDetails')
                        }, 1000)
                    } else {
                        setTimeout(() => {
                            navigate('/saloonChain/employeeDetails')
                        }, 1000)
                    }
                }
                else {
                    if (res.data && res.data.err) {
                        setMsg(res.data.err);
                        setStatus(false);
                    } else {
                        setMsg(`Error: ${res.status} - ${res.data.msg}`);
                        setStatus(false);
                    }
                }
            }
            catch (error) {
                handleClickOpen()
                setMsg('Network Error !');
                setStatus(false);
            }
            finally {
                setMkLoading(false)
            }
        },
    });

    useEffect(() => {
        // Update formik2 values when loginDetail changes
        formik2.setValues({
            username: loginDetail?.mobile_1 ?? "",
            email: loginDetail?.email ?? "",
            password: "",
            cpassword: ""
        });
    }, [loginDetail]);
    const formik = useFormik({
        initialValues: {
            date_of_relieving: currentDate,
        },
        validationSchema: dateOfRelievingValidationSchema,
        onSubmit: (values) => {
            statusDeActive(deactivateEmployee, values.date_of_relieving);
        },
    })

    const branchOptions = [{ value: "All", label: "All" }, ...branchInfo?.map((item: any) => ({
        value: item.id,
        label: item.branch_name,
    }))];

    const employeeOptions = val != undefined && val?.label != "All" ? [{ value: "All", label: "All" }, ...employeeList.filter((item: any) => {
        return item.branch_info.id === val?.value;
    }).map((item: any) => ({
        value: item.id,
        label: item.first_name + " " + item.last_name,
    }))] : [{ value: "All", label: "All" }, ...employeeList?.map((item: any) => ({
        value: item.id,
        label: item.first_name + " " + item.last_name,
    }))];

    // const empList = async () => {
    //     try {
    //         const response = await AuthApiService.GetApiCall(urls.EMPLOYEE_LIST, admin.token);
    //         if (response.status) {
    //             dispatch(listEmployeeReducer(_.orderBy(response.data.data, ['first_name'], ['asc'])))
    //         } else {
    //             setMsg(response.data.msg);
    //         }
    //     } catch (error) {
    //         const errorMessage: string = (error as any)?.response?.data?.msg || 'An error occurred. Please try again.';
    //         setMsg(errorMessage);
    //     }
    // }
    const empList = async () => {
        try {
            const response = await AuthApiService.GetApiCall(urls.EMPLOYEE_LIST, admin.token);
            if (response.status) {
                dispatch(listEmployeeReducer(response.data.data))
            } else {
                // Handle error when response status is not successful
                setMsg(response.data.msg);
                dispatch(listEmployeeReducer([])); // Set employee list to empty array
            }
        } catch (error: any) {
            // Handle network or other errors
            const errorMessage = (error.response && error.response.data && error.response.data.msg) || 'An error occurred. Please try again.';
            setMsg(errorMessage);
            dispatch(listEmployeeReducer([])); // Set employee list to empty array
        }
    }

    const sortByName = () => {
        setsort(!sort);
        if (sort) {
            const nw = _.orderBy(employeeList, ['first_name'], ['asc'])
            dispatch(listEmployeeReducer(nw))
        }
        else {
            const nw = _.orderBy(employeeList, ['first_name'], ['desc'])
            dispatch(listEmployeeReducer(nw))
        }
    }

    const formateDate = (datestr: any) => {
        if (!datestr) {
            return '-';
        }

        const date = new Date(datestr);
        return date.toLocaleDateString();
    }

    const edit = (employee: any) => {
        localStorage.setItem("empinfo", JSON.stringify({
            id: employee?.id, emp_id: employee?.emp_id, first_name: employee?.first_name,
            last_name: employee?.last_name, gender: employee?.gender, email: employee?.email, mobile_1: employee?.mobile_1,
            mobile_2: employee?.mobile_2, date_of_birth: employee?.date_of_birth?.substring(0, 10), date_of_joining: employee?.date_of_joining?.substring(0, 10),
            date_of_relieving: employee?.date_of_relieving?.substring(0, 10), category: employee?.category_info?.id, branch_id: employee?.branch_info?.id,
            salary: employee?.salary, govt_id: employee?.govt_id
        }))
        const d = dispatch(updateEmployeeReducer(employee))
        navigate("/saloonChain/editEmployee")
    }

    const statusActive = async (employee: any) => {
        try {

            setUpLoading(employee.id)
            const confirmMessage = window.confirm('Are you sure you want to Activate the employee?')

            if (confirmMessage) {
                const ob = {
                    is_active: true,
                    date_of_relieving: null,
                    created_by: admin.uid,
                    updated_by: admin.uid
                };
                const UPURL = urls.EMPLOYEE_UPDATE + employee.id;
                const resp = await AuthApiService.PutApiCall(UPURL, ob, admin.token);
                if (resp.status === 200) {
                    handleClickOpen()
                    setMsg(resp.data.msg);
                    setStatus(true);
                    const updatedList = employeeList?.map((emp: any) =>
                        emp.id === employee.id ? { ...emp, is_active: !emp.is_active, date_of_relieving: !emp.date_of_relieving ? emp.date_of_relieving : null } : emp
                    );
                    dispatch(listEmployeeReducer(updatedList));
                    // setsortedlist(updatedList);
                    const updatedCurrentPage = employeeList?.map((emp: any) =>
                        emp.id === employee.id ? { ...emp, is_active: !emp.is_active, date_of_relieving: !emp.date_of_relieving ? emp.date_of_relieving : null } : emp
                    );
                    dispatch(listEmployeeReducer(updatedCurrentPage.slice(indexOfFirstEmp, indexOfLastEmp)));
                } else {
                    if (resp.data && resp.data.err) {
                        setMsg(resp.data.err);
                        setStatus(false);
                    } else {
                        setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
                        setStatus(false);
                    }
                }
            }
        } catch (error) {
            handleClickOpen()
            setMsg('Network Error !');
            setStatus(false);
        } finally {
            setUpLoading(null);
        }

    }

    const statusDeActive = async (employee: any, dateOfRelieving: string | null) => {
        try {
            setUpLoading(employee.id);
            if (deactivateEmployee) {
                const ob = {
                    is_active: false,
                    date_of_relieving: dateOfRelieving
                        ? new Date(dateOfRelieving).toISOString()
                        : null,
                    created_by: admin.uid,
                    updated_by: admin.uid,
                };
                const UPURL = urls.EMPLOYEE_UPDATE + employee.id;
                const resp = await AuthApiService.PutApiCall(UPURL, ob, admin.token);
                if (resp.status === 200) {
                    setMsg(resp.data.msg);
                    setStatus(true);
                    handleClickOpen()
                    handleUpdateModalClose();
                    const updatedList = employeeList?.map((emp: any) =>
                        emp.id === employee.id
                            ? { ...emp, is_active: false, date_of_relieving: ob.date_of_relieving }
                            : emp
                    );
                    dispatch(listEmployeeReducer(updatedList));
                    const updatedCurrentPage = employeeList?.map((emp: any) =>
                        emp.id === employee.id
                            ? { ...emp, is_active: false, date_of_relieving: ob.date_of_relieving }
                            : emp
                    );
                    dispatch(listEmployeeReducer(updatedCurrentPage.slice(indexOfFirstEmp, indexOfLastEmp)));
                } else {
                    if (resp.data && resp.data.err) {
                        setMsg(resp.data.err);
                        setStatus(false);
                    } else {
                        setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
                        setStatus(false);
                    }
                }
            }
        } catch (error) {
            setMsg('Network Error !');
            setStatus(false);
            handleClickOpen()
        } finally {
            setUpLoading(null);
            // setTimeout(() => {
            //     setMsg("")
            // }, 2000);
        }
    }

    const handleUserActivate = async (data: any) => {
        try {
            setStatusLoading(data?.user_role_info[0]?.id)
            const confirmMessage = window.confirm('Are you sure you want to Activate the UserRole?')
            if (confirmMessage) {
                const ob = {
                    isActive: true,
                    created_by: admin.uid,
                    updated_by: admin.uid
                };
                const roleId = data?.user_role_info.length > 0 ? data.user_role_info[0].id : null;
                const url = `${urls.UPDATE_ROLE}/${roleId}`;
                const resp = await AuthApiService.PutApiCall(url, ob, admin.token);
                if (resp.status === 200) {
                    handleClickOpen()
                    setMsg(resp.data.msg);
                    setStatus(true);

                    if (resp.data.status == 201) {
                        setTimeout(() => {
                            handleLoginDetailClose();
                            navigate('/saloonChain/employeeDetails')
                        }, 1000)
                    } else {
                        setTimeout(() => {
                            navigate('/saloonChain/employeeDetails')
                        }, 1000)
                    }
                } else {
                    if (resp.data && resp.data.err) {
                        setMsg(resp.data.err);
                        setStatus(false);
                    } else {
                        setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
                        setStatus(false);
                    }
                }
            }
        } catch (error) {
            handleClickOpen()
            setMsg('Network Error !');
            setStatus(false);
        } finally {
            setStatusLoading(null)
        }
    }

    const handleUserDeactivate = async (data: any) => {
        try {
            setStatusLoading(data?.user_role_info[0]?.id)
            const confirmMessage = window.confirm('Are you sure you want to DeActivate the UserRole?')
            if (confirmMessage) {
                const ob = {
                    isActive: false,
                    created_by: admin.uid,
                    updated_by: admin.uid
                };
                const roleId = data?.user_role_info.length > 0 ? data.user_role_info[0].id : null;
                const url = `${urls.UPDATE_ROLE}/${roleId}`;
                const resp = await AuthApiService.PutApiCall(url, ob, admin.token);
                if (resp.status === 200) {
                    handleClickOpen()
                    setMsg(resp.data.msg);
                    setStatus(true);
                    if (resp.data.status == 201) {
                        setTimeout(() => {
                            handleLoginDetailClose();
                            navigate('/saloonChain/employeeDetails')
                        }, 1000)
                    } else {
                        setTimeout(() => {
                            navigate('/saloonChain/employeeDetails')
                        }, 1000)
                    }
                } else {
                    if (resp.data && resp.data.err) {
                        setMsg(resp.data.err);
                        setStatus(false);
                    } else {
                        setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
                        setStatus(false);
                    }
                }
            }
        } catch (error) {
            handleClickOpen()
            setMsg('Network Error !');
            setStatus(false);
        } finally {
            setStatusLoading(null)
        }
    }

    const handleUpdateDateOfRelieving = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        statusDeActive(deactivateEmployee, dateOfRelievingInput);
    };


    // pagination
    const indexOfLastEmp = currentPage * EmpPerPage;
    const indexOfFirstEmp = indexOfLastEmp - EmpPerPage;
    // const currentEmp = employeeList.data.slice(indexOfFirstEmp, indexOfLastEmp);
    const currentEmp = Array.isArray(employeeList)
        ? employeeList.slice(indexOfFirstEmp, indexOfLastEmp)
        : [];
    const totalPages = Math.ceil((employeeList?.length || 0) / EmpPerPage);
    const handleChange = (page: number) => {
        setCurrentPage(page);
    };

    const onSearch = async (data: any) => {
        if (data !== null && data?.value != "All") {
            setLoading(true)
            const url = `${urls.EMPLOYEE_SEARCH}`;
            const ob = { nameChars: data?.label.split(" ")[0] }
            const res = await AuthApiService.SearchApiCall(url, ob, admin.token);
            if (res.status) {
                setLoading(null)
                // setsortedlist(res.data.data)
                dispatch(listEmployeeReducer(res.data.data));
            }
        }
        else {
            await empList();
        }
    };
    const onBranchSearch = async (data: any) => {
        if (data !== null && data.value != "All") {
            setLoading(true)
            const url = `${urls.EMPLOYEE_BY_BRANCH}?branchId=${data?.value}`;
            // const ob = {nameChars:data.value}
            const res = await AuthApiService.SearchApiCallForEmployeeAndBranch(url, data.value, admin.token);
            if (res.status) {
                setLoading(null)
                // setsortedlist(res.data.data)
                dispatch(listEmployeeReducer(res.data.data));
            }
        }
        else {
            await empList();
        }
    };

    const toggleRowExpansion = (index: any) => {
        setExpandedRowIndex(expandedRowIndex === index ? null : index);
    };

    const renderRowExpansionContent = (employee: any) => {
        return (<><th></th>
            <tr> <td className="text-wrap">  <b>Gender:</b><span>{employee.gender}</span></td></tr>
            <tr> <td className="text-wrap">   <b>PhoneNo:</b><span>{employee.mobile_1}</span></td></tr>
            <tr> <td className="text-wrap"> <b>Category:</b><span>{employee?.category_info?.category_title}</span></td></tr>
            <tr> <td className="text-wrap"><b>Salary:</b><span>{employee.salary}</span></td></tr>
            <tr> <td className="text-wrap"><b>Joining Date:</b><span>{employee.date_of_joining.split('T')[0]}</span></td></tr>
            <tr> <td className="text-wrap"><b>Branch:</b><span>{employee?.branch_info?.branch_name}</span></td></tr>
            {employee.date_of_relieving == "" || null ? <></> : <tr> <td className="text-wrap"><b>Relieving Date:</b><span>{employee.date_of_relieving !== null ? formateDate(employee.date_of_relieving) : '-'}</span></td></tr>}
            {employee.date_of_birth == "" || null ? <></> : <tr> <td className="text-wrap"><b>DOB:</b><span>{formateDate(employee.date_of_birth)}</span></td></tr>}
            {employee.govt_id == "" || null ? <></> : <tr> <td className="text-wrap"><b>Govt.Id:</b><span>{employee.govt_id ? employee.govt_id : "-"}</span></td></tr>}
            {employee.email == "" || null ? <></> : <tr> <td className="text-wrap"><b>Email:</b><span>{employee.email ? employee.email : "-"}</span></td></tr>}
        </>
        );
    };
    const handleWindowResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        empList()
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        if (branchInfo.length === 1) {
            const singleBranch = branchInfo[0];
            const singleBranchOption = { value: singleBranch.id, label: singleBranch.branch_name };
            setSelectedBranch(singleBranchOption);
            onBranchSearch(singleBranchOption);
        }
    }, [branchInfo]);
    const loadingSpinner = () => {
        <div className="d-flex justify-content-center">
            <div className="spinner-border">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    };
    const handleClick = () => {
        if (admin.accessType === 'Trail' && employeeList.length >= 4) {
            // Show Snackbar with a message
            setMsg('You cannot add more than 4 employees during the trial period. Please upgrade to Premium.');

            handleClickOpen()
        } else {
            // Navigate to the new branch page
            navigate('/saloonChain/newEmployee'); // or use history.push if using react-router v5
        }
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const ActiveEmp = () => {
        const emp = employeeList.filter((o: any) => o.is_active == true).map((ob: any) => ob)
        dispatch(listEmployeeReducer(emp))
    }
    const InActiveEmp = () => {
        const emp = employeeList.filter((o: any) => o.is_active == false).map((ob: any) => ob)
        dispatch(listEmployeeReducer(emp))
    }
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const handleUpdateModalClose = () => setShowUpdateModal(false);
    const handleUpdateModalShow = () => setShowUpdateModal(true);

    const [showMakeUserModal, setShowMakeUserModal] = useState(false);
    const handleMakeUserClose = () => setShowMakeUserModal(false);
    const handleMakeUserShow = () => setShowMakeUserModal(true);

    const [showLoginDetailModal, setShowLoginDetailModal] = useState(false);
    const handleLoginDetailClose = () => setShowLoginDetailModal(false);
    const handleLoginDetailShow = () => setShowLoginDetailModal(true);

    const updateModal = () => {
        return <>
            <Modal show={showUpdateModal} onHide={handleUpdateModalClose} centered backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Deactivate Employee</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Please Insert the date of relieiving for Deactivating the employee.</p>
                    <form onSubmit={formik.handleSubmit}>
                        <>
                            <div className="mb-3 row">
                                <label htmlFor="date_of_relieving" className="col-sm-3 col-form-label">
                                    Date of Relieving<span style={{ color: "red" }}>*</span>
                                </label>
                                <div className="col-sm-9">
                                    <input
                                        type="date"
                                        className={`form-control shadow-sm ${formik.errors.date_of_relieving ? "is-invalid" : ""}`}
                                        id="date_of_relieving"
                                        name="date_of_relieving"
                                        value={formik.values.date_of_relieving}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.date_of_relieving && typeof formik.errors.date_of_relieving === 'string' ? (
                                        <div className="invalid-feedback">{formik.errors.date_of_relieving}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="mb-3 row p-2 pb-0">
                                <button type="submit" className="btn btn-purple btn-sm text-center" >
                                    {uploading ? "Updating..." : "Update"}
                                </button>
                            </div>
                        </>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" disabled={uploading ? true : false} onClick={handleUpdateModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    }
    const MakeUserModal = () => {
        return <>
            <Modal show={showMakeUserModal} onHide={handleMakeUserClose} centered backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Create User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={formik2.handleSubmit}>
                        <>
                            <p>{loginDetail?.first_name}&nbsp; {loginDetail?.last_name} - {loginDetail?.branch_info?.branch_name}</p>
                            <div className="mb-2 mt-0 row">
                            </div>
                            <>
                                <div className="mb-2 row">
                                    <label htmlFor="username" className="col-sm-3 col-form-label">
                                        Username<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            className={`form-control shadow-sm ${formik2.touched.username && formik2.errors.username ? "is-invalid" : ""}`}
                                            id="username"
                                            name="username"
                                            placeholder="Enter username or mobile"
                                            value={formik2.values.username}
                                            onChange={formik2.handleChange}
                                            onBlur={formik2.handleBlur}
                                        />
                                        <div className="invalid-feedback">{formik2.touched.username && formik2.errors.username}</div>
                                    </div>
                                </div>
                                <div className="mb-2 row">
                                    <label htmlFor="email" className="col-sm-3 col-form-label">
                                        Email<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            className={`form-control shadow-sm ${formik2.touched.email && formik2.errors.email ? "is-invalid" : ""}`}
                                            id="email"
                                            name="email"
                                            placeholder="Enter email"
                                            value={formik2.values.email}
                                            onChange={formik2.handleChange}
                                            onBlur={formik2.handleBlur}
                                        />
                                        <div className="invalid-feedback">{formik2.touched.email && formik2.errors.email}</div>
                                    </div>
                                </div>
                                <div className="mb-2 row">
                                    <label htmlFor="password" className="col-sm-3 col-form-label">
                                        Password<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="password"
                                            className={`form-control shadow-sm ${formik2.touched.password && formik2.errors.password ? "is-invalid" : ""}`}
                                            id="password"
                                            placeholder="Enter Password"
                                            name="password"
                                            value={formik2.values.password}
                                            onChange={formik2.handleChange}
                                            onBlur={formik2.handleBlur}
                                        />
                                        <div className="invalid-feedback">{formik2.touched.password && formik2.errors.password}</div>
                                    </div>
                                </div>
                                <div className="mb-2 row">
                                    <label htmlFor="cpassword2" className="col-sm-3 col-form-label">
                                        Confirm Password<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="password"
                                            className={`form-control shadow-sm ${formik2.touched.cpassword && formik2.errors.cpassword ? "is-invalid" : ""}`}
                                            id="cpassword"
                                            placeholder="Enter Confirm Password"
                                            name="cpassword"
                                            value={formik2.values.cpassword}
                                            onChange={formik2.handleChange}
                                            onBlur={formik2.handleBlur}
                                        />
                                        <div className="invalid-feedback">{formik2.touched.cpassword && formik2.errors.cpassword}</div>
                                    </div>
                                </div>
                                <div className="mb-2 row p-2 pb-0">
                                    <button type="submit" className="btn btn-purple btn-sm text-center" >
                                        {mkLoading ? "Creating User" : "Create"}
                                    </button>
                                </div>
                            </>
                        </>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" disabled={uploading ? true : false} onClick={handleMakeUserClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    }

    const LoginDetailModal = () => {
        return <>
            <Modal show={showLoginDetailModal} onHide={handleLoginDetailClose} centered backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{loginDetail?.first_name}&nbsp; {loginDetail?.last_name} - {loginDetail?.branch_info?.branch_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body><>
                    <form onSubmit={formik.handleSubmit}>
                        <>

                            <>
                                <div className="mb-2 row">
                                    <label className="col-sm-3 ">
                                        Username -
                                    </label>
                                    <div className="col-sm-9">
                                        {loginDetail?.userinfo?.username}
                                    </div>
                                </div>
                                <div className="mb-2 row">
                                    <label htmlFor="password" className="col-sm-3">
                                        Password -
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="password"
                                            className="form-control border-0 border-none"
                                            id="password"
                                            placeholder=""
                                            name="password"
                                            value="1234567890"
                                        />
                                    </div>
                                </div>

                                <div className="mb-2 row p-2 pb-0">
                                    <p>Send The Login Details to the User by -</p>
                                    <div className="col-lg-4 mb-xl-0 mb-2">
                                        <button type="submit" className="btn btn-purple btn-sm text-center form-control" >
                                            Send Email
                                        </button>
                                    </div>
                                    <div className="col-lg-4 mb-xl-0 mb-2">
                                        <button type="submit" className="btn btn-purple btn-sm text-center form-control" >
                                            Send SMS
                                        </button>
                                    </div>
                                    <div className="col-lg-4 mb-xl-0 mb-2">
                                        <button type="submit" className="btn btn-purple btn-sm text-center form-control" >
                                            Send Whatsapp
                                        </button>
                                    </div>
                                </div>
                            </>
                        </>
                    </form>
                    <div className="mb-2 row p-2 pb-0 justify-content-center"><div className="col-lg-3">
                        {loginDetail?.user_role_info[0]?.Role_status == true ? (
                            <button type="submit"
                                className="btn btn-warning btn-sm text-center form-control"
                                onClick={() => { handleUserDeactivate(loginDetail) }} disabled={statusLoading}
                            >
                                {statusLoading ? "Activating..." : "DeActivate"}
                            </button>
                        ) : (
                            <button type="submit"
                                className="btn btn-danger  btn-sm text-center form-control"
                                // style={{ "width": "120px" }}
                                onClick={() => handleUserActivate(loginDetail)} disabled={statusLoading}
                            >
                                {statusLoading ? "DeActivating..." : "Activate"}
                            </button>
                        )}
                    </div>

                    </div>
                </>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
    }
    return <>
        {updateModal()}
        {MakeUserModal()}
        {LoginDetailModal()}
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}>
            <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert>
        </Snackbar>
        <div className="container-fluid p-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 col-md-8 col-sm-8 d-flex justify-content-center ">
                        <div className="text-center">
                            <h4>Employees</h4>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4 mt-2 d-flex justify-content-end">
                        {/* <Link to="/saloonChain/newEmployee"> */}
                        <button className="btn btn-sm btn-purple" onClick={handleClick}><FontAwesomeIcon icon={faPlus} />&nbsp;New Employee</button>
                        {/* <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            open={open}
                            autoHideDuration={5000}
                            onClose={handleClose}
                        >
                            <Alert onClose={handleClose} severity="error" variant="filled" sx={{ width: '100%' }}>
                                {msg}
                            </Alert>
                        </Snackbar> */}
                        {/* </Link> */}
                    </div>
                </div>
            </div>
            <div className="row mt-2 mt-xl-4 pb-4 p-1">
                <div className="col-lg-3 col-md-3 col-sm-12">
                    <label>Select Branch</label>
                    {/* <Select
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={"All"}
                        placeholder="All"
                        isLoading={!branchInfo}
                        isClearable={true}
                        isSearchable={true}
                        options={branchInfo ? branchOptions : []}
                        onChange={(e: string) => { setval(e); onBranchSearch(e) }}
                        value={val}
                    /> */}
                    <Select
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder="All"
                        isLoading={branchInfo.length <= 0}
                        isClearable={true}
                        isSearchable={true}
                        options={branchOptions}
                        onChange={(e: any) => { setSelectedBranch(e); onBranchSearch(e); }}
                        value={selectedBranch}
                    />
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12 ">
                    <label>Select Employee</label>
                    <Select
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder={val?.label || "All"}
                        isLoading={employeeList?.length < 0 ? true : false}
                        isClearable={true}
                        isSearchable={true}
                        options={employeeOptions}
                        // value={searchInput}
                        onChange={(e: string) => { setSearchInput(e); onSearch(e) }}
                        escapeClearsValue={true}
                        backspaceRemovesValue={false}
                        filterOption={(option, inputValue) =>
                            option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                        }
                    />
                </div>
                {/* <div className="col-lg-3 col-md-3 col-sm-3 "> </div> */}
                {/* <div className="col-lg-1 col-md-1 col-sm-1 "> <label></label><button className="btn btn-detail justify-content-end" onClick={ActiveEmp}>Active</button></div>
                <div className="col-lg-1 col-md-1 col-sm-1 "><label></label><button className="btn btn-darkblue" onClick={InActiveEmp}>InActive</button></div> */}

            </div>
            {loading || !employeeList || employeeList.length === 0 ? (
                <div className="mt-4">
                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center">
                            {loadingSpinner()}
                        </div>
                    ) : (
                        <h5>No Employees found. You can add New Employee.</h5>
                    )}
                </div>
            ) : <div className="mt-2 mt-xl-2 mt-sm-2 justify-content-center">
                <div className={`table-container ${isMobile ? 'table-fixed' : 'table-responsive'}`}>
                    <table className="table table-striped  table-hover border-light-subtle ">
                        <thead>
                            <tr className="text-nowrap text-center">
                                <th>

                                </th>
                                {!isMobile && <th>Emp. Code</th>}
                                <th style={{ "cursor": "pointer" }} onClick={() => { sortByName() }}>Name&nbsp;<FontAwesomeIcon icon={faSort} cursor="pointer" onClick={() => { sortByName() }} /></th>
                                {!isMobile && <th>Gender </th>}
                                {!isMobile && <th>Phone No.</th>}
                                {!isMobile && <th>Category &nbsp;</th>}
                                {!isMobile && <th>Salary </th>}
                                {!isMobile && <th>Joining Date</th>}
                                {!isMobile && <th>Branch</th>}

                                {!isMobile && <th>Relieving Date  </th>}
                                {!isMobile && <th>DOB </th>}
                                {!isMobile && <th>Govt.Id</th>}
                                {!isMobile && <th>Email </th>}
                                <th>Status</th>
                                <th>{isMobile ? <>User < br /> Detail</> : <> User Detail</>}</th>
                                {isMobile && <th></th>}
                            </tr>
                        </thead>
                        <tbody className="text-nowrap">
                            {employeeList?.map((employee: any, index: number) => {
                                const isExpanded = expandedRowIndex === index; return (
                                    <React.Fragment key={index}>
                                        <tr key={index} className="text-center">
                                            <td className="fontIcon" onClick={() => edit(employee)} style={{ cursor: "pointer" }}>
                                                <FontAwesomeIcon icon={faEdit} className="fontIcon" />
                                            </td>
                                            {!isMobile && <td className={`${isMobile ? 'text-wrap' : 'text-nowrap'}`}>{employee.emp_id}</td>}
                                            <td className={`${isMobile ? 'text-wrap' : 'text-nowrap'}`}>{isMobile ? <>{employee.first_name}<br></br>{employee.last_name}</> : <>{employee.first_name}&nbsp; {employee.last_name}</>}</td>
                                            {!isMobile && <td>{employee.gender}</td>}
                                            {!isMobile && <td>{employee.mobile_1}</td>}
                                            {!isMobile && <td>{employee?.category_info?.category_title}</td>}
                                            {!isMobile && <td>{employee.salary}</td>}
                                            {!isMobile && <td>{employee.date_of_joining.split('T')[0]}</td>}

                                            {!isMobile && <td>{employee?.branch_info?.branch_name}</td>}

                                            {!isMobile && <td>{employee.date_of_relieving !== null ? formateDate(employee.date_of_relieving) : '-'}</td>}
                                            {!isMobile && <td>{formateDate(employee.date_of_birth)}</td>}
                                            {!isMobile && <td>{employee.govt_id ? employee.govt_id : "-"}</td>}
                                            {!isMobile && <td>{employee.email ? employee.email : "-"}</td>}

                                            <td>
                                                {employee.is_active ? (
                                                    <button
                                                        className="btn btn-sm btn-warning"
                                                        onClick={() => { handleUpdateModalShow(); setDeactivateEmployee(employee) }}
                                                    >
                                                        {uploading === employee.id ? "Deactivating..." : "Active"}
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="btn btn-sm btn-danger"
                                                        // style={{ "width": "120px" }}
                                                        onClick={() => statusActive(employee)}
                                                    >
                                                        {uploading === employee.id ? "Activating..." : "Deactive"}
                                                    </button>
                                                )}
                                            </td>

                                            <td>
                                                {employee.userinfo != null ? (
                                                    <button
                                                        className="btn btn-sm btn-info"
                                                        onClick={() => { handleLoginDetailShow(); setLoginDetail(employee) }}
                                                    >
                                                        {isMobile ? <>Login < br />Details</> : <>Login Details</>}
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="btn btn-sm btn-purple"
                                                        // style={{ "width": "120px" }}
                                                        onClick={() => { handleMakeUserShow(); setLoginDetail(employee) }}
                                                    >
                                                        {isMobile ? <>Make < br />User</> : <> Make User</>}
                                                    </button>
                                                )}
                                            </td>
                                            {isMobile && (
                                                <td>
                                                    {!isExpanded ? (
                                                        <button
                                                            className="btn btn-link"
                                                            onClick={() => toggleRowExpansion(index)}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faArrowDown}
                                                                className="fontIcon"
                                                                size="sm"
                                                            />
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="btn btn-link"
                                                            onClick={() => toggleRowExpansion(index)}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faArrowUp}
                                                                className="fontIcon"
                                                                size="sm"
                                                            />
                                                        </button>
                                                    )}</td>
                                            )}
                                        </tr>
                                        {isExpanded && isMobile && (
                                            <tr>
                                                <td colSpan="5">{renderRowExpansionContent(employee)}</td>
                                            </tr>
                                        )}
                                    </React.Fragment>)
                            }
                            )}
                        </tbody>
                    </table>
                </div>
            </div>}
        </div >
    </>
}



