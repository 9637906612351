import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface TransactionState {
    value: Transaction[];
    value2: Transaction[];
    transactionDate:any;
    upData?: any; // Update this type based on your use case
}

interface Transaction {

    // Add other properties as needed
}

const initialState: TransactionState = {
    value: [],
    value2: [],
    transactionDate:null,
    upData: null,
};

const transactionSlice = createSlice({
    name: "transaction",
    initialState,
    reducers: {
        addTransactionReducer: (state, action:any) => {
            state.value.push(action.payload);
        },
        listTransactionReducer: (state, action: PayloadAction<Transaction[]>) => {
            state.value = action.payload;
        },
        listTransactionByBranchReducer: (state, action: PayloadAction<Transaction[]>) => {
            state.value2 = action.payload;
        },
        updateTransactionReducer: (state, action: PayloadAction<any>) => {
            state.upData = action.payload;
        },
        addDateReducer:(state, action: PayloadAction<any>)=>{
            state.transactionDate = action.payload;
        }
    },
});

export const {addTransactionReducer,listTransactionReducer,updateTransactionReducer,listTransactionByBranchReducer,addDateReducer} = transactionSlice.actions;
export default transactionSlice.reducer;
