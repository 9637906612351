import { faArrowDown, faArrowUp, faPlus, faSearch, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { listBranchServicesReducer } from "../../../reduxData/branchServicesSlice";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { Alert, Snackbar } from "@mui/material";
import Select from "react-select"
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { listAdvancePaymentReducer } from "../../../reduxData/advancePaymentSlice";
import { getCustomerTransactionReducer, listCustomerReducer } from "../../../reduxData/customerSlice";

export default function CustomerList() {
  const _ = require("lodash")
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const branchServicesInfo = useSelector((state: any) => state.branchServicesInfo.value);
  const customerInfo = useSelector((state: any) => state.customerInfo.value);
  const branchInfo = useSelector((state: any) => state.branchInfo.value);
  const auth = useSelector((state: any) => state.authUserInfo.value);
  const [loading, setLoading] = useState(false);
  const [sort, setsort] = useState(false);
  const [msg, setMsg] = useState("");
  const [status, setStatus] = useState();
  const [open, setOpen] = useState(false);
  const [val, setval] = useState();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const currentDate = new Date().toISOString().substring(0, 10);

  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [month, setMonth] = useState(`${currentYear}-0${currentMonth}`);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const branchOptions = [{ value: "All", label: "All" }, ...branchInfo.map((item: any) => ({
    value: item.id,
    label: item.branch_name,
  }))];

  const sortByName = () => {
    setsort(!sort);
    const sortedCustomers = _.orderBy(customerInfo.data, [(item: any) => item.name.toLowerCase()], sort ? 'desc' : 'asc');
    dispatch(listCustomerReducer({ ...customerInfo, data: sortedCustomers }));
  };
  const sortByBranch = () => {
    setsort(!sort);
    const sortedCustomers = _.orderBy(customerInfo.data, [(item: any) => item.branchinfo.branch_name.toLowerCase()], sort ? 'desc' : 'asc');
    dispatch(listCustomerReducer({ ...customerInfo, data: sortedCustomers }));
  };

  const totalRecords = customerInfo?.pagination?.totalRecords

  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPageButtons = 3;

    if (totalPages <= maxPageButtons) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const leftEllipsis = currentPage > 2;
      const rightEllipsis = currentPage < totalPages - 1;

      if (leftEllipsis) {
        pageNumbers.push(1, '...');
      }

      const start = Math.max(1, currentPage - 1);
      const end = Math.min(totalPages, currentPage + 1);

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }

      if (rightEllipsis) {
        pageNumbers.push('...', totalPages);
      }
    }

    return pageNumbers;
  };

  const customerList = async (page: number, itemsPerPage: number) => {
    try {
      setLoading(true)
      const URL = `${urls.CUSTOMER_LIST}/${page}/${itemsPerPage}`;
      const res = await AuthApiService.GetApiCallWithPagination(URL, auth.token, page, itemsPerPage);
      if (res.status) {
        dispatch(listCustomerReducer(res.data));
      }
    } catch (error) {
      setMsg("Network Error !");
    } finally {
      setLoading(false)
    }

  };


  const onSearch = async (searchInput: any, branch: any, page: any, itemsPerPage: any) => {

    // let queryParams :any= {};



    try {
      setLoading(true);
      let queryParams: any = {};
      if (queryParams) {
        if (branch && branch.value && branch.value !== "All") {
          queryParams.branch_id = branch.value;
        }

        if (searchInput) {

          if (/^\d+$/.test(searchInput)) {
            queryParams.mobileDigits = searchInput;
          } else {
            queryParams.nameChars = searchInput;
          }
        }
        const queryString = new URLSearchParams(queryParams).toString();
        let url = `${urls.CUSTOMER_LIST_BY_SEARCH}/${page}/${itemsPerPage}`;
        if (queryString) {
          url += `?${queryString}`;
        }

        const res = await AuthApiService.SearchApiCallWithQuery(url, {}, auth.token);

        if (res.status) {
          setLoading(false);
          dispatch(listCustomerReducer(res.data));
        }
      } else {
        await customerList(currentPage, itemsPerPage)
      }
    } catch (error) {
      setLoading(false);
      setMsg("Network Error !");
    }

  };




  useEffect(() => {
    customerList(currentPage, itemsPerPage);
  }, [currentPage]);



  const handleChange = async (page: number) => {
    setCurrentPage(page);
    // if (searchInput != '') {
    //     await onSearch(searchInput, page, itemsPerPage)
    // }
    // else {
    await customerList(page, itemsPerPage);
    // }
  };


  const loadingSpinner = () => {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  const toggleRowExpansion = (index: any) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const renderRowExpansionContent = (data: any) => {
    return (<>

      <tr><td className="text-wrap">  <b>Email: </b><span>{data?.email ? data?.email : "-"}</span></td></tr>
      <tr><td className="text-wrap">  <b>Branch: </b><span>{data?.branchinfo?.branch_name ? data?.branchinfo?.branch_name : '-'}</span></td></tr>
    </>
    );
  };

  const handleWindowResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleViewHistory = (data: any) => {
    localStorage.setItem("customerhistoryinfo", JSON.stringify(data));
    navigate("/saloonChain/customerHistory");
    dispatch(getCustomerTransactionReducer(data));
  }

  useEffect(() => {
    if (branchInfo.length === 1) {
      const singleBranch = branchInfo[0];
      const singleBranchOption = { value: singleBranch.id, label: singleBranch.branch_name };
      setSelectedBranch(singleBranchOption);
      // onBranchSearch(singleBranchOption);
    }
  }, [branchInfo]);
  return <>
    {/* {addModal()} */}
    <div className="container-fluid pt-2">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert>
      </Snackbar>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="text-center">
              <h4>Customers</h4>
            </div>
          </div>
          <div className="row mt-2 mt-xl-4 pb-1 p-1">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <label>Select Branch</label>
              <Select
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                menuPosition="fixed"
                className="basic-single"
                classNamePrefix="select"
                defaultValue={"All"}
                placeholder="All"
                isLoading={branchInfo.length <= 0 ? true : false}
                isClearable={true}
                isSearchable={true}
                options={branchOptions}
                onChange={(selectedOption: any) => {
                  setval(selectedOption);
                  onSearch(searchInput, selectedOption, currentPage, itemsPerPage); setSelectedBranch(selectedOption);
                }}
                value={selectedBranch}
              />
              {/* <Select
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                menuPosition="fixed"
                className="basic-single"
                classNamePrefix="select"
                placeholder="All"
                isLoading={branchInfo.length <= 0}
                isClearable={true}
                isSearchable={true}
                options={branchOptions}
                onChange={(e: any) => { setSelectedBranch(e); onBranchSearch(e); }}
                value={selectedBranch}
              /> */}
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 ">
              <label>Search:</label>

              <div className="input-group input-group-md">
                <input
                  type="text"
                  className="form-control shadow-sm"
                  placeholder="Enter Name or Mobile"
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value); // Update search input
                    onSearch(e.target.value, val, currentPage, itemsPerPage); // Trigger search on input change
                  }}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 ">
              <label>Dues:</label>

              <div className="input-group input-group-md">
                <button className="btn btn-purple" onClick={() => navigate("/saloonChain/customerDues")}>Due Report</button>
              </div>
            </div>
          </div>
        </div>

      </div>
      {
        loading || customerInfo?.length <= 0 ? (
          <div className="mt-4">
            {loading ? (
              // Display loading spinner
              <div className="d-flex justify-content-center align-items-center">
                {loadingSpinner()}
              </div>
            ) : (
              // Display custom message for no records
              <h5>No Customer found.</h5>
            )}
          </div>
        ) :
          <div className="mt-2 mt-xl-4 mt-sm-2 justify-content-center">
            <div className={`table-container table-responsive`}>
              <table className="table table-striped table-hover border-light-subtle" >
                <thead>
                  <tr>
                    {!isMobile && <th>S.no</th>}
                    <th style={{ cursor: "pointer" }}
                      onClick={() => { sortByName() }}
                    >
                      Name &nbsp;
                      <FontAwesomeIcon icon={faSort} cursor="pointer"
                        onClick={() => { sortByName() }}
                      />
                    </th>
                    <th>Mobile</th>
                    {!isMobile && <th style={{ cursor: "pointer" }}
                      onClick={() => { sortByBranch() }}
                    >Branch &nbsp;
                      <FontAwesomeIcon icon={faSort} cursor="pointer"
                        onClick={() => { sortByBranch() }}
                      /></th>}
                    {!isMobile && <th>Email</th>}


                    <th>History</th>
                    {/* {!isMobile && <th>Description</th>} */}
                    {isMobile && <th></th>}
                  </tr>
                </thead>
                <tbody>
                  {
                    customerInfo?.data?.map((data: any, index: any) => {
                      const isExpanded = expandedRowIndex === index;
                      return (
                        <React.Fragment key={index}>
                          <tr >
                            {!isMobile && <td style={isMobile ? { width: 'auto' } : {}}>{index + 1}</td>}
                            <td className="text-wrap">{data.name}</td>
                            <td>{data.mobile ? data.mobile : '-'}</td>
                            {!isMobile && <td>{data?.branchinfo?.branch_name ? data?.branchinfo?.branch_name : '-'}</td>}
                            {!isMobile && <td>{data?.email ? data?.email : "-"}</td>}

                            <td><button className="btn btn-sm btn-purple" onClick={() => handleViewHistory(data)}>View</button></td>
                            {isMobile && (
                              <td>
                                {!isExpanded ? (
                                  <button
                                    className="btn btn-link"
                                    onClick={() => toggleRowExpansion(index)}
                                  >
                                    <FontAwesomeIcon
                                      icon={faArrowDown}
                                      className="fontIcon"
                                      size="sm"
                                    />
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-link"
                                    onClick={() => toggleRowExpansion(index)}
                                  >
                                    <FontAwesomeIcon
                                      icon={faArrowUp}
                                      className="fontIcon"
                                      size="sm"
                                    />
                                  </button>
                                )}
                              </td>
                            )}
                          </tr>
                          {isExpanded && isMobile && (
                            <tr>
                              <td colSpan="5">{renderRowExpansionContent(data)}</td>
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })
                  }
                </tbody>
              </table>
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button
                      className="page-link "
                      onClick={() => handleChange(currentPage - 1)}
                      disabled={currentPage === 1 || loading}
                    >
                      Previous
                    </button>
                  </li>
                  {generatePageNumbers().map((page, index) => (
                    <li
                      key={index}
                      className={`page-item ${page === currentPage ? 'active' : ''}`}
                    >
                      {page === '...' ? (
                        <span className="page-link">...</span>
                      ) : (
                        <button
                          className="page-link btn-sm"
                          onClick={() => handleChange(page)}
                          disabled={loading}
                        >
                          {page}
                        </button>
                      )}
                    </li>
                  ))}
                  <li
                    className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
                  >
                    <button
                      className="page-link btn-sm"
                      onClick={() => handleChange(currentPage + 1)}
                      disabled={loading || currentPage === totalPages}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
      }
    </div >
  </>
}