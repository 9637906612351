import { faArrowDown, faArrowUp, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { listBranchServicesReducer } from "../../../reduxData/branchServicesSlice";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";

export default function ManagerServicesList() {
  const bmanager = useSelector((state: any) => state.authUserInfo?.value);

  const _ = require("lodash")
  const admin = useSelector((state: any) => state.authUserInfo.value);

  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const branchServicesInfo = useSelector((state: any) => state.branchServicesInfo.value);
  console.log(branchServicesInfo)
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [sort, setsort] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  const sortByServiceName = () => {
    setsort(!sort);
    const sortedServices = _.orderBy(branchServicesInfo, (item: any) => item.serviceMasterInfo.service_title, sort ? 'desc' : 'asc');
    dispatch(listBranchServicesReducer(sortedServices));
  }

  const loadingSpinner = () => {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  const toggleRowExpansion = (index: any) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };


  const renderRowExpansionContent = (data: any) => {
    return (<><th></th>
      {<tr><td className="text-wrap">
        <strong>Description:</strong> <span>{data.description != '' ? data.description : '-'}</span>

      </td></tr>}
      {/* {<tr><td className="text-wrap">
        <strong>Gender:</strong><span>{data.serviceMasterInfo?.gender}</span>
      </td></tr>} */}
      {<tr><td className="text-wrap">
        <strong>Category:</strong><span>{data.serviceMasterInfo?.service_cat_master_info?.category_title}</span>
      </td></tr>}</>
    );
  };

  const handleWindowResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  const filteredBranchServices = branchServicesInfo.filter((service: any) =>
    service.serviceMasterInfo.service_title.toLowerCase().includes(searchInput.toLowerCase())
  );

  return <>
    <div className="container-fluid p-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="text-center">
              <h4>Services</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-2 mt-xl-4 pb-4 p-1">
        <div className="col-lg-3 col-md-3 col-sm-12 ">
          <label>Search</label>
          <div className="input-group input-group-md">
            <input
              type="text"
              className="form-control shadow-sm"
              placeholder="Enter Service Name"
              value={searchInput}
              onChange={handleSearchInputChange}
            />
          </div>
        </div>
      </div>

      {loading || filteredBranchServices.length === 0 ? (
        <div className="mt-4">
          {loading ? (
            // Display loading spinner
            <div className="d-flex justify-content-center align-items-center">
              {loadingSpinner()}
            </div>
          ) : (
            // Display custom message for no records
            <h5>No Services found.</h5>
          )}
        </div>
      ) : <div className="mt-2 mt-xl-2 mt-sm-2 justify-content-center">
        <div className={`table-container ${isMobile ? 'table-fixed' : 'table-responsive'}`}>
          <table className="table table-striped table-hover border-light-subtle">
            <thead>
              <tr>
                {!isMobile && <th style={isMobile ? { width: 'auto' } : {}}>S.No</th>}
                <th className="text-wrap text-center" style={{ cursor: "pointer" }} onClick={() => { sortByServiceName() }}>
                  Service &nbsp;
                  <FontAwesomeIcon icon={faSort} cursor="pointer" onClick={() => { sortByServiceName() }} />
                </th>
                {!isMobile && <th className="text-center">Category</th>}

                <th className="text-center">Charges({bmanager.currency})</th>
                <th className="text-center">Gender</th>

                {!isMobile && <th>Description</th>}
                {isMobile && <th></th>}
              </tr>
            </thead>
            <tbody>
              {filteredBranchServices.map((data: any, index: any) => {
                const isExpanded = expandedRowIndex === index;
                return (
                  <React.Fragment key={index}>
                    <tr>
                      {!isMobile && <td style={isMobile ? { width: 'auto' } : {}}>{index + 1}</td>}
                      {/* <td>{data.branchinfo?.branch_name}</td> */}
                      <td className="text-wrap text-center">{data.serviceMasterInfo?.service_title}</td>
                      {!isMobile && <td className="text-center text-wrap">{data.serviceMasterInfo?.service_cat_master_info?.category_title}</td>}
                      <td className="text-center">{data?.service_charge}</td>
                      <td className="text-center">{data?.serviceMasterInfo?.gender}</td>


                      {!isMobile && <td>{data.description ? data.description : '-'}</td>}
                      {isMobile && (
                        <td>
                          {!isExpanded ? (
                            <button
                              className="btn btn-link"
                              onClick={() => toggleRowExpansion(index)}
                            >
                              <FontAwesomeIcon
                                icon={faArrowDown}
                                className="fontIcon"
                                size="sm"
                              />
                            </button>
                          ) : (
                            <button
                              className="btn btn-link"
                              onClick={() => toggleRowExpansion(index)}
                            >
                              <FontAwesomeIcon
                                icon={faArrowUp}
                                className="fontIcon"
                                size="sm"
                              />
                            </button>
                          )}
                        </td>
                      )}
                    </tr>
                    {isExpanded && isMobile && (
                      <tr>
                        <td colSpan="4">{renderRowExpansionContent(data)}</td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      }
    </div >
  </>
}