import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

import { App } from './app';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './reduxData/store';


ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <App />
        </Provider>
    </BrowserRouter>
    , document.getElementById("root"));


// import ReactDOM from 'react-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import { HashRouter } from 'react-router-dom';
// import { Provider } from 'react-redux';
// import store from './reduxData/store';
// import { App } from './app';

// ReactDOM.render(
//     <HashRouter>
//         <Provider store={store}>
//             <App />
//         </Provider>
//     </HashRouter>,
//     document.getElementById('root')
// );

