import { faArrowDown, faArrowUp, faEdit, faPlus, faSort, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import { listExpenseHeadReducer } from "../../../reduxData/expenseHeadSlice";
import { useDispatch, useSelector } from "react-redux";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { Alert, Snackbar } from "@mui/material";
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { ExpenseHeadSchema } from "../../../validations/Validations";
import Select from "react-select";

export default function ExpenseHeadList() {
  const _ = require("lodash")
  const admin = useSelector((state: any) => state.authUserInfo.value);
  const expenseHeadInfo = useSelector(
    (state: any) => state.expenseHeadInfo.value
  );
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [statusLoading,setStatusLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const branchInfo = useSelector((state: any) => state.branchInfo.value);
  const [status, setStatus] = useState();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate()
  const [msgs, setMsg] = useState("");
  const[updateData,setUpdateData] = useState(undefined);
  const [sort, setsort] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
};

const handleClose = () => {
    setOpen(false);
};

const branchOptions = branchInfo.map((item: any) => ({
    value: item.id,
    label: item.branch_name,
}));

useEffect(() => {
  if (branchInfo.length === 1) {
      const singleBranch = branchInfo[0];
      formik.setFieldValue("branch_id", singleBranch.id);
  }
}, [branchInfo]);

  const formik = useFormik({
    initialValues:{
        branch_id:"",
        category:"",
        description:"",
    },
    validationSchema:ExpenseHeadSchema,
    onSubmit:async (values) => {
       
        try {
            setStatusLoading(true);
            const ob = {
                branch_id:values.branch_id,
                category:values.category,
                description:values.description,
                isActive:true,
                created_by:admin.uid,
                updated_by:admin.uid,
            }
            const resp = await AuthApiService.SaveApiCall(urls.SAVE_EXPENSE_HEAD, ob, admin.token);
            if (resp.status === 200 || resp.data.status===201) {
              if(resp.data.status==false){
                setMsg(resp.data.msg);
                handleClickOpen();
                setStatus(resp.data.status)
              }
              else{
                setMsg(resp.data.msg);
                handleClickOpen();
                formik.resetForm()
                setStatus(resp.data.status)
                handleAddModelClose();
                await ExpenseHeadList(currentPage,itemsPerPage)
              }     
            } else {
                setMsg(resp.data.msg);
                handleClickOpen();
                formik.resetForm()
                setStatus(resp.data.status)
                // handleAddModelClose();
            }
        } catch (error: any) {
            setMsg(error.response.data.error == "Internal server error" ? "All Fileds Are Required" : error.response.data.error);
            setStatus(error.response.status);
            handleClickOpen()
        }
        finally {
           setStatusLoading(false);
        }
    },
})

const formik2 = useFormik({
  initialValues:{
      branch_id:"",
      category:"",
      description:"",
  },
  validationSchema:ExpenseHeadSchema,
  onSubmit:async (values) => {
     
      try {
          setStatusLoading(true);
          const ob = {
              branch_id:values.branch_id,
              category:values.category,
              description:values.description,
              isActive:true,
              created_by:admin.uid,
              updated_by:admin.uid,
          }
          const url = `${urls.EXPENSE_HEAD_UPDATE}/${updateData?.id}`
          const resp = await AuthApiService.PutApiCall(url, ob, admin.token);
          if (resp.status === 200 || resp.data.status===201) {
              setMsg(resp.data.msg);
              handleClickOpen();
              formik2.resetForm()
              setStatus(resp.data.status)
              handleUpdateModalClose();
              await ExpenseHeadList(currentPage,itemsPerPage)
          } else {
              setMsg(resp.data.msg);
              handleClickOpen();
              formik2.resetForm()
              setStatus(resp.data.status)
              handleUpdateModalClose();
          }
      } catch (error: any) {
          setMsg(error.response.data.error == "Internal server error" ? "All Fileds Are Required" : error.response.data.error);
          setStatus(error.response.status);
          handleClickOpen()
      }
      finally {
         setStatusLoading(false);
      }
  },
})

  const ExpenseHeadList = async (page: number, itemsPerPage: number) => {
    try{
      setLoading(true)
    const url = `${urls.EXPENSE_HEAD_LIST}/${page}/${itemsPerPage}`;
    const res = await AuthApiService.GetApiCallWithPagination(
      url,
      admin.token,
      page,
      itemsPerPage
    );
    dispatch(listExpenseHeadReducer(res.data));
    }
    catch(error){
      setStatus(false)
      setMsg("Network Error")
      handleClickOpen()
    }
    finally{
      setLoading(false)
    }
  };

  const sortByExpenseHead = () => {
    setsort(!sort);
    const sortedList = _.orderBy(expenseHeadInfo?.data, [(item: any) => item?.category?.toLowerCase()], sort ? 'desc' : 'asc');
    dispatch(listExpenseHeadReducer({ ...expenseHeadInfo, data: sortedList }));
  };
  const sortByBranch = () => {
    setsort(!sort);
    const sortedList = _.orderBy(expenseHeadInfo?.data, [(item: any) => item?.branchinfo?.branch_name?.toLowerCase()], sort ? 'desc' : 'asc');
    dispatch(listExpenseHeadReducer({ ...expenseHeadInfo, data: sortedList }));
  };

  //--------------------------Pagination-Start------------------------------
  const totalRecords = expenseHeadInfo?.pagination?.totalRecords;


  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPageButtons = 3; // Adjust the number of buttons you want to show

    if (totalPages <= maxPageButtons) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const leftEllipsis = currentPage > 2;
      const rightEllipsis = currentPage < totalPages - 1;

      if (leftEllipsis) {
        pageNumbers.push(1, "...");
      }

      const start = Math.max(1, currentPage - 1);
      const end = Math.min(totalPages, currentPage + 1);

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }

      if (rightEllipsis) {
        pageNumbers.push("...", totalPages);
      }
    }

    return pageNumbers;
  };

  const handlePageChange = async (page: number) => {
    setCurrentPage(page);
    await ExpenseHeadList(page, itemsPerPage);
    // }
  };
  //------------------------Pagination-End-----------------------------

  // -------------------------UseEffect-Start--------------------------------
  useEffect(() => {
    ExpenseHeadList(currentPage, itemsPerPage);
  },[]);
  useEffect(() => {
    formik2.setValues({
        branch_id: updateData?.branch_id ?? "",
        category: updateData?.category ?? "",
        description: updateData?.description ?? "",
    });
}, [updateData]);
  // -------------------------UseEffect-End--------------------------------

  // -------------------------LoadingSpinner-Start--------------------------------
  const loadingSpinner = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  };
  // -------------------------LoadingSpinner-End--------------------------------

  const handleDeleteExpenseHead = async (data:any) => {
    let confirmation = confirm(`Are you sure you want to delete?`);
    if(confirmation){
      try{
        setStatusLoading(true);
        const url = `${urls.EXPENSE_HEAD_DELETE}/${data.id}`;
        const resp = await AuthApiService.DelApiCall(url,admin.token)
        if (resp.data.status === 201 && resp.status===200) {
          setMsg(resp.data.msg);
          handleClickOpen();
          setStatus(resp.data.status)
          await ExpenseHeadList(currentPage,itemsPerPage)
      } else {
          setMsg(resp.data.msg);
          handleClickOpen();
          setStatus(resp.status)
      }
    }
    catch (error) {
      handleClickOpen()
      setMsg(error.response.data);
      setStatus(false);
    } finally {
      setStatusLoading(false)
    }
    }else{
      return
    }
  }

  const handleStatusUpdate = async(data:any) => {
    let confirmation = confirm(`Are you sure you want to ${data.isActive?"Deactivate":"Activate"}?`);
    if(confirmation){
      try{
        setStatusLoading(data);
        const url = `${urls.EXPENSE_HEAD_UPDATE}/${data.id}`;
        const ob = {}; 
        if(data.isActive){
          ob.updated_by = admin.uid;
          ob.isActive = false;
        }
        else{
          ob.updated_by = admin.uid;
          ob.isActive = true;
        }
        const resp = await AuthApiService.PutApiCall(url,ob,admin.token)
        if (resp.data.status === 201 && resp.status===200) {
          setMsg(resp.data.msg);
          handleClickOpen();
          setStatus(resp.data.status)
          await ExpenseHeadList(currentPage,itemsPerPage)
      } else {
          setMsg(resp.data.msg);
          handleClickOpen();
          setStatus(resp.data.status)
      }
    }
    catch (error) {
      handleClickOpen()
      setMsg('Network Error !');
      setStatus(false);
    } finally {
      setStatusLoading(false)
    }
    }else{
      return
    }
  }


  //----------------------------AddModel-Start----------------------------------

      const [showAddModal, setShowAddModal] = useState(false);
      const handleAddModelClose = () => {setShowAddModal(false);formik.resetForm()}
      const handleAddModelShow = () => {
        setShowAddModal(true);
      }
    
      const addModal = () => {
        return <>
          <Modal show={showAddModal} onHide={handleAddModelClose} centered backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>Add Expense Head</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <form
                            onFocus={() => setMsg("")}
                            onSubmit={formik.handleSubmit}>
                                 <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="branch_id" className="col-sm-4 col-form-label">Branch Name<span style={{ color: "red" }}>*</span> :</label>
                                    <div className="col-sm-8">
                                        <Select
                                            id="branch_id"
                                            name="branch_id"
                                            className={`react-select-container ${formik.touched.branch_id && formik.errors.branch_id ? 'is-invalid' : ''}`}
                                            options={branchOptions}
                                            isSearchable
                                            placeholder="Select Branch Name"
                                            onChange={(selectedOption) =>
                                                formik.setFieldValue("branch_id", selectedOption.value)
                                            }
                                            onBlur={formik.handleBlur}
                                            value={
                                                branchOptions.find((option: any) => option.value === formik.values.branch_id) ||
                                                null
                                            }
                                        />
                                        <div className="invalid-feedback">{formik.touched.branch_id && typeof formik.errors.branch_id === 'string' ? (formik.errors.branch_id) : null}</div>

                                    </div>
                                </div>
                            <div className="mb-1 mb-xl-3 row">
                                <label htmlFor="category" className="col-sm-4 col-form-label">Expense Head<span style={{ color: "red" }}>*</span>:</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        placeholder="Enter Expense Head"
                                        id="category"
                                        name="category"
                                        className={`form-control shadow-sm ${formik.touched.category && formik.errors.category ? 'is-invalid' : ''}`}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.category}
                                    />
                                    <div className="invalid-feedback">{formik.touched.category && typeof formik.errors.category === 'string' ? (formik.errors.category) : null}</div>

                                </div>
                            </div>
                            <div className="mb-1 mb-xl-3 row">
                                <label htmlFor="desc" className="col-sm-4 col-form-label">Description:</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        placeholder="Enter Description"
                                        id="description"
                                        name="description"
                                        className={`form-control shadow-sm ${formik.touched.description && formik.errors.description ? 'is-invalid' : ''}`}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.description}
                                    />
                                    <div className="invalid-feedback">{formik.touched.description && typeof formik.errors.description === 'string' ? (formik.errors.description) : null}</div>

                                </div>
                            </div>
                            <div className="mt-3 mb-xl-3 row text-center">
                                <div className="col-lg-4"></div>
                                <div className="col-lg-4">
                                    <button type="submit" className="btn btn-md form-control btn-purple" disabled={statusLoading ? true : false}>
                                        {statusLoading ? "Saving..." : "Add"}
                                    </button>
                                </div>
                                <div className="col-lg-4"></div>
                            </div>
                        </form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" disabled={statusLoading ? true : false} onClick={handleAddModelClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      }
  
  //-----------------------------------AddModel-End----------------------------------------

  //-----------------------------------UpdateModel-Start-----------------------------------

  const [showUpdateModal, setShowUpdateModal] = useState(false);
    const handleUpdateModalClose = () =>{ setShowUpdateModal(false);setUpdateData(undefined)};
    const handleUpdateModalShow = () => setShowUpdateModal(true);

  const updateModal = () => {
    return <>
        <Modal show={showUpdateModal} onHide={handleUpdateModalClose} centered backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Update Expense Head</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form
                            onFocus={() => setMsg("")}
                            onSubmit={formik2.handleSubmit}>
                                 <div className="mb-1 mb-xl-3 row">
                                    <label htmlFor="branch_id" className="col-sm-4 col-form-label">Branch Name<span style={{ color: "red" }}>*</span> :</label>
                                    <div className="col-sm-8">
                                        <Select
                                            id="branch_id"
                                            name="branch_id"
                                            className={`react-select-container ${formik2.touched.branch_id && formik2.errors.branch_id ? 'is-invalid' : ''}`}
                                            options={branchOptions}
                                            isSearchable
                                            placeholder="Select Branch Name"
                                            onChange={(selectedOption) =>
                                                formik2.setFieldValue("branch_id", selectedOption.value)
                                            }
                                            onBlur={formik2.handleBlur}
                                            value={
                                                branchOptions.find((option: any) => option.value === formik2.values.branch_id) ||
                                                null
                                            }
                                            isDisabled
                                        />
                                        <div className="invalid-feedback">{formik2.touched.branch_id && typeof formik2.errors.branch_id === 'string' ? (formik2.errors.branch_id) : null}</div>

                                    </div>
                                </div>
                            <div className="mb-1 mb-xl-3 row">
                                <label htmlFor="category" className="col-sm-4 col-form-label">Expense Head<span style={{ color: "red" }}>*</span>:</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        placeholder="Enter Expense Head"
                                        id="category"
                                        name="category"
                                        className={`form-control shadow-sm ${formik2.touched.category && formik2.errors.category ? 'is-invalid' : ''}`}
                                        onChange={formik2.handleChange}
                                        onBlur={formik2.handleBlur}
                                        value={formik2.values.category}
                                    />
                                    <div className="invalid-feedback">{formik2.touched.category && typeof formik2.errors.category === 'string' ? (formik2.errors.category) : null}</div>

                                </div>
                            </div>
                            <div className="mb-1 mb-xl-3 row">
                                <label htmlFor="desc" className="col-sm-4 col-form-label">Description:</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        placeholder="Enter Description"
                                        id="description"
                                        name="description"
                                        className={`form-control shadow-sm ${formik2.touched.description && formik2.errors.description ? 'is-invalid' : ''}`}
                                        onChange={formik2.handleChange}
                                        onBlur={formik2.handleBlur}
                                        value={formik2.values.description}
                                    />
                                    <div className="invalid-feedback">{formik2.touched.description && typeof formik2.errors.description === 'string' ? (formik2.errors.description) : null}</div>

                                </div>
                            </div>
                            <div className="mt-3 mb-xl-3 row text-center">
                                <div className="col-lg-4"></div>
                                <div className="col-lg-4">
                                    <button type="submit" className="btn btn-md form-control btn-purple" disabled={statusLoading ? true : false}>
                                        {statusLoading ? "Updating..." : "Update"}
                                    </button>
                                </div>
                                <div className="col-lg-4"></div>
                            </div>
                        </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" disabled={statusLoading ? true : false} onClick={handleUpdateModalClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}

  //-----------------------------------UpdateMode-End--------------------------------------

  const toggleRowExpansion = (index: any) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const renderRowExpansionContent = (item: any) => {
    return (<><th></th>
      {<tr><th className="text-wrap">
        Description: <span style={{fontWeight:"normal"}}>{item?.description ? item?.description : '-'}</span>
      </th></tr>}</>
    );
  };

  const handleWindowResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <>
    {addModal()}
    {updateModal()}
    <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msgs}</Alert>
      </Snackbar>
      <div className="container-fluid p-0">
        <div className="row align-items-center">
          <div className="col-lg-10 col-md-10 col-sm-10">
            <div className="text-center">
              <h4>Expense Head</h4>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2 mt-2 d-flex justify-content-end">
              <button className="btn btn-sm btn-purple" onClick={handleAddModelShow}>
                <FontAwesomeIcon icon={faPlus} />
                &nbsp;New Expense Head
              </button>
          </div>
        </div>

        {loading || expenseHeadInfo?.data?.length === 0 ? (
          <div className="mt-4">
            {loading ? (
              // Display loading spinner
              <div className="d-flex justify-content-center align-items-center">
                {loadingSpinner()}
              </div>
            ) : (
              // Display custom message for no records
              <h5>No Transaction Details found.</h5>
            )}
          </div>
        ) : (
          <div className="mt-2 mt-xl-2 mt-sm-2 justify-content-center">
            <div className={`table-container table-responsive`}>
              <table className="table table-striped table-hover border-light-subtle">
                <thead>
                  <tr>
                    <th></th>
                    <th style={{ cursor: "pointer" }}
                      onClick={() => { sortByExpenseHead() }}
                    >Expense Head &nbsp;
                    <FontAwesomeIcon icon={faSort} cursor="pointer"
                      onClick={() => { sortByExpenseHead() }}
                    /></th>
                   {!isMobile && <th>Description</th>}
                    <th style={{ cursor: "pointer" }}
                      onClick={() => { sortByBranch() }}>Branch &nbsp;
                      <FontAwesomeIcon icon={faSort} cursor="pointer"
                        onClick={() => { sortByBranch() }}
                      /></th>
                    <th>Status</th>
                {isMobile && <th></th>}
                  </tr>
                </thead>
                <tbody>
                    {expenseHeadInfo?.data?.map((item:any,index:any)=>
                    { const isExpanded = expandedRowIndex === index;
                      return(<React.Fragment key={index}>
                        <tr key={item.id}>
                         <td><FontAwesomeIcon icon={faEdit} cursor="pointer" className="fontIcon" onClick={()=>{handleUpdateModalShow();setUpdateData(item)}} />&nbsp;<FontAwesomeIcon icon={faTrash} cursor="pointer" className="fontIcon" onClick={()=>handleDeleteExpenseHead(item)}/></td>
                         <td>{item?.category}</td>
                         {!isMobile && <td>{item?.description==""||item?.description==null?"-":item?.description}</td>}
                         {<td>{item?.branchinfo?.branch_name}</td>}
                         <td>{item?.isActive?<button className="btn btn-sm btn-warning" onClick={()=>handleStatusUpdate(item)}>{statusLoading.id==item.id?"Deactivating...":"Active"}</button>:<button onClick={()=>handleStatusUpdate(item)} className="btn btn-sm btn-danger">{statusLoading.id==item.id?"Activating...":"Deactive"}</button>}</td>
                         {isMobile && (
                              <td>
                                {!isExpanded ? (
                                  <button
                                    className="btn btn-link"
                                    onClick={() => toggleRowExpansion(index)}
                                  >
                                    <FontAwesomeIcon
                                      icon={faArrowDown}
                                      className="fontIcon"
                                      size="sm"
                                    />
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-link"
                                    onClick={() => toggleRowExpansion(index)}
                                  >
                                    <FontAwesomeIcon
                                      icon={faArrowUp}
                                      className="fontIcon"
                                      size="sm"
                                    />
                                  </button>
                                )}
                              </td>
                            )}
                         </tr>
                         {isExpanded && isMobile && (
                            <tr>
                              <td colSpan="5">{renderRowExpansionContent(item)}</td>
                            </tr>
                          )}
                     </React.Fragment>) 
                    }    
                    )
                    }
                </tbody>
              </table>
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link "
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1 || loading}
                    >
                      Previous
                    </button>
                  </li>
                  {generatePageNumbers()?.map((page, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        page === currentPage ? "active" : ""
                      }`}
                    >
                      {page === "..." ? (
                        <span className="page-link">...</span>
                      ) : (
                        <button
                          className="page-link btn-sm"
                          onClick={() => handlePageChange(page)}
                          disabled={loading}
                        >
                          {page}
                        </button>
                      )}
                    </li>
                  ))}
                  <li
                    className={`page-item ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link btn-sm"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={loading || currentPage === totalPages}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
