import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { useState } from "react";
import { listEmployeeLeaveReducer } from "../../../reduxData/leaveSlice";
import { leaveValidationSchema } from "../../../validations/Validations";
import { Alert, Snackbar } from "@mui/material";
import Select from "react-select";



export default function NewLeave() {

    const navigate = useNavigate()
    const currentDate = new Date().toISOString();
    const admin = useSelector((state: any) => state.authUserInfo.value);
    const employee = useSelector((state: any) => state.employeeInfo.value.filter((data: any) => { return (data.is_active == true && data.date_of_joining <= currentDate && (data.date_of_relieving >= currentDate || data.date_of_relieving == null)) }));

    const dispatch = useDispatch()
    const [msg, setMsg] = useState("");
    const [status, setStatus] = useState();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emp, setemp] = useState();

    const employeeOptions = employee.map((item: any) => ({
        value: item.id,
        label: item.first_name + " " + item.last_name,
    }));


    const formik = useFormik({
        initialValues: {
            employee_id: "",
            leave_type: "Full Day",
            start_date: new Date().toISOString().substring(0, 10),
            end_date: new Date().toISOString().substring(0, 10),
            reason: "",
        },
        validationSchema: leaveValidationSchema,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const ob: any = {
                    employee_id: values.employee_id,
                    leave_type: values.leave_type,
                    start_date: values.start_date,
                    end_date: values.end_date,
                    reason: values.reason ? values.reason : '',
                    created_by: admin.uid,
                    updated_by: admin.uid,
                };
                console.log("selected", ob);
                const resp = await AuthApiService.SaveApiCall(urls.LEAVE_SAVE, ob, admin.token)
                console.log("response", resp);
                if (resp.status) {
                    setLoading(false)
                    setMsg(resp.data.msg);

                    handleClickOpen();
                    setStatus(resp.data.status)
                    dispatch(listEmployeeLeaveReducer(ob))
                    formik.resetForm();
                    setTimeout(() => {
                        navigate("/branchManager/leavelist")
                    }, 1000)

                }
                // } else {
                //     if (resp.data && resp.data.err) {
                //         setMsg(resp.data.err);
                //         setStatus(resp.data.status)

                //     } else {
                //         setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
                //         setStatus(resp.data.status)
                //     }
                // }
            } catch (error: any) {
                setLoading(false);
                console.error("Error:", error);
                if (error.response && error.response.status === 409 && error.response.data && error.response.data.msg) {
                    setMsg(error.response.data.msg);
                } else {
                    setMsg("Error: Conflict occurred.");
                }
                setStatus(error.response.status);
                handleClickOpen();
            }
            finally {
            }
        },
    });

    const handleCustomChange = (e: any) => {
        formik.setFieldValue('start_date', e.target.value)
        if (formik.values.leave_type === "Half Day") {
            formik.setFieldValue('end_date', e.target.value)
        }
    }


    // const branch = useSelector((state: any) => state.branchInfo.value);

    // console.log(branch)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className="container-fluid p-0">
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert>
            </Snackbar>
            <div className="container">

                <div className="row pr-xl-5 pl-xl-5">
                    <div className="col-lg-10 col-md-8 col-sm-8 d-flex justify-content-center">
                        <div className="text-center">
                            <h4>New Leave</h4>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4 mt-2 d-flex pr-2 justify-content-end">
                        <Link to="/branchManager/leavelist">
                            <span>
                                <button className=" btn btn-sm btn-purple">All Leave List</button>
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="p-1 px-xl-2 py-xl-2 mt-xl-4 mt-0 justify-content-center">
                <div className="container">
                    {/* <b className={status ? "text-success" : "text-danger"}>{msg}</b> */}
                    <div className="row justify-content-center mt-3">
                        <div className="col-lg-12">
                            <form onFocus={() => setMsg("")} onSubmit={formik.handleSubmit}>
                                <div className="mb-1 mb-xl-3 row">
                                    <label className="col-sm-3 col-form-label" htmlFor="employee_id">
                                        Employee Name<span style={{ color: "red" }}>*</span> :
                                    </label>
                                    <div className="col-sm-7">
                                        <Select
                                            name="employee_id"
                                            id="employee_id"
                                            className={`basic-single shadow-sm ${formik.touched.employee_id && formik.errors.employee_id
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                            classNamePrefix="select"
                                            placeholder="Select Employee"
                                            isLoading={employee.length <= 0 ? true : false}
                                            // isClearable={true}
                                            isSearchable={true}
                                            options={employeeOptions}
                                            value={employeeOptions.find((option: any) => option.value === formik.values.employee_id) || null}
                                            onChange={(selectedOption) => formik.setFieldValue("employee_id", selectedOption.value)}
                                            onBlur={formik.handleBlur}
                                            // escapeClearsValue={true}
                                            filterOption={(option, inputValue) =>
                                                option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                              }
                                            backspaceRemovesValue={false}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    borderColor: state.isFocused ? '#80bdff' : state.menuIsOpen ? '#80bdff' : (formik.touched.employee_id && formik.errors.employee_id) ? '#dc3545' : '#ced4da',
                                                    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : state.menuIsOpen ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : state.isValid ? null : 'none',
                                                }),
                                            }}
                                        />
                                        <div className="invalid-feedback">{formik.touched.employee_id && typeof formik.errors.employee_id === 'string' ? (formik.errors.employee_id) : null}</div>
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label className="col-sm-4 col-form-label">
                                        Leave Type<span style={{ color: "red" }}>*</span> :
                                    </label>

                                    <div className="form-check col-sm-3 col-4">
                                        <input
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            // value={formik.values.payment_mode}
                                            className="form-check-input"
                                            type="radio"
                                            name="leave_type"
                                            id="flexRadioDefault1"
                                            value="Full Day"
                                            checked={formik.values.leave_type === "Full Day"}
                                            onFocus={() => { formik.values.start_date = formik.initialValues.start_date; formik.values.end_date = formik.initialValues.end_date }}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="flexRadioDefault1"
                                        >
                                            Full Day
                                        </label>
                                    </div>
                                    <div className="form-check col-sm-3 col-4">
                                        <input
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            // value={formik.values.payment_mode}
                                            className="form-check-input"
                                            type="radio"
                                            name="leave_type"
                                            id="flexRadioDefault2"
                                            value="Half Day"
                                            checked={formik.values.leave_type === "Half Day"}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="flexRadioDefault2"
                                        >
                                            Half Day
                                        </label>
                                    </div>

                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label className="col-sm-3 col-form-label" htmlFor="start_date">
                                        Start Date<span style={{ color: "red" }}>*</span> :
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="date"
                                            placeholder="Enter Start Date"
                                            id="start_date"
                                            name="start_date"
                                            className={`form-control shadow-sm ${formik.touched.start_date && formik.errors.start_date ? 'is-invalid' : ''}`}
                                            min={employee.filter((data: any) => data.id == formik.values.employee_id).map((data: any) => data.date_of_joining.substring(0, 10))}
                                            max={employee.filter((data: any) => data.id == formik.values.employee_id).map((data: any) => data.date_of_relieving?.substring(0, 10) == null ? undefined : data.date_of_relieving?.substring(0, 10))}
                                            onChange={handleCustomChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.start_date}
                                        />
                                        <div className="invalid-feedback">{formik.touched.start_date && typeof formik.errors.start_date === 'string' ? (formik.errors.start_date) : null}</div>
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label className="col-sm-3 col-form-label" htmlFor="end_date">
                                        End Date<span style={{ color: "red" }}>*</span> :
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="date"
                                            placeholder="Enter End Date"
                                            id="end_date"
                                            name="end_date"
                                            className={`form-control shadow-sm ${formik.touched.end_date && formik.errors.end_date ? 'is-invalid' : ''}`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.start_date && formik.values.leave_type === "Half Day" ? formik.values.start_date : formik.values.end_date}
                                            min={formik.values.start_date}
                                            max={employee.filter((data: any) => data.id == formik.values.employee_id).map((data: any) => data.date_of_relieving?.substring(0, 10) == null ? undefined : data.date_of_relieving?.substring(0, 10))}
                                            disabled={formik.values.leave_type === "Half Day"}
                                        />
                                        <div className="invalid-feedback">{formik.touched.end_date && typeof formik.errors.end_date === 'string' ? (formik.errors.end_date) : null}</div>
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label className="col-sm-3 col-form-label" htmlFor="reason">
                                        Reason:
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            placeholder="Enter Reason"
                                            id="reason"
                                            name="reason"
                                            className={`form-control shadow-sm ${formik.touched.reason && formik.errors.reason ? 'is-invalid' : ''}`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.reason}
                                        />
                                        <div className="invalid-feedback">{formik.touched.reason && typeof formik.errors.reason === 'string' ? (formik.errors.reason) : null}</div>
                                    </div>
                                </div>
                                <div className="mt-3 mb-xl-3 row text-center">
                                    <div className="col-lg-5"></div>
                                    <div className="col-lg-2">
                                        <button type="submit" className="btn form-control btn-purple" disabled={loading ? true : false}>
                                            {loading ? "Saving..." : "Add"}
                                        </button>
                                    </div>
                                    <div className="col-lg-5"></div>
                                </div>

                                {/* <b className={status ? "text-success" : "text-danger"}>{msg}</b> */}

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
