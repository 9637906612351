import { faArrowDown, faArrowUp, faPlus, faSearch, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { listTransactionReducer } from "../../../reduxData/transactionSlice";
import Select from "react-select"
import { listCustomerBillReducer } from "../../../reduxData/customerSlice";
export default function DailyTransactionsListForStaff() {
    const [expandedRowIndex, setExpandedRowIndex] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const _ = require("lodash");
    const [sort, setsort] = useState(false);
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState("");
    const [searchInput, setSearchInput] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [showDetails, setShowDetails] = useState([]);
    const itemsPerPage = 10;
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const admin = useSelector((state: any) => state.authUserInfo.value);
    const employeeInfo = useSelector((state: any) => state.employeeInfo.value);
    let count = 0;
    const [selectedCustomer, setSelectedCustomer] = useState(false);


    const transactioninfo = useSelector((state: any) => state?.transactionInfo?.value);
    console.log("transs", transactioninfo)

    const employeeOptions = [{ value: "All", label: "All" }, ...employeeInfo.map((item: any) => ({
        value: item.id,
        label: item.first_name + " " + item.last_name,
    }))];


    const totalRecords = transactioninfo?.pagination?.totalRecords
    console.log("total records", totalRecords)


    const totalPages = Math.ceil(totalRecords / itemsPerPage);
    const generatePageNumbers = () => {
        const pageNumbers = [];
        const maxPageButtons = 3; // Adjust the number of buttons you want to show

        if (totalPages <= maxPageButtons) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            const leftEllipsis = currentPage > 2;
            const rightEllipsis = currentPage < totalPages - 1;

            if (leftEllipsis) {
                pageNumbers.push(1, '...');
            }

            const start = Math.max(1, currentPage - 1);
            const end = Math.min(totalPages, currentPage + 1);

            for (let i = start; i <= end; i++) {
                pageNumbers.push(i);
            }

            if (rightEllipsis) {
                pageNumbers.push('...', totalPages);
            }
        }

        return pageNumbers;
    };

    const transactionlist = async (page: number, itemsPerPage: number) => {
        console.log(page, itemsPerPage, admin.token)
        try {
            setLoading(true)
            const URL = `${urls.TRANSACTION_LIST}/${page}/${itemsPerPage}`;
            const res = await AuthApiService.GetApiCallWithPagination(URL, admin.token, page, itemsPerPage);
            console.log("for staff response", res)

            if (res.status) {
                const d = dispatch(listTransactionReducer(res.data));
                // setLoading(false)
                console.log(d)
            }
        } catch (error) {
            setMsg("Network Error !");
        } finally {
            setLoading(false)
        }

    };

    useEffect(() => {
        transactionlist(currentPage, itemsPerPage);
    }, [currentPage]);


    // const onSearch = async (data: any, page: number, itemsPerPage: number) => {
    //     console.log(data, searchInput);
    //     if (data !== null && data.value != "All") {
    //         setLoading(true)
    //         const url = `${urls.TRANSACTION_LIST_BY_SEARCH}/${page}/${itemsPerPage}`;
    //         const ob = { id: data.value }
    //         console.log(ob);
    //         const res = await AuthApiService.SearchApiCallWithQuery(url, ob, admin.token,);
    //         console.log(res);
    //         if (res.status) {
    //             setLoading(false)
    //             dispatch(listTransactionReducer(res.data));
    //             console.log(res);
    //         }
    //     }
    //     else {
    //         await transactionlist(page, itemsPerPage);
    //     }
    // };

    // const handleChange = async (page: number) => {
    //     setCurrentPage(page);
    //     if (searchInput != '') {
    //         await onSearch(searchInput, page, itemsPerPage)
    //     }
    //     else {
    //         await transactionlist(page, itemsPerPage);
    //     }
    // };

    const sorting = (d: any) => {
        setsort(!sort)
        if (d == 'employee') {
            const data = sort ? _.orderBy(transactioninfo.data, (d: any) => d.employee_info.first_name, ['asc']) : _.orderBy(transactioninfo.data, (d: any) => d.employee_info.first_name, ['desc']);
            const transactionInfo = { ...transactioninfo, data };
            dispatch(listTransactionReducer(transactionInfo));
        }
        else if (d == 'service') {
            const data = sort ? _.orderBy(transactioninfo.data, (d: any) => d.transactionItems.map(d => d.item_type), ['asc']) : _.orderBy(transactioninfo.data, (d: any) => d.transactionItems.map(d => d.item_type), ['desc']);
            console.log(data)
            const transactionInfo = { ...transactioninfo, data };
            dispatch(listTransactionReducer(transactionInfo));
        }
        else if (d == 'payment') {
            const data = sort ? _.orderBy(transactioninfo.data, (d: any) => d.payment_info.map(d => d.payment_mode), ['asc']) : _.orderBy(transactioninfo.data, (d: any) => d.payment_info.map((d: any) => d.payment_mode), ['desc']);
            const transactionInfo = { ...transactioninfo, data };
            dispatch(listTransactionReducer(transactionInfo));
        }
        else if (d == 'tdate') {
            const data = sort ? _.orderBy(transactioninfo.data, (d: any) => d.createdAt, ['asc']) : _.orderBy(transactioninfo.data, (d: any) => d.createdAt, ['desc']);
            const transactionInfo = { ...transactioninfo, data };
            dispatch(listTransactionReducer(transactionInfo));
        }
    }


    const handleChange = async (page: number) => {
        setCurrentPage(page);
        await transactionlist(page, itemsPerPage);
    };



    const toggleRowExpansion = (index: any) => {
        setExpandedRowIndex(expandedRowIndex === index ? null : index);
    };

    const renderRowExpansionContent = (data: any) => {
        return (<><th></th>

            <tr> <td className="text-wrap">  <b>Qty:&nbsp;</b><span>{data?.transactionItems?.map((d: any) => <><span>{d.quantity}</span>,</>)}</span></td></tr>
            <tr><td><b>Amount({admin.currency}):&nbsp;</b>{data?.transactionItems?.map((d: any) => <><span>{d.amount},</span></>)}</td></tr>

            <tr> <td className="text-wrap"> <b>Remark:&nbsp;</b><span>{data.remark == "" ? "-" : data.remark}</span></td></tr>
            <tr> <td className="text-wrap"><b>Cust.Name:&nbsp;</b><span>{data?.customerinfo?.name == null ? "-" : data?.customerinfo?.name}</span></td></tr>
            <tr> <td className="text-wrap"> <b>Cust.Email:&nbsp;</b><span>{data.cutomer_email == null ? "-" : data.cutomer_email}</span></td></tr>
            <tr> <td className="text-wrap"> <b>Cust.Phone:&nbsp;</b><span>{data.customer_mobile == null ? "-" : data.customer_mobile}</span></td></tr>

        </>

        )
    }

    const handleShowBill = async (data: any) => {
        console.log(data)
        const url = `${urls.TRANSACTION_GET}${data}`
        const res = await AuthApiService.GetApiCall(url, admin.token);
        console.log(res, url)
        if (res.status == 200 && res.data.status == 200) {
            const ob = res.data.data;
            dispatch(listCustomerBillReducer(ob));
            localStorage.setItem("billinfo", JSON.stringify(res.data.data))
            navigate("/branchManager/managerCustomerBill")
        }
    }

    // const ShowDetails = (data: any) => {
    //     console.log(showDetails, data)
    //     // let temp = [...showDetails];
    //     let temp = showDetails.includes(data) ? showDetails.filter(d => d != data) : [...showDetails, data]
    //     console.log(temp)
    //     setShowDetails(temp);
    //     setSelectedCustomer(true);
    // }

    const handleWindowResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return <>
        <div className="container-fluid P-0 ml-0 ml-xl-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="text-center">
                            <h4>Daily Transactions</h4>
                        </div>
                    </div>
                    {/* <div className="col-lg-2 col-md-2 col-sm-2 mt-2 d-flex justify-content-end"> */}
                    {/* <Link to="/branchManager/addDailyTransaction">
                            <button className="btn btn-sm btn-purple"><FontAwesomeIcon icon={faPlus} />&nbsp;New Transaction</button>
                        </Link> */}
                    {/* </div> */}
                    {/* <div className="col-lg-1 col-md-1 col-sm-1 mt-2 d-flex justify-content-end">
                        <Link to="/branchManager/managerTransactionReport">
                            <button className="btn btn-sm btn-purple">Report</button>
                        </Link>
                    </div> */}
                </div>
            </div>
            {/* <div className="row mt-2 mt-xl-4 pb-4 p-1">
                <div className="col-lg-3 col-md-3 col-sm-12 ">
                    <label>Select Employee:</label>
                    <Select
                        // type="text"
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder="All"
                        isLoading={employeeInfo.length <= 0 ? true : false}
                        isClearable={true}
                        isSearchable={true}
                        // defaultInputValue="All"
                        options={employeeOptions}
                        // value={searchInput}
                        onChange={(e: string) => { setSearchInput(e); setCurrentPage(1); onSearch(e, 1, itemsPerPage) }}
                        escapeClearsValue={true}
                        backspaceRemovesValue={false}
                    />
                </div>
            </div> */}

            {/* {loading || !transactioninfo || transactioninfo?.length === 0 ? (
                <div className="mt-4">
                    {loading ? (
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>) : (
                        // Display custom message for no records
                        <h5>No Transactions found.</h5>
                    )}
                </div>
            ) :  */}
            <div className="mt-2 mt-xl-2 mt-sm-2 justify-content-center">
                <div className={`table-container table-responsive`}>
                    <table className="table table-striped  table-hover border-light-subtle " id={isMobile ? 're-table' : 'report-table'}>
                        <thead>
                            <tr className="text-nowrap">
                                {!isMobile && <th style={isMobile ? { width: 'auto' } : {}}>S.No</th>}
                                <th className={`${isMobile ? 'text-wrap' : 'text-nowrap'}`} style={{ cursor: "pointer" }} onClick={() => { sorting('tdate') }}>{!isMobile ? "Trans. Date " : "Date"}&nbsp;<FontAwesomeIcon icon={faSort} /></th>
                                {!isMobile && <th>Customer</th>}
                                <th style={{ cursor: "pointer" }} onClick={() => { sorting('service') }}>Service &nbsp;<FontAwesomeIcon icon={faSort} /></th>
                                {!isMobile && <th>Qty.</th>}
                                {!isMobile && <th className={`${isMobile ? 'text-wrap' : 'text-nowrap'}`}>Amount ({admin.currency})</th>}
                                {<th style={{ cursor: "pointer" }} onClick={() => { sorting('payment') }}>{!isMobile ? "Payment" : "Pmt."} &nbsp;<FontAwesomeIcon icon={faSort} /></th>}
                                {/* <th style={{ cursor: "pointer" }} onClick={() => { sorting('employee') }}>{!isMobile ? "Employee" : "Emp."} &nbsp;<FontAwesomeIcon icon={faSort} /></th> */}
                                {!isMobile && <th>Remark</th>}
                                {/* {!isMobile && <th>Customer Email</th>}
                                {!isMobile && <th>Customer Mobile</th>} */}
                                {isMobile && <th></th>}
                            </tr>
                        </thead>
                        <tbody className="text-nowrap">
                            {transactioninfo?.data?.map((data: any, index: any) => {
                                const isExpanded = expandedRowIndex === index; return (
                                    <React.Fragment key={index}><tr className="text-nowrap">
                                        {!isMobile && <td style={isMobile ? { width: 'auto' } : {}}>{index + 1}</td>}
                                        <td className="text-wrap">{new Date(data.createdAt).toLocaleString('en-GB', { dateStyle: "short", timeStyle: "short", hour12: "true" })}<br /><button className="btn btn-link" onClick={() => { handleShowBill(data.id) }}>Bill</button></td>
                                        {/* {!isMobile && <td>{data?.customerinfo?.name == null ? "-" : <span style={{ cursor: "pointer" }} onClick={() => { ShowDetails(data?.customerinfo?.id) }}>{selectedCustomer && showDetails.includes(data?.customerinfo?.id) ? <>{data?.customerinfo?.name}<br />{data?.customerinfo?.mobile}</> : <p style={{ textDecoration: "underline" }} className="link-primary text-underline">{data?.customerinfo?.name}</p>}</span>}</td>} */}

                                        {!isMobile && <td>{data?.customerinfo?.name == null ? "-" : data?.customerinfo?.name} <br />{data?.customerinfo?.mobile}</td>}
                                        <td className="text-wrap">{data?.transactionItems?.map((d: any) => <><span>{d.service_type ?? d.product_name}</span><br /></>)}</td>
                                        {!isMobile && <td>{data?.transactionItems?.map((d: any) => <><span>{d.quantity}</span><br /></>)}</td>}
                                        {!isMobile && <td>{data?.transactionItems?.map((d: any) => <><span>{d.amount}</span><br /></>)}</td>}
                                        {<td className={`${isMobile ? 'text-wrap' : 'text-nowrap'}`}><b>Total :</b>{data.amount}<br /><b>Paid :</b>{data.payment_info[0].amount_paid}<br /><b>Due :</b>{data.payment_info[0].due_amount ?? "0"}<br /><b>Mode :</b>{data.payment_info[0].payment_mode}</td>}
                                        {/* <td className={`${isMobile ? 'text-wrap' : 'text-nowrap'}`}>{isMobile ? <>{data?.employee_info?.first_name}<br></br>{data?.employee_info?.last_name}-<br></br>{data?.employee_info?.emp_id}</> : <>{data.employee_info?.first_name}&nbsp;{data.employee_info?.last_name} - {data?.employee_info?.emp_id}</>}</td> */}
                                        {!isMobile && <td>{data?.remark == null || data?.remark == "" ? "-" : data?.remark}</td>}
                                        {/* {!isMobile && <td>{data?.customerinfo?.email == null ? "-" : data?.customerinfo?.email}</td>}
                                                {!isMobile && <td>{data?.customerinfo?.mobile == null ? "-" : data?.customerinfo?.mobile}</td>} */}
                                        {isMobile && (
                                            <td>
                                                {!isExpanded ? (
                                                    <button
                                                        className="btn btn-link"
                                                        onClick={() => toggleRowExpansion(index)}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faArrowDown}
                                                            className="fontIcon"
                                                            size="sm"
                                                        />
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="btn btn-link"
                                                        onClick={() => toggleRowExpansion(index)}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faArrowUp}
                                                            className="fontIcon"
                                                            size="sm"
                                                        />
                                                    </button>
                                                )}</td>
                                        )}
                                    </tr>
                                        {isExpanded && isMobile && (
                                            <tr>
                                                <td colSpan="5">{renderRowExpansionContent(data)}</td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                    // data?.transactionItems?.map((data2: any, index2: any) => {
                                    //     count++;
                                    //     return (

                                    //     )
                                    // })
                                )
                            }
                            )}
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                        <ul className="pagination">
                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                <button
                                    className="page-link "
                                    onClick={() => handleChange(currentPage - 1)}
                                    disabled={currentPage === 1 || loading}
                                >
                                    Previous
                                </button>
                            </li>
                            {generatePageNumbers().map((page, index) => (
                                <li
                                    key={index}
                                    className={`page-item ${page === currentPage ? 'active' : ''}`}
                                >
                                    {page === '...' ? (
                                        <span className="page-link">...</span>
                                    ) : (
                                        <button
                                            className="page-link btn-sm"
                                            onClick={() => handleChange(page)}
                                            disabled={loading}
                                        >
                                            {page}
                                        </button>
                                    )}
                                </li>
                            ))}
                            <li
                                className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
                            >
                                <button
                                    className="page-link btn-sm"
                                    onClick={() => handleChange(currentPage + 1)}
                                    disabled={loading || currentPage === totalPages}
                                >
                                    Next
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            {/* } */}
        </div >
    </>
}
