import { faArrowDown, faArrowUp, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { listExpenseReducer } from "../../../reduxData/expenseSlice";
import { listExpenseHeadReducer } from "../../../reduxData/expenseHeadSlice";
import Select from "react-select";
import React from "react";

export default function ManagerExpensesList() {
  const authInfo = useSelector((state: any) => state.authUserInfo.value);
  const expenseInfo = useSelector(
    (state: any) => state.expenseInfo.value
  );
  const expenseHeadInfo = useSelector(
    (state: any) => state.expenseHeadInfo.value
  );
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  console.log("auth", authInfo, expenseInfo, expenseHeadInfo)
  const dispatch = useDispatch();
  const [status, setStatus] = useState();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate()
  const [msgs, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const currentDate = new Date().toISOString().substring(0, 10)
  const [fromDate, setFromDate] = useState(currentDate)
  const [toDate, setToDate] = useState(currentDate)
  const [isSearching, setSearching] = useState(false);
  const [branchSearchValue, setBranchSearchValue] = useState();
  const [expHeadSearchValue, setExpHeadSearchValue] = useState();
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const expensesHeadOptions = expenseHeadInfo?.data?.length > 0 && [{ value: "All", label: "All" }, ...expenseHeadInfo?.data?.map((item: any) => ({
    value: item?.id,
    label: item?.category
  }))];

  const ExpenseHeadList = async () => {
    try {
      setLoading(true)
      const url = `${urls.ALL_EXPENSE_HEAD_LIST}`;
      const res = await AuthApiService.GetApiCall(
        url,
        authInfo.token,
      );
      console.log(res)
      dispatch(listExpenseHeadReducer(res.data));
    }
    catch (error) {
      console.log(error)
      setStatus(false)
      setMsg("Network Error")
      handleClickOpen()
    }
    finally {
      setLoading(false)
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ExpensesList = async (page: number, itemsPerPage: number) => {
    try {
      setLoading(true)
      const url = `${urls.EXPENSE_LIST}/${page}/${itemsPerPage}`;
      const res = await AuthApiService.GetApiCallWithPagination(
        url,
        authInfo.token,
        page,
        itemsPerPage
      );
      dispatch(listExpenseReducer(res.data));
    }
    catch (error) {
      console.log(error)
      setStatus(false)
      setMsg("Network Error")
      handleClickOpen()
    }
    finally {
      setLoading(false)
    }
  };

  const onSearch = async (page, itemsPerPage, fDate, tDate, expense_head_id) => {
    try {
      setLoading(true)
      setSearching(true)
      const url = `${urls.EXPENSE_LIST_BY_DATE}/${fDate}/${tDate}/${page}/${itemsPerPage}`;
      const obj = {
        expense_head_id: expense_head_id
      }
      const resp = await AuthApiService.SearchApiCallWithQuery(url, obj, authInfo.token)
      console.log(url, obj, resp);
      dispatch(listExpenseReducer(resp.data));
    }
    catch (error) {
      console.log(error)
      setStatus(false)
      setMsg("Network Error")
      handleClickOpen()
    }
    finally {
      setLoading(false)
    }
  }

  //--------------------------Pagination-Start------------------------------
  const totalRecords = expenseInfo?.pagination?.totalRecords;

  console.log("total records", totalRecords);

  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPageButtons = 3; // Adjust the number of buttons you want to show

    if (totalPages <= maxPageButtons) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const leftEllipsis = currentPage > 2;
      const rightEllipsis = currentPage < totalPages - 1;

      if (leftEllipsis) {
        pageNumbers.push(1, "...");
      }

      const start = Math.max(1, currentPage - 1);
      const end = Math.min(totalPages, currentPage + 1);

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }

      if (rightEllipsis) {
        pageNumbers.push("...", totalPages);
      }
    }

    return pageNumbers;
  };

  const handlePageChange = async (page: number) => {
    setCurrentPage(page);
    if (isSearching) {
      await onSearch(page, itemsPerPage, fromDate, toDate, expHeadSearchValue)
    }
    // else
    // if (isDateSearching) {
    //     await handleDateSubmit(val, searchInput, page, itemsPerPage)
    // }
    // else if (val != undefined) {
    //     await onBranchSearch(val, page, itemsPerPage);
    // }
    else {
      await ExpensesList(page, itemsPerPage);
    }
  };
  //------------------------Pagination-End-----------------------------

  // -------------------------LoadingSpinner-Start--------------------------------
  const loadingSpinner = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  };
  // -------------------------LoadingSpinner-End--------------------------------

  useEffect(() => {
    ExpensesList(currentPage, itemsPerPage);
    ExpenseHeadList()
  }, []);

  const toggleRowExpansion = (index: any) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const renderRowExpansionContent = (item: any) => {
    return (<><th></th>
      {<tr><th className="text-wrap">
        Expense Head: <span style={{ fontWeight: "normal" }}>{item?.expenseHeadInfo?.category ? item?.expenseHeadInfo?.category : '-'}</span>
      </th></tr>}
      {<tr><th className="text-wrap">
        Invoice No.: <span style={{ fontWeight: "normal" }}>{item?.invoice_no ? item?.invoice_no : '-'}</span>
      </th></tr>}
      {<tr><th className="text-wrap">
        Description: <span style={{ fontWeight: "normal" }}>{item?.description ? item?.description : '-'}</span>
      </th></tr>}</>
    );
  };

  const handleWindowResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return <>
    <div className="container-fluid p-0">
      <div className="row">
        <div className="col-lg-10 col-md-10 col-sm-10">
          <div className="text-center">
            <h4>Expenses</h4>
          </div>
        </div>
        <div className="col-lg-2 col-md-2 col-sm-2 mt-2 d-flex justify-content-end">
          <Link
            to="/branchManager/managerNewExpense">
            <button className="btn btn-sm btn-purple" ><FontAwesomeIcon icon={faPlus} />&nbsp;New Expense</button>
          </Link>
        </div>
      </div>
      <div className="row mt-2 mt-xl-4 pb-4 p-1 align-items-baseline">
        <div className="col-lg-3 col-md-3 col-sm-12">
          <label>Select Expense Head</label>
          <Select
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            id="expense_head_id"
            name="expense_head_id"
            className={`react-select-container`}
            options={expensesHeadOptions}
            isSearchable
            // defaultValue={"All"}
            placeholder="All"
            // isClearable={true}
            onChange={(selectedOption) => {
              if (selectedOption == null || selectedOption.value == "All") {
                setCurrentPage(1);
                setExpHeadSearchValue(null)
                onSearch(1, itemsPerPage, fromDate, toDate, null)
              }
              else {
                setCurrentPage(1);
                setExpHeadSearchValue(selectedOption.value)
                onSearch(1, itemsPerPage, fromDate, toDate, selectedOption.value)
              }
            }}
          />
        </div>
        <div className="col-lg-2 col-md-2 col-4 ">
          <label>From Date:</label>

          <div className="input-group input-group-md">
            <input
              type="date"
              className="form-control shadow-sm"
              placeholder="Search..."
              value={fromDate}
              max={currentDate}
              onChange={(event) => {
                if (new Date(event.target.value) > new Date(toDate)) {
                  setToDate(event.target.value); setFromDate(event.target.value);
                } else {
                  setFromDate(event.target.value)
                }
              }}
            /></div>
        </div>
        <div className="col-lg-2 col-md-2 col-4 ">
          <label>To Date:</label>

          <div className="input-group input-group-md">
            <input
              type="date"
              className="form-control shadow-sm"
              placeholder="Search..."
              max={currentDate}
              min={fromDate}
              value={toDate}
              onChange={(event) => {
                setToDate(event.target.value);
              }}
            /></div>
        </div>
        <div className="col-lg-2 col-md-2 col-4 mt-2 align-self-end">
          <button className="btn btn-purple" onClick={() => {
            onSearch(currentPage, itemsPerPage, fromDate, toDate, expHeadSearchValue); setSearching(true)
          }}>Submit</button>
        </div>
        {isSearching && <div className="col-lg-2 col-md-2 col-6 mt-2 align-self-end">
          <button className="btn btn-danger" onClick={() => { ExpensesList(1, itemsPerPage); setCurrentPage(1); setSearching(false); setFromDate(currentDate); setToDate(currentDate) }}>Reset</button>
        </div>}
      </div>

      {loading || expenseInfo?.data?.length <= 0 ? (
        <div className="mt-4">
          {loading ? (
            // Display loading spinner
            <div className="d-flex justify-content-center align-items-center">
              {loadingSpinner()}
            </div>
          ) : (isSearching ? <h5>No Expenses Found.</h5> :
            // Display custom message for no records
            <h5>No Expenses Found. You can add New Expense.</h5>
          )}
        </div>
      ) :
        <div className="mt-2 mt-xl-2 mt-sm-2 justify-content-center">
          <div className={`table-container table-responsive`}>
            <table className="table table-striped table-hover border-light-subtle">
              <thead>
                <tr>
                  {!isMobile && <th>S.no</th>}
                  <th >{isMobile ? "Exp. Date" : "Expense Date"}</th>
                  <th >Amount({authInfo.currency})</th>
                  <th>Expense Name&nbsp;</th>

                  {!isMobile && <th>Expense Head</th>}
                  {!isMobile && <th >Invoice Number</th>}
                  {!isMobile && <th >Description</th>}
                  {isMobile && <th></th>}
                </tr>
              </thead>
              <tbody>
                {expenseInfo?.data?.map((data: any, index: any) => {
                  const isExpanded = expandedRowIndex === index;
                  return (<React.Fragment>
                    <tr>
                      {!isMobile && <td style={isMobile ? { width: 'auto' } : {}}>{index + 1}</td>}
                      <td>{new Date(data?.date).toLocaleString("en-GB", { dateStyle: "short" })}</td>
                      <td>{data?.amount}</td>

                      <td>{data?.item_name}</td>
                      {!isMobile && <td>{data?.expenseHeadInfo?.category}</td>}

                      {!isMobile && <td>{data?.invoice_no == "" || data?.invoice_no == null ? "-" : data?.invoice_no}</td>}
                      {!isMobile && <td>{data?.description == "" || data?.description == null ? "-" : data?.description}</td>}
                      {isMobile && (
                        <td>
                          {!isExpanded ? (
                            <button
                              className="btn btn-link"
                              onClick={() => toggleRowExpansion(index)}
                            >
                              <FontAwesomeIcon
                                icon={faArrowDown}
                                className="fontIcon"
                                size="sm"
                              />
                            </button>
                          ) : (
                            <button
                              className="btn btn-link"
                              onClick={() => toggleRowExpansion(index)}
                            >
                              <FontAwesomeIcon
                                icon={faArrowUp}
                                className="fontIcon"
                                size="sm"
                              />
                            </button>
                          )}
                        </td>
                      )}
                    </tr>
                    {isExpanded && isMobile && (
                      <tr>
                        <td colSpan="5">{renderRowExpansionContent(data)}</td>
                      </tr>)
                    }
                  </React.Fragment>
                  )
                })}
              </tbody>
            </table>
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""
                    }`}
                >
                  <button
                    className="page-link "
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1 || loading}
                  >
                    Previous
                  </button>
                </li>
                {generatePageNumbers()?.map((page, index) => (
                  <li
                    key={index}
                    className={`page-item ${page === currentPage ? "active" : ""
                      }`}
                  >
                    {page === "..." ? (
                      <span className="page-link">...</span>
                    ) : (
                      <button
                        className="page-link btn-sm"
                        onClick={() => handlePageChange(page)}
                        disabled={loading}
                      >
                        {page}
                      </button>
                    )}
                  </li>
                ))}
                <li
                  className={`page-item ${currentPage === totalPages ? "disabled" : ""
                    }`}
                >
                  <button
                    className="page-link btn-sm"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={loading || currentPage === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      }
    </div>
  </>
}