// import React, { FormEvent, useEffect, useRef, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Link, useNavigate } from "react-router-dom";
// import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
// import { ListBranchReducer, UpdateBranchReducer } from "../../../reduxData/branchSlice";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import { branchValidationSchema } from "../../../validations/Validations";
// import { Alert, Snackbar } from "@mui/material";


// export default function EditBranch() {
//     const admin = useSelector((state: any) => state.authUserInfo.value);
//     const branchData = useSelector((state: any) => state.branchInfo.upData);
//     const employeeList = useSelector((state: any) => state.employeeInfo.value);
//     const dispatch = useDispatch();
//     const [msgs, setMsg] = useState("");
//     const [filteredEmployeeList, setFilteredEmployeeList] = useState([]);
//     const [status, setStatus] = useState();
//     const [open, setOpen] = useState(false);
//     const [loading, setLoading] = useState(false);
//     const navigate = useNavigate()

//     const formik = useFormik({
//         initialValues: {
//             branch_name: branchData?.branch_name || "",
//             saloon_type: branchData?.saloon_type || "",
//             branch_address: branchData?.branch_address || "",
//             phone_number: branchData?.phone_number || "",
//             email: branchData?.email || "",
//             branch_manager_id: branchData?.managerinfo?.id || "",
//         },
//         validationSchema: branchValidationSchema,
//         onSubmit: async (values) => {
//             try {
//                 setLoading(true);
//                 const branchManagerId = values.branch_manager_id || "";
//                 let ob: {
//                     branch_name: string;
//                     branch_address: string;
//                     email: string;
//                     phone_number: string;
//                     saloon_type: string;
//                     branch_manager_id?: string;
//                 };
//                 // if (branchData && branchData.managerinfo == null) {
//                 ob = {
//                     branch_name: values.branch_name,
//                     branch_address: values.branch_address,
//                     email: values.email ? values.email : null,
//                     phone_number: values.phone_number,
//                     saloon_type: values.saloon_type

//                 }

//                 console.log("updating this branch data", ob)
//                 const upURL = urls.BRANCH_UPDATE + branchData.id;

//                 const resp = await AuthApiService.PutApiCall(upURL, ob, admin.token);

//                 if (resp.status == 200) {
//                     setMsg(resp.data.msg);
//                     setStatus(resp.data.status)
//                     handleClickOpen();
//                     // dispatch(UpdateBranchReducer(resp.data));
//                     const data = dispatch(ListBranchReducer(resp.data))
//                     console.log(data)
//                     if (resp.data.code == 500) {
//                         setStatus(false)
//                         if (resp.data.err === "Validation error") {
//                             setMsg("This Branch name is already in use please use different name...")
//                         }

//                     } else {
//                         setStatus(true)
//                         // formik.resetForm();
//                     }

//                     if (resp.data.status == 201) {
//                         setTimeout(() => {
//                             navigate('/saloonChain/branchDetails')
//                         }, 2000)
//                     } else {
//                         setTimeout(() => {
//                             navigate('/saloonChain/editBranch')
//                         }, 2000)
//                     }
//                 } else {
//                     if (resp.data && resp.data.err) {
//                         setMsg(resp.data.err);
//                         setStatus(resp.data.status)

//                     } else {
//                         setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
//                         setStatus(resp.data.status)

//                     }

//                 }
//             } catch (error: any) {
//                 console.log(error)
//                 setMsg("Network Error !");
//                 setStatus(error.response.status);
//                 handleClickOpen()
//             }
//             finally {
//                 setLoading(false);
//                 setTimeout(() => {

//                 }, 5000);
//             }
//         },
//     });

//     const handleBranchSelect = () => {
//         const selectedBranchId = branchData?.id;

//         // Check if employeeList.data is defined before filtering
//         if (employeeList.data) {
//             const filteredEmployees = employeeList.data.filter(
//                 (emp: any) => emp.branch_info.id === selectedBranchId
//             );
//             setFilteredEmployeeList(filteredEmployees);
//         }
//     };
//     useEffect(() => {
//         handleBranchSelect();
//     }, [branchData, employeeList]);

//     useEffect(() => {
//         return () => {
//             dispatch(UpdateBranchReducer(null)); // or undefined
//         };
//     }, []);

//     const handleClickOpen = () => {
//         setOpen(true);
//     };

//     const handleClose = () => {
//         setOpen(false);
//     };

//     return (
//         <>
//             <div className="container-fluid p-0">
//                 <Snackbar
//                     anchorOrigin={{ vertical: "top", horizontal: "center" }}
//                     open={open}
//                     autoHideDuration={5000}
//                     onClose={handleClose}
//                 >
//                     <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msgs}</Alert>
//                 </Snackbar>
//                 <div className="container">

//                     <div className="row pr-xl-5 pl-xl-5">
//                         <div className="col-lg-10 col-md-8 col-sm-8 d-flex justify-content-center">
//                             <div className="text-center">
//                                 <h4>Update Branch </h4>
//                             </div>
//                         </div>
//                         <div className="col-lg-2 col-md-4 col-sm-4 mt-2 d-flex pr-2 justify-content-end">
//                             <Link to="/saloonChain/branchDetails">
//                                 <span>
//                                     <button className=" btn btn-sm btn-purple">All Branches</button>
//                                 </span>
//                             </Link>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="p-1 px-xl-2 py-xl-2 justify-content-center">
//                     <div className="container">
//                         {/* <b className={status ? "text-success" : "text-danger"}>{msgs}</b> */}
//                         <div className="row justify-content-center mt-3">
//                             <div className="col-lg-12">
//                                 <form onSubmit={formik.handleSubmit}>
//                                     <div className="mb-1 mb-xl-3 row">
//                                         <label htmlFor="name" className="col-sm-3 col-form-label">
//                                             Branch Name<span style={{ color: "red" }}>*</span> :
//                                         </label>
//                                         <div className="col-sm-7">
//                                             <input
//                                                 type="text"
//                                                 onChange={formik.handleChange}
//                                                 onBlur={formik.handleBlur}
//                                                 value={formik.values.branch_name}
//                                                 className={`form-control ${formik.touched.branch_name && formik.errors.branch_name ? "is-invalid" : ""
//                                                     }`}
//                                                 id="name"
//                                                 placeholder="Enter Name"
//                                                 name="branch_name"
//                                             />
//                                             <div className="invalid-feedback">{formik.touched.branch_name && typeof formik.errors.branch_name === 'string' ? (formik.errors.branch_name) : null}</div>
//                                         </div>
//                                     </div>
//                                     <div className="mb-1 mb-xl-3 row">
//                                         <label htmlFor="gender" className="col-sm-3 col-form-label">
//                                             Saloon Type<span style={{ color: "red" }}>*</span> :
//                                         </label>
//                                         <div className="col-sm-7">
//                                             <select
//                                                 onChange={formik.handleChange}
//                                                 onBlur={formik.handleBlur}
//                                                 value={formik.values.saloon_type}
//                                                 className={`form-control form-select ${formik.touched.saloon_type && formik.errors.saloon_type ? "is-invalid" : ""
//                                                     }`}
//                                                 id="gender"
//                                                 name="saloon_type"
//                                             >
//                                                 <option value="">Select Saloon Type</option>
//                                                 <option value="Male">Male</option>
//                                                 <option value="Female">Female</option>
//                                                 <option value="Unisex">Unisex</option>
//                                             </select>
//                                             <div className="invalid-feedback">{formik.touched.saloon_type && typeof formik.errors.saloon_type === 'string' ? (formik.errors.saloon_type) : null}</div>
//                                         </div>
//                                     </div>
//                                     <div className="mb-1 mb-xl-3 row">
//                                         <label htmlFor="address" className="col-sm-3 col-form-label">
//                                             Address<span style={{ color: "red" }}>*</span> :
//                                         </label>
//                                         <div className="col-sm-7">
//                                             <input
//                                                 type="text"
//                                                 onChange={formik.handleChange}
//                                                 onBlur={formik.handleBlur}
//                                                 value={formik.values.branch_address}
//                                                 className={`form-control ${formik.touched.branch_address && formik.errors.branch_address ? "is-invalid" : ""
//                                                     }`}
//                                                 id="address"
//                                                 placeholder="Enter Address"
//                                                 name="branch_address"
//                                             />
//                                             <div className="invalid-feedback">{formik.touched.branch_address && typeof formik.errors.branch_address === 'string' ? (formik.errors.branch_address) : null}</div>
//                                         </div>
//                                     </div>
//                                     <div className="mb-1 mb-xl-3 row">
//                                         <label htmlFor="phone" className="col-sm-3 col-form-label">
//                                             Phone<span style={{ color: "red" }}>*</span> :
//                                         </label>
//                                         <div className="col-sm-7">
//                                             <input
//                                                 type="text"
//                                                 onChange={formik.handleChange}
//                                                 onBlur={formik.handleBlur}
//                                                 value={formik.values.phone_number}
//                                                 className={`form-control ${formik.touched.phone_number && formik.errors.phone_number ? "is-invalid" : ""
//                                                     }`}
//                                                 id="phone"
//                                                 placeholder="Enter Phone"
//                                                 name="phone_number"
//                                             />
//                                             <div className="invalid-feedback">{formik.touched.phone_number && typeof formik.errors.phone_number === 'string' ? (formik.errors.phone_number) : null}</div>
//                                         </div>
//                                     </div>
//                                     <div className="mb-1 mb-xl-3 row">
//                                         <label htmlFor="email" className="col-sm-3 col-form-label">
//                                             Email:
//                                         </label>
//                                         <div className="col-sm-7">
//                                             <input
//                                                 type="email"
//                                                 onChange={formik.handleChange}
//                                                 onBlur={formik.handleBlur}
//                                                 value={formik.values.email}
//                                                 className={`form-control ${formik.touched.email && formik.errors.email ? "is-invalid" : ""
//                                                     }`}
//                                                 id="email"
//                                                 placeholder="Enter email"
//                                                 name="email"
//                                             />
//                                             <div className="invalid-feedback">{formik.touched.email && typeof formik.errors.email === 'string' ? (formik.errors.email) : null}</div>
//                                         </div>
//                                     </div>

//                                     {/* <div className="mb-3 row">
//                                         <label
//                                             htmlFor="branchManager"
//                                             className={`col-sm-3 col-form-label${!branchData || !branchData.managerinfo ? 'text-muted ' : ''}`}
//                                         >
//                                             Branch Manager:
//                                         </label> */}
//                                     {/* <div className="col-sm-7"> */}
//                                     {/* <select

//                                                 onChange={formik.handleChange}
//                                                 onBlur={formik.handleBlur}

//                                                 // value={formik.values.branch_manager_id || ''}
//                                                 className="form-control form-select"
//                                                 id="branchManager"
//                                                 name="branch_manager_id" disabled={!branchData || !branchData.managerinfo}
//                                             >
//                                                 {branchData && branchData.managerinfo ? (
//                                                     <option value={branchData.managerinfo.id} selected>{branchData.managerinfo.first_name}</option>
//                                                 ) : (
//                                                     <option disabled>Select Branch Manager</option>
//                                                 )}

//                                                 {filteredEmployeeList
//                                                     .filter((employee: any) => !branchData || !branchData.managerinfo || employee.first_name !== branchData.managerinfo?.first_name)
//                                                     .map((employee: any) => (
//                                                         <option key={employee.id} value={employee.id}>
//                                                             {employee.first_name}
//                                                         </option>
//                                                     ))}

//                                             </select> */}

//                                     {/* <select
//                                                 onChange={formik.handleChange}
//                                                 onBlur={formik.handleBlur}
//                                                 value={formik.values.branch_manager_id || ''}
//                                                 className="form-control form-select"
//                                                 id="branchManager"
//                                                 name="branch_manager_id"
//                                                 disabled={!branchData || !branchData.managerinfo}
//                                             >
//                                                 {!branchData || !branchData.managerinfo ? (
//                                                     <option disabled>Select Branch Manager</option>
//                                                 ) : (
//                                                     <>
//                                                         <option value={branchData.managerinfo.id}>
//                                                             {branchData.managerinfo.first_name}
//                                                         </option>
//                                                         {filteredEmployeeList
//                                                             .filter(employee => employee.emp_id !== branchData.managerinfo.id)
//                                                             .map((employee: any) => (
//                                                                 <option
//                                                                     key={employee.emp_id}
//                                                                     value={employee.emp_id}
//                                                                 >
//                                                                     {employee.first_name}
//                                                                 </option>
//                                                             ))}
//                                                     </>
//                                                 )}
//                                             </select> */}

//                                     {/* <div className="invalid-feedback">{formik.touched.email && formik.errors.email}</div> */}
//                                     {/* </div> */}
//                                     {/* </div> */}

//                                     <div className="mt-3 mb-xl-3 row text-center">
//                                         <div className="col-lg-5"></div>
//                                         <div className="col-lg-2">
//                                             <button type="submit" className="btn btn-purple form-control btn-md" disabled={loading}>
//                                                 {loading ? "Updating..." : "Update"}
//                                             </button>
//                                         </div>
//                                         <div className="col-lg-4"></div>
//                                     </div>


//                                 </form>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>


//         </>
//     );
// }

import React, { FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { ListBranchReducer, UpdateBranchReducer } from "../../../reduxData/branchSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { branchValidationSchema } from "../../../validations/Validations";
import { Alert, Snackbar } from "@mui/material";
import Select from "react-select";


export default function EditBranch() {
    const admin = useSelector((state: any) => state.authUserInfo.value);
    const currencyList = useSelector((state: any) => state.currencyInfo.value);
    const branchData = useSelector((state: any) => state.branchInfo.upData);
    const employeeList = useSelector((state: any) => state.employeeInfo.value);
    const localBranchData = JSON.parse(localStorage.getItem("branchinfo"));
    console.log(branchData,localBranchData)
    const dispatch = useDispatch();
    const [msgs, setMsg] = useState("");
    const [filteredEmployeeList, setFilteredEmployeeList] = useState([]);
    const [status, setStatus] = useState();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            branch_name: (branchData?.branch_name??localBranchData.branch_name) || "",
            saloon_type: (branchData?.saloon_type??localBranchData.saloon_type) || "",
            branch_address: (branchData?.branch_address??localBranchData.branch_address) || "",
            phone_number: (branchData?.phone_number??localBranchData.phone_number) || "",
            email: (branchData?.email??localBranchData.email) || "",
            branch_manager_id: (branchData?.managerinfo?.id??localBranchData.manager_id) || "",
            currency: (branchData?.currency??localBranchData.currency)|| null
        },
        validationSchema: branchValidationSchema,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const branchManagerId = values.branch_manager_id || "";
                let ob: {
                    branch_name: string;
                    branch_address: string;
                    email: string;
                    phone_number: string;
                    saloon_type: string;
                    branch_manager_id?: string;
                    currency: string;
                };
                // if (branchData && branchData.managerinfo == null) {
                ob = {
                    branch_name: values.branch_name,
                    branch_address: values.branch_address,
                    email: values.email ? values.email : null,
                    phone_number: values.phone_number,
                    saloon_type: values.saloon_type,
                    currency: values.currency

                }

                console.log("updating this branch data", ob)
                const upURL = `${urls.BRANCH_UPDATE}${branchData?.id??localBranchData?.id}`;
                console.log(upURL)
                const resp = await AuthApiService.PutApiCall(upURL, ob, admin.token);

                if (resp.status == 200) {
                    setMsg(resp.data.msg);
                    setStatus(resp.data.status)
                    handleClickOpen();
                    // dispatch(UpdateBranchReducer(resp.data));
                    const data = dispatch(ListBranchReducer(resp.data))
                    console.log(data)
                    if (resp.data.code == 500) {
                        setStatus(false)
                        if (resp.data.err === "Validation error") {
                            setMsg("This Branch name is already in use please use different name...")
                        }

                    } else {
                        setStatus(true)
                        // formik.resetForm();
                    }

                    if (resp.data.status == 201) {
                        setTimeout(() => {
                            localStorage.removeItem("branchinfo")
                            navigate('/saloonChain/branchDetails')
                        }, 2000)
                    } else {
                        setTimeout(() => {
                            navigate('/saloonChain/editBranch')
                        }, 2000)
                    }
                } else {
                    if (resp.data && resp.data.err) {
                        setMsg(resp.data.err);
                        setStatus(resp.data.status)

                    } else {
                        setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
                        setStatus(resp.data.status)

                    }

                }
            } catch (error: any) {
                console.log(error)
                setMsg("Network Error !");
                setStatus(error.response.status);
                handleClickOpen()
            }
            finally {
                setLoading(false);
                setTimeout(() => {

                }, 5000);
            }
        },
    });

    const handleBranchSelect = () => {
        const selectedBranchId = branchData?.id;

        // Check if employeeList.data is defined before filtering
        if (employeeList.data) {
            const filteredEmployees = employeeList.data.filter(
                (emp: any) => emp.branch_info.id === selectedBranchId
            );
            setFilteredEmployeeList(filteredEmployees);
        }
    };
    useEffect(() => {
        handleBranchSelect();
    }, [branchData, employeeList]);

    useEffect(() => {
        return () => {
            dispatch(UpdateBranchReducer(null)); // or undefined
        };
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const currencyOptions = currencyList.map((item: any) => ({
        value: item.id,
        label: item.currency,
    }));
    return (
        <>
            <div className="container-fluid p-0">
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={open}
                    autoHideDuration={5000}
                    onClose={handleClose}
                >
                    <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msgs}</Alert>
                </Snackbar>
                <div className="container">

                    <div className="row pr-xl-5 pl-xl-5">
                        <div className="col-lg-10 col-md-8 col-sm-8 d-flex justify-content-center">
                            <div className="text-center">
                                <h4>Update Branch </h4>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-4 mt-2 d-flex pr-2 justify-content-end">
                            <Link to="/saloonChain/branchDetails">
                                <span>
                                    <button className=" btn btn-sm btn-purple" onClick={()=>{localStorage.removeItem("branchinfo")}}>All Branches</button>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="p-1 px-xl-2 py-xl-2 justify-content-center">
                    <div className="container">
                        {/* <b className={status ? "text-success" : "text-danger"}>{msgs}</b> */}
                        <div className="row justify-content-center mt-3">
                            <div className="col-lg-12">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="mb-1 mb-xl-3 row">
                                        <label htmlFor="name" className="col-sm-3 col-form-label">
                                            Branch Name<span style={{ color: "red" }}>*</span> :
                                        </label>
                                        <div className="col-sm-7">
                                            <input
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.branch_name}
                                                className={`form-control ${formik.touched.branch_name && formik.errors.branch_name ? "is-invalid" : ""
                                                    }`}
                                                id="name"
                                                placeholder="Enter Name"
                                                name="branch_name"
                                            />
                                            <div className="invalid-feedback">{formik.touched.branch_name && typeof formik.errors.branch_name === 'string' ? (formik.errors.branch_name) : null}</div>
                                        </div>
                                    </div>
                                    <div className="mb-1 mb-xl-3 row">
                                        <label htmlFor="gender" className="col-sm-3 col-form-label">
                                            Saloon Type<span style={{ color: "red" }}>*</span> :
                                        </label>
                                        <div className="col-sm-7">
                                            <select
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.saloon_type}
                                                className={`form-control form-select ${formik.touched.saloon_type && formik.errors.saloon_type ? "is-invalid" : ""
                                                    }`}
                                                id="gender"
                                                name="saloon_type"
                                            >
                                                <option value="">Select Saloon Type</option>
                                                <option value="MALE">Male</option>
                                                <option value="FEMALE">Female</option>
                                                <option value="UNISEX">Unisex</option>
                                            </select>
                                            <div className="invalid-feedback">{formik.touched.saloon_type && typeof formik.errors.saloon_type === 'string' ? (formik.errors.saloon_type) : null}</div>
                                        </div>
                                    </div>
                                    <div className="mb-1 mb-xl-3 row">
                                        <label htmlFor="address" className="col-sm-3 col-form-label">
                                            Address<span style={{ color: "red" }}>*</span> :
                                        </label>
                                        <div className="col-sm-7">
                                            <input
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.branch_address}
                                                className={`form-control ${formik.touched.branch_address && formik.errors.branch_address ? "is-invalid" : ""
                                                    }`}
                                                id="address"
                                                placeholder="Enter Address"
                                                name="branch_address"
                                            />
                                            <div className="invalid-feedback">{formik.touched.branch_address && typeof formik.errors.branch_address === 'string' ? (formik.errors.branch_address) : null}</div>
                                        </div>
                                    </div>
                                    <div className="mb-1 mb-xl-3 row">
                                        <label htmlFor="phone" className="col-sm-3 col-form-label">
                                            Phone<span style={{ color: "red" }}>*</span> :
                                        </label>
                                        <div className="col-sm-7">
                                            <input
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.phone_number}
                                                className={`form-control ${formik.touched.phone_number && formik.errors.phone_number ? "is-invalid" : ""
                                                    }`}
                                                id="phone"
                                                placeholder="Enter Phone"
                                                name="phone_number"
                                            />
                                            <div className="invalid-feedback">{formik.touched.phone_number && typeof formik.errors.phone_number === 'string' ? (formik.errors.phone_number) : null}</div>
                                        </div>
                                    </div>
                                    <div className="mb-1 mb-xl-3 row">
                                        <label htmlFor="email" className="col-sm-3 col-form-label">
                                            Email:
                                        </label>
                                        <div className="col-sm-7">
                                            <input
                                                type="email"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.email}
                                                className={`form-control ${formik.touched.email && formik.errors.email ? "is-invalid" : ""
                                                    }`}
                                                id="email"
                                                placeholder="Enter email"
                                                name="email"
                                            />
                                            <div className="invalid-feedback">{formik.touched.email && typeof formik.errors.email === 'string' ? (formik.errors.email) : null}</div>
                                        </div>
                                    </div>
                                    <div className="mb-1 mb-xl-3 row">
                                        <label htmlFor="email" className="col-sm-3 col-form-label">
                                            Currency
                                        </label>
                                        <div className="col-sm-7">
                                            <Select
                                                // menuIsOpen={true}
                                                maxMenuHeight="8em"
                                                id="currency"
                                                name="currency"
                                                className={`react-select-container ${formik.touched.currency && formik.errors.currency ? 'is-invalid' : ''}`}
                                                options={currencyOptions}
                                                isSearchable
                                                placeholder="Select currency"
                                                onChange={(selectedOption) =>
                                                    formik.setFieldValue("currency", selectedOption.value)
                                                }
                                                onBlur={formik.handleBlur}
                                                value={currencyOptions.find(option => option.value === formik.values.currency)}

                                            />
                                            <div className="invalid-feedback">{formik.touched.currency && typeof formik.errors.currency === 'string' ? (formik.errors.currency) : null}</div>
                                        </div>
                                    </div>

                                    <div className="mt-3 mb-xl-3 row text-center">
                                        <div className="col-lg-5"></div>
                                        <div className="col-lg-2">
                                            <button type="submit" className="btn btn-purple form-control btn-md" disabled={loading}>
                                                {loading ? "Updating..." : "Update"}
                                            </button>
                                        </div>
                                        <div className="col-lg-4"></div>
                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}

