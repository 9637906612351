import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import branchReducer from './branchSlice';
import employeeReducer from './employeeSlice'
import leaveSlice from './leaveSlice';
import incentiveRuleSlice from './incentiveRuleSlice';
import incentiveEmployeeSlice from './incentiveEmployeeSlice';
import transactionSlice from './transactionSlice';
import branchServicesSlice from './branchServicesSlice';
import serviceMasterSlice from './serviceMasterSlice';
import advancePaymentSlice from './advancePaymentSlice';
import SalarySlice from './SalarySlice';
import settingSlice from './settingSlice';
import currencySlice from './currencySlice';
import serviceCategorySlice from './serviceCategorySlice';
import productSlice from './productSlice';
import unitSlice from './unitSlice';
import customerSlice from './customerSlice';
import expenseHeadSlice from './expenseHeadSlice';
import expenseSlice from './expenseSlice';
const store = configureStore({
    reducer: {
        authUserInfo: authReducer,
        branchInfo: branchReducer,
        employeeInfo: employeeReducer,
        incentiveRuleInfo: incentiveRuleSlice,
        incentiveEmployeeInfo: incentiveEmployeeSlice,
        leaveInfo: leaveSlice,
        transactionInfo: transactionSlice,
        branchServicesInfo: branchServicesSlice,
        serviceMasterInfo: serviceMasterSlice,
        AdvancePaymentInfo: advancePaymentSlice,
        salaryInfo: SalarySlice,
        settingInfo: settingSlice,
        currencyInfo: currencySlice,
        serviceCategoryInfo: serviceCategorySlice,
        productInfo: productSlice,
        unitInfo: unitSlice,
        customerInfo:customerSlice,
        expenseHeadInfo:expenseHeadSlice,
        expenseInfo:expenseSlice,
    },
});

export default store;



