import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface EmployeeLeaveState {
    value: any[];
    upData: any; // Update this type based on your use case
    leaveMonth:any,
}

interface Leave {

    // Add other properties as needed
}





const initialState: EmployeeLeaveState = {
    value: [],
    upData: null,
    leaveMonth:null,
};

const employeeLeaveSlice = createSlice({
    name: "employeeleave",
    initialState,
    reducers: {
        addEmployeeLeaveReducer: (state, action: PayloadAction<Leave>) => {
            state.value.push(action.payload);
        },
        listEmployeeLeaveReducer: (state, action: PayloadAction<Leave[]>) => {
            state.value = action.payload;
        },
        updateEmployeeLeaveReducer: (state, action: PayloadAction<any>) => {
            console.log("Updating upData:", action.payload);
            state.upData = action.payload;
        },
        deleteEmployeeLeaveReducer: (state, action: PayloadAction<any>) => {
            console.log("deleting", action.payload);
        },
        addMonthReducer: (state, action: PayloadAction<any>) => {
            state.leaveMonth = action.payload;
        },
        // deleteEmployeeLeaveReducer: (state, action: PayloadAction<number>) => {
        //     // Assuming state.value is an array, we use filter to remove the item with the specified id
        //     state.value = state.value.filter((item) => item.id !== action.payload);
        // },
    },
});

export const { listEmployeeLeaveReducer, addEmployeeLeaveReducer, updateEmployeeLeaveReducer, deleteEmployeeLeaveReducer, addMonthReducer } = employeeLeaveSlice.actions;
export default employeeLeaveSlice.reducer;
