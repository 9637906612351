import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { addEmployeeReducer, empCategoryReducer, listEmployeeReducer } from "../../../reduxData/employeeSlice";
import { ListBranchReducer } from "../../../reduxData/branchSlice";
import { employeeValidationSchema } from "../../../validations/Validations";
import Select from "react-select";
import { Alert, Snackbar } from "@mui/material";
import { useLocation } from "react-router-dom";
interface FormValues {
    emp_id: string;
    first_name: string;
    last_name: string;
    gender: string;
    email: string;
    mobile_1: string;
    mobile_2: string;
    date_of_birth: Date;
    date_of_joining: Date;
    date_of_relieving: Date;
    category: number;
    branch_id: number;
    salary: string;
    govt_id: string;
    is_active: boolean;
    created_by: number;
    updated_by: number

}

export default function NewEmployee() {
    const admin = useSelector((state: any) => state.authUserInfo.value);
    const branchList = useSelector((state: any) => state.branchInfo.value);
    const empCat = useSelector((state: any) => state.employeeInfo.cateValue);
    const employeeList = useSelector((state: any) => state.employeeInfo.value);

    const dispatch = useDispatch()
    const [msg, setMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState();
    const [open, setOpen] = useState(false);
    const [errorstatus, setErrorStatus] = useState(false)
    const navigate = useNavigate()

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    let branchId = queryParams.get("branchId") || "";

    const branchlist = async () => {
        try {
            const resp = await AuthApiService.GetApiCall(urls.BRANCH_LIST, admin.token);
            if (resp.status) {
                dispatch(ListBranchReducer(resp.data.data));
            }
        } catch (error) {
            setMsg("Network Error !");
        }
    };
    useEffect(() => {
        // empCategoryList();
        branchlist()

    }, []);


    // useEffect(() => {
    //     formik.setFieldValue("branch_id", branchId);
    // }, [branchId]);

    useEffect(() => {
        if (branchList.length === 1) {
            const singleBranch = branchList[0];
            formik.setFieldValue("branch_id", singleBranch.id);
        }
    }, [branchList]);
    if (branchId === null || branchId === "null") {
        branchId = "";
    }

    const formik = useFormik({
        initialValues: {
            emp_id: "",
            first_name: "",
            last_name: "",
            gender: "Male",
            email: "",
            mobile_1: "",
            mobile_2: "",
            date_of_birth: "",
            date_of_joining: "",
            date_of_relieving: "",
            category: "",
            branch_id: branchId || "",
            salary: "",
            govt_id: "",
            is_active: false,
            created_by: admin.uid,
            updated_by: admin.uid,
        },
        validationSchema: employeeValidationSchema,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const ob = {
                    emp_id: values.emp_id,
                    first_name: values.first_name,
                    last_name: values.last_name,
                    gender: values.gender,
                    email: values.email ? values.email : null,
                    mobile_1: values.mobile_1,
                    mobile_2: values.mobile_2,
                    date_of_birth: values.date_of_birth ? values.date_of_birth : null,
                    date_of_joining: values.date_of_joining,
                    date_of_relieving: null,
                    category: values.category,
                    branch_id: values.branch_id,
                    salary: values.salary,
                    govt_id: values.govt_id,
                    is_active: true,
                    created_by: admin.uid,
                    updated_by: admin.uid
                }

                const resp = await AuthApiService.SaveApiCall(urls.EMPLOYEE_SAVE, ob, admin.token)
                if (resp.status === 200) {
                    setMsg(resp.data.msg);
                    handleClickOpen();
                    setStatus(resp.data.status)
                    const d = dispatch(addEmployeeReducer(ob))
                    dispatch(listEmployeeReducer([...employeeList, ob]));
                    // formik.resetForm();
                    if (resp.data.code == 500) {
                        setErrorStatus(true)
                        setMsg(resp.data.err)
                        // navigate('/saloonChain/newEmployee')

                    } else {
                        setErrorStatus(false)
                        // navigate('/saloonChain/employeeDetails')
                    }
                    if (resp.data.status == 201) {
                        setTimeout(() => {
                            navigate('/saloonChain/employeeDetails')
                        }, 2000)
                    } else {
                        setTimeout(() => {
                            navigate('/saloonChain/newEmployee')
                        }, 2000)
                    }
                } else {
                    if (resp.data && resp.data.err) {
                        setMsg(resp.data.err);
                        setStatus(resp.data.status)
                        // navigate('/saloonChain/newEmployee')

                    } else {
                        setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
                        setStatus(resp.data.status)
                    }
                }
            } catch (error: any) {
                setMsg("Network Error !");
                setStatus(error.response.status);
                handleClickOpen()
            }
            finally {
                setLoading(false);
            }
        }
    })

    const categoryOptions = empCat.map((item: any) => ({
        value: item.id,
        label: item.category_title
    }))

    // const branchOptions = branchList.map((item: any) => ({
    //     value: item.id,
    //     label: item.branch_name
    // }))
    const branchOptions = branchList.map((item: any) => ({
        value: item.id,
        label: item.branch_name,
        isSelected: item.id === parseInt(branchId)
    }));
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getCurrentDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const dd = String(today.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    };
    return <>
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
        >
            <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert>
        </Snackbar>
        <div className="container-fluid p-0">
            <div className="container">

                <div className="row pr-xl-5 pl-xl-5">
                    <div className="col-lg-10 col-md-8 col-sm-8 d-flex justify-content-center ">
                        <div className="text-center">
                            <h4>New Employee</h4>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4 mt-2 d-flex pr-2 justify-content-end">
                        <Link to="/saloonChain/employeeDetails"><span><button className="btn btn-sm btn-purple">All Employees</button></span></Link></div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-2 col-md-2 col-sm-2 text-sm-end">
                </div>
                <div className="col-lg-8"></div>
            </div>
            <div className="p-1 px-xl-2 py-xl-2 justify-content-center">
                <div className="container">

                    <div className="row justify-content-center mt-3">
                        <div className="col-lg-12 ">
                            <form
                                onFocus={() => setMsg("")}
                                onSubmit={formik.handleSubmit}>
                                <div className="mb-1 mb-xl-3 row">
                                    <div className="col-lg-2 col-md-2">
                                        <label htmlFor="branch" className="col-form-label">Branch<span style={{ color: "red" }}>*</span> :</label>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        {/* <Select
                                            id="branch_id"
                                            name="branch_id"
                                            className={`react-select-container  ${formik.touched.branch_id && formik.errors.branch_id
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                            options={branchOptions}
                                            isSearchable
                                            placeholder="Select branch name..."
                                            onChange={(selectedOption) =>
                                                formik.setFieldValue("branch_id", selectedOption.value)}
                                            onBlur={formik.handleBlur}
                                            value={
                                                branchOptions.find((option: any) => option.isSelected || option.value === formik.values.branch_id)
                                            }
                                        /> */}
                                        <Select
                                            id="branch_id"
                                            name="branch_id"
                                            className={`react-select-container ${formik.touched.branch_id && formik.errors.branch_id ? "is-invalid" : ""}`}
                                            options={branchOptions}
                                            isSearchable
                                            placeholder="Select Branch"
                                            onChange={(selectedOption) => formik.setFieldValue("branch_id", selectedOption.value)}
                                            onBlur={formik.handleBlur}
                                            value={branchOptions.find((option: any) => option.isSelected || option.value === formik.values.branch_id)}
                                        />
                                        {formik.touched.branch_id && formik.errors.branch_id ? (
                                            <div className="invalid-feedback">{formik.errors.branch_id}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-lg-2 col-md-2">
                                        <label htmlFor="fname" className="col-form-label">Employee Code:</label>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <input
                                            type="text"
                                            className={`form-control shadow-sm ${formik.touched.emp_id && formik.errors.emp_id ? "is-invalid" : ""
                                                }`}
                                            id="fname"
                                            placeholder="Enter Employee Code"
                                            name="emp_id"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.emp_id}
                                        />
                                        {formik.touched.emp_id && formik.errors.emp_id ? (
                                            <div className="invalid-feedback">{formik.errors.emp_id}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <div className="col-lg-2 col-md-2">
                                        <label htmlFor="fname" className="col-form-label">First Name<span style={{ color: "red" }}>*</span> :</label>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <input
                                            type="text"
                                            className={`form-control shadow-sm ${formik.touched.first_name && formik.errors.first_name ? "is-invalid" : ""
                                                }`}
                                            id="fname"
                                            placeholder="Enter First Name"
                                            name="first_name"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.first_name}
                                        />
                                        {formik.touched.first_name && formik.errors.first_name ? (
                                            <div className="invalid-feedback">{formik.errors.first_name}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-lg-2 col-md-2">
                                        <label htmlFor="gender" className="col-form-label">Gender<span style={{ color: "red" }}>*</span> :</label>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <select
                                            className={`form-control form-select shadow-sm ${formik.touched.gender && formik.errors.gender ? "is-invalid" : ""
                                                }`}
                                            id="gender"
                                            name="gender"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.gender}
                                        >
                                            {/* <option value="">Select Gender</option> */}
                                            <option value="Male" selected>Male</option>
                                            <option value="Female">Female</option>
                                        </select>
                                        {formik.touched.gender && formik.errors.gender ? (
                                            <div className="invalid-feedback">{formik.errors.gender}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <div className="col-lg-2 col-md-2">
                                        <label htmlFor="last_name" className="col-form-label">Last Name<span style={{ color: "red" }}>*</span> :</label>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <input
                                            type="text"
                                            className={`form-control shadow-sm ${formik.touched.last_name && formik.errors.last_name ? "is-invalid" : ""
                                                }`}
                                            id="last_name"
                                            placeholder="Enter Last Name"
                                            name="last_name"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.last_name}
                                        />
                                        {formik.touched.last_name && formik.errors.last_name ? (
                                            <div className="invalid-feedback">{formik.errors.last_name}</div>
                                        ) : null}
                                    </div>

                                    <div className="col-lg-2 col-md-2">
                                        <label htmlFor="mobile_1" className="col-form-label">Contact<span style={{ color: "red" }}>*</span> :</label>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <input
                                            type="text"
                                            className={`form-control shadow-sm ${formik.touched.mobile_1 && formik.errors.mobile_1 ? "is-invalid" : ""
                                                }`}
                                            id="mobile_1"
                                            placeholder="Enter Contact"
                                            name="mobile_1"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.mobile_1}
                                        />
                                        {formik.touched.mobile_1 && formik.errors.mobile_1 ? (
                                            <div className="invalid-feedback">{formik.errors.mobile_1}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <div className="col-lg-2 col-md-2">
                                        <label htmlFor="date_of_joining" className="col-form-label">Date of Joining<span style={{ color: "red" }}>*</span> :</label>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <input
                                            type="date"
                                            className={`form-control shadow-sm ${formik.touched.date_of_joining && formik.errors.date_of_joining ? "is-invalid" : ""
                                                }`}
                                            id="date_of_joining"
                                            name="date_of_joining"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.date_of_joining}
                                            max={getCurrentDate()}
                                        />
                                        {formik.touched.date_of_joining && formik.errors.date_of_joining ? (
                                            <div className="invalid-feedback">{formik.errors.date_of_joining}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-lg-2 col-md-2">
                                        <label htmlFor="category" className="col-form-label">Designation<span style={{ color: "red" }}>*</span> :</label>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <select

                                            className={`form-control form-select shadow-sm ${formik.touched.category && formik.errors.category ? "is-invalid" : ""
                                                }`}
                                            id="category"

                                            name="category"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.category}
                                        ><option value="">Select Designation</option>
                                            {empCat.map((category: any) => (
                                                <option key={category.id} value={category.id}>
                                                    {category.category_title}
                                                </option>
                                            ))}
                                        </select>
                                        {formik.touched.category && formik.errors.category ? (
                                            <div className="invalid-feedback">{formik.errors.category}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <div className="col-lg-2 col-md-2">
                                        <label htmlFor="salary" className="col-form-label">Salary<span style={{ color: "red" }}>*</span> :</label>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <input type="number" className={`form-control shadow-sm ${formik.touched.salary && formik.errors.salary ? "is-invalid" : ""
                                            }`}
                                            id="salary"
                                            placeholder="Enter Salary"
                                            name="salary"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.salary}
                                        />
                                        {formik.touched.salary && formik.errors.salary ? (
                                            <div className="invalid-feedback">{formik.errors.salary}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-lg-2 col-md-2">
                                        <label htmlFor="mobile_2" className="col-form-label">Alternate Contact:</label>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <input
                                            type="text"
                                            className={`form-control shadow-sm ${formik.touched.mobile_2 && formik.errors.mobile_2 ? "is-invalid" : ""
                                                }`}
                                            id="mobile_2"
                                            placeholder="Enter Mobile 2"
                                            name="mobile_2"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.mobile_2}
                                        />
                                        {formik.touched.mobile_2 && formik.errors.mobile_2 ? (
                                            <div className="invalid-feedback">{formik.errors.mobile_2}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <div className="col-lg-2 col-md-2">
                                        <label htmlFor="date_of_birth" className="col-form-label">Date of Birth:</label>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <input
                                            type="date"
                                            className={`form-control shadow-sm ${formik.touched.date_of_birth && formik.errors.date_of_birth ? "is-invalid" : ""
                                                }`}
                                            id="date_of_birth"
                                            name="date_of_birth"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.date_of_birth}
                                        />
                                        {formik.touched.date_of_birth && formik.errors.date_of_birth ? (
                                            <div className="invalid-feedback">{formik.errors.date_of_birth}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-lg-2 col-md-2">
                                        <label htmlFor="email" className="col-form-label">Email:</label>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <input
                                            type="email"
                                            className={`form-control shadow-sm ${formik.touched.email && formik.errors.email ? "is-invalid" : ""
                                                }`}
                                            id="email"
                                            placeholder="Enter email"
                                            name="email"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email}
                                        />
                                        {formik.touched.email && formik.errors.email ? (
                                            <div className="invalid-feedback">{formik.errors.email}</div>
                                        ) : null}
                                    </div>
                                </div>
                                {/* <div className="mb-3 row">
                                    <label htmlFor="date_of_relieving" className="col-sm-3 col-form-label">Date of Relieving:</label>
                                    <div className="col-sm-8">
                                        <input
                                            type="date"
                                            className={`form-control shadow-sm ${formik.touched.date_of_relieving && formik.errors.date_of_relieving ? "is-invalid" : ""
                                                }`}
                                            id="date_of_relieving"
                                            name="date_of_relieving"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.date_of_relieving}
                                        />
                                        {formik.touched.date_of_relieving && formik.errors.date_of_relieving ? (
                                            <div className="invalid-feedback">{formik.errors.date_of_relieving}</div>
                                        ) : null}
                                    </div>
                                </div> */}




                                <div className="mb-1 mb-xl-3 row">
                                    <div className="col-lg-2 col-md-2">
                                        <label htmlFor="govtid" className="col-form-label">Govt.Id:</label>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <input type="text" className={`form-control shadow-sm ${formik.touched.govt_id && formik.errors.govt_id ? "is-invalid" : ""
                                            }`} id="govtid" placeholder="Enter Aadhar/PAN" name="govt_id" onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.govt_id} />
                                        {formik.touched.govt_id && formik.errors.govt_id ? (
                                            <div className="invalid-feedback">{formik.errors.govt_id}</div>
                                        ) : null}
                                    </div>
                                </div>
                                {/* <div className="mb-3 row">
                                    <label htmlFor="is_active" className="col-sm-3 col-form-label">Status</label>
                                    <div className="col-sm-8">
                                        <select className="form-control form-select shadow-sm" id="is_active" name="is_active" ><option>Active</option>
                                            <option>DeActive</option>
                                        </select>
                                    </div>
                                </div> */}
                                {/* <div className="mb-3 text-center">
                                    <button type="submit" className="btn btn-primary btn-sm">Add</button>

                                </div> */}
                                <div className=" fixed-bottom d-flex mb-5 pb-4 row justify-content-center">
                                    <div className="col-lg-2 col-md-2 col-sm-6">
                                        <button type="submit" className="btn form-control btn-purple btn-md" disabled={loading}>
                                            {loading ? 'Saving...' : 'Add'}
                                        </button>
                                    </div>
                                </div>
                                {/* <b className={status ? "text-success" : "text-danger"}>{msg}</b> */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div >



    </>
}


