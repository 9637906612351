import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { listTransactionReducer } from "../../../reduxData/transactionSlice";
import { Alert, Snackbar } from "@mui/material";
import Select, { components } from "react-select"
import CreatableSelect from 'react-select/creatable'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { listBranchServicesReducer } from "../../../reduxData/branchServicesSlice";
import { listCustomerReducer } from "../../../reduxData/customerSlice";

export default function AddDailyTransaction() {

  const _ = require("lodash")
  const [myId, setMyId] = useState(0);
  const [prevItemType, setPrevItemType] = useState("Service");
  const navigate = useNavigate();
  const currentDate = new Date();
  const formattedDateTime = currentDate.getFullYear() + '-' +
    ('0' + (currentDate.getMonth() + 1)).slice(-2) + '-' +
    ('0' + currentDate.getDate()).slice(-2) + 'T' +
    ('0' + currentDate.getHours()).slice(-2) + ':' +
    ('0' + currentDate.getMinutes()).slice(-2) + ':' +
    ('0' + currentDate.getSeconds()).slice(-2);

  // Format the date string
  const formattedDate = currentDate.getFullYear() + '-' +
    ('0' + (currentDate.getMonth() + 1)).slice(-2) + '-' +
    ('0' + currentDate.getDate()).slice(-2);


  const branchInfo = useSelector((state: any) => state.branchInfo.value);
  const branchServiceInfo = useSelector(
    (state: any) => state.branchServicesInfo.value
  );
  const productInfo = useSelector((state: any) => state.productInfo.value)
  const employeeInfo = useSelector((state: any) => state.employeeInfo.value.filter((data: any) => { return (data.is_active == true && data.date_of_joining <= formattedDate && (data.date_of_relieving >= formattedDate || data.date_of_relieving == null)) }));
  const admin = useSelector((state: any) => state.authUserInfo.value);
  const dispatch = useDispatch();
  const [status, setStatus] = useState();
  const [open, setOpen] = useState(false);
  const [msgs, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const serviceCategoryInfo = useSelector((state: any) => state.serviceCategoryInfo.value)
  const customerInfo = useSelector((state: any) => state.customerInfo.value);


  const AddDailyTransactionValidationSchema = Yup.object().shape({
    employee_id: Yup.string().required("Employee Name is required"),

    // amount_paid: Yup.number().min(0, "Enter Positive Number").required("Amount is required"),
    transaction_date: Yup.string().required("Transaction date is required"),
    // payment_mode: Yup.string().required("Payment Mode is required"),
    item_type: Yup.string().required("Service Type is required"),
    customer_name: Yup.string().when('amount_paid', (amount_paid, schema) => {
      return amount_paid < serviceTotal ? schema.required("Customer Name is required") : schema;
    }),
    customer_mobile: Yup.string().when('amount_paid', (amount_paid, schema) => {
      return amount_paid < serviceTotal ? schema.required("Customer Mobile Number is required") : schema;
    }).matches(/^[0-9]{10}$/, 'Customer Mobile Number must be 10 digits'),

    online_payment: Yup.string().required("Payment Mode is required"),
    cash_payment: Yup.string().required("Payment Mode is required"),
  });


  const customerList = async (id: any) => {
    let query = { branchId: id };
    try {
      const url = `${urls.ALL_CUSTOMER_LIST}`;
      const res = await AuthApiService.SearchApiCallWithQuery(url, query, admin.token);
      if (res.status) {
        dispatch(listCustomerReducer(res.data));
      }
    } catch (error) {
      setMsg("Network Error !");
    }
  }
  const [selectedServices, setSelectedServices] = useState([]);
  const [serviceTotal, setServiceTotal] = useState(0);

  const [selectedPaymentMode, setSelectedPaymentMode] = useState({});
  const [discounts, setDiscounts] = useState({});

  useEffect(() => {
    customerList(admin.saloon_id)
  }, [])


  // const handleInputChange = (d: any, event: any) => {
  //   const val = event.target.value === "" ? 0 : event.target.value;
  //   const oldArray = selectedServices;
  //   const obj = { ...d, charge: parseInt(val) };
  //   const ind = oldArray.findIndex((data: any) => data.id === obj.id);
  //   const newArray = oldArray.toSpliced(ind, 1, obj);
  //   setSelectedServices(_.uniqBy(newArray, (d: any) => d.id));
  //   setServiceTotal(newArray.map((d: any) => d.charge * d.qty).reduce((a, data) => data + a, 0));
  // };

  // const handleInputDelete = (d: any) => {
  //   const oldArray = selectedServices;
  //   const ind = oldArray.findIndex((data: any) => data.id === d.id);
  //   const newArray = oldArray.toSpliced(ind, 1);
  //   setSelectedServices(newArray);
  //   setServiceTotal(serviceTotal - d.charge * d.qty);
  // };

  // const handleQtyInputChange = (d: any, event: any) => {
  //   const val = event.target.value === "" ? 1 : event.target.value;
  //   const oldArray = selectedServices;
  //   const obj = { ...d, qty: parseInt(val) };
  //   const ind = oldArray.findIndex((data: any) => data.id === obj.id);
  //   const newArray = oldArray.toSpliced(ind, 1, obj);
  //   setSelectedServices(_.uniqBy(newArray, (d: any) => d.id));
  //   setServiceTotal(newArray.map((d: any) => d.charge * d.qty).reduce((a, data) => data + a, 0));
  // };

  // // const handleDiscountChange = (d: any, event: any) => {
  // //   const discountPercent = parseFloat(event.target.value);
  // //   const discountedPrice = d.mrp * ((100 - discountPercent) / 100);
  // //   const oldArray = selectedServices;
  // //   const obj = { ...d, discount: discountPercent, charge: discountedPrice };
  // //   const ind = oldArray.findIndex((data: any) => data.id === obj.id);
  // //   const newArray = oldArray.toSpliced(ind, 1, obj);
  // //   setSelectedServices(_.uniqBy(newArray, (d: any) => d.id));
  // //   setServiceTotal(newArray.map((d: any) => d.charge * d.qty).reduce((a, data) => data + a, 0));
  // // };

  // // const handleDiscountedAmountChange = (d: any, event: any) => {
  // //   const discountedAmount = parseFloat(event.target.value);
  // //   const discountPercent = ((d.mrp - discountedAmount) / d.mrp) * 100;
  // //   const oldArray = selectedServices;
  // //   const obj = { ...d, discount: discountPercent, charge: discountedAmount };
  // //   const ind = oldArray.findIndex((data: any) => data.id === obj.id);
  // //   const newArray = oldArray.toSpliced(ind, 1, obj);
  // //   setSelectedServices(_.uniqBy(newArray, (d: any) => d.id));
  // //   setServiceTotal(newArray.map((d: any) => d.charge * d.qty).reduce((a, data) => data + a, 0));
  // // };

  // const handleDiscountChange = (d: any, event: any) => {
  //   let discountPercent = parseFloat(event.target.value);
  //   if (discountPercent < 0 || discountPercent > 100) {
  //     // alert("Discount must be between 0 and 100%");
  //     return;
  //   }
  //   discountPercent = parseFloat(discountPercent.toFixed(2));
  //   const discountedPrice = parseFloat((d.mrp * ((100 - discountPercent) / 100)).toFixed(2));
  //   const oldArray = selectedServices;
  //   const obj = { ...d, discount: discountPercent, charge: discountedPrice };
  //   const ind = oldArray.findIndex((data: any) => data.id === obj.id);
  //   const newArray = oldArray.toSpliced(ind, 1, obj);
  //   setSelectedServices(_.uniqBy(newArray, (d: any) => d.id));
  //   setServiceTotal(newArray.map((d: any) => d.charge * d.qty).reduce((a, data) => data + a, 0));
  // };

  // const handleDiscountedAmountChange = (d: any, event: any) => {
  //   let discountedAmount = parseFloat(event.target.value);
  //   if (discountedAmount < 0 || discountedAmount > d.mrp) {
  //     // alert("Discounted amount must be between 0 and the MRP");
  //     return;
  //   }
  //   discountedAmount = parseFloat(discountedAmount.toFixed(2));
  //   const discountPercent = parseFloat((((d.mrp - discountedAmount) / d.mrp) * 100).toFixed(2));
  //   const oldArray = selectedServices;
  //   const obj = { ...d, discount: discountPercent, charge: discountedAmount };
  //   const ind = oldArray.findIndex((data: any) => data.id === obj.id);
  //   const newArray = oldArray.toSpliced(ind, 1, obj);
  //   setSelectedServices(_.uniqBy(newArray, (d: any) => d.id));
  //   setServiceTotal(newArray.map((d: any) => d.charge * d.qty).reduce((a, data) => data + a, 0));
  // };


  const handleInputChange = (d: any, event: any) => {
    const val = event.target.value === "" ? 0 : event.target.value;
    const oldArray = selectedServices;
    const obj = { ...d, charge: parseFloat(val) };
    const ind = oldArray.findIndex((data: any) => data.id === obj.id);
    const newArray = oldArray.toSpliced(ind, 1, obj);
    setSelectedServices(_.uniqBy(newArray, (d: any) => d.id));
    setServiceTotal(
      newArray
        .map((d: any) => d.charge * d.qty)
        .reduce((a, data) => data + a, 0)
    );
    formik.setFieldValue("cash_payment", 0);
    formik.setFieldValue("online_payment", 0);
    formik.setFieldValue("amount_paid", 0);
  };

  const handleInputDelete = (d: any) => {
    const oldArray = selectedServices;
    const ind = oldArray.findIndex((data: any) => data.id === d.id);
    const newArray = oldArray.toSpliced(ind, 1);
    setSelectedServices(newArray);
    setServiceTotal(
      newArray.map((d: any) => d.charge * d.qty).reduce((a, data) => data + a, 0)
    );
    formik.setFieldValue("cash_payment", 0);
    formik.setFieldValue("online_payment", 0);
    formik.setFieldValue("amount_paid", 0);
  };

  const handleQtyInputChange = (d: any, event: any) => {
    const val = event.target.value === "" ? 1 : event.target.value;
    const oldArray = selectedServices;
    const obj = { ...d, qty: parseInt(val) };
    const ind = oldArray.findIndex((data: any) => data.id === obj.id);
    const newArray = oldArray.toSpliced(ind, 1, obj);
    setSelectedServices(_.uniqBy(newArray, (d: any) => d.id));
    setServiceTotal(
      newArray
        .map((d: any) => d.charge * d.qty)
        .reduce((a, data) => data + a, 0)
    );
  };


  // const handleDiscountChange = (d: any, event: any) => {
  //   let discountPercent = parseFloat(event.target.value);
  //   if (discountPercent < 0 || discountPercent > 100) {
  //     // alert("Discount must be between 0 and 100%");
  //     return;
  //   }
  //   discountPercent = parseFloat(discountPercent.toFixed(2));
  //   const discountedPrice = parseFloat(
  //     (d.mrp * ((100 - discountPercent) / 100)).toFixed(2)
  //   );
  //   const newArray = [...selectedServices]; // Make a copy of selectedServices array
  //   const obj = { ...d, discount: discountPercent, charge: discountedPrice };
  //   const ind = newArray.findIndex((data: any) => data.id === obj.id);
  //   newArray.splice(ind, 1, obj); // Replace the element at index ind with obj
  //   setSelectedServices(_.uniqBy(newArray, (d: any) => d.id)); // Update selectedServices with the updated newArray
  //   setServiceTotal(
  //     newArray
  //       .map((d: any) => d.charge * d.qty)
  //       .reduce((a, data) => data + a, 0)
  //   );
  // };

  // const handleDiscountedAmountChange = (d: any, event: any) => {
  //   let discountedAmount = parseFloat(event.target.value) || 0;
  //   if (discountedAmount < 0 || discountedAmount > d.mrp) {
  //     // alert("Discounted amount must be between 0 and the MRP");
  //     return;
  //   }
  //   discountedAmount = parseFloat(discountedAmount.toFixed(2));
  //   const discountPercent = parseFloat(
  //     (((d.mrp - discountedAmount) / d.mrp) * 100).toFixed(2)
  //   );
  //   const newArray = [...selectedServices]; // Make a copy of selectedServices array
  //   const obj = { ...d, discount: discountPercent, charge: discountedAmount };
  //   const ind = newArray.findIndex((data: any) => data.id === obj.id);
  //   newArray.splice(ind, 1, obj); // Replace the element at index ind with obj
  //   setSelectedServices(_.uniqBy(newArray, (d: any) => d.id)); // Update selectedServices with the updated newArray
  //   setServiceTotal(
  //     newArray
  //       .map((d: any) => d.charge * d.qty)
  //       .reduce((a, data) => data + a, 0)
  //   );
  // };
  const handleMrpChange = (d: any, event: React.ChangeEvent<HTMLInputElement>) => {
    let mrp = event.target.value;
    console.log("MRP Input Value", mrp);

    if (mrp === '' || isNaN(parseFloat(mrp))) {
      mrp = 0;
    } else {
      mrp = parseFloat(mrp);
      if (mrp < 0) {
        return; // Prevent negative MRP values
      }
    }

    // Calculate discounted price based on the new MRP and existing discount
    const discountPercent = d.discount || 0;
    const discountedPrice = (mrp * ((100 - discountPercent) / 100)).toFixed(2);
    console.log("Discounted Price", discountedPrice);

    const newArray = [...selectedServices];
    const updatedService = { ...d, mrp, charge: discountedPrice };
    const index = newArray.findIndex((data: any) => data.id === updatedService.id);

    if (index > -1) {
      newArray.splice(index, 1, updatedService);
    }

    setSelectedServices(_.uniqBy(newArray, (item: any) => item.id));

    const total = newArray
      .map((item: any) => item.charge * item.qty)
      .reduce((acc, curr) => acc + curr, 0);
    setServiceTotal(total);

    console.log("Updated Service Total", total);
  };
  const handleDiscountChange = (d: any, event: React.ChangeEvent<HTMLInputElement>) => {
    let discountPercent = event.target.value;

    if (discountPercent === '' || isNaN(parseFloat(discountPercent))) {
      discountPercent = 0;
    } else {
      discountPercent = parseFloat(discountPercent);
      if (discountPercent < 0 || discountPercent > 100) {
        return;
      }
      discountPercent = parseFloat(discountPercent); // Limit to two decimal places
    }

    const discountedPrice = parseFloat((d.mrp * ((100 - discountPercent) / 100))).toFixed(2);
    // const newArray = selectedServices.map(service =>
    //   service.id === d.id ? { ...service, discount: discountPercent, charge: discountedPrice } : service
    // );
    // setSelectedServices(newArray);
    // updateServiceTotal(newArray);
    const newArray = [...selectedServices]; // Make a copy of selectedServices array
    const obj = { ...d, discount: discountPercent, charge: discountedPrice };
    const ind = newArray.findIndex((data: any) => data.id === obj.id);
    newArray.splice(ind, 1, obj); // Replace the element at index ind with obj
    setSelectedServices(_.uniqBy(newArray, (d: any) => d.id)); // Update selectedServices with the updated newArray
    setServiceTotal(
      newArray
        .map((d: any) => d.charge * d.qty)
        .reduce((a, data) => data + a, 0)
    );
  };

  const handleDiscountedAmountChange = (d: any, event: React.ChangeEvent<HTMLInputElement>) => {
    let discountedAmount = event.target.value;

    if (discountedAmount === '' || isNaN(parseFloat(discountedAmount))) {
      discountedAmount = 0;
    } else {
      discountedAmount = parseFloat(discountedAmount);
      if (discountedAmount < 0 || discountedAmount > d.mrp) {
        return;
      }
      discountedAmount = parseFloat(discountedAmount);
    }

    const discountPercent = parseFloat((((d.mrp - discountedAmount) / d.mrp) * 100)).toFixed(2);

    // const newArray = selectedServices.map(service =>
    //   service.id === d.id ? { ...service, discount: discountPercent, charge: discountedAmount } : service
    // );
    // setSelectedServices(newArray);
    // updateServiceTotal(newArray);
    const newArray = [...selectedServices];
    const obj = { ...d, discount: discountPercent, charge: discountedAmount };
    const ind = newArray.findIndex((data: any) => data.id === obj.id);
    newArray.splice(ind, 1, obj);
    setSelectedServices(_.uniqBy(newArray, (d: any) => d.id));
    setServiceTotal(
      newArray
        .map((d: any) => d.charge * d.qty)
        .reduce((a, data) => data + a, 0)
    );
  };


  const employeeOptions = employeeInfo.map((item: any) => ({
    value: item.id,
    label: item.first_name + " " + item.last_name,
  }));

  // Service and product options with "Others" at the top
  const serviceOptions = branchServiceInfo?.map((item: any) => ({
    value: item.serviceMasterInfo.id,
    label: item.serviceMasterInfo.service_title,
  }));

  const productOptions = productInfo?.map((item: any) => ({
    value: item.id,
    label: item.product_name,
  }));

  const othersServiceOption = serviceOptions.find((option: any) => option.label === "Others");
  const othersProductOption = productOptions.find((option: any) => option.label === "Others");

  if (othersServiceOption) {
    serviceOptions.splice(serviceOptions.indexOf(othersServiceOption), 1);
    serviceOptions.unshift(othersServiceOption);
  }

  if (othersProductOption) {
    productOptions.splice(productOptions.indexOf(othersProductOption), 1);
    productOptions.unshift(othersProductOption);
  }

  const defaultServiceOption = serviceOptions.find((option: any) => option.label === "Others");
  const defaultProductOption = productOptions.find((option: any) => option.label === "Others");


  const customerOptions = customerInfo?.data?.map((item: any) => ({
    value: item.id,
    label: item.name
  }));

  const formik = useFormik({

    initialValues: {
      employee_id: "",
      transaction_date: formattedDateTime,
      date_only: formattedDate,
      item_type: "Service",
      amount_paid: 0,
      customer_name: "",
      customer_mobile: "",
      customer_id: "",
      cash_payment: 0,
      online_payment: 0,
    },
    // validationSchema: (values: any) => values?.item_type === "Service" ? AddDailyTransactionValidationSchema : AddDailyTransactionValidationSchemaProduct,
    validationSchema: () => {
      if (formik.values.item_type === "Service") {
        return AddDailyTransactionValidationSchema;
      } else {
        return Yup.object().shape({
          transaction_date: Yup.string().required("Transaction date is required"),
          item_type: Yup.string().required("Service Type is required"),
          customer_name: Yup.string().when('amount_paid', (amount_paid, schema) => {
            return amount_paid < serviceTotal ? schema.required("Customer Name is required") : schema;
          }),
          customer_mobile: Yup.string().when('amount_paid', (amount_paid, schema) => {
            return amount_paid < serviceTotal ? schema.required("Customer Mobile Number is required") : schema;
          }).matches(/^[0-9]{10}$/, 'Customer Mobile Number must be 10 digits'),

          online_payment: Yup.string().required("Payment Mode is required"),
          cash_payment: Yup.string().required("Payment Mode is required"),
        });
      }
    },
    onSubmit: async (values) => {
      // alert("hello")

      try {
        // alert(values)
        setLoading(true);
        let ob = {}
        if (values.item_type === "Service") {
          ob = {
            branch_id: branchInfo[0].id,
            employee_id: values.employee_id,
            amount: serviceTotal,
            transaction_date: values.transaction_date,
            customer_id: values.customer_id,
            services_id: selectedServices?.filter(d => d?.service_id).map(d => ({ id: d?.service_id, amount: d?.mrp, quantity: d?.qty, discount_percent: d?.discount })),
            products_id: [],
            payment_mode: {
              Cash: values.cash_payment,
              Online: values.online_payment
            },
            amount_paid: values.amount_paid,
            remark: "",
            customer_name: values.customer_name,
            customer_mobile: values.customer_mobile,
            created_by: admin.uid,
            updated_by: admin.uid,
          };
        } else {
          ob = {
            branch_id: branchInfo[0].id,
            employee_id: null,
            amount: serviceTotal,
            transaction_date: values.transaction_date,
            customer_id: values.customer_id,
            services_id: [],
            products_id: selectedServices?.filter(d => d?.product_id).map(d => ({ id: d?.product_id, amount: d?.mrp, quantity: d?.qty, discount_percent: d?.discount })),
            payment_mode: {
              Cash: values.cash_payment,
              Online: values.online_payment
            },
            amount_paid: values.amount_paid,
            remark: "",
            customer_name: values.customer_name,
            customer_mobile: values.customer_mobile,
            created_by: admin.uid,
            updated_by: admin.uid,
          };
        }
        const resp = await AuthApiService.SaveApiCall(
          urls.TRANSACTION_SAVE,
          ob,
          admin.token
        );
        if (resp.status === 200 || resp.status === 201) {
          setLoading(false);
          formik.resetForm();
          setMsg(resp.data.msg);
          handleClickOpen();
          setStatus(resp.data.status)
          // dispatch(addTransactionReducer(ob));
          dispatch(listTransactionReducer(ob));
          setTimeout(() => {
            navigate("/branchManager/dailytransactionslist")
          }, 1000);
        } else {
          if (resp.data && resp.data.err) {
            setMsg(resp.data.err);
            setStatus(resp.data.status)
          } else {
            setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
            setStatus(resp.data.status)
          }
        }
      }
      catch (error: any) {
        setLoading(false);
        setMsg(error.response.data.msg);
        setStatus(error.response.status);
        handleClickOpen()
      }
    },
  });
  const dueAmount = serviceTotal - (formik.values.amount_paid || 0);
  const handleDateChange = (e: any) => {
    const dateValue = e.target.value;
    formik.setFieldValue("date_only", dateValue);
    const dateTimeValue = dateValue + "T" + ('0' + currentDate.getHours()).slice(-2) + ':' + ('0' + currentDate.getMinutes()).slice(-2) + ':' + ('0' + currentDate.getSeconds()).slice(-2);
    formik.setFieldValue("transaction_date", dateTimeValue);
  };

  const handleItemTypeChange = () => {
    // Clear selected services or products when item type changes
    setSelectedServices([]);
    setServiceTotal(0);
    formik.setFieldValue("cash_payment", 0);
    formik.setFieldValue("online_payment", 0);
    formik.setFieldValue("amount_paid", 0);


  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAmount = (e: any) => {
    formik.handleChange(e);
    const ch = branchServiceInfo.filter((d: any) => d.id == e.target.value).map((da: any) => da.service_charge)[0]
    formik.setFieldValue("amount", ch)
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msgs}</Alert>
      </Snackbar>
      <div className="container-fluid p-0">
        <div className="container">

          <div className="row pr-xl-5 pl-xl-5">
            <div className="col-lg-10 col-md-8 col-sm-8 d-flex justify-content-center">
              <div className="text-center">
                <h4>Add Daily Transaction</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4 mt-2 d-flex pr-2 justify-content-end">
              <Link to="/branchManager/dailytransactionslist">
                <span>
                  <button className=" btn btn-purple btn-sm">
                    All Daily Transaction
                  </button>
                </span>
              </Link>
            </div>

          </div>
        </div>
        <div className="p-1 px-xl-2 py-xl-2 mt-xl-4 mt-0 justify-content-center">
          <div className="container">
            <div className="row justify-content-center mt-3">
              <div className="col-lg-12">
                <form onSubmit={formik.handleSubmit}>
                  {/* staff and date */}
                  <div className="mb-1 mb-xl-3 row">

                    <label
                      htmlFor="transaction_date"
                      className="col-sm-3 col-form-label"
                    >
                      Transaction Date<span style={{ color: "red" }}>*</span> :
                    </label>
                    <div className="col-sm-2">
                      <input
                        type="date"
                        className="form-control"
                        name="date_only"
                        onChange={handleDateChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.date_only}
                        readOnly
                      />
                      <input
                        type="hidden"

                        placeholder="Enter Transaction Date"
                        id="transaction_date"
                        name="transaction_date"
                        className={`form-control shadow-sm  ${formik.touched.transaction_date &&
                          formik.errors.transaction_date
                          ? "is-invalid"
                          : ""
                          }`}
                        min={employeeInfo.filter((data: any) => data.id == formik.values.employee_id).map((data: any) => data.date_of_joining.substring(0, 10))}
                        max={employeeInfo.filter((data: any) => data.id == formik.values.employee_id).map((data: any) => data.date_of_relieving?.substring(0, 10) == null ? undefined : data.date_of_relieving?.substring(0, 10))}

                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.transaction_date}
                        readOnly
                      />
                      <div className="invalid-feedback">
                        {formik.touched.transaction_date &&
                          typeof formik.errors.transaction_date === "string"
                          ? formik.errors.transaction_date
                          : null}
                      </div>
                    </div>
                    {/* Service and product select */}
                    <div className="pe-5 form-check-reverse m-0 col-xl-2 col-lg-2 col-md-2 col-4">
                      <input
                        onChange={(e) => {
                          formik.handleChange(e);
                          handleItemTypeChange(); // Call this function when item type changes
                        }}
                        onBlur={formik.handleBlur}
                        // value={formik.values.payment_mode}
                        className="form-check-input"
                        type="radio"
                        name="item_type"
                        id="flexRadioDefault1"
                        value="Service"
                        checked={formik.values.item_type === "Service"}
                      />
                      <label
                        className="form-check-label pe-1 "
                        htmlFor="flexRadioDefault1"
                      >
                        Service
                      </label>
                    </div>
                    {/* product */}
                    <div className="pe-5 form-check-reverse col-xl-2 col-lg-2 col-md-2 col-2">

                      <input
                        onChange={(e) => {
                          formik.handleChange(e);
                          handleItemTypeChange(); // Call this function when item type changes
                        }}
                        onBlur={formik.handleBlur}
                        // value={formik.values.payment_mode}
                        className="form-check-input"
                        type="radio"
                        name="item_type"
                        id="flexRadioDefault2"
                        value="Product"
                        checked={formik.values.item_type === "Product"}
                      />
                      <label
                        className="pe-1 mr-0 form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        Product
                      </label>
                      {/* <div className="invalid-feedback">
                        {formik.touched.item_type && formik.errors.item_type ? (
                          <div className="invalid-feedback">{formik.errors.item_type}</div>
                        ) : null}
                      </div> */}
                    </div>

                    {/* </div> */}
                  </div>
                  {formik.values.item_type == "Service" ? <div className="mb-1 mb-xl-3 row">
                    <label
                      htmlFor="employee_id"
                      className="col-sm-3  col-form-label"
                    >
                      Staff<span style={{ color: "red" }}>*</span> :
                    </label>
                    <div className="col-sm-6">
                      <Select
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        name="employee_id"
                        id="employee_id"
                        className={`react-select-container ${formik.touched.employee_id && formik.errors.employee_id ? "is-invalid" : ""}`}
                        classNamePrefix="select"
                        placeholder="Select employee"
                        isLoading={employeeInfo.length <= 0 ? true : false}
                        // isClearable={true}
                        isSearchable={true}
                        options={employeeOptions}
                        value={employeeOptions.find((option: any) => option.value === formik.values.employee_id) || undefined}
                        onChange={(selectedOption) => formik.setFieldValue("employee_id", selectedOption.value)}
                        onBlur={formik.handleBlur}
                        filterOption={(option, inputValue) =>
                          option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                        }
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            borderColor: state.isFocused ? '#80bdff' : state.menuIsOpen ? '#80bdff' : (formik.touched.employee_id && formik.errors.employee_id) ? '#dc3545' : '#ced4da',
                            boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : state.menuIsOpen ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : state.isValid ? null : 'none',
                          }),
                          menuPortal: base => ({ ...base, zIndex: 9999 })
                        }}
                        backspaceRemovesValue={false}
                      />
                      <div className="invalid-feedback">{formik.touched.employee_id && typeof formik.errors.employee_id === 'string' ? (formik.errors.employee_id) : null}</div>
                    </div>
                    {/* </div> */}
                  </div> : <div></div>}
                  {/* service and product */}

                  {/* service and product select dropdown */}
                  <div className="mb-1 mb-xl-3 row">
                    <label htmlFor="item_type" className="col-sm-3 col-form-label">{formik.values.item_type == "Service" ? "Service" : "Product"}<span style={{ color: "red" }}>*</span> :</label>
                    <div className="col-sm-6">
                      {formik.values.item_type == "Service" ? <><Select
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        // menuIsOpen={true}
                        // maxMenuHeight="15rem"
                        className={`react-select-container ${formik.touched.item_type && formik.errors.item_type ? "is-invalid" : ""}`}
                        options={serviceOptions}
                        isSearchable
                        placeholder="Select Service Name"
                        defaultValue={defaultServiceOption}
                        onChange={(selectedOption) => {
                          let ser = branchServiceInfo.filter((d: any) => d.serviceMasterInfo.id == selectedOption.value).map((d: any) => ({ id: myId, charge: d.service_charge, service_id: d.id, item: d.serviceMasterInfo.service_title, qty: 1, mrp: d.service_charge }));
                          setMyId(myId + 1);
                          // console.log("ser", ser)
                          setSelectedServices(!selectedServices.map((d: any) => d.service_id).includes(ser[0].service_id) ? [...selectedServices, ...ser] : selectedServices)
                          setServiceTotal(!selectedServices.map((d: any) => d.service_id).includes(ser[0].service_id) ? serviceTotal + (ser[0].charge * ser[0].qty) : serviceTotal);
                          // console.log("selectedServices", selectedServices)
                        }

                        }
                        onBlur={formik.handleBlur}
                      /> <div className="invalid-feedback">
                          {formik.touched.item_type && formik.errors.item_type ? (
                            <div className="invalid-feedback">{formik.errors.item_type}</div>
                          ) : null}
                        </div></> : <>
                        <Select
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          menuPortalTarget={document.body}
                          menuPosition="fixed"
                          // menuIsOpen={true}
                          // maxMenuHeight="15rem"
                          id="service"
                          name="service"
                          className={`react-select-container   ${formik.touched.item_type &&
                            formik.errors.item_type
                            ? "is-invalid"
                            : ""
                            }`}
                          options={productOptions}
                          defaultValue={defaultProductOption}
                          isSearchable
                          placeholder="Select Product"
                          onChange={(selectedOption) => {
                            let pro = productInfo.filter((d: any) => d.id == selectedOption.value).map(d => ({ id: myId, item: d.product_name, qty: d.qty, charge: d.sale_price, product_id: d.id, mrp: d.sale_price }));
                            setMyId(myId + 1);
                            // console.log(pro)
                            // console.log("selectedProduct", selectedServices)
                            // console.log(pro[0].id)
                            setSelectedServices(!selectedServices.map((d: any) => d.product_id).includes(pro[0].product_id) ? [...selectedServices, ...pro] : selectedServices)
                            setServiceTotal(!selectedServices.map((d: any) => d.product_id).includes(pro[0].product_id) ? serviceTotal + (pro[0].charge * pro[0].qty) : serviceTotal);

                          }
                          }
                          onBlur={formik.handleBlur}
                        />
                        <div className="invalid-feedback">
                          {formik.touched.item_type && formik.errors.item_type ? (<div className="invalid-feedback">{formik.errors.item_type}</div>) : null}
                        </div></>
                      }
                      {/* <div className="invalid-feedback">
                        {formik.touched.item_type && formik.errors.item_type ? (
                          <div className="invalid-feedback">{formik.errors.item_type}</div>
                        ) : null}
                      </div> */}
                    </div>

                    {/* </div> */}
                  </div>

                  {/* selected service and product */}

                  {!selectedServices.length == 0 ? <div className="mb-1 mb-xl-3 row">

                    <label htmlFor="" className="col-sm-3 col-form-label"></label>
                    <div className="col-sm-6  card rounded-1">
                      <label className="col-form-label ps-2" > Selected {formik.values.item_type == "Service" ? "Service" : "Product"}:  </label>
                      <div className="table-responsive" style={{ height: "" }}>
                        <table className="table table-bordered" id="report-table">
                          <thead>
                            <tr>
                              <th>
                                {formik.values.item_type == "Service" ? "Service" : "Product"}
                              </th>
                              <th>
                                MRP
                              </th>
                              {formik.values.item_type == "Service" ?
                                "" : <th>Qty</th>}
                              <th>Discount(%)</th>
                              <th>
                                Amount({admin.currency})
                              </th>
                            </tr>
                          </thead>
                          <tbody>

                            {(selectedServices.map((d: any) => (
                              <tr key={d.id}>
                                <td>{d.item}</td>
                                <td><input style={{ "width": "60px" }}
                                    type="number"

                                    className="form-control shadow-sm d-inline "
                                    name=""
                                    id=""
                                    min={0}

                                    value={d?.mrp != null ? d?.mrp : 0}
                                    onChange={(event) => handleMrpChange(d, event)}
                                  /></td>
                                {formik.values.item_type == "Service" ? (
                                  ""
                                ) : (
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control shadow-sm"
                                      disabled={d.service_id}
                                      name=""
                                      id=""
                                      min={1}
                                      value={d.qty}
                                      onChange={(event) => handleQtyInputChange(d, event)}
                                    />
                                  </td>
                                )}
                                <td width={20}>
                                  <input style={{ "width": "60px" }}
                                    type="number"
                                    step="0.01"
                                    className="form-control shadow-sm d-inline "
                                    name={`discount_${d.id}`}
                                    id={`discount_${d.id}`}
                                    min={0}
                                    max={100}
                                    value={d?.discount != null ? d?.discount : 0}
                                    onChange={(event) => handleDiscountChange(d, event)}
                                  />&nbsp;%
                                </td>
                                <td >
                                  <input
                                    type="number"
                                    step="0.01"
                                    className="form-control shadow-sm"
                                    name=""
                                    id=""
                                    min={0}
                                    max={d?.mrp}
                                    value={d?.charge}
                                    onChange={(event) => handleDiscountedAmountChange(d, event)}
                                  />
                                </td>
                                <td>
                                  <FontAwesomeIcon
                                    icon={faXmark}
                                    color="red"
                                    cursor="pointer"
                                    onClick={() => handleInputDelete(d)}
                                  />
                                </td>
                              </tr>
                            )))}

                          </tbody>
                          <tfoot className="align-self-end">
                            <tr>
                              <th colSpan={6}>
                                Total({admin.currency}) - {serviceTotal || 0}
                              </th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    {/* </div> */}

                  </div> : ""}

                  {/* Payment Mode */}
                  <div className="mb-1 mb-xl-3 row">
                    <label htmlFor="payment_mode" className="col-sm-3 col-form-label">Payment Received<span style={{ color: "red" }}>*</span>:</label>
                    <div className="col-sm-6 d-flex">
                      {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6"> */}
                      <label className="form-check-label col-sm-2" htmlFor="cash_payment">
                        Cash
                      </label>
                      <div className="col-sm-3">

                        <input
                          type="number"
                          step="0.01"
                          id="cash_payment"
                          name="cash_payment"
                          className={`form-control shadow-sm ${formik.touched.cash_payment && formik.errors.cash_payment ? "is-invalid" : ""}`}
                          onChange={(e) => {
                            formik.handleChange(e);
                            const cashAmount = parseFloat(e.target.value);
                            const onlineAmount = parseFloat(formik.values.online_payment || 0);
                            formik.setFieldValue('amount_paid', cashAmount + onlineAmount);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.cash_payment}
                          min="0"
                        />
                        <div className="invalid-feedback">
                          {formik.touched.cash_payment && typeof formik.errors.cash_payment === 'string' ? (formik.errors.cash_payment) : null}
                        </div>
                      </div>
                      <div className="col-sm-1">
                      </div>
                      {/* Add the online payment input field similarly */}
                      {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6"> */}
                      <label className="form-check-label col-sm-2" htmlFor="online_payment">
                        Online
                      </label>
                      <div className="col-sm-3">
                        <input
                          type="number"
                          step="0.01"
                          id="online_payment"
                          name="online_payment"
                          className={`form-control shadow-sm ${formik.touched.online_payment && formik.errors.online_payment ? "is-invalid" : ""}`}
                          onChange={(e) => {
                            formik.handleChange(e);
                            const cashAmount = parseFloat(formik.values.cash_payment || 0);
                            const onlineAmount = parseFloat(e.target.value);
                            formik.setFieldValue('amount_paid', cashAmount + onlineAmount);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.online_payment}
                          min="0"
                        />
                        <div className="invalid-feedback">
                          {formik.touched.online_payment && typeof formik.errors.online_payment === 'string' ? (formik.errors.online_payment) : null}
                        </div>
                      </div>
                    </div>
                  </div>



                  {/* amount paid */}
                  <div className="mb-1 mb-xl-3 row">
                    <label htmlFor="service_charge" className="col-sm-3 col-form-label"></label>
                    <div className="col-sm-3 col-lg-3 col-6">
                      <label htmlFor="amount_paid" className="col-form-label" >
                        Total({admin.currency})<span style={{ color: "red" }}>*</span> :
                      </label>
                      {/* Display the total amount paid in a <span> tag */}
                      <span>{formik.values.amount_paid || 0}</span>

                    </div>

                    {/* due amount */}
                    <div className="col-sm-3 col-lg-3 col-6 p-0">
                      <label htmlFor="due_amount" className="col-form-label">
                        Due({admin.currency}):
                      </label>
                      {/* Calculate and display the due amount */}
                      {/* <span>{(formik.values.amount_paid || 0) && serviceTotal - (formik.values.amount_paid || 0)}</span> */}
                      <span>{dueAmount}</span>
                    </div>
                  </div>
                  {/* Customer */}
                  <div className="mb-1 mb-xl-3 row">
                    <label htmlFor="customer_name" className="col-sm-3 col-form-label">Customer Details:</label>
                    <div className="col-sm-7 d-flex ">
                      <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-5">
                        <label htmlFor="customer_name" className=" col-form-label" >
                          Name:
                        </label>
                        <CreatableSelect
                          menuPortalTarget={document.body}
                          menuPosition="fixed"
                          name="customer_name"
                          id="customer_name"
                          className={`react-select-container ${formik.touched.customer_name && formik.errors.customer_name ? "is-invalid" : ""}`}
                          classNamePrefix="select"
                          placeholder="Select Customer"
                          isClearable={true}
                          isSearchable={true}
                          options={customerOptions}
                          formatCreateLabel={(input) => <div><span>Add Customer: </span><span>{input}</span></div>}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              borderColor: state.isFocused ? '#80bdff' : state.menuIsOpen ? '#80bdff' : (formik.touched.customer_name && formik.errors.customer_name) ? '#dc3545' : '#ced4da',
                              boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : state.menuIsOpen ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : state.isValid ? null : 'none',
                            }),
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                          }}
                          onChange={async (selectedOption, ActionTypes) => {
                            if (ActionTypes.action == "select-option") {
                              const url = `${urls.CUSTOMER_LIST_BY_SEARCH}/1/10`;
                              const data = {
                                nameChars: selectedOption.label
                              }
                              const res = await AuthApiService.SearchApiCallWithQuery(url, data, admin.token);
                              if (res.status == 200 || res.data.status == 200) {
                                const customer = res.data.data.find((e: any) => e.id == selectedOption.value)
                                formik.setFieldValue("customer_mobile", customer.mobile)
                                formik.setFieldValue("customer_id", customer.id);
                                formik.setFieldValue("customer_name", customer.name)
                              }
                            }
                            else if (ActionTypes.action == "clear") {
                              formik.setFieldValue("customer_mobile", "")
                              formik.setFieldValue("customer_id", "");
                              formik.setFieldValue("customer_name", "")
                            }
                            else {
                              formik.setFieldValue("customer_name", selectedOption.label)
                            }
                          }}
                          onBlur={formik.handleBlur}
                          backspaceRemovesValue={false}
                        />
                        <div className="invalid-feedback">{formik.touched.customer_name && formik.errors.customer_name ? formik.errors.customer_name : null}</div>
                      </div>
                      <div className="form-check col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-5">
                        <label
                          htmlFor="customer_mobile"
                          className=" col-form-label"
                        >
                          Mobile:
                        </label>
                        <input
                          type="text"
                          placeholder="Eg. +91-XXXXX-XXXXX"
                          id="customer_mobile"
                          name="customer_mobile"
                          className={`form-control shadow-sm  ${formik.touched.customer_mobile &&
                            formik.errors.customer_mobile
                            ? "is-invalid"
                            : ""
                            }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.customer_mobile}
                        />
                        <div className="invalid-feedback">
                          {formik.touched.customer_mobile &&
                            typeof formik.errors.customer_mobile === "string"
                            ? formik.errors.customer_mobile
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="bottom-0 col-lg-2 col-md-2 col-sm-6">
                      <button type="submit" className="btn form-control btn-purple btn-md" disabled={loading}>
                        {loading ? 'Saving...' : 'Add'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div >
        </div >
      </div >
    </>
  );
}
