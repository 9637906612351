import React, { useRef, useState, FormEvent } from 'react';
import { Link } from 'react-router-dom';
import AuthApiService, { urls } from '../apiServices/AuthApiService';

export default function Register() {
  const [msg, setMsg] = useState('');

  const firstNameBox = useRef<HTMLInputElement>(null);
  const lastNameBox = useRef<HTMLInputElement>(null);
  const mobileBox = useRef<HTMLInputElement>(null);
  const emailBox = useRef<HTMLInputElement>(null);
  const passwordBox = useRef<HTMLInputElement>(null);

  const handleRegister = async (event: FormEvent) => {
    event.preventDefault();
    setMsg('');

    const ob = {
      password: passwordBox.current?.value,
      email: emailBox.current?.value,
      first_name: firstNameBox.current?.value,
      last_name: lastNameBox.current?.value,
      mobile_number: mobileBox.current?.value,
      username: mobileBox.current?.value,
      isActive: true,
    };

    try {
      const response = await AuthApiService.PostApiCall(urls.REGISTER, ob);

      if (response.data.status) {
        console.log(response);
        setMsg(response.data.msg);
      } else {
        console.log(response);
        setMsg(response.data.msg);
      }
    } catch (error) {
      const errorMessage: string = (error as any)?.response?.data?.msg || 'An error occurred. Please try again.';
      setMsg(errorMessage);
    }
  };

  return (
    <div className="login-page">
      <div className="container-fluid ">
        <div className="row w-100  mx-0">
          <div className="col-lg-4 mx-auto">
            <div className="card border-0 py-5 px-4 px-sm-5 mt-5">
              <div className="brand-logo text-center">
                {/* <h2>HAIRSALOON</h2> */}
                <Link className="navbar-brand brand-logo " to=""><img src="images/Saloonist_Logo/newLogo.png" className="img-fluid" alt="logo" /></Link>
              </div>
              <p className="font-weight-light text-center">Sign up here...</p>
              <form onSubmit={handleRegister} className="pt-2">
                <div className="form-group mb-3">
                  <input type="text" ref={firstNameBox} className="form-control" id="userName" placeholder="Your Name" />
                </div>
                <div className="form-group mb-3">
                  <input type="text" ref={lastNameBox} className="form-control" id="lastName" placeholder="Saloon Name" />
                </div>
                <div className="form-group mb-3">
                  <input type="text" ref={mobileBox} className="form-control " id="mobile" placeholder="Mobile" />
                </div>
                {/* <div className="form-group mb-3">
                  <input type="email" ref={emailBox} className="form-control" id="email" placeholder="Email" />
                </div> */}
                <div className="form-group mb-3">
                  <input type="password" ref={passwordBox} className="form-control " id="password" placeholder="Password" />
                </div>

                <div className="mt-3">
                  <button type="submit" className="btn btn-purple form-control">
                    SIGN UP
                  </button>
                </div>
                <div className="text-center mt-2 font-weight-light">
                  Already have an account? <Link to="/login" className="text-primary">Login</Link>
                </div>
                <b className="text-danger">{msg}</b>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


// import React, { useRef } from 'react';
// import { Link } from 'react-router-dom';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import AuthApiService, { urls } from '../apiServices/AuthApiService';

// const RegisterSchema = Yup.object().shape({
//   firstName: Yup.string().required('First Name is required'),
//   lastName: Yup.string().required('Last Name is required'),
//   mobile: Yup.string().required('Mobile is required'),
//   email: Yup.string().email('Invalid email address').required('Email is required'),
//   password: Yup.string().required('Password is required'),
// });

// export default function Register() {
//   const firstNameBox = useRef<HTMLInputElement>(null);
//   const lastNameBox = useRef<HTMLInputElement>(null);
//   const mobileBox = useRef<HTMLInputElement>(null);
//   const emailBox = useRef<HTMLInputElement>(null);
//   const passwordBox = useRef<HTMLInputElement>(null);

//   const formik = useFormik({
//     initialValues: {
//       firstName: '',
//       lastName: '',
//       mobile: '',
//       email: '',
//       password: '',
//     },
//     validationSchema: RegisterSchema,
//     onSubmit: async (values) => {
//       try {
//         const ob = {
//           password: values.password,
//           email: values.email,
//           first_name: values.firstName,
//           last_name: values.lastName,
//           mobile_number: values.mobile,
//           username: values.mobile,
//           isActive: true,
//         };

//         const response = await AuthApiService.PostApiCall(urls.REGISTER, ob);

//         if (response.data.status) {
//           console.log(response);
//           formik.resetForm();
//         } else {
//           console.log(response);
//         }
//       } catch (error) {
//         const errorMessage: string =
//           (error as any)?.response?.data?.msg || 'An error occurred. Please try again.';
//         console.error(errorMessage);
//       }
//     },
//   });

//   return (
//     <div className="login-page">
//       <div className="container-fluid ">
//         <div className="row w-100  mx-0">
//           <div className="col-lg-4 mx-auto">
//             <div className="card border-0 py-5 px-4 px-sm-5 mt-5">
//               <div className="brand-logo text-center">
//                 <h2>HAIRSALOON</h2>
//               </div>
//               <p className="font-weight-light text-center">Sign up here..!</p>
//               <form onSubmit={formik.handleSubmit} className="pt-2">
//                 <div className="form-group mb-3">
//                   <input
//                     type="text"
//                     ref={firstNameBox}
//                     className={`form-control ${formik.touched.firstName && formik.errors.firstName ? 'is-invalid' : ''}`}
//                     id="firstName"
//                     placeholder="First Name"
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     value={formik.values.firstName}
//                   />
//                   {formik.touched.firstName && formik.errors.firstName && (
//                     <div className="invalid-feedback">{formik.errors.firstName}</div>
//                   )}
//                 </div>
//                 <div className="form-group mb-3">
//                   <input
//                     type="text"
//                     ref={lastNameBox}
//                     className={`form-control ${formik.touched.lastName && formik.errors.lastName ? 'is-invalid' : ''}`}
//                     id="lastName"
//                     placeholder="Last Name"
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     value={formik.values.lastName}
//                   />
//                   {formik.touched.lastName && formik.errors.lastName && (
//                     <div className="invalid-feedback">{formik.errors.lastName}</div>
//                   )}
//                 </div>
//                 <div className="form-group mb-3">
//                   <input
//                     type="text"
//                     ref={mobileBox}
//                     className={`form-control ${formik.touched.mobile && formik.errors.mobile ? 'is-invalid' : ''}`}
//                     id="mobile"
//                     placeholder="Mobile"
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     value={formik.values.mobile}
//                   />
//                   {formik.touched.mobile && formik.errors.mobile && (
//                     <div className="invalid-feedback">{formik.errors.mobile}</div>
//                   )}
//                 </div>
//                 <div className="form-group mb-3">
//                   <input
//                     type="email"
//                     ref={emailBox}
//                     className={`form-control ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
//                     id="email"
//                     placeholder="Email"
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     value={formik.values.email}
//                   />
//                   {formik.touched.email && formik.errors.email && (
//                     <div className="invalid-feedback">{formik.errors.email}</div>
//                   )}
//                 </div>
//                 <div className="form-group mb-3">
//                   <input
//                     type="password"
//                     ref={passwordBox}
//                     className={`form-control ${formik.touched.password && formik.errors.password ? 'is-invalid' : ''}`}
//                     id="password"
//                     placeholder="Password"
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     value={formik.values.password}
//                   />
//                   {formik.touched.password && formik.errors.password && (
//                     <div className="invalid-feedback">{formik.errors.password}</div>
//                   )}
//                 </div>

//                 <div className="mt-3">
//                   <button type="submit" className="btn btn-purple form-control">
//                     SIGN UP
//                   </button>
//                 </div>
//                 <div className="text-center mt-2 font-weight-light">
//                   Already have an account? <Link to="/login" className="text-primary">Login</Link>
//                 </div>
//                 <b className="text-danger">{msg}</b>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
