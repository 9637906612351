import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ServiceCategoryState {
    value: ServiceCategory[];
    upData?: any; // Update this type based on your use case
}

interface ServiceCategory {

    // Add other properties as needed
}

const initialState: ServiceCategoryState = {
    value: [],
    upData: null,
};

const serviceCategorySlice = createSlice({
    name: "servicecategory",
    initialState,
    reducers: {
        // addEmployeeLeaveReducer: (state, action: PayloadAction<Service>) => {
        //     state.value.push(action.payload);
        // },
        listservicecategoryReducer: (state, action: PayloadAction<ServiceCategory[]>) => {
            state.value = action.payload;
        },
        // updateEmployeeLeaveReducer: (state, action: PayloadAction<any>) => {
        //     console.log("Updating upData:", action.payload);
        //     state.upData = action.payload;
        // },
    },
});

export const {listservicecategoryReducer} = serviceCategorySlice.actions;
export default serviceCategorySlice.reducer;
