import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface CustomerState {
    value: Customer[];
    value2: Customer[];
    dues: Customer[];
    bill: any;
    upData?: any; // Update this type based on your use case
}

interface Customer {

    // Add other properties as needed
}

const initialState: CustomerState = {
    value: [],
    value2: [],
    dues: [],
    bill: JSON.parse(localStorage.getItem("bill")) || null,
    upData: null,
};

const customerSlice = createSlice({
    name: "customer",
    initialState,
    reducers: {
        listCustomerDuesReducer: (state, action: PayloadAction<Customer[]>) => {
            state.dues = action.payload
        },
        // listCustomerBillReducer: (state, action: PayloadAction<Customer[]>) => {
        //     state.bill = action.payload
        // },
        listCustomerBillReducer: (state, action: PayloadAction<Customer[]>) => {
            state.bill = action.payload;
            // Save bill data to localStorage
            localStorage.setItem("bill", JSON.stringify(action.payload));
        },
        listCustomerReducer: (state, action: PayloadAction<Customer[]>) => {
            state.value = action.payload
        },
        getCustomerTransactionReducer: (state, action: PayloadAction<any>) => {
            state.upData = action.payload;
        },
        listCustomerTransactionReducer: (state, action: PayloadAction<any>) => {
            state.value2 = action.payload;
        },
        updateCustomerReducer: (state, action: PayloadAction<any>) => {
            console.log("Updating upData:", action.payload);
            state.upData = action.payload;

        },
    },
});

export const { listCustomerReducer, listCustomerTransactionReducer, getCustomerTransactionReducer, listCustomerDuesReducer, listCustomerBillReducer, updateCustomerReducer } = customerSlice.actions;
export default customerSlice.reducer;
