import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface UnitState {
    value: Unit[];
    upData?: any; // Update this type based on your use case
}

interface Unit {

    // Add other properties as needed
}

const initialState: UnitState = {
    value: [],
    upData: null,
};

const productSlice = createSlice({
    name: "unit",
    initialState,
    reducers: {
        listUnitReducer: (state, action: PayloadAction<Unit[]>) => {
            state.value = action.payload;
        },

    },
});

export const { listUnitReducer } = productSlice.actions;
export default productSlice.reducer;
