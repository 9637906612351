import { useDispatch, useSelector } from "react-redux";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import { updateEmployeeLeaveReducer } from "../../../reduxData/leaveSlice";
import { leaveValidation, leaveValidationSchema } from "../../../validations/Validations";
import { Alert, Snackbar } from "@mui/material";



export default function EditLeave() {
  const leaveInfo = useSelector((state: any) => state.leaveInfo.upData);
  const admin = useSelector((state: any) => state.authUserInfo.value);
  const employee = useSelector((state: any) => state.employeeInfo.value);
  const localLeaveInfo = JSON.parse(localStorage.getItem("leaveinfo"))
  // console.log(employee);
  const [employeeInfo, setemployeeInfo]: any = useState([]);
  // console.log(leaveInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  // console.log(employeeInfo);

  const [status, setStatus] = useState();
  const [open, setOpen] = useState(false);
  const [msgs, setMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const handleGetLeaveInfo = async () => {
    const url = urls.GET_LEAVE + leaveInfo?.id;
    const res = await AuthApiService.GetApiCall(url, admin.token);
    console.log(res.data.data);
    setemployeeInfo(res.data.data);
  };
  useEffect(() => {
    handleGetLeaveInfo();
  }, [setemployeeInfo]);

  const formik = useFormik({
    initialValues: {
      employee_id: (leaveInfo?.employee_id ?? localLeaveInfo?.employee_id) || undefined,
      leave_type: (leaveInfo?.leave_type ?? localLeaveInfo?.leave_type) || "FullDay",
      leave_date: (leaveInfo?.leave_date?.substring(0, 10) ?? localLeaveInfo?.leave_date) || "",
      reason: (leaveInfo?.reason ?? localLeaveInfo?.reason) || "",
    },
    validationSchema: leaveValidation,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const ob = {
          employee_id: values?.employee_id,
          leave_type: values?.leave_type,
          leave_date: values?.leave_date,
          reason: values?.reason,
          updated_by: admin.uid,
        };
        console.log("ob", ob);
        const url = `${urls.UPDATE_LEAVE}${leaveInfo?.id ?? localLeaveInfo?.id}`;
        const resp = await AuthApiService.PutApiCall(url, ob, admin.token);
        console.log("resp", resp);
        if (resp.status === 200) {
          setMsg(resp.data.msg);
          setStatus(resp.data.status)
          handleClickOpen();
          if (admin.roleValue == "SCM") {
            if (resp.data.status == 201) {
              formik.resetForm()
              setTimeout(() => {
                navigate('/saloonChain/leaves')
                localStorage.removeItem("leaveinfo")
              }, 2000)
            } else {
              setTimeout(() => {
                navigate('/saloonChain/editleave')
              }, 2000)
            }
          } else if (admin.roleValue == "BRM") {
            if (resp.data.status == 201) {
              formik.resetForm()
              setTimeout(() => {
                navigate('/branchManager/leavelist')
                localStorage.removeItem("leaveinfo")
              }, 2000)
            } else {
              setTimeout(() => {
                navigate('/branchManager/editleave')
              }, 2000)
            }
          }
        } else {
          if (resp.data && resp.data.err) {
            setMsg(resp.data.err);
            setStatus(resp.data.status)
          } else {
            setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
            setStatus(resp.data.status)
          }
        }
      } catch (error: any) {
        console.error("Error:", error);
        if (error.response && error.response.status === 409 && error.response.data && error.response.data.msg) {
          setMsg(error.response.data.msg);
        } else {
          setMsg("Error: Conflict occurred.");
        }
        setStatus(error.response.status);
        handleClickOpen()
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="container-fluid p-0">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msgs}</Alert>
      </Snackbar>
      <div className="container">
        <div className="row pr-xl-5 pl-xl-5">
          <div className="col-lg-10 col-md-8 col-sm-8 d-flex justify-content-center">
            <div className="text-center">
              <h4>Update Leave</h4>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-4 mt-2 d-flex pr-2 justify-content-end">
            <Link to="/saloonChain/leaves">
              <span>
                <button className=" btn btn-sm btn-purple">All Leave List</button>
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="p-1 px-xl-2 py-xl-2 justify-content-center">
        <div className="container">

          {/* <b className={status ? "text-success" : "text-danger"}>{msgs}</b> */}
          <div className="row justify-content-center mt-3">
            <div className="col-lg-12">
              <form
                onFocus={() => setMsg("")}
                onSubmit={formik.handleSubmit}>
                <div className="mb-1 mb-xl-3 row">
                  <label
                    className="col-sm-3 col-form-label"
                    htmlFor="employee_id"
                  >
                    Employee Name<span style={{ color: "red" }}>*</span> :
                  </label>
                  <div className="col-sm-7">
                    <select
                      id="employee_id"
                      name="employee_id"
                      className={`form-control shadow-sm ${formik.touched.employee_id && formik.errors.employee_id
                        ? "is-invalid"
                        : ""
                        }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.employee_id}
                      disabled
                    >
                      <option value="">Select Employee Name</option>
                      {employee.map((data: any) => {
                        return (
                          <option value={data.id} key={data.id}>
                            {data.first_name + " " + data.last_name}
                          </option>
                        );
                      })}
                    </select>
                    <div className="invalid-feedback">
                      {formik.touched.employee_id &&
                        typeof formik.errors.employee_id === "string"
                        ? formik.errors.employee_id
                        : null}
                    </div>
                  </div>
                </div>
                <div className="mb-1 mb-xl-3 row d-flex">
                  <label className="col-sm-4 col-form-label">Leave Type<span style={{ color: "red" }}>*</span> :</label>

                  <div className="form-check col-sm-3 col-4 ">
                    <input
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      // value={formik.values.payment_mode}
                      className="form-check-input"
                      type="radio"
                      name="leave_type"
                      id="flexRadioDefault1"
                      value="Full Day"
                      checked={formik.values.leave_type === "FullDay"}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Full Day
                    </label>
                  </div>
                  <div className="form-check col-sm-3 col-4">
                    <input
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      // value={formik.values.payment_mode}
                      className="form-check-input"
                      type="radio"
                      name="leave_type"
                      id="flexRadioDefault2"
                      value="Half Day"
                      checked={formik.values.leave_type === "HalfDay"}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault2"
                    >
                      Half Day
                    </label>
                  </div>
                </div>
                <div className="mb-1 mb-xl-3 row">
                  <label
                    className="col-sm-3 col-form-label"
                    htmlFor="leave_date"
                  >
                    Leave Date<span style={{ color: "red" }}>*</span> :
                  </label>
                  <div className="col-sm-7">
                    <input
                      type="date"
                      placeholder="Enter Start Date"
                      id="leave_date"
                      name="leave_date"
                      className={`form-control shadow-sm ${formik.touched.leave_date && formik.errors.leave_date
                        ? "is-invalid"
                        : ""
                        }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      min={employee.filter((data: any) => data.id == formik.values.employee_id).map((data: any) => data.date_of_joining.substring(0, 10))}
                      max={employee.filter((data: any) => data.id == formik.values.employee_id).map((data: any) => data.date_of_relieving?.substring(0, 10) == null ? undefined : data.date_of_relieving?.substring(0, 10))}
                      value={formik.values.leave_date}
                    />
                    <div className="invalid-feedback">
                      {formik.touched.leave_date &&
                        typeof formik.errors.leave_date === "string"
                        ? formik.errors.leave_date
                        : null}
                    </div>
                  </div>
                </div>
                {/* <div className="mb-3 row">
                                    <label className="col-sm-3 col-form-label" htmlFor="end_date">
                                        End Date
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="date"
                                            placeholder="Enter End Date"
                                            id="end_date"
                                            name="end_date"
                                            className={`form-control shadow-sm ${formik.touched.end_date && formik.errors.end_date ? 'is-invalid' : ''}`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.end_date}
                                        />
                                        <div className="invalid-feedback">{formik.touched.end_date && typeof formik.errors.end_date === 'string' ? (formik.errors.end_date) : null}</div>
                                    </div>
                                </div> */}
                <div className="mb-1 mb-xl-3 row">
                  <label className="col-sm-3 col-form-label" htmlFor="reason">
                    Reason:
                  </label>
                  <div className="col-sm-7">
                    <input
                      type="text"
                      placeholder="Enter Reason"
                      id="reason"
                      name="reason"
                      className={`form-control shadow-sm ${formik.touched.reason && formik.errors.reason
                        ? "is-invalid"
                        : ""
                        }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.reason}
                    />
                    <div className="invalid-feedback">
                      {formik.touched.reason &&
                        typeof formik.errors.reason === "string"
                        ? formik.errors.reason
                        : null}
                    </div>
                  </div>
                </div>
                <div className="mt-3 mb-xl-3 row text-center">
                  <div className="col-lg-5"></div>
                  <div className="col-lg-2">
                    <button
                      type="submit"
                      className="btn  btn-md form-control btn-purple"
                      disabled={loading ? true : false}
                    >
                      {loading ? "Saving..." : "Update"}
                    </button>
                  </div>
                  <div className="col-lg-5"></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}
