import { faArrowDown, faArrowUp, faSearch, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { listCustomerBillReducer, listCustomerTransactionReducer } from "../../../reduxData/customerSlice";
import React from "react";

export default function ManagerCustomerHistory() {
    const admin = useSelector((state: any) => state.authUserInfo.value);
    const [expandedRowIndex, setExpandedRowIndex] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const _ = require("lodash");
    const [sort, setsort] = useState(false);
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState("");
    const [searchInput, setSearchInput] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const customerHistoryInfo = useSelector((state: any) => state.customerInfo.upData);
    const localCustomerHistoryInfo = JSON.parse(localStorage.getItem("customerhistoryinfo"))
    const customerTransactionInfo = useSelector((state: any) => state.customerInfo.value2);

    console.log(customerHistoryInfo, customerTransactionInfo)

    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    const currentDate = new Date().toISOString().substring(0, 10);
    const [fromDate, setFromDate] = useState()
    const [toDate, setToDate] = useState()
    const [searching, setSearching] = useState(false);

    const [month, setMonth] = useState(`${currentYear}-0${currentMonth}`);

    // const toggleRowExpansion = (index: any) => {
    //     setExpandedRowIndex(expandedRowIndex === index ? null : index);
    // };

    // const renderRowExpansionContent = (data: any) => {
    //     return (<><th></th>
    //         <tr> <td className="text-wrap">  <b>Service Type:&nbsp;</b><span>{data.branchServiceInfo.serviceMasterInfo.service_title}</span></td></tr>

    //         <tr> <td className="text-wrap">   <b>Payment Mode:&nbsp;</b><span>{data.payment_mode}</span></td></tr>
    //         <tr> <td className="text-wrap">  <b>Trans. Date:&nbsp;</b><span>{data.transaction_date.substring(0, 10)}</span></td></tr>

    //         <tr> <td className="text-wrap"> <b>Remark:&nbsp;</b><span>{data.remark == null ? "-" : data.remark}</span></td></tr>
    //         <tr> <td className="text-wrap"><b>Cust.Name:&nbsp;</b><span>{data.customer_name == null ? "-" : data.customer_name}</span></td></tr>
    //         <tr> <td className="text-wrap"> <b>Cust.Email:&nbsp;</b><span>{data.cutomer_email == null ? "-" : data.cutomer_email}</span></td></tr>
    //         <tr> <td className="text-wrap"> <b>Cust.Phone:&nbsp;</b><span>{data.customer_mobile == null ? "-" : data.customer_mobile}</span></td></tr>

    //     </>

    //     )
    // }

    const totalRecords = customerTransactionInfo?.pagination?.totalRecords
    console.log("total records", totalRecords)

    const totalPages = Math.ceil(totalRecords / itemsPerPage);
    const generatePageNumbers = () => {
        const pageNumbers = [];
        const maxPageButtons = 3;

        if (totalPages <= maxPageButtons) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            const leftEllipsis = currentPage > 2;
            const rightEllipsis = currentPage < totalPages - 1;

            if (leftEllipsis) {
                pageNumbers.push(1, '...');
            }

            const start = Math.max(1, currentPage - 1);
            const end = Math.min(totalPages, currentPage + 1);

            for (let i = start; i <= end; i++) {
                pageNumbers.push(i);
            }

            if (rightEllipsis) {
                pageNumbers.push('...', totalPages);
            }
        }

        return pageNumbers;
    };

    const handleChange = async (page: number) => {
        setCurrentPage(page);
        // if (searchInput != '') {
        //     await onSearch(searchInput, page, itemsPerPage)
        // }
        // else {
        await CustomerHistory(page, itemsPerPage, fromDate, toDate);
        // }
    };

    const toggleRowExpansion = (index: any) => {
        setExpandedRowIndex(expandedRowIndex === index ? null : index);
    };

    const renderRowExpansionContent = (data: any) => {
        return (<><th></th>
            {/* {<tr><td className="text-wrap"><strong>Service:</strong> {data.service_info.map((data2: any) => <>{data2.service_type ?? data2.product_name}<span>({data2.quantity ?? data2.quantity})</span><br /></>)}</td>
            </tr>} */}
            {<tr><td><strong>Amount({admin.currency}):</strong> {data.service_info.map((data2: any) => <>{data2.amount}<br /></>)}</td></tr>}
        </>
        );
    };

    const handleWindowResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);


    const CustomerHistory = async (page: number, itemsPerPage: number, fromDate: any, toDate: any) => {

        const data = { from_date: fromDate, to_date: toDate }

        try {
            setLoading(true)
            const url = `${urls.CUSTOMER_TRANSACTIONS}/${page}/${itemsPerPage}/${customerHistoryInfo?.id ?? localCustomerHistoryInfo?.id}`;
            const res = await AuthApiService.SearchApiCallWithQuery(url, data, admin.token)
            if (res.status) {
                console.log(res)
                dispatch(listCustomerTransactionReducer(res.data));
            }
        } catch (error) {
            setMsg("Network Error !");
        } finally {
            setLoading(false)
        }
    }

    const handleShowBill = async (data) => {
        console.log(data)
        const url = `${urls.TRANSACTION_GET}${data}`
        const res = await AuthApiService.GetApiCall(url, admin.token);
        console.log(res, url)
        if (res.status == 200 && res.data.status == 200) {
            const ob = res.data.data;
            dispatch(listCustomerBillReducer(ob));
            localStorage.setItem("billinfo", JSON.stringify(res.data.data))
            navigate("/branchManager/managerCustomerBill")
        }
    }

    useEffect(() => {
        CustomerHistory(currentPage, itemsPerPage, fromDate, toDate);
    }, [])

    return <>
        <div className="container-fluid pt-2">
            <div className="container">
                <div className="row m-0">
                    <div className="col-lg-2 col-md-2 col-sm-3 col-2 mt-2 d-flex justify-content-start">
                        <Link to="/branchManager/managerCustomerList">
                            <span>
                                <button className=" btn btn-sm btn-purple">Back</button>
                            </span>
                        </Link>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <div className="text-center">
                            <h4>Transaction History of Customer</h4>
                            <h5>Name-{customerHistoryInfo?.name ?? localCustomerHistoryInfo?.name} </h5>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2 d-flex justify-content-end">
                        <Link
                            to="/branchManager/managerCustomerList">
                            <button className="btn btn-sm btn-purple" >All Customers</button>
                        </Link>
                    </div>
                </div>
                <div className="row mt-2 mt-xl-4 pb-4 p-1 align-items-end">
                    <div className="col-lg-2 col-md-2 col-4">
                        <label>From Date:</label>

                        <div className="input-group input-group-md">
                            <input
                                type="date"
                                className="form-control shadow-md"
                                placeholder="Search..."
                                value={fromDate}
                                max={currentDate}
                                onChange={(e) => {
                                    if (new Date(e.target.value) > new Date(toDate)) {
                                        setToDate(e.target.value); setFromDate(e.target.value);
                                    } else {
                                        setFromDate(e.target.value)
                                    }
                                }}
                            /></div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-4 ">
                        <label>To Date:</label>

                        <div className="input-group input-group-md">
                            <input
                                type="date"
                                className="form-control shadow-md"
                                placeholder="Search..."
                                value={toDate}
                                max={currentDate}
                                min={fromDate}
                                onChange={(e) => {
                                    setToDate(e.target.value)
                                }}
                            /></div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-4 mt-2">
                        <button className="btn btn-purple" onClick={() => { CustomerHistory(1, itemsPerPage, fromDate, toDate); setSearching(true); setCurrentPage(1) }}>Submit</button>
                    </div>
                    {searching && <div className="col-lg-2 col-md-2 col-6 mt-2">
                        <button className="btn btn-danger" onClick={() => { CustomerHistory(1, itemsPerPage, null, null); setSearching(false); setCurrentPage(1) }}>Reset</button>
                    </div>}
                </div>
            </div>

            {
                loading || customerTransactionInfo?.data?.length === 0 ? (
                    <div className="mt-4">
                        {loading ? (
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>) : (
                            <h5>No Records found.</h5>
                        )}
                    </div>
                ) :
                    <div className="mt-2 mt-xl-2 mt-sm-2 justify-content-center">
                        <div className={`table-container table-responsive`}>
                            <table id="report-table" className="table table-striped  table-hover border-light-subtle ">
                                <thead>
                                    <tr>
                                        <th>Total Amount({admin.currency})</th>
                                        <th>Total Paid({admin.currency})</th>
                                        <th>Total Due({admin.currency})</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{customerTransactionInfo?.due_report?.total_amount}</td>
                                        <td>{customerTransactionInfo?.due_report?.total_paid}</td>
                                        <td>{customerTransactionInfo?.due_report?.total_due}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={`table-container table-responsive`}>
                            <table className="table table-striped  table-hover border-light-subtle " id={isMobile ? '' : 'report-table'}>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Service</th>
                                        <th>Qty.</th>
                                        {!isMobile && <th>Amount({admin.currency})</th>}
                                        <th>Payment({admin.currency})</th>
                                        {/* <th>Remaining</th> */}
                                        {isMobile && <th></th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        customerTransactionInfo?.data?.map((data: any, index: any) => {
                                            const isExpanded = expandedRowIndex === index;
                                            return (<React.Fragment>
                                                <tr key={data.id}>
                                                    <td>{new Date(data.transaction_date).toLocaleString('en-GB', { dateStyle: "short" })}<br /><button className="btn btn-link" onClick={() => { handleShowBill(data.id) }}>Bill</button></td>
                                                    <td>{data.service_info.map((data2: any) => <>{data2.service_type ?? data2.product_name}<br /></>)}</td>
                                                    <td>{data.service_info.map((data2: any) => <>{data2.quantity}<br /></>)}</td>
                                                    {!isMobile && <td>{data.service_info.map((data2: any) => <>{data2.amount}<br /></>)}</td>}
                                                    <td className={`${isMobile ? 'text-wrap' : 'text-nowrap'}`}><b>Total :</b>{data.amount}<br /><b>Paid :</b>{data.paymentInfo[0].amount_paid}<br /><b>Due :</b>{data.dues ?? "0"}<br /><b>Mode :</b>{data.paymentInfo[0].payment_mode}</td>
                                                    {/* <td>{data.dues}</td> */}
                                                    {isMobile && (
                                                        <td>
                                                            {!isExpanded ? (
                                                                <button
                                                                    className="btn btn-link"
                                                                    onClick={() => toggleRowExpansion(index)}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faArrowDown}
                                                                        className="fontIcon"
                                                                        size="sm"
                                                                    />
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    className="btn btn-link"
                                                                    onClick={() => toggleRowExpansion(index)}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faArrowUp}
                                                                        className="fontIcon"
                                                                        size="sm"
                                                                    />
                                                                </button>
                                                            )}
                                                        </td>
                                                    )}
                                                </tr>
                                                {isExpanded && isMobile && (
                                                    <tr>
                                                        <td colSpan="5">{renderRowExpansionContent(data)}</td>
                                                    </tr>
                                                )}
                                            </React.Fragment>
                                            )
                                        }
                                        )
                                    }
                                </tbody>
                            </table>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                        <button
                                            className="page-link "
                                            onClick={() => handleChange(currentPage - 1)}
                                            disabled={currentPage === 1 || loading}
                                        >
                                            Previous
                                        </button>
                                    </li>
                                    {generatePageNumbers().map((page, index) => (
                                        <li
                                            key={index}
                                            className={`page-item ${page === currentPage ? 'active' : ''}`}
                                        >
                                            {page === '...' ? (
                                                <span className="page-link">...</span>
                                            ) : (
                                                <button
                                                    className="page-link btn-sm"
                                                    onClick={() => handleChange(page)}
                                                    disabled={loading}
                                                >
                                                    {page}
                                                </button>
                                            )}
                                        </li>
                                    ))}
                                    <li
                                        className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
                                    >
                                        <button
                                            className="page-link btn-sm"
                                            onClick={() => handleChange(currentPage + 1)}
                                            disabled={loading || currentPage === totalPages}
                                        >
                                            Next
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>}
        </div >
    </>
}