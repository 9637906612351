import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ProductState {
    value: Product[];
    value2: Product[];
    upData?: any; // Update this type based on your use case
}

interface Product {

    // Add other properties as needed
}

const initialState: ProductState = {
    value: [],
    value2:[],
    upData: null,
};

const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {
        listAllProductReducer: (state, action: PayloadAction<Product[]>) => {
            state.value2 = action.payload
        },
        listProductReducer: (state, action: PayloadAction<Product[]>) => {
            state.value = action.payload.map(d=>({...d,qty:1}));
        },
        updateProductReducer: (state, action: PayloadAction<any>) => {
            console.log("Updating upData:", action.payload);
            state.upData = action.payload;
        },
    },
});

export const {listProductReducer,listAllProductReducer,updateProductReducer} = productSlice.actions;
export default productSlice.reducer;
