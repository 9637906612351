import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp, faEdit, faPlus, faSort, faTrash, } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listIncentiveRuleReducer, updateIncentiveRuleReducer } from "../../../reduxData/incentiveRuleSlice";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import Select from 'react-select'
import { Alert, Snackbar } from "@mui/material";

export default function IncentiveRuleList() {
    const [expandedRowIndex, setExpandedRowIndex] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const _ = require("lodash");
    const incentiveRules = useSelector((state: any) => state.incentiveRuleInfo.value)
    const branchInfo = useSelector((state: any) => state.branchInfo.value);
    const [recordLoading, setRecordLoading] = useState<string | null>(null);
    const admin = useSelector((state: any) => state.authUserInfo.value);
    const [sortedlist, setsortedlist] = useState(incentiveRules);
    const [sort, setsort] = useState(false);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [msg, setMsg] = useState("");
    const [status, setStatus] = useState();
    const [open, setOpen] = useState(false);
    const [val, setval] = useState(null);
    const [loading, setLoading] = useState(false);

    const loadingSpinner = () => {
        return <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    }

    const toggleRowExpansion = (index: any) => {
        setExpandedRowIndex(expandedRowIndex === index ? null : index);
    };

    const renderRowExpansionContent = (rule: any) => {
        return (<><th></th>
            <tr> <td className="text-wrap">
                <b>Incentive (%):</b><span>&nbsp;{rule.incentive_percent}</span></td ></tr>
            <tr> <td className="text-wrap"><b>Description:</b>&nbsp;<span>{rule.description ? rule.description : '-'}</span>

            </td></tr></>
        );
    };

    const edit = (rule: any) => {
        localStorage.setItem("incentiveruleinfo",JSON.stringify({id:rule?.id,from_amount: rule?.from_amount,
            to_amount: rule?.to_amount,incentive_percent: rule?.incentive_percent,description: rule?.description
        }))
        dispatch(updateIncentiveRuleReducer(rule))
        navigate("/saloonChain/editIncentiveRule")
    }

    const Delete = async (rule: any) => {

        const al = confirm("Are you sure you want to delete");
        try {
            if (al) {
                setRecordLoading(rule);
                const url = `${urls.INCENTIVE_RULE_DELETE}${rule}`;
                const resp = await AuthApiService.DelApiCall(url, admin.token);
                if (resp.status) {
                    const newlist: any = incentiveRules.filter((d: any) => { return d.id !== rule })
                    dispatch(listIncentiveRuleReducer(newlist))
                    // Update sortedlist state with the new filtered list
                    // setsortedlist(newlist);
                    setMsg(resp.data.msg)
                    setStatus(resp.data.status)
                    handleClickOpen();
                }
            }
            else { return }
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.err) {
                // Check if the error is related to a foreign key constraint
                if (error.response.data.err.includes("foreign key constraint fails")) {
                    setMsg("This rule is assigned to Employee. You cannot delete this rule.");
                    setStatus(error.response.status);
                    handleClickOpen()
                } else {
                    // Handle other errors
                    setMsg("Network Error !");
                    setStatus(error.response.data.status)
                    handleClickOpen()
                }
            } else {
                setMsg("Network Error !");
                setStatus(error.response.status);
                handleClickOpen()
            }
        } finally {
            setRecordLoading(null);
            setTimeout(() => {
                // setMsg('');
            }, 5000);
        }
    }

    // const sortByBranchName = () => {
    //     setsort(!sort);
    //     if (sort) {
    //         setsortedlist(_.orderBy(sortedlist, (item: any) => item.branchinfo.branch_name, ['asc']))
    //     }
    //     else {
    //         setsortedlist(_.orderBy(sortedlist, (item: any) => item.branchinfo.branch_name, ['desc']))
    //     }
    // }

    const branchOptions = branchInfo.map((item: any) => ({
        value: item.id,
        label: item.branch_name,
    }));

    // const onBranchSearch = async (data: any) => {
    //     if (data !== null) {
    //         setLoading(true)
    //         const url = `${urls.INCENTIVE_RULE_BY_ID}`;
    //         // const ob = {nameChars:data.value}
    //         const res = await AuthApiService.SearchApiCallWithQuery(url, data.value, admin.token);
    //         console.log(res);
    //         if (res.status) {
    //             setLoading(false);
    //             dispatch(listIncentiveRuleReducer(res.data.data))
    //         }
    //     }
    //     else {
    //         setsortedlist(incentiveRules)
    //     }
    // };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleWindowResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    return <>
        <div className="container-fluid p-0">
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert>
            </Snackbar>
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 col-md-8 col-sm-8 d-flex justify-content-center ">
                        <div className="text-center">
                            <h4>Incentive Rules</h4>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4 mt-2 d-flex justify-content-end">
                        <Link to="/saloonChain/newIncentiveRule">
                            <button className="btn btn-purple"><FontAwesomeIcon icon={faPlus} />&nbsp;New Rule</button>
                        </Link>
                    </div>
                </div>
            </div>
            {/* <b className={status ? "text-success" : "text-danger"}>{msg}</b> */}

            {loading || incentiveRules.length === 0 ? (
                <div className="mt-4">
                    {loading ? (
                        // Display loading spinner
                        <div className="d-flex justify-content-center align-items-center">
                            {loadingSpinner()}
                        </div>
                    ) : (
                        // Display custom message for no records
                        <h5>No incentive rules found. You can add a new rule.</h5>
                    )}
                </div>
            ) : <div className="mt-4 mt-xl-4 mt-sm-2 justify-content-center"> <div className="table-responsive">
                <table className="table table-striped  table-hover border-light-subtle ">
                    <thead>
                        <tr className="text-nowrap text-center">
                            <th>

                            </th>
                            <th className={`${isMobile ? 'text-wrap' : 'text-nowrap'}`}>From Amount (INR)</th>
                            <th className={`${isMobile ? 'text-wrap' : 'text-nowrap'}`}>To Amount (INR)</th>
                            {!isMobile && <th>Incentive (%)</th>}
                            {!isMobile && <th className="text-center">Description</th>}
                            {isMobile && <th></th>}
                        </tr>

                    </thead>
                    <tbody className="text-nowrap">
                        {incentiveRules?.map((rule: any, index: any) => {
                            const isExpanded = expandedRowIndex === index; return (
                                <React.Fragment key={index}>
                                    <tr className="text-center" key={rule.id}>

                                        <td>
                                            <FontAwesomeIcon
                                                icon={faEdit}
                                                className="fontIcon"
                                                onClick={() => edit(rule)}
                                                style={{ cursor: "pointer" }}
                                            />
                                            &nbsp;
                                            {recordLoading === rule.id ? <>Deleting...</> : <><FontAwesomeIcon icon={faTrash} className="fontIcon" onClick={() => { Delete(rule.id) }} cursor="pointer" /></>}
                                        </td>
                                        <td>{rule.from_amount}</td>
                                        <td>{rule.to_amount}</td>
                                        {!isMobile && <td>{rule.incentive_percent}</td>}
                                        {!isMobile && <td>{rule.description}</td>}{isMobile && (
                                            <td>
                                                {!isExpanded ? (
                                                    <button
                                                        className="btn btn-link"
                                                        onClick={() => toggleRowExpansion(index)}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faArrowDown}
                                                            className="fontIcon"
                                                            size="sm"
                                                        />
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="btn btn-link"
                                                        onClick={() => toggleRowExpansion(index)}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faArrowUp}
                                                            className="fontIcon"
                                                            size="sm"
                                                        />
                                                    </button>
                                                )}
                                            </td>
                                        )}
                                    </tr>
                                    {isExpanded && isMobile && (
                                        <tr>
                                            <td colSpan="4">{renderRowExpansionContent(rule)}</td>
                                        </tr>
                                    )}
                                </React.Fragment>)
                        }
                        )}
                    </tbody>
                </table>
            </div> </div>}

        </div >
    </>
}















// import { Link, useNavigate } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEdit, faPlus, faSort, faTrash, } from "@fortawesome/free-solid-svg-icons";
// import { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { listIncentiveRuleReducer, updateIncentiveRuleReducer } from "../../../reduxData/incentiveRuleSlice";
// import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
// import Select from 'react-select'
// import { Alert, Snackbar } from "@mui/material";

// export default function IncentiveRuleList() {
//     const _ = require("lodash");
//     const incentiveRules = useSelector((state: any) =>state.incentiveRuleInfo.value)
//     const branchInfo = useSelector((state: any) => state.branchInfo.value);
//     const [recordLoading, setRecordLoading] = useState<string | null>(null);
//     const admin = useSelector((state: any) => state.authUserInfo.value);
//     const [sortedlist, setsortedlist] = useState(incentiveRules);
//     const [sort, setsort] = useState(false);
//     const navigate = useNavigate()
//     const dispatch = useDispatch()
//     const [msg, setMsg] = useState("");
//     const [status, setStatus] = useState();
//     const [open, setOpen] = useState(false);
//     const [val, setval] = useState(null);
//     const [loading, setLoading] = useState(false);

//     const loadingSpinner = () => {
//         return <div className="d-flex justify-content-center align-items-center">
//             <div className="spinner-border" role="status">
//                 <span className="visually-hidden">Loading...</span>
//             </div>
//         </div>
//     }

//     const edit = (rule: any) => {
//         dispatch(updateIncentiveRuleReducer(rule))
//         navigate("/saloonChain/editIncentiveRule")
//     }

//     const Delete = async (rule: any) => {
//         console.log(rule)
//         // if (!rule || !rule.id) {
//         //     console.error("Invalid rule object:", rule);
//         //     return;
//         // }
//         console.log("rule", rule)
//         const al = confirm("Are you sure you want to delete");
//         try {
//             if (al) {
//                 setRecordLoading(rule);
//                 console.log(rule)
//                 const url = `${urls.INCENTIVE_RULE_DELETE}${rule}`;
//                 const resp = await AuthApiService.DelApiCall(url, admin.token);
//                 console.log(resp)
//                 if (resp.status) {
//                     const newlist: any = incentiveRules.filter((d: any) => { return d.id !== rule })
//                     dispatch(listIncentiveRuleReducer(newlist))
//                     // Update sortedlist state with the new filtered list
//                     setsortedlist(newlist);
//                     setMsg(resp.data.msg)
//                     setStatus(resp.data.status)
//                     handleClickOpen();
//                 }
//             }
//             else { return }
//         } catch (error: any) {
//             if (error.response && error.response.data && error.response.data.err) {
//                 // Check if the error is related to a foreign key constraint
//                 if (error.response.data.err.includes("foreign key constraint fails")) {
//                     setMsg("This rule is assigned to Employee. You cannot delete this rule.");
//                     setStatus(error.response.status);
//                     handleClickOpen()
//                 } else {
//                     // Handle other errors
//                     console.error("Error:", error);
//                     setMsg("Network Error !");
//                     setStatus(error.data.status)
//                 }
//             } else {
//                 console.error("Error:", error);
//                 setMsg("Network Error !");
//                 setStatus(error.response.status);
//             }
//         } finally {
//             setRecordLoading(null);
//             setTimeout(() => {
//                 setMsg('');
//             }, 5000);
//         }
//     }

//     const sortByBranchName = () => {
//         setsort(!sort);
//         if (sort) {
//             setsortedlist(_.orderBy(sortedlist, (item: any) => item.branchinfo.branch_name, ['asc']))
//         }
//         else {
//             setsortedlist(_.orderBy(sortedlist, (item: any) => item.branchinfo.branch_name, ['desc']))
//         }
//     }

//     const branchOptions = branchInfo.map((item: any) => ({
//         value: item.id,
//         label: item.branch_name,
//     }));

//     const onBranchSearch = async (data: any) => {
//         if (data !== null) {
//             setLoading(true)
//             const url = `${urls.INCENTIVE_RULE_BY_ID}`;
//             // const ob = {nameChars:data.value}
//             const res = await AuthApiService.SearchApiCallWithQuery(url, data.value, admin.token);
//             console.log(res);
//             if (res.status) {
//                 setLoading(false);
//                 dispatch(listIncentiveRuleReducer(res.data.data))
//             }
//         }
//         else {
//             setsortedlist(incentiveRules)
//         }
//     };

//     const handleClickOpen = () => {
//         setOpen(true);
//     };

//     const handleClose = () => {
//         setOpen(false);
//     };
//     return <>
//         <div className="container-fluid">
//             <Snackbar
//                 anchorOrigin={{ vertical: "top", horizontal: "center" }}
//                 open={open}
//                 autoHideDuration={5000}
//                 onClose={handleClose}
//             >
//                 <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert>
//             </Snackbar>
//             <div className="container mt-5">
//                 <div className="row">
//                     <div className="col-lg-10 col-md-8 col-sm-8">
//                         <div className="text-center">
//                             <h3>Incentive Rules</h3>
//                         </div>
//                     </div>
//                     <div className="col-lg-2 col-md-4 col-sm-4 mt-3 mt-sm-0 text-sm-end">
//                         <Link to="/saloonChain/newIncentiveRule">
//                             <button className="btn btn-purple"><FontAwesomeIcon icon={faPlus} />&nbsp;New Rule</button>
//                         </Link>
//                     </div>
//                 </div>
//             </div>
//             {/* <b className={status ? "text-success" : "text-danger"}>{msg}</b> */}
//             <div className="mt-5 justify-content-center">
//                 {loading || incentiveRules<=0 ? loadingSpinner() : <div className="table-responsive">
//                     <table className="table table-striped  table-hover border-light-subtle ">
//                         <thead>
//                             <tr className="text-nowrap text-center">
//                                 <th>

//                                 </th>
//                                 <th>From Amount</th>
//                                 <th>To Amount</th>
//                                 <th>Percent</th>

//                                 <th className="text-center">Description</th>
//                             </tr>
//                         </thead>
//                         <tbody className="text-nowrap">
//                             {incentiveRules?.map((rule: any) => (
//                                 <tr className="text-center" key={rule.id}>
//                                     <td>
//                                         <FontAwesomeIcon
//                                             icon={faEdit}
//                                             className="fontIcon"
//                                             onClick={() => edit(rule)}
//                                             style={{ cursor: "pointer" }}
//                                         />
//                                         &nbsp;
//                                         {recordLoading === rule.id ? <>Deleting...</> : <><FontAwesomeIcon icon={faTrash} className="fontIcon" onClick={() => { Delete(rule) }} cursor="pointer" /></>}
//                                     </td>
//                                     <td>{rule.from_amount}</td>
//                                     <td>{rule.to_amount}</td>
//                                     <td>{rule.incentive_percent}%</td>
//                                     <td>{rule.description}</td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>}
//             </div>
//         </div >
//     </>
// }



