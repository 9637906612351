import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp, faCog, faEdit, faPen, faPlus, faSearch, faSort, faTrash } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { deleteEmployeeLeaveReducer, listEmployeeLeaveReducer, updateEmployeeLeaveReducer } from "../../../reduxData/leaveSlice";
import Select from "react-select";
import { Alert, Snackbar } from "@mui/material";

export default function Leaves() {
    const [expandedRowIndex, setExpandedRowIndex] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const _ = require("lodash");
    const [sort, setsort] = useState(false);
    const admin = useSelector((state: any) => state.authUserInfo.value);
    const leavelist = useSelector((state: any) => state.leaveInfo.value);
    const branchInfo = useSelector((state: any) => state.branchInfo.value);
    const employeeInfo = useSelector((state: any) => state.employeeInfo.value);
    const [val, setval] = useState();
    const [loading, setLoading] = useState(false);
    const [recordLoading, setRecordLoading] = useState<string | null>(null);
    const [status, setStatus] = useState();
    const [open, setOpen] = useState(false);
    const [filterleavelist, setfilterlist] = useState([]);
    const [searchInput, setSearchInput] = useState();
    const [isDateSearching, setDateSearching] = useState(false);
    const currentDate = new Date().toISOString().substring(0, 10)
    const [fromDate, setFromDate] = useState(currentDate)
    const [toDate, setToDate] = useState(currentDate);
    const [showReset, setShowReset] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [msg, setMsg] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const branchOptions = branchInfo && Array.isArray(branchInfo) ? [{ value: "All", label: "All" }, ...branchInfo?.map((item: any) => ({
        value: item.id,
        label: item.branch_name,
    }))]:[];

    const employeeOptions = val != undefined && val?.label != "All" ? [{ value: "All", label: "All" }, ...employeeInfo?.filter((item: any) => {
        return item.branch_info.id === val?.value;
    }).map((item: any) => ({
        value: item.id,
        label: item.first_name + " " + item.last_name,
    }))] : [{ value: "All", label: "All" }, ...employeeInfo?.map((item: any) => ({
        value: item.id,
        label: item.first_name + " " + item.last_name,
    }))];

    const [currentPage, setCurrentPage] = useState(1);
    const leavesPerPage = 20;

    const totalRecords = leavelist?.pagination?.totalRecords || 0;

    const totalPages = Math.ceil(totalRecords / leavesPerPage);

    const generatePageNumbers = () => {
        const pageNumbers = [];
        const maxPageButtons = 3; // Adjust the number of buttons you want to show

        if (totalPages <= maxPageButtons) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            const leftEllipsis = currentPage > 2;
            const rightEllipsis = currentPage < totalPages - 1;

            if (leftEllipsis) {
                pageNumbers.push(1, '...');
            }

            const start = Math.max(1, currentPage - 1);
            const end = Math.min(totalPages, currentPage + 1);

            for (let i = start; i <= end; i++) {
                pageNumbers.push(i);
            }

            if (rightEllipsis) {
                pageNumbers.push('...', totalPages);
            }
        }

        return pageNumbers;
    };

    const leavelists = async (page: number, leavesPerPage: number) => {
        try {
            setLoading(true);
            const URL = `${urls.LEAVE_LIST}/${page}/${leavesPerPage}`;
            const res = await AuthApiService.GetApiCallWithPagination(URL, admin.token, page, leavesPerPage);


            if (res.status) {
                dispatch(listEmployeeLeaveReducer(res.data));
                setfilterlist(res.data);
            }
        } catch (error) {
            setMsg("Network Error !");
        } finally {
            setLoading(false);
        }
    };


    const handleChange = async (page: number) => {
        setCurrentPage(page)
        // if(searchInput!=''){
        //     await onSearch(searchInput,page,leavesPerPage)
        // }
        // else 
        if (isDateSearching) {
            handleDateSubmit(val, searchInput, currentPage, leavesPerPage)
        }
        // else if(val!=undefined){                      
        //     await onBranchSearch(val,page, leavesPerPage);
        // }
        else {
            await leavelists(page, leavesPerPage);
        }
    };



    const handleDateSubmit = async (val, searchInput, page, leavesPerPage) => {
        // if(searchInput?.label =="All" || val?.label =="All"){
        //     await leavelists(page,leavesPerPage)
        // }else{
        setDateSearching(true);
        setLoading(true)
        const url = `${urls.LEAVE_BY_DATE}/${fromDate}/${toDate}/${page}/${leavesPerPage}`
        const obj: any = {
            branch_id: val?.label == "All" ? "" : val?.value,
            employee_id: searchInput?.label == "All" ? "" : searchInput?.value
        }
        const res = await AuthApiService.SearchApiCallWithQuery(url, obj, admin.token);
        if (res.status == 200) {
            dispatch(listEmployeeLeaveReducer(res.data));
            //    var gt = res.data.grand_total;
            //    setGrandTotal(gt);
            setLoading(false)
        }
        // }
    }

    const editLeave = (data: any) => {
        localStorage.setItem("leaveinfo", JSON.stringify({
            id: data?.id, employee_id: data?.employee_id, leave_type: data?.leave_type,
            leave_date: data?.leave_date.substring(0, 10), reason: data?.reason
        }))
        dispatch(updateEmployeeLeaveReducer(data));
        navigate("/saloonChain/editleave");
    }

    const deleteLeave = async (data: any) => {
        const al = confirm("Are you sure you want to delete");
        try {
            if (al) {
                setRecordLoading(data.id);
                const url = urls.DELETE_LEAVE + data.id;
                const resp = await AuthApiService.DelApiCall(url, admin.token)
                if (resp.status) {

                    const newlist = leavelist?.data?.filter((d: any) => d.id !== data.id);
                    dispatch(listEmployeeLeaveReducer(newlist));
                    setMsg(resp.data.msg);
                    setStatus(resp.data.status)
                    handleClickOpen();

                    await leavelists(currentPage, leavesPerPage);
                }
                else {
                    setMsg(resp.data.msg)
                    setStatus(resp.data.status)
                }
            }
            else { return }
        } catch (error: any) {
            setMsg("Network Error or Delete Failed!");

            setStatus(error.response.status);
            handleClickOpen()
        } finally {
            setRecordLoading(null);
            setTimeout(() => {
                // setMsg('');
            }, 5000);
        }
    }

    const loadingSpinner = () => {
        return <div className="d-flex justify-content-center">
            <div className="spinner-border" >
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    }

    useLayoutEffect(() => {
        // if(searchInput!=''){
        //     onSearch(searchInput,currentPage,leavesPerPage)
        // }
        // else{
        leavelists(currentPage, leavesPerPage);
        // }
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const sorting = (d) => {
        setsort(!sort)
        if (d == 'branch') {
            const data = sort ? _.orderBy(leavelist.data, (d) => d.employeeinfo.branch_info.branch_name, ['asc']) : _.orderBy(leavelist.data, (d) => d.employeeinfo.branch_info.branch_name, ['desc']);
            const leavelistInfo = { ...leavelist, data };
            dispatch(listEmployeeLeaveReducer(leavelistInfo));
        }
        else if (d == 'employee') {
            const data = sort ? _.orderBy(leavelist.data, (d) => d.employeeinfo.first_name, ['asc']) : _.orderBy(leavelist.data, (d) => d.employeeinfo.first_name, ['desc']);
            const leavelistInfo = { ...leavelist, data };
            dispatch(listEmployeeLeaveReducer(leavelistInfo));
        }
        else if (d == 'ltype') {
            const data = sort ? _.orderBy(leavelist.data, (d) => d.leave_type, ['asc']) : _.orderBy(leavelist.data, (d) => d.leave_type, ['desc']);
            const leavelistInfo = { ...leavelist, data };
            dispatch(listEmployeeLeaveReducer(leavelistInfo));
        }
        else if (d == 'ldate') {
            const data = sort ? _.orderBy(leavelist.data, (d) => d.leave_date, ['asc']) : _.orderBy(leavelist.data, (d) => d.leave_date, ['desc']);
            const leavelistInfo = { ...leavelist, data };
            dispatch(listEmployeeLeaveReducer(leavelistInfo));
        }
    }

    const toggleRowExpansion = (index: any) => {
        setExpandedRowIndex(expandedRowIndex === index ? null : index);
    };

    const renderRowExpansionContent = (data: any) => {
        return (<><th></th>

            {/* <tr> <td className="text-wrap">  <b>Branch&nbsp;</b><span>{data.employeeinfo?.branch_info?.branch_name}</span></td></tr> */}
            <tr> <td className="text-wrap">   <b>Leave Type:&nbsp;</b><span>{data.leave_type}</span></td></tr>
            {/* <tr> <td className="text-wrap"> <b>Leave <br></br>Date:&nbsp;</b><span>{data.leave_date?.substring(0, 10)}</span></td></tr> */}
            <tr> <td className="text-wrap"><b>Reason:&nbsp;</b><span>{data.reason ? data.reason : '-'}</span></td></tr>
            <tr> <td className="text-wrap"> <b>Created By:&nbsp;</b><span>{data.createLeaveBy?.first_name + " " + data.createLeaveBy?.last_name}</span></td></tr>
        </>


        );
    }


    const handleWindowResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    useEffect(() => {
        if (branchInfo.length === 1) {
          const singleBranch = branchInfo[0];
          const singleBranchOption = { value: singleBranch.id, label: singleBranch.branch_name };
          setSelectedBranch(singleBranchOption);
        //   onBranchSearch(singleBranchOption);
        }
      }, [branchInfo]);

    return <>
        <div className="container-fluid p-0">
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert>
            </Snackbar>
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 col-md-9 col-sm-9">
                        <div className="text-center">
                            <h4>Employee Leaves </h4>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2 mt-2 d-flex justify-content-end"><Link to="/saloonChain/newLeave">
                        <button className="btn btn-sm btn-purple"><FontAwesomeIcon icon={faPlus} />New Leave</button></Link>
                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-1 mt-2 d-flex justify-content-end"><Link to="/saloonChain/report">
                        <button className="btn btn-sm btn-purple">Reports</button></Link>
                    </div>

                </div>
            </div >
            <div className="row mt-2 mt-xl-4 pb-2 pb-xl-4 p-1">
                <div className="col-lg-3 col-md-3 col-sm-12">
                    <label>Select Branch</label>
                    <Select
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={"All"}
                        placeholder="All"
                        // isDisabled={isDisabled}
                        isLoading={branchInfo.length <= 0 ? true : false}
                        isClearable={true}
                        // isRtl={isRtl}
                        isSearchable={true}
                        options={branchOptions ?? []}
                        onChange={(e:any) => { setShowReset(true); setval(e); setCurrentPage(1); handleDateSubmit(e, searchInput, 1, leavesPerPage); setSelectedBranch(e); }}
                        // value={val}
                        value={selectedBranch}
                    />
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12">
                    {/* <div className="input-group input-group-sm shadow-sm "> */}
                    <label>Select Employee</label>
                    <Select
                        // type="text"
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder={val?.label || "All"}
                        isLoading={employeeInfo.length <= 0 ? true : false}
                        isClearable={true}
                        isSearchable={true}
                        options={employeeOptions}
                        // value={searchInput}
                        onChange={(e: string) => { setShowReset(true); setSearchInput(e); setCurrentPage(1); handleDateSubmit(val, e, 1, leavesPerPage) }}
                        escapeClearsValue={true}
                        backspaceRemovesValue={false}
                        value={searchInput}
                        filterOption={(option, inputValue) =>
                            option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                        }
                    />

                </div>
                <div className="col-lg-5 col-md-5 p-0 m-0">
                    <form onSubmit={(e) => { e.preventDefault(); setCurrentPage(1); handleDateSubmit(val, searchInput, 1, leavesPerPage) }}>
                        <div className="row m-0 p-0">
                            <div className="col-lg-4 col-md-4 col-sm-6 col-4 m-0 ">
                                <label>From Date:</label>
                                <div className="input-group input-group-md ">
                                    <input
                                        type="Date"
                                        className="form-control shadow-sm"
                                        placeholder="Search..."
                                        value={fromDate}
                                        onChange={(e) => { setFromDate(e.target.value) }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 col-4 m-0 p-0 mr-xl-2 ">

                                <label>To Date:</label>
                                <div className="input-group input-group-md">
                                    <input
                                        type="Date"
                                        className="form-control shadow-sm"
                                        placeholder="Search..."
                                        value={toDate}
                                        min={fromDate}
                                        onChange={(e) => { setToDate(e.target.value) }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-3 col-sm-6 col-4 m-0 ml-1 ml-xl-2">

                                <label></label>
                                <br />
                                <button type="submit" className="btn btn-md btn-purple" onClick={() => { setShowReset(true) }}>Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
                {showReset && <div className="col-1">
                    <label></label>
                    <br />
                    <button className="btn btn-danger" onClick={() => { setShowReset(false); setCurrentPage(1); setFromDate(currentDate); setToDate(currentDate); setDateSearching(false), setSearchInput(""), setval(""), leavelists(1, leavesPerPage) }}>Reset</button>
                </div>}
            </div>
            {/* <b className={status ? "text-success" : "text-danger"}>{msg}</b> */}
            {
                loading || leavelist?.data?.length === 0 ? (
                    <div className="mt-4">
                        {loading ? (
                            // Display loading spinner
                            <div className="d-flex justify-content-center align-items-center">
                                {loadingSpinner()}
                            </div>
                        ) : (
                            // Display custom message for no records
                            <h5>No Leaves found.</h5>
                        )}
                    </div>
                ) : <div className="mt-2 mt-xl-2 mt-sm-2 justify-content-center">
                    <div className={`table-container ${isMobile ? 'table-fixed' : 'table-responsive'}`}>
                        <table className="table table-striped  table-hover border-light-subtle ">
                            <thead>
                                <tr className="text-nowrap">
                                    <th>
                                    </th>
                                    <th style={{ cursor: "pointer" }} onClick={() => { sorting('branch') }}>Branch &nbsp;<FontAwesomeIcon icon={faSort} /></th>
                                    <th style={{ cursor: "pointer" }} onClick={() => { sorting('employee') }}>Employee &nbsp;<FontAwesomeIcon icon={faSort} /> </th>
                                    {!isMobile && <th style={{ cursor: "pointer" }} onClick={() => { sorting('ltype') }}>Leave Type &nbsp;<FontAwesomeIcon icon={faSort} /></th>}
                                    <th style={{ cursor: "pointer" }} onClick={() => { sorting('ldate') }}>{isMobile ? <>Leave<br></br>Date</> : "Leave Date"} &nbsp;<FontAwesomeIcon icon={faSort} /></th>
                                    {!isMobile && <th>Reason</th>}
                                    {!isMobile && <th>Created By</th>}
                                    {isMobile && <th></th>}
                                </tr>


                            </thead>
                            <tbody className="text-nowrap">
                                {leavelist?.data?.map((data: any, index: any) => {
                                    const isExpanded = expandedRowIndex === index; return (
                                        <React.Fragment key={index}> <tr key={index}>
                                            <td>{isMobile ? <><FontAwesomeIcon onClick={() => { editLeave(data) }} cursor="pointer" icon={faEdit} className="fontIcon" /><br></br>
                                                {recordLoading === data.id ? <>Deleting...</> : <><FontAwesomeIcon icon={faTrash} className="fontIcon" onClick={() => { deleteLeave(data) }} cursor="pointer" /></>}</> : <><FontAwesomeIcon onClick={() => { editLeave(data) }} cursor="pointer" icon={faEdit} className="fontIcon" />&nbsp;
                                                {recordLoading === data.id ? <>Deleting...</> : <><FontAwesomeIcon icon={faTrash} className="fontIcon" onClick={() => { deleteLeave(data) }} cursor="pointer" /></>}</>}
                                            </td>
                                            <td className={`${isMobile ? 'text-wrap' : 'text-nowrap'}`}>
                                                {data.employeeinfo?.branch_info?.branch_name}</td>
                                            <td className={`${isMobile ? 'text-wrap' : 'text-nowrap'}`}>{isMobile ? <>{data.employeeinfo?.first_name}<br></br>{data.employeeinfo?.last_name}</> : <>{data.employeeinfo?.first_name + " " + data.employeeinfo?.last_name}</>}</td>
                                            {!isMobile && <td>{data.leave_type}</td>}
                                            <td className={`${isMobile ? 'text-wrap' : 'text-nowrap'}`}>{data.leave_date?.substring(0, 10)}</td>

                                            {!isMobile && <td>{data.reason ? data.reason : '-'}</td>}
                                            {!isMobile && <td>{data.createLeaveBy?.first_name + " " + data.createLeaveBy?.last_name}</td>}{isMobile && (
                                                <td>
                                                    {!isExpanded ? (
                                                        <button
                                                            className="btn btn-link"
                                                            onClick={() => toggleRowExpansion(index)}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faArrowDown}
                                                                className="fontIcon"
                                                                size="sm"
                                                            />
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="btn btn-link"
                                                            onClick={() => toggleRowExpansion(index)}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faArrowUp}
                                                                className="fontIcon"
                                                                size="sm"
                                                            />
                                                        </button>
                                                    )}</td>
                                            )}
                                        </tr>
                                            {isExpanded && isMobile && (
                                                <tr>
                                                    <td colSpan="5">{renderRowExpansionContent(data)}</td>
                                                </tr>
                                            )}
                                        </React.Fragment>)
                                }
                                )}
                            </tbody>
                        </table>
                        <nav aria-label="Page navigation example">
                            <ul className="pagination">
                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <button
                                        className="page-link "
                                        onClick={() => handleChange(currentPage - 1)}
                                        disabled={currentPage === 1 || loading}
                                    >
                                        Previous
                                    </button>
                                </li>
                                {generatePageNumbers().map((page, index) => (
                                    <li
                                        key={index}
                                        className={`page-item ${page === currentPage ? 'active' : ''}`}
                                    >
                                        {page === '...' ? (
                                            <span className="page-link">...</span>
                                        ) : (
                                            <button
                                                className="page-link btn-sm"
                                                onClick={() => { handleChange(page) }}
                                                disabled={loading}
                                            >
                                                {page}
                                            </button>
                                        )}
                                    </li>
                                ))}
                                <li
                                    className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
                                >
                                    <button
                                        className="page-link btn-sm"
                                        onClick={() => handleChange(currentPage + 1)}
                                        disabled={loading || currentPage === totalPages}
                                    >
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            }

        </div >
    </>
}
