import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ExpenseState {
    value: Expense[];
    upData?: any; // Update this type based on your use case
}

interface Expense {

    // Add other properties as needed
}

const initialState: ExpenseState = {
    value: [],
    upData: null,
};

const expenseSlice = createSlice({
    name: "Expense",
    initialState,
    reducers: {
        addExpenseReducer: (state, action: PayloadAction<Expense>) => {
            state.value.push(action.payload);
        },
        listExpenseReducer: (state, action: PayloadAction<Expense[]>) => {
            state.value = action.payload;
        },
        updateExpenseReducer: (state, action: PayloadAction<any>) => {
            state.upData = action.payload;
        },
        // deleteAdvancePaymentReducer:(state,action:PayloadAction<ExpenseHead[]>)=>{
        //     console.log("del",action.payload);
        // }
    },
});

export const {addExpenseReducer,listExpenseReducer,updateExpenseReducer} = expenseSlice.actions;
export default expenseSlice.reducer;
