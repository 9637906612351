import { useFormik } from "formik";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AdvancePaymentValidationSchema } from "../../../validations/Validations";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { addAdvancePaymentReducer, listAdvancePaymentReducer } from "../../../reduxData/advancePaymentSlice";
import { Alert, Snackbar } from "@mui/material";
import Select from "react-select";


export default function AddCustomer() {
    const navigate = useNavigate();
    const currentDate = new Date().toISOString();
    const employee = useSelector((state: any) => state.employeeInfo.value.filter((data: any) => { return (data.is_active == true && data.date_of_joining <= currentDate && (data.date_of_relieving >= currentDate || data.date_of_relieving == null)) }));
    const branchInfo = useSelector((state: any) => state.branchInfo.value);
    const auth = useSelector((state: any) => state.authUserInfo.value);
    console.log(auth)
    const [msg, setMsg] = useState("");
    const [status, setStatus] = useState();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const[emp,setemp] = useState();

    const employeeOptions = employee.map((item: any) => ({
        value: item.id,
        label: item.first_name + " " + item.last_name,
    }));

    const formik = useFormik({
        initialValues: {
            employee_id: "",
            payment_date: new Date().toISOString().substring(0, 10),
            amount: "",
            reason: "",
            payment_mode: "Cash",
        },
        validationSchema: AdvancePaymentValidationSchema,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const ob: any = {
                    employee_id: values.employee_id,
                    branch_id: branchInfo[0].id,
                    payment_date: values.payment_date,
                    amount: values.amount,
                    reason: values.reason,
                    payment_mode: values.payment_mode,
                    created_by: auth.uid,
                    updated_by: auth.uid
                };
                console.log(ob);

                const resp = await AuthApiService.SaveApiCall(urls.ADVANCED_PAYMENT_SAVE, ob, auth.token)
                console.log(resp);
                if (resp.data.status === 201) {
                    setLoading(false);
                    setMsg(resp.data.msg);
                    setStatus(resp.data.status)
                    dispatch(listAdvancePaymentReducer(ob))
                    formik.resetForm();
                    handleClickOpen();
                    setTimeout(()=>{
                        navigate("/branchManager/managerAdvancePayment")
                    },1000)
                } else {
                    if (resp.data && resp.data.err) {
                        setMsg(resp.data.err);
                        setStatus(resp.data.status)

                    } else {
                        setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
                        setStatus(resp.data.status)
                    }
                }
            } catch (error: any) {
                setLoading(false);
                console.error("Error:", error);
                setMsg("Network Error !");
                setStatus(error.response.status);
                handleClickOpen()
            }
            finally {
            }
        }
    },)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className="container-fluid p-0">
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert>
            </Snackbar>
            <div className="container">

                <div className="row pr-xl-5 pl-xl-5">
                    <div className="col-lg-10 col-md-8 col-sm-8 d-flex justify-content-center">
                        <div className="text-center">
                            <h4>New Customer</h4>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4 mt-2 d-flex pr-2 justify-content-end">
                        <Link to="/branchManager/customerList">
                            <span>
                                <button className=" btn btn-sm btn-purple">All Customers List</button>
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="p-1 px-xl-2 py-xl-2 mt-xl-4 mt-0 justify-content-center">
                <div className="container">
                    {/* <b className={status ? "text-success" : "text-danger"}>{msg}</b> */}
                    <div className="row justify-content-center mt-3">
                        <div className="col-lg-12">
                            <form onFocus={() => setMsg("")} 
                            // onSubmit={formik.handleSubmit}
                            >
                                <div className="mb-1 mb-xl-3 row">
                                    <label className="col-sm-3 col-form-label" htmlFor="customer_name">
                                      Name<span style={{ color: "red" }}>*</span> :
                                    </label>
                                    <div className="col-sm-7">
                                    <input
                                            type="text"
                                            placeholder="Enter Name"
                                            id="customer_name"
                                            name="customer_name"
                                            className={`form-control shadow-sm`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.reason}
                                        />
                                        {/* <div className="invalid-feedback">{formik.touched.reason && typeof formik.errors.reason === 'string' ? (formik.errors.reason) : null}</div> */}
                                        <div className="invalid-feedback">{formik.touched.employee_id && typeof formik.errors.employee_id === 'string' ? (formik.errors.employee_id) : null}</div>
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label className="col-sm-3 col-form-label" htmlFor="customer_email">
                                         Email<span style={{ color: "red" }}>*</span> :
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            placeholder="Enter email"
                                            id="customer_email"
                                            name="customer_email"
                                            className={`form-control shadow-sm`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.reason}
                                        />
                                        {/* <div className="invalid-feedback">{formik.touched.reason && typeof formik.errors.reason === 'string' ? (formik.errors.reason) : null}</div> */}
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <label className="col-sm-3 col-form-label" htmlFor="customer_mobile">
                                         Mobile<span style={{ color: "red" }}>*</span> :
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            placeholder="Enter mobile"
                                            id="customer_mobile"
                                            name="customer_mobile"
                                            className={`form-control shadow-sm`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.reason}
                                        />
                                        {/* <div className="invalid-feedback">{formik.touched.reason && typeof formik.errors.reason === 'string' ? (formik.errors.reason) : null}</div> */}
                                    </div>
                                </div>
                                 <div className="row mt-4 justify-content-center">
                                    <div className="col-lg-2 col-md-2 col-sm-6">
                                        <button type="submit" className="btn form-control btn-purple btn-md" disabled={loading}>
                                            {loading ? 'Saving...' : 'Add'}
                                        </button>
                                    </div>
                                </div>
                                {/* <b className={status ? "text-success" : "text-danger"}>{msg}</b> */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
