import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ExpenseHeadState {
    value: ExpenseHead[];
    upData?: any; // Update this type based on your use case
}

interface ExpenseHead {

    // Add other properties as needed
}

const initialState: ExpenseHeadState = {
    value: [],
    upData: null,
};

const expenseHeadSlice = createSlice({
    name: "ExpenseHead",
    initialState,
    reducers: {
        addExpenseHeadReducer: (state, action: PayloadAction<ExpenseHead>) => {
            state.value.push(action.payload);
        },
        listExpenseHeadReducer: (state, action: PayloadAction<ExpenseHead[]>) => {
            state.value = action.payload;
        },
        // updateAdvancePaymentReducer: (state, action: PayloadAction<any>) => {
        //     console.log("Updating upData:", action.payload);
        //     state.upData = action.payload;
        // },
        // deleteAdvancePaymentReducer:(state,action:PayloadAction<ExpenseHead[]>)=>{
        //     console.log("del",action.payload);
        // }
    },
});

export const {addExpenseHeadReducer,listExpenseHeadReducer} = expenseHeadSlice.actions;
export default expenseHeadSlice.reducer;
