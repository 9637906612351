import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface settingState {
    value: Setting[];
    upData?: any; // Update this type based on your use case
}

interface Setting {

    // Add other properties as needed
}

const initialState: settingState = {
    value: [],
    upData: null,
};

const settingSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        // addEmployeeLeaveReducer: (state, action: PayloadAction<Service>) => {
        //     state.value.push(action.payload);
        // },
        listsettingReducer: (state, action: PayloadAction<Setting[]>) => {
            state.value = action.payload;
        },
        updateSettingReducer: (state, action: PayloadAction<any>) => {
            console.log("Updating upData:", action.payload);
            state.upData = action.payload;
        },
    },
});

export const { listsettingReducer, updateSettingReducer } = settingSlice.actions;
export default settingSlice.reducer;
