import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface CurrencyState {
    value: Currency[];
    upData?: any;
}

interface Currency {

    // Add other properties as needed
}

const initialState: CurrencyState = {
    value: [],
    upData: null,
};

const CurrencySlice = createSlice({
    name: "servicemaster",
    initialState,
    reducers: {

        listCurrencyReducer: (state, action: PayloadAction<Currency[]>) => {
            state.value = action.payload;
        },

    },
});

export const { listCurrencyReducer } = CurrencySlice.actions;
export default CurrencySlice.reducer;
