import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import AuthApiService, { urls } from "../../../apiServices/AuthApiService";
import { listEmployeeReducer, updateEmployeeReducer } from "../../../reduxData/employeeSlice";
import { editEmployeeValidationSchema } from "../../../validations/Validations";
import Select from "react-select";
import { Alert, Snackbar } from "@mui/material";

export default function EditEmployee() {
    const admin = useSelector((state: any) => state.authUserInfo.value);
    const employeeData = useSelector((state: any) => state.employeeInfo.upData);
    const localEmpData = JSON.parse(localStorage.getItem("empinfo"));
    const employeeList = useSelector((state: any) => state.employeeInfo.value);
    const empCat = useSelector((state: any) => state.employeeInfo.cateValue);
    const branchList = useSelector((state: any) => state.branchInfo.value);
    console.log(localEmpData)

    const dispatch = useDispatch();
    const [msg, setMsg] = useState("");
    const [status, setStatus] = useState();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()
    const [employeeId, setEmployeeId] = useState<number | null>(null);

    useEffect(() => {
        if (employeeData && employeeData.id) {
            setEmployeeId(employeeData.id);
        }
    }, [employeeData]);
    // edit employee
    const formik = useFormik({
        initialValues: {
            emp_id: (employeeData?.emp_id??localEmpData.emp_id) || "",
            first_name: (employeeData?.first_name??localEmpData.first_name) || "",
            last_name: (employeeData?.last_name??localEmpData.last_name) || "",
            gender: (employeeData?.gender??localEmpData.gender) || "",
            email: (employeeData?.email??localEmpData.email) || null,
            mobile_1: (employeeData?.mobile_1??localEmpData.mobile_1) || "",
            mobile_2: (employeeData?.mobile_2??localEmpData.mobile_2) || "",
            date_of_birth:(employeeData?.date_of_birth?.substring(0, 10)??localEmpData.date_of_birth),
            date_of_joining:(employeeData?.date_of_joining?.substring(0, 10)??localEmpData.date_of_joining),
            date_of_relieving: (employeeData?.date_of_relieving?.substring(0, 10)??localEmpData.date_of_relieving),
            category: (employeeData?.category_info?.id??localEmpData.category) || "",
            branch_id: (employeeData?.branch_info?.id??localEmpData.branch_id) || "",
            salary: (employeeData?.salary??localEmpData.salary) || "",
            govt_id: (employeeData?.govt_id??localEmpData.govt_id) || "",
            // is_active: false,
            created_by: admin.uid,
            updated_by: admin.uid,
        },
        validationSchema: editEmployeeValidationSchema,
        onSubmit: async (values) => {
            console.log(values)
            try {
                setLoading(true);
                const updatedEmployee = {
                    ...employeeData,

                    ...values,


                };
                console.log(updatedEmployee)
                const ob = {
                    emp_id: values.emp_id,
                    first_name: values.first_name,
                    last_name: values.last_name,
                    gender: values.gender,
                    email: values.email ? values.email : null,
                    mobile_1: values.mobile_1,
                    mobile_2: values.mobile_2,
                    date_of_birth: values.date_of_birth ? values.date_of_birth : null,
                    date_of_joining: values.date_of_joining,
                    date_of_relieving: values.date_of_relieving ? values.date_of_relieving : null,
                    category: values.category || "", // Use the correct selector
                    branch_id: values.branch_id || "",
                    salary: values.salary,
                    govt_id: values.govt_id,
                    // is_active: true,
                    created_by: admin.uid,
                    updated_by: admin.uid
                }
                console.log("ob", ob)
                const upURL = `${urls.EMPLOYEE_UPDATE}${employeeId??localEmpData?.id}`;
                console.log(upURL)
                const resp = await AuthApiService.PutApiCall(upURL, updatedEmployee, admin.token)
                console.log(resp)
                console.log("API Response:", resp);

                if (resp.status === 200) {
                    setMsg(resp.data.msg);
                    setStatus(resp.data.status)
                    handleClickOpen();
                    dispatch(updateEmployeeReducer(updatedEmployee));
                    dispatch(updateEmployeeReducer(ob))
                    if (resp.data.status == 201) {
                        setTimeout(() => {
                            localStorage.removeItem("empinfo")
                            navigate('/saloonChain/employeeDetails')
                        }, 2000)
                    } else {
                        setTimeout(() => {
                            navigate('/saloonChain/EditEmployee')
                        }, 2000)
                    }
                } else {
                    if (resp.data && resp.data.err) {
                        setMsg(resp.data.err);
                        setStatus(resp.data.status)
                    } else {
                        setMsg(`Error: ${resp.status} - ${resp.data.msg}`);
                        setStatus(resp.data.status)
                    }
                }
            } catch (error: any) {
                setMsg("Network Error !");
                console.error("Error:", error);
                setStatus(error.response.status);
                handleClickOpen()
            }
            finally {
                setLoading(false);
                setTimeout(() => {
                }, 2000);
            }
        }
    })

    const branchOptions = branchList?.map((item: any) => ({
        value: item.id,
        label: item.branch_name
    }))

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return <>
        <div className="container-fluid p-0">
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={(status >= 200 && status < 400 || status == true) ? "success" : "error"} variant="filled" sx={{ width: "100%" }}>{msg}</Alert>
            </Snackbar>
            <div className="container">

                <div className="row pr-xl-5 pl-xl-5">
                    <div className="col-lg-10 col-md-8 col-sm-8 d-flex justify-content-center ">
                        <div className="text-center">
                            <h4>Update Employee </h4>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4 mt-2 d-flex pr-2 justify-content-end">
                        <Link to="/saloonChain/employeeDetails"><span><button className=" btn btn-purple btn-sm" onClick={()=>localStorage.removeItem("empinfo")}>All Employees</button></span></Link></div>
                </div>
            </div>
            <div className="p-1 px-xl-2 py-xl-2 justify-content-center">
                <div className="container">
                    <div className="row justify-content-center mt-3 position-relative">
                        <div className="col-lg-12">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="mb-1 mb-xl-3 row">
                                    <div className="col-lg-2 col-md-2">
                                        <label htmlFor="branch" className="col-form-label">Branch<span style={{ color: "red" }}>*</span> :</label>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <Select
                                            id="branch_id"
                                            name="branch_id"
                                            className={`react-select-container ${formik.touched.branch_id && formik.errors.branch_id
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                            options={branchOptions}
                                            isSearchable
                                            placeholder="Select branch name..."
                                            onChange={(selectedOption) =>
                                                formik.setFieldValue("branch_id", selectedOption.value)}
                                            onBlur={formik.handleBlur}
                                            value={
                                                branchOptions.find((option: any) => option.value === formik.values.branch_id) ||
                                                null
                                            } isDisabled={true}
                                        />
                                        {formik.touched.branch_id && typeof formik.errors.branch_id === 'string' ? (
                                            <div className="invalid-feedback">{formik.errors.branch_id}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-lg-2 col-md-2">
                                        <label htmlFor="fname" className="col-form-label">Employee Code:</label>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <input
                                            type="text"
                                            className={`form-control shadow-sm ${formik.touched.emp_id && formik.errors.emp_id ? "is-invalid" : ""
                                                }`}
                                            id="emp_id"
                                            placeholder="Enter Employee Code"
                                            name="emp_id"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.emp_id}
                                        />
                                        {formik.touched.emp_id && typeof formik.errors.emp_id === 'string' ? (
                                            <div className="invalid-feedback">{formik.errors.emp_id}</div>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="mb-1 mb-xl-3 row">
                                    <div className="col-lg-2 col-md-2">
                                        <label htmlFor="fname" className="col-form-label">First Name<span style={{ color: "red" }}>*</span> :</label>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <input
                                            type="text"
                                            className={`form-control shadow-sm ${formik.touched.first_name && formik.errors.first_name ? "is-invalid" : ""
                                                }`}
                                            id="fname"
                                            placeholder="Enter First Name"
                                            name="first_name"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.first_name}
                                        />
                                        {formik.touched.first_name && typeof formik.errors.first_name === 'string' ? (
                                            <div className="invalid-feedback">{formik.errors.first_name}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-lg-2 col-md-2">
                                        <label htmlFor="last_name" className="col-form-label">Last Name<span style={{ color: "red" }}>*</span> :</label>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <input
                                            type="text"
                                            className={`form-control shadow-sm ${formik.touched.last_name && formik.errors.last_name ? "is-invalid" : ""
                                                }`}
                                            id="last_name"
                                            placeholder="Enter Last Name"
                                            name="last_name"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.last_name}
                                        />
                                        {formik.touched.last_name && typeof formik.errors.last_name === 'string' ? (
                                            <div className="invalid-feedback">{formik.errors.last_name}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <div className="col-lg-2 col-md-2">
                                        <label htmlFor="gender" className="col-form-label">Gender<span style={{ color: "red" }}>*</span> :</label>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <select
                                            className={`form-control form-select shadow-sm ${formik.touched.gender && formik.errors.gender ? "is-invalid" : ""
                                                }`}
                                            id="gender"
                                            name="gender"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.gender}
                                        >
                                            <option value="">Select Gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </select>
                                        {formik.touched.gender && typeof formik.errors.gender === 'string' ? (
                                            <div className="invalid-feedback">{formik.errors.gender}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-lg-2 col-md-2">
                                        <label htmlFor="mobile_1" className="col-form-label">Contact<span style={{ color: "red" }}>*</span> :</label>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <input
                                            type="text"
                                            className={`form-control shadow-sm ${formik.touched.mobile_1 && formik.errors.mobile_1 ? "is-invalid" : ""
                                                }`}
                                            id="mobile_1"
                                            placeholder="Enter Mobile 1"
                                            name="mobile_1"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.mobile_1}
                                        />
                                        {formik.touched.mobile_1 && typeof formik.errors.mobile_1 === 'string' ? (
                                            <div className="invalid-feedback">{formik.errors.mobile_1}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <div className="col-lg-2 col-md-2">
                                        <label htmlFor="date_of_joining" className="col-form-label">Date of Joining<span style={{ color: "red" }}>*</span> :</label>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <input
                                            type="date"
                                            className={`form-control shadow-sm ${formik.touched.date_of_joining && formik.errors.date_of_joining ? "is-invalid" : ""
                                                }`}
                                            id="date_of_joining"
                                            name="date_of_joining"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.date_of_joining}
                                        />
                                        {formik.touched.date_of_joining && typeof formik.errors.date_of_joining === 'string' ? (
                                            <div className="invalid-feedback">{formik.errors.date_of_joining}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-lg-2 col-md-2">
                                        <label htmlFor="category" className="col-form-label">Designation<span style={{ color: "red" }}>*</span> :</label>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <select

                                            className={`form-control form-select shadow-sm ${formik.touched.category && formik.errors.category ? "is-invalid" : ""
                                                }`}
                                            id="category"

                                            name="category"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.category}
                                        ><option value="">Select Designation</option>
                                            {empCat?.map((category: any) => (
                                                <option key={category.id} value={category.id}>
                                                    {category.category_title}
                                                </option>
                                            ))}
                                        </select>
                                        {formik.touched.category && typeof formik.errors.category === 'string' ? (
                                            <div className="invalid-feedback">{formik.errors.category}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <div className="col-lg-2 col-md-2">
                                        <label htmlFor="salary" className="col-form-label">Salary<span style={{ color: "red" }}>*</span> :</label>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <input type="text" className={`form-control shadow-sm ${formik.touched.salary && formik.errors.salary ? "is-invalid" : ""
                                            }`}
                                            id="salary"
                                            placeholder="Enter Salary"
                                            name="salary"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.salary}
                                        />
                                        {formik.touched.salary && typeof formik.errors.salary === 'string' ? (
                                            <div className="invalid-feedback">{formik.errors.salary}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-lg-2 col-md-2">
                                        <label htmlFor="mobile_2" className="col-form-label">Alternate Contact:</label>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <input
                                            type="text"
                                            className={`form-control shadow-sm ${formik.touched.mobile_2 && formik.errors.mobile_2 ? "is-invalid" : ""
                                                }`}
                                            id="mobile_2"
                                            placeholder="Enter Mobile 2"
                                            name="mobile_2"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.mobile_2}
                                        />
                                        {formik.touched.mobile_2 && typeof formik.errors.mobile_2 === 'string' ? (
                                            <div className="invalid-feedback">{formik.errors.mobile_2}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="mb-1 mb-xl-3 row">
                                    <div className="col-lg-2 col-md-2">
                                        <label htmlFor="date_of_birth" className="col-form-label">Date of Birth:</label>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <input
                                            type="date"
                                            className={`form-control shadow-sm ${formik.touched.date_of_birth && formik.errors.date_of_birth ? "is-invalid" : ""
                                                }`}
                                            id="date_of_birth"
                                            name="date_of_birth"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.date_of_birth}
                                        />
                                        {formik.touched.date_of_birth && typeof formik.errors.date_of_birth === 'string' ? (
                                            <div className="invalid-feedback">{formik.errors.date_of_birth}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-lg-2 col-md-2">
                                        <label htmlFor="date_of_relieving" className="col-form-label">Date of Relieving:</label>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <input
                                            type="date"
                                            className={`form-control shadow-sm ${formik.touched.date_of_relieving && formik.errors.date_of_relieving ? "is-invalid" : ""
                                                }`}
                                            id="date_of_relieving"
                                            name="date_of_relieving"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.date_of_relieving}
                                        />
                                        {formik.touched.date_of_relieving && typeof formik.errors.date_of_relieving === 'string' ? (
                                            <div className="invalid-feedback">{formik.errors.date_of_relieving}</div>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="mb-1 mb-xl-3 row">
                                    <div className="col-lg-2 col-md-2">
                                        <label htmlFor="email" className="col-form-label">Email:</label>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <input
                                            type="email"
                                            className={`form-control shadow-sm ${formik.touched.email && formik.errors.email ? "is-invalid" : ""
                                                }`}
                                            id="email"
                                            placeholder="Enter email"
                                            name="email"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email}
                                        />
                                        {formik.touched.email && typeof formik.errors.email === 'string' ? (
                                            <div className="invalid-feedback">{formik.errors.email}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-lg-2 col-md-2">
                                        <label htmlFor="govtid" className="col-form-label">Govt.Id:</label>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <input type="text" className={`form-control shadow-sm ${formik.touched.govt_id && formik.errors.govt_id ? "is-invalid" : ""
                                            }`} id="govtid" placeholder="Enter Aadhar/PAN" name="govt_id" onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.govt_id} />
                                        {formik.touched.govt_id && typeof formik.errors.govt_id === 'string' ? (
                                            <div className="invalid-feedback">{formik.errors.govt_id}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="mt-3 sticky-button row justify-content-center">
                                    <div className="col-lg-2 col-md-2 col-sm-6">
                                        <button type="submit" className=" btn form-control btn-purple btn-md" disabled={loading}>
                                            {loading ? 'Updating...' : 'Update'}
                                        </button>
                                    </div>
                                </div>
                                {/* <b className={status ? "text-success" : "text-danger"}>{msg}</b> */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div >


    </>
}



